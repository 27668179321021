@if(checklistData && checklistData.length > 0) {
@for(checkList of checklistData; track $index) {
<div class="mb-2">
    <nz-collapse [nzExpandIconPosition]="'end'"
        [ngClass]="checkList.isCompleted ? 'completed completed-ant-collapse' : 'pending pending-ant-collapse'">
        <nz-collapse-panel [nzActive]="isPanelActive(checkList)" [nzHeader]="associationHeader">

            <ng-template #associationHeader>
                <div class="checklist-header-item">
                    <div>
                        @if(checkList.isCompleted) {<i class="fa fa-check-circle medium-icon-size"
                            aria-hidden="true"></i> }
                        {{checkList.checklistName}}
                    </div>
                    <div>
                        {{checkList.totalItemsCompleted +'/' +checkList.children.length}} Completed
                    </div>
                </div>
            </ng-template>

            @for(categories of checkList.children; track $index) {
            <nz-collapse [nzExpandIconPosition]="'end'"
                [ngClass]="categories.isCompleted ? 'completed completed-ant-collapse' : 'pending pending-ant-collapse'">
                <nz-collapse-panel [nzActive]="isPanelActive(categories)" [nzHeader]="categoryHeader">

                    <ng-template #categoryHeader>
                        <div class="checklist-header-item">
                            <div>
                                @if(categories.isCompleted) {<i class="fa fa-check-circle medium-icon-size"
                                    aria-hidden="true"></i> }
                                {{categories.checklistName}}
                            </div>
                            <div>
                                {{categories.totalItemsCompleted +'/' +categories.totalItems}} Completed
                            </div>
                        </div>
                    </ng-template>

                    @for(checklistItem of categories.items; track $index) {
                    <div class="mb-3 checklist-question">
                        <div class="question-text">
                            @if(checklistItem.checkListStatus && checklistItem.checkListStatus === 'C') {<i
                                class="primary-icon fa fa-check-circle medium-icon-size" aria-hidden="true"></i> }
                            {{checklistItem.question}}?
                        </div>
                        @switch(checklistItem.fieldType) {
                        @case ("radio"){
                        <div class="question-body">
                            <nz-radio-group [(ngModel)]="checklistItem.value" class="ppl-radio-group" [disabled]="checklistItem.readonly">
                                @for(opt of radioOptions; track $index){
                                <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                                }
                            </nz-radio-group>
                        </div>
                        }
                        @case ("text"){
                        <div class="question-body">
                            <input nz-input [(ngModel)]="checklistItem.value" type="{{checklistItem.dataType}}" [disabled]="checklistItem.readonly"
                                placeholder="{{checklistItem.question}}" />
                        </div>
                        }
                        @case ("label"){
                        <div class="question-body">
                            {{checklistItem.value}}
                        </div>
                        }
                        @case ("dropdown") {
                        <div class="question-body">
                            <nz-select class="ppl-select" [(ngModel)]="checklistItem.value" [disabled]="checklistItem.readonly"
                                nzPlaceHolder="{{checklistItem.question}}" nzShowSearch nzAllowClear>
                                @for(opt of checklistItem.lookupValue; track $index){
                                <nz-option [nzLabel]="opt.value" [nzValue]="opt.key"></nz-option>
                                }
                            </nz-select>
                        </div>
                        }
                        @case ("date"){
                        <div class="question-body">
                            <nz-date-picker style="height: 32px;" [(ngModel)]="checklistItem.value" [disabled]="checklistItem.readonly"
                                [nzFormat]="'MM/dd/yyyy'"></nz-date-picker>
                        </div>
                        }
                        }

                        @if(checklistItem.showDateRange) {
                        <div class="question-body ml-4">

                            <nz-range-picker nzFormat="yyyy-MM-dd" [(ngModel)]="checklistItem.dateRange" [disabled]="checklistItem.readonly"
                                (ngModelChange)="onChange($event, checklistItem)"></nz-range-picker>
                        </div>

                        }

                        @if(checklistItem.formMasterId !== 0) {
                        <div class="question-form question-body ml-4">

                            <ppl-button label="View Form" icon="fa fa-file-text" [customClass]="'alt-button'"
                                [iconPosition]="'right'" (click)="handleCheckListClick(checklistItem)"></ppl-button>
                        </div>
                        }
                    </div>
                    }

                    @for(subCategories of categories.children; track $index) {
                    <nz-collapse [nzExpandIconPosition]="'end'"
                        [ngClass]="subCategories.isCompleted ? 'completed completed-ant-collapse' : 'pending pending-ant-collapse'">
                        <nz-collapse-panel [nzActive]="isPanelActive(subCategories)" [nzHeader]="subCategoryHeader">


                            <ng-template #subCategoryHeader>
                                <div class="checklist-header-item">
                                    <div>
                                        @if(subCategories.isCompleted) {<i class="fa fa-check-circle medium-icon-size"
                                            aria-hidden="true"></i> }
                                        {{subCategories.checklistName}}
                                    </div>
                                    <div>
                                        {{subCategories.totalItemsCompleted +'/' +subCategories.totalItems}}
                                        Completed
                                    </div>
                                </div>
                            </ng-template>

                            @for(checklistItem of subCategories.items; track $index) {
                            <div class="mb-3 checklist-question">
                                <div class="question-text">
                                    @if(checklistItem.checkListStatus && checklistItem.checkListStatus === 'C') {<i
                                        class="primary-icon fa fa-check-circle medium-icon-size" aria-hidden="true"></i>
                                    } {{checklistItem.question}}?
                                </div>
                                @switch(checklistItem.fieldType) {
                                @case ("radio"){
                                <div class="question-body">
                                    <nz-radio-group [(ngModel)]="checklistItem.value" class="ppl-radio-group" [disabled]="checklistItem.readonly">
                                        @for(opt of radioOptions; track $index){
                                        <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                                        }
                                    </nz-radio-group>
                                </div>
                                }
                                @case ("text"){
                                <div class="question-body">
                                    <input nz-input [(ngModel)]="checklistItem.value" type="{{checklistItem.dataType}}" [disabled]="checklistItem.readonly"
                                        placeholder="{{checklistItem.question}}" />
                                </div>
                                }
                                @case ("label"){
                                <div class="question-body">
                                    {{checklistItem.value}}
                                </div>
                                }
                                @case ("dropdown") {
                                <div class="question-body">
                                    <nz-select [(ngModel)]="checklistItem.value" class="ppl-select" [disabled]="checklistItem.readonly"
                                        nzPlaceHolder="{{checklistItem.question}}" nzShowSearch nzAllowClear>
                                        @for(opt of checklistItem.lookupValue; track $index){
                                        <nz-option [nzLabel]="opt.value" [nzValue]="opt.key"></nz-option>
                                        }
                                    </nz-select>
                                </div>
                                }
                                @case ("date"){
                                <div class="question-body">
                                    <nz-date-picker [(ngModel)]="checklistItem.value" style="height: 32px;" [disabled]="checklistItem.readonly"
                                        [nzFormat]="'yyyy-MM-dd'"></nz-date-picker>
                                </div>
                                }
                                }

                                @if(checklistItem.showDateRange) {
                                <div class="question-body ml-4">
                                    <nz-range-picker nzFormat="yyyy-MM-dd" [(ngModel)]="checklistItem.dateRange" [disabled]="checklistItem.readonly"
                                        (ngModelChange)="onChange($event, checklistItem)"></nz-range-picker>
                                </div>

                                }

                                @if(checklistItem.formMasterId !== 0) {
                                <div class="question-form question-body ml-4">

                                    <ppl-button label="View Form" icon="fa fa-file-text" [customClass]="'alt-button'"
                                        [iconPosition]="'right'"
                                        (click)="handleCheckListClick(checklistItem)"></ppl-button>
                                </div>
                                }
                            </div>
                            }
                        </nz-collapse-panel>
                    </nz-collapse>
                    <span class="mt-1">&nbsp;</span>
                    }
                </nz-collapse-panel>
            </nz-collapse>
            <span class="mt-1">&nbsp;</span>
            }
        </nz-collapse-panel>
    </nz-collapse>
</div>
}
}
@else if (showNoRecordsMesssage){
<nz-alert class="mb-2" nzType="info" [nzMessage]="message" nzShowIcon></nz-alert>
}