import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { Table, TableModule } from 'primeng/table';
import { TableRowActionsModel, ButtonConfig, ColumnType, FilterRuleType, FilterType, ListConfigData, ListGridRequestPayload, RefrralHeaderType, SearchParams } from '../../interfaces/list.interface';
import { NotificationSMSComponent } from '../../modals/notification-sms/notification-sms.component';
import { ErrorAlertService } from '../../services/error-alert.service';
import { ListService } from '../../services/list.service';
import { SharedDataService } from '../../services/shared-data-service';
import { ActionsComponent } from '../actions/actions.component';
import { ButtonComponent } from "../button/button.component";
import { PopupDialogComponent } from "../popup-dialog/popup-dialog.component";
import { GridAction } from './grid-action.model';
import { listablecongfig } from './listable-constant';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';
import { NzAriaLabelDirective } from '../../directives/nz-aria-label.directive';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BooleanYesNoPipe } from "../../pipes/boolean-yes-no.pipe";

@Component({
  selector: 'ppl-list-table',
  standalone: true,
  providers: [ListService],
  imports: [TableModule, CommonModule, ReactiveFormsModule, FormsModule, RadioButtonModule, ButtonModule, NgZorroModule, ActionsComponent,
    NotificationSMSComponent, ButtonComponent, PopupDialogComponent, IconFieldModule, InputIconModule, NzAriaLabelDirective, BooleanYesNoPipe],
  templateUrl: './list-table.component.html',
  styleUrl: './list-table.component.css'
})
export class ListTableComponent implements OnInit, OnChanges {


  @ViewChild('inputRef') inputRef!: ElementRef;
  @ViewChild('dt2') dt!: Table;
  @Input() showCustomSearch = true;
  @Input() data: any[] = [];
  @Input() cols: ColumnType[] = [];
  @Input() lineItemCols: ColumnType[] = [];
  @Input() globalFilterField: any[] = [];
  @Input() disableSort = false;
  @Input() disableSearch = false;
  @Input() disableSelection = false;
  @Input() enableActions = false;
  /**
   * For lazy loading pagination, loadPage = true
   * For eager loading pagination, loadPage = false, rowsToDisplay = x, pagination = true
   */
  @Input() pagination = true;
  @Input() rowsToDisplay = 5;
  @Input() loadPage = true;
  @Input() jsonList = '';
  @Input() sharedDataKey = '';
  @Input() columnsToBeHidden: string[] = [];
  @Input() actionsToBeHidden: string[] = [];
  @Input() isVerifiedFlag = false;

  @Input() actionButtons: TableRowActionsModel | null | undefined = null;
  public panelWidth = 1024;
  protected filterTypes: FilterType[] = [];
  button: any[] = [];
  @Input() pageTitle: string | undefined;
  @Input() gridTitle = true;
  @Input() showLineItem = false;
  @Input() pageConfig = "";
  @Input() from = "";
  @Output() gridAction = new EventEmitter<GridAction>();
  @Output() rowDetailEvent = new EventEmitter<any>();
  @Input() isProvider? :boolean = false;
  @Input() tableWidth : any;
  @Input() tableScrollable = true;
  @Input() tableScrollHeight?: string = undefined;

  // What field from the listData / rowData primarily identifies it? eg. id, provider-ppl-id, tracking_number, etc.
  @Input() rowDataAccessibilityKey = '';

  protected globalFilterColumns: any[] = [];
  protected filterRules: FilterRuleType[] = []
  protected listData: any[] = [];
  protected filteredData: any[] = [];
  protected restoreHeaderData: RefrralHeaderType[] = [];
  protected countdisplay_N = 0;
  protected isApplyBtnVisible = false;
  protected globalFilterfield: string[] = [];
  protected totalRecords = 0;
  protected pageSize: number = listablecongfig.pageSize;
  protected intialLoad = true;
  protected pagesLoaded = 0;
  protected sortByOptions: FilterType[] = listablecongfig.sortByOptions;
  protected sortBy!: string;
  protected columnName!: string;
  protected columnsDropdownOptions!: any[];
  protected lastLoadedPage = 0;
  protected isBackwardNavigation = false;
  private nextRefreshPage = 1;
  private recordsPerPage = 20;
  selectedRow: any[] = [];
  protected condition = 'and';
  protected conditionType: FilterType[] = listablecongfig.conditonType;
  protected listButton: ButtonConfig[] = [];
  protected listConfigData!: ListConfigData;
  protected filterHeaders: RefrralHeaderType[] = [];
  isVisible = false;
  title = '';
  okText = '';
  placeholder = '';
  isAssignToUser = false;
  listOfOption: string[] = [];
  gridTextSearchFilter: any = '';
  gridSearchData: any[] = [];
  isFilterApplied = false;
  selectedColumnFilters: Record<number, string> = {};
  isLoadedDataFromSharedService = false;
  selectedCheckBoxValue: any[] = [];
  showSMSModal: any = '';
  selectedProgram !: any;
  notificationType: any;
  filterError: any;
  showSortByError: boolean =false;
  show = false;
  parentUrlSubscription: Subscription = new Subscription();
  isCreationFlow: boolean = false;
  first = 0;

  constructor(private listService: ListService, private changeDetectRef: ChangeDetectorRef,
    private i18n: NzI18nService, private sharedDataService: SharedDataService,
    private errorAlertService: ErrorAlertService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {

    this.selectedProgram = JSON.parse(localStorage.getItem("selected_program")!) as any;
    console.log(this.jsonList, "*****", this.title, 'json&title', this.listData)
    this.i18n.setLocale(en_US);
    if (this.data) {
      console.log('cols', this.cols);

      this.init();
    }

    const appliedFilters = this.sharedDataService.getData(this.sharedDataKey);
    if (this.sharedDataKey && appliedFilters) {
      this.isLoadedDataFromSharedService = true;
      this.reloadDataFromSharedService(appliedFilters);
    } else {
      this.addRule();
    }

    if (this.route.parent) {
      this.parentUrlSubscription = this.route.parent.url.subscribe(data => this.isCreationFlow = data[0].path === 'newReferral');
    }
  }

  ngOnDestory() {
    this.parentUrlSubscription.unsubscribe();
  }

  setPanelWidth() {
    const inputWidth = this.inputRef?.nativeElement?.offsetWidth;
    this.panelWidth = inputWidth;

  }

  leadUpdated(event: any) {
    if (event) {
      const payload = this.buildRequestPayload();
      this.refetchDataAfterUpdate(payload);
      this.selectedCheckBoxValue = [];
      this.gridTextSearchFilter = '';
      this.first = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pageConfig']) {
      this.initHeader();
    }
    if (changes['data']) { // refresh grid when the external data input changes
      this.refreshGridData();
      this.changeDetectRef.detectChanges();
    }
    if (changes['rowsToDisplay']) {
      this.pageSize = this.rowsToDisplay;
    }
  }

  isPaginationVisible() {
    if (this.pagination) {
      return true
    }
    if (this.gridTextSearchFilter.length > 0 && this.gridSearchData.length > this.pageSize) {
      return true
    }
    if (this.gridTextSearchFilter.length == 0 && this.listData.length > this.pageSize) {
      return true;
    }
    return false;
  }

  init() {
    this.filteredData = [...this.data];
    this.globalFilterColumns = this.setGlobalFilterColumn();
  }

  refreshGridData() {
    this.filteredData = this.data ? [...this.data] : [];
  }

  setGlobalFilterColumn() {
    if (this.data[0]) {
      const dataKeys: any[] = Object.keys(this.data![0]);
      console.log(dataKeys)
      const columnValues = dataKeys?.map((key: string) => {
        console.log(key.charAt(0).toUpperCase() + key.slice(1).toLowerCase())
        return { label: key.charAt(0).toUpperCase() + key.slice(1).toLowerCase(), value: key }
      })
      return columnValues;
    }
    else {
      return [];
    }

  }

  initHeader() {
    this.listConfigData = this.listService.getListConfigData(this.pageConfig, this.jsonList);
    if (this.listConfigData?.columns && this.columnsToBeHidden && this.columnsToBeHidden.length > 0) {
      this.listConfigData.columns = this.getAuthorizedColumns(this.listConfigData.columns);
    }

    if (this.listConfigData?.columns) {
      this.restoreHeaderData = JSON.parse(JSON.stringify(this.listConfigData?.columns));
      this.listButton = this.getAuthorizedButtons(this.listConfigData?.buttons);
      this.setDefaultHeader();
      this.columnsDropdownOptions = this.getAvailableColumn();
      this.globalFilterfield = this.extractGlobalFilterField();
    }
  }

  getAuthorizedColumns(columns: RefrralHeaderType[]): RefrralHeaderType[] {
    return columns.filter(c => this.columnsToBeHidden.find(hc => hc === c.header_id) === undefined);
  }

  getAuthorizedButtons(buttons: ButtonConfig[]): ButtonConfig[] {
    return buttons.filter(c => this.actionsToBeHidden.find(hc => hc === c.button_id) === undefined);
  }

  extractGlobalFilterField() {
    const fields = this.listConfigData.columns.filter((el: RefrralHeaderType) => el.isSearchable === 'y').
      map(res => res.jsonpath);
    return fields;
  }

  updateFilterTypes(rule: any) {
    rule.filtertype = '';
    rule.value = '';
    this.filterTypes = [
      { label: 'Equal', value: 'Equal' },
      { label: 'Not Equal', value: 'NOTEQUAL' },
      { label: 'Greater than', value: 'greaterthan' },
      { label: 'Less than', value: 'LESSTHAN' }
    ];
    if (!rule.column.toLowerCase().includes('date')) {
      this.filterTypes.push(
        { label: 'Contains', value: 'contains' },
        { label: 'Ends with', value: 'endsWith' },
        { label: 'Starts with', value: 'startswith' },
        { label: 'Greater than Equal To', value: 'GREATERTHANOREQUAL' },
        { label: 'Less than Equal To', value: 'LESSTHANOREQUAL' }
      );
    }
  }

  onCheckBoxHandler(event: any, rowData: any) {
    console.log(event);
    console.log(rowData);
  }


  getCounterOfDisplayNHeader() {
    this.filterRules.forEach(rule => {
      const matchingHeader = this.listConfigData.columns.find((header: RefrralHeaderType) =>
        header.jsonpath === rule.column
      )
      if (matchingHeader?.isDisplayedAtHeader == 'n') {

        this.countdisplay_N++;
      }
    })
  }

  sendSMS() {
    this.showSMSModal = 'sms'
  }

  onHandleCancel(event: any) {
    this.showSMSModal = event;
  }

  addRule() {
    if (this.filterRules.length < 8) {
      this.filterRules.push({ column: '', filtertype: '', value: '', isApplied: false });
    }
    console.log('filterRules', this.filterRules)
  }

  removeRule(index: number) {
    if (this.filterRules.length > 1) {
      this.filterRules.splice(index, 1);
    }
  }

  getAvailableColumnForSort() {
    return this.listConfigData?.columns?.filter(header => header.isSearchable === 'y');
  }

  getAvailableColumn() {
    return this.listConfigData?.columns
      ?.filter(header => header.isSearchable === 'y')
      .map(header => 
        header.header_id.toLowerCase().includes('date')
          ? { ...header, header_label: `${header.header_label} (in MM/DD/YYYY)` }
          : header
      );
  }

  getAvailableColumnsByFilter(index: number) {
    const selectedColumnFilterSet = new Set(Object.values(this.selectedColumnFilters));
    const currentSelection = this.selectedColumnFilters[index];
    const selectedColumn = this.filterRules.map((filter, i) => i === index ? filter.column : '');
    if (!currentSelection && selectedColumn && selectedColumn.length > 0 && selectedColumn[index] && !selectedColumnFilterSet.has(selectedColumn[index])) {
      this.selectedColumnFilters[index] = selectedColumn[index].toString();
    }
    const transfromData = this.listConfigData?.columns?.filter(header => header.isSearchable === 'y' && (!selectedColumnFilterSet.has(header.jsonpath) || header.jsonpath === currentSelection));
    return transfromData;
  }

  setDefaultHeader() {
    let count = 1;
    this.filterHeaders = [];
    this.filterHeaders = this.listConfigData.columns.filter((header: RefrralHeaderType) => header.isDisplayedAtHeader === 'y');
    this.filterRules.forEach((filter: FilterRuleType) => {
      const header = this.listConfigData.columns.find((h: RefrralHeaderType) => h.jsonpath === filter.column);
      if (header && header.isDisplayedAtHeader == 'n' && count < 4) {
        console.log('count', count)
        count++;
        this.filterHeaders.push(header);
      }
    })

    this.cols = this.filterHeaders?.map((el: RefrralHeaderType) => {
      return { field: el.jsonpath, header: el.header_label }
    })

  }

  gridActionClicked(btn: any, item: any) {
    this.gridAction.emit({ "action": btn, "data": item });
  }

  rowClicked(event: any) {
    if (this.showLineItem) {
      event.showLineItem = event.showLineItem ? false : true; 
    } else {
      this.rowDetailEvent.emit(event);
    }
  }

  clearNotApplicableFilterRules() {
    this.filterRules = this.filterRules?.filter(e => e.isApplied);
    if (this.filterRules.length == 0) {
      this.addRule();
    }

    if (!this.isFilterApplied) {
      this.sortBy = '';
      this.columnName = '';
      this.condition = 'and';
    }
  }

  calculatePagination(payload: ListGridRequestPayload) {
    const page = parseInt(payload.startingPageNumber + '');
    this.lastLoadedPage = page;
    if (this.filteredData.length > 0) {
      this.recordsPerPage = 20;
      this.nextRefreshPage = page + 5;
      this.totalRecords = this.listData?.length + 1;
    } else {
      this.totalRecords = 0;
    }
    this.changeDetectRef.detectChanges();
  }

  refetchDataAfterUpdate(
    payload: ListGridRequestPayload,
    event?: any
  ) {
    this.loadDataFromAPI(payload, event, true);
  }

  loadDataFromAPI(
    payload: ListGridRequestPayload,
    event?: any,
    hasUpdatedEntry?: boolean,
  ) {
    this.listService.getListData(payload, this.from, hasUpdatedEntry).subscribe((data: any) => {
      console.log("Data",data);
      if (data?.responsemsg[0]?.code !== 'INTERNAL_SERVER_ERROR') {
        if (this.isFilterApplied && String(payload?.startingPageNumber) === '1') {
          this.listData = [...data.responsedata];

          if (this.sharedDataKey) {
            this.sharedDataService.setData(this.sharedDataKey, this.generateFilterObject(this.filterRules, this.columnName, this.sortBy, this.condition));
          }
        } else {
          if (parseInt(payload?.startingPageNumber + '') === 1) {
            this.listData = [];
          }
          this.listData = ([...this.listData, ...data.responsedata]);
        }
        this.setFilterData(this.listData, event?.first ?? 0);
        this.calculatePagination(payload);
        this.resetTableSortingState();
      }
    });
  }

  resetTableSortingState() {
    this.dt.sortOrder = 0;
    this.dt.sortField = null;
    this.dt.tableService.onSort(null);
  }

  setFilterData(data: any, recordsFrom: number) {
    this.filteredData = [...data.slice(recordsFrom, recordsFrom + this.recordsPerPage)];
  }

  validateFilterRules(): boolean {
    this.showSortByError = false;
    this.filterRules.forEach(e => {
      if (e.column === '' || e.column === null || e.filtertype === '' || e.filtertype === null || e.value === '' || e.value.trim() === '') {
        this.filterError = 'Please fix filter errors and proceed ';
        return;
      }
    });

    if (this.columnName && this.columnName.length > 0 && !this.sortBy) {
      this.showSortByError = true;
      this.filterError = 'Please Select Sort By';
    }

    return this.filterError ? true : false;
  }

  applyFilter() {
    this.isFilterApplied = false;
    this.filterError = '';
    /*  this.buildRequestPayload(); */
    if (this.validateFilterRules()) {
      return;
    }
    this.setDefaultHeader();
    this.show = false; // updated to new modal
    /* this.overlayPanel.hide(); */
    const payload = this.buildRequestPayload();
    if (this.filterRules.length > 0 && this.filterRules[0].column == '') {
      payload.searchParam = []
    }
    if (this.isFilterApplicable(payload.searchParam)) {
      this.isFilterApplied = true;
      this.filterRules?.forEach(e => {
        e.isApplied = true;
      });
    }

    if (String(payload.startingPageNumber) === '1') {
      this.first = 0;
    }

    this.loadDataFromAPI(payload);
  }

  private buildRequestPayload(event?: any) {
    const payload: ListGridRequestPayload = {
      pageName: this.listConfigData?.pageName,
      programCode: this.selectedProgram?.program_code,
      condition: this.condition ? this.condition : '',
      recordsPerPage: 20,
      //sortby : this.sortBy,
      startingPageNumber: event ? (event?.startingPageNumber).toString() : '1',
      searchParam: [],
      orderByColumn: this.columnName ? [this.columnName, ...this.listConfigData.orderByColumn] : this.listConfigData.orderByColumn,
      orderBy: this.sortBy ? [this.sortBy, ...this.listConfigData.orderBy] : this.listConfigData.orderBy,
    }
    payload.searchParam = this.filterRules?.filter(e => e.column != '' && e.filtertype != '' && e.value != '' && e.value.trim() != '').map((el: FilterRuleType) => {
      return { jsonPath: el.column, value: el.value.trim(), operator: el.filtertype }
    });
    return payload;
  }

  clearAllFilters() {
    this.filterRules = [{
      column: '',
      filtertype: '',
      value: '',
      isApplied: false
    }];
    this.sortBy = '';
    this.columnName = '';
    this.selectedColumnFilters = {};
    this.condition = 'and';
    if (this.sharedDataKey) {
      this.sharedDataService.clearData(this.sharedDataKey);
    }
  }

  onCancel() {
    /* this.overlayPanel.hide(); */
    this.show = false; // updated to new modal
    this.gridTextSearchFilter = '';
    const payload = this.buildRequestPayload();
    payload.searchParam = [];
    payload.orderByColumn = this.listConfigData.orderByColumn;
    payload.orderBy = this.listConfigData.orderBy
    this.isFilterApplied = false;
    this.selectedCheckBoxValue = [];
    this.clearAllFilters();
    this.loadDataFromAPI(payload);
    this.dt.reset();
  }

  // sendNotification(type: string) {
  //   const values = this.selectedCheckBoxValue;
  //   this.listService.setApplicationData(values);
  //   this.notificationType = type == 'sms' ? 'mobile number' : 'email address';
  //   if (!values.length) {
  //     const errorMessage = `Please select a record to proceed with this action.`;
  //     this.errorAlertService.openModal('error', 'Error', errorMessage, '');
  //   } else {
  //     this.showSMSModal = 'sms';
  //   }
  // }

  loadRecords(event?: any) {
    console.log("EVENT!!!!!", event);
    if (this.data.length > 0 || this.isLoadedDataFromSharedService || this.disableSort) {
      return
    }
    const page = Math.floor(event.first / event.rows) + 1;
    const apiTriggerPage = page >= this.nextRefreshPage;
    if (apiTriggerPage) {
      event = { ...event, startingPageNumber: page };
      const payLoad = this.buildRequestPayload(event);
      console.log("payload===", payLoad);
      this.loadDataFromAPI(payLoad, event);
    } else {

      let resData: any[] = []
      if (this.gridTextSearchFilter.length === 0) {
        resData = [...this.listData]
      } else {
        resData = [...this.gridSearchData];
      }
      if (resData.length > 0) {
        this.setFilterData(resData, event.first);
        this.changeDetectRef.detectChanges();
      }
    }

  }

  sortByColumn(event: any) {
    const sortData = (data: any) => {
      data.sort((a: any, b: any) => {
        const valueA = a[event.field]?.toLowerCase() ? a[event.field]?.toLowerCase() : a[event.field];
        const valueB = b[event.field]?.toLowerCase() ? b[event.field]?.toLowerCase() : b[event.field];

        const isValue1Empty = valueA === null || valueA === '';
        const isValue2Empty = valueB === null || valueB === '';
        if (isValue1Empty && isValue2Empty) return 0; //both values are empty
        if (isValue1Empty) return 1; //place empty values at the end
        if (isValue2Empty) return -1; //place empty values at the end

        let result;
        if (Date.parse(valueA) && Date.parse(valueB)) {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);
          result = dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
        }
        else {
          result = valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        }
        return event.order === 1 ? result : -result;
      })
    }

    if (event.field) {
      sortData(this.listData);
    }

    if (this.gridTextSearchFilter.length > 0) {
      sortData(this.gridSearchData);
      return this.filteredData = [...this.gridSearchData]
    }

    return this.filteredData = [...this.listData]
  }


  onColumnFilter(event: any) {
    let filtered: any[] = [...this.listData];
    if (event.filters) {
      Object.keys(event.filters).forEach(key => {
        const filterValue = event.filters![key][0].value?.toLowerCase();
        const matchMode = event.filters![key][0].matchMode || 'contains';
        if (filterValue) {
          filtered = filtered.filter((item: any) => {

            const itemValue = item[key]?.toString().toLowerCase();
            console.log(itemValue, 'itemValue')
            switch (matchMode) {
              case 'startsWith':
                console.log(matchMode, itemValue)
                return itemValue.startsWith(filterValue);

              case 'contains':
                return itemValue.includes(filterValue);

              case 'notContains':
                return !itemValue.includes(filterValue)

              case 'endsWith':
                return itemValue.endsWith(filterValue);

              case 'equals':
                return itemValue === filterValue;

              case 'notEquals':
                return itemValue !== filterValue

              default: return true

            }
          })
        }
      });
    }

    return this.filteredData = [...filtered];

  }

  onSearch(event: any) {
    const searchKey = event?.trim();
    this.gridSearchData = [...this.listData];

    if (searchKey?.length > 0) {
      this.gridSearchData = this.gridSearchData.filter(item => {
        return this.cols.some(field =>
          item[field.field]?.toString()?.toLowerCase()?.includes(searchKey.toLowerCase())
        );
      });
    }

    this.totalRecords = this.gridSearchData.length;
    return this.filteredData = [...this.gridSearchData];
  }

  selectAll(event: any) {
    const filtered: any[] = [...this.listData];
    this.selectedRow = filtered.map(data => {
      const selected = { ...data, isSelected: event.checked }
      return selected
    });
    return this.selectedRow;
  }

  isFilterApplicable(searchParams: SearchParams[]) {
    if (searchParams.length > 0 || (this.columnName && this.sortBy)) {
      return true;
    }

    return false;
  }

  generateFilterObject(filterRules: FilterRuleType[], columnName: string, sortBy: string, condition: string): any {
    return {
      filterRules: filterRules,
      columnName: columnName,
      sortBy: sortBy,
      condition: condition,
    };
  }

  reloadDataFromSharedService(appliedFilters: any) {
    this.filterRules = appliedFilters.filterRules;
    this.columnName = appliedFilters.columnName;
    this.sortBy = appliedFilters.sortBy;
    this.condition = appliedFilters.condition;
    this.isFilterApplied = true;
    const payload = this.buildRequestPayload();
    this.loadDataFromAPI(payload);
  }

  getErrorStatus(hasError: boolean) {
    return hasError ? 'error' : ''
  }
}