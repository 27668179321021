import { Component, OnInit } from '@angular/core';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { CheckListService } from '../../../../services/check-list.service';
import { ChecklistResponse } from '../../../../interfaces/checklist.model';
import { ChecklistHelperService } from '../../../../services/checklist.helper.service';
import { NzAlertComponent } from 'ng-zorro-antd/alert';
import { EnrollmentDataService } from '../../../../services/enrollment-data.service';
import { PROVIDER_TYPES } from '../../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-to-do-list',
  standalone: true,
  imports: [AccordionComponent, NzAlertComponent],
  templateUrl: './to-do-list.component.html',
  styleUrl: './to-do-list.component.css'
})
export class ToDoListComponent implements OnInit {
  program: any;
  userId?: string | null;
  checkListData: any = [];
  isParticipantFlow = false;
  entry: string | null = '';
  prvdrAssociations: any = [];
  associationDetailsData: any = {};

  constructor(private checkListService: CheckListService, private checklistHelperService: ChecklistHelperService, private enrollmentDataService: EnrollmentDataService,) { }

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.userId = localStorage.getItem('selected_lead');
    this.isParticipantFlow = this.entry == 'participantEnrollment';
    this.getChecklist();

    if (!this.isParticipantFlow) {
      this.enrollmentDataService.getParticipantAssocChanged().subscribe(() => {
        this.getAssociationDetails();
      })
    }
  }

  getChecklist() {
    this.checkListService.getChecklist(this.program?.program_code, this.userId || '', this.isParticipantFlow).subscribe((data: any) => {
      if (data.responsedata) {
        const checklist: ChecklistResponse = data.responsedata
        if (checklist && checklist.checkList && checklist.checkList.length > 0) {
          this.checkListData = this.checklistHelperService.buildToDoChecklist(checklist.checkList.filter(e => e.checkListStatus == 'P'), this.isParticipantFlow, true);
        }
      }
    });
  }


  getAssociationDetails() {
    const isParticipant = this.entry === 'participantEnrollment';
    this.enrollmentDataService.getAssociationsDetails(this.program.program_code, this.userId || '', isParticipant).subscribe((response: any) => {
      console.log(response.responsedata);
      const data = response.responsedata;
      if (isParticipant) {
        for (const key in PROVIDER_TYPES) {
          this.associationDetailsData[key] = data[key] || [];
        }
      } else {
        this.prvdrAssociations = data.participantAssociations.filter((e: any) => e.checklistStatus == 'P' && e.associationStatus != 'Disassociated');
        console.log('this.prvdrAssociations', this.prvdrAssociations);
      }
    });
  }
}
