<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            File Triage
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table
                      [pageConfig]="programName"
                      [sharedDataKey]="sharedDataKey"
                      (rowDetailEvent)="rowClicked($event)"
                      [loadPage]="lazyLoad"
                      [columnsToBeHidden]="columnsToBeHidden"
                      [actionsToBeHidden]="actionsToBeHidden"
                      [jsonList]="'list_file_triage'"
                      rowDataAccessibilityKey="fileName"
                      [showCustomSearch] = "false"
                    />
                </div>
            </div>
        </div>
    </div>

    <ng-template #dialogHeader>
        <section id="fileTriagePreviewHeader">
            @if (selectedRow()) {
                <div>{{ selectedRow()?.fileName }}</div>
            }
            <ppl-button 
            (handleClick)="assignPreviewFile()" 
            [label]="'Assign'" 
            class="mr-1"
            customClass="primary-button"
            ></ppl-button>
        </section>
    </ng-template>

    <ng-template #dialogContent let-confirm="confirm" let-cancel="cancel">
        @if (pdfFile) {
            <section id="fileTriagePreview">
                <ppl-editpdf [readonly]="true" [pdfFile]="pdfFile"></ppl-editpdf>
            </section>
        }
    </ng-template>

    <ng-template #dialogContent2>
      <ppl-search-and-select-provider 
        (cancel)="onCancelAssignment()"
        (providerSelected)="handleAddProvider($event)"
        (participantSelected)="handleAddParticipant($event)"
      />
    </ng-template>
    }

</ng-container>