{
    "sectionId": "authorizations",
    "header": "Authorizations",
    "available_actions": null,
    "attributes": [
      {
        "attribute_ID": "consumerFirstName",
        "fieldName": "consumerFirstName",
        "label": "First Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter consumer first name",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "consumerLastName",
        "fieldName": "consumerLastName",
        "label": "Last Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter consumer Last Name",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "medicaid",
        "fieldName": "medicaid",
        "label": "CIN Number (Medicaid ID)",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter your Medicaid Id #",
        "isAvailable": "Y",
        "placeholder": "CIN Number"
      },
      {
        "attribute_ID": "consumerPPLId",
        "fieldName": "consumerPPLId",
        "label": "PPL ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter PPl Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "clientAuth",
        "fieldName": "clientAuth",
        "label": "Authorization number",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Client Auth Number",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "serviceCode",
        "fieldName": "serviceCode",
        "label": "Service Code",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Service Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "diagnosisId",
        "fieldName": "diagnosisId",
        "label": "Primary Diagnosis code",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Primary Diagnosis Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "primaryDiagnosisId1",
        "fieldName": "primaryDiagnosisId1",
        "label": "Primary Diagnosis code 2",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Primary Diagnosis Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "primaryDiagnosisId2",
        "fieldName": "primaryDiagnosisId2",
        "label": "Primary Diagnosis code 3",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Primary Diagnosis Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "primaryDiagnosisId3",
        "fieldName": "primaryDiagnosisId3",
        "label": "Primary Diagnosis code 4",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Primary Diagnosis Code",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "authFrequencyLkp",
        "fieldName": "authFrequencyLkp",
        "label": "Authorization Frequency Type",
        "type": "select",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Authorization Frequency Type",
        "isAvailable": "Y",
        "placeholder": "Select Authorization Frequency Type"
      },
      {
        "attribute_ID": "carryoverUnits",
        "fieldName": "carryoverUnits",
        "label": "Carry Forward Allowed",
        "type": "radio",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select if carry forward is allowed",
        "isAvailable": "Y",
        "placeholder": "Select"
      },
      {
        "attribute_ID": "dailyHours",
        "fieldName": "dailyHours",
        "label": "Daily hours",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter daily hours",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "sunHrs",
        "fieldName": "sunHrs",
        "label": "Sun",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Sunday",
        "isAvailable": "Y",
        "placeholder": "Sunday"
      },
      {
        "attribute_ID": "monHrs",
        "fieldName": "monHrs",
        "label": "Mon",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Monday",
        "isAvailable": "Y",
        "placeholder": "Monday"
      },
      {
        "attribute_ID": "tuesHrs",
        "fieldName": "tuesHrs",
        "label": "Tues",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Tuesday",
        "isAvailable": "Y",
        "placeholder": "Tuesday"
      },
      {
        "attribute_ID": "wedHrs",
        "fieldName": "wedHrs",
        "label": "Wed",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Wednesday",
        "isAvailable": "Y",
        "placeholder": "Wednesday"
      },
      {
        "attribute_ID": "thuHrs",
        "fieldName": "thuHrs",
        "label": "Thurs",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Thursday",
        "isAvailable": "Y",
        "placeholder": "Thursday"
      },
      {
        "attribute_ID": "friHrs",
        "fieldName": "friHrs",
        "label": "Fri",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Friday",
        "isAvailable": "Y",
        "placeholder": "Friday"
      },
      {
        "attribute_ID": "satHrs",
        "fieldName": "satHrs",
        "label": "Sat",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter hours for Saturday",
        "isAvailable": "Y",
        "placeholder": "Saturday"
      },
      {
        "attribute_ID": "hiosIdLkp",
        "fieldName": "hiosIdLkp",
        "label": "HIOS ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter HIOS Id",
        "isAvailable": "N"
      },
      {
        "attribute_ID": "planId",
        "fieldName": "planId",
        "label": "Plan ID",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Plan Id",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "serviceAdminLkp",
        "fieldName": "serviceAdminLkp",
        "label": "Service Administration",
        "type": "select",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please select Service Administration",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "modifierCode1",
        "fieldName": "modifierCode1",
        "label": "Modifier",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Modifier",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "units",
        "fieldName": "units",
        "label": "Units",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Units",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "unittypeLkp",
        "fieldName": "unittypeLkp",
        "label": "Unit Type",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Unit Type",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "startDate",
        "fieldName": "startDate",
        "label": "Auth Start Date",
        "type": "input",
        "mandatory": "Y",
        "validator": null,
        "tooltip": "Please enter Auth Start Date",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "endDate",
        "fieldName": "endDate",
        "label": "Auth End Date",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter Auth End Date",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "orgName",
        "fieldName": "orgName",
        "label": "MCO/Agency Name",
        "type": "input",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please enter MCO/Agency ID",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "source",
        "fieldName": "source",
        "label": "Auth Source",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Auth Source",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "statusLkp",
        "fieldName": "statusLkp",
        "label": "Auth Status",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Auth Status",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "sourceDetail",
        "fieldName": "sourceDetail",
        "label": "Source Detail",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Auth Status",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "decisionComments",
        "fieldName": "decisionComments",
        "label": "Decision Comments",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Auth Status",
        "isAvailable": "Y"
      },
      {
        "attribute_ID": "decisionBy",
        "fieldName": "decisionBy",
        "label": "Decision By",
        "type": "select",
        "mandatory": "N",
        "validator": null,
        "tooltip": "Please select Auth Status",
        "isAvailable": "Y"
      }
    ],
    "validations": [
      {
        "whatToCheck": "atLeastXEntered",
        "clientOrServer": "C",
        "attributes": [
          {
              "validatorid": 1,
              "attribute_ID": ["clientAuth"],
              "functionParams": {
                "ErrMsg": "Please enter Client Authorization Number."
              }
            },
            {
              "validatorid": 2,
              "attribute_ID": ["serviceCode"],
              "functionParams": {
                "ErrMsg": "Please select Service Code"
              }
            },
            {
              "validatorid": 3,
              "attribute_ID": ["diagnosisId"],
              "functionParams": {
                "ErrMsg": "Please select Primary Diagnosis Code"
              }
            },
            {
              "validatorid": 4,
              "attribute_ID": ["startDate"],
              "functionParams": {
                "ErrMsg": "Please select Start Date"
              }
            },
            {
              "validatorid": 5,
              "attribute_ID": ["serviceAdminLkp"],
              "functionParams": {
                "ErrMsg": "Please select Service Administration"
              }
            },
            {
              "validatorid": 6,
              "attribute_ID": ["authFrequencyLkp"],
              "functionParams": {
                "ErrMsg": "Please select Authorization Frequency Type"
              }
            }
      ]
      },
      {
        "whatToCheck": "checkFieldValue",
        "clientOrServer": "C",
        "attributes": []
      },
      {
        "whatToCheck": "conditionalCheck",
        "clientOrServer": "C",
        "attributes": []
      },
      {
        "whatToCheck": "customValidator",
        "clientOrServer": "C",
        "attributes": []
      }
    ],
    "actions": [
      {
        "Name": "Previous",
        "Validate": null
      },
      {
        "Name": "Submit",
        "Validate": [
          {
            "checkType": "atLeastXEntered",
            "validatorid": [1,2,3,4,5,6]
          },
          {
            "checkType": "checkFieldValue",
            "validatorid": []
          },
          {
            "checkType": "conditionalCheck",
            "validatorid": []
          },
          {
            "checkType": "customValidator",
            "validatorid": []
          }
        ]
      }
    ],
    "additionalDetails": {
      "sectionId": "authorizations",
      "header": "Authorizations",
      "available_actions": null,
      "attributes": [
        {
          "attribute_ID": "hhaId",
          "fieldName": "hhaId",
          "label": "HHA ID",
          "type": "input",
          "mandatory": "N",
          "validator": null,
          "tooltip": "Please enter consumer first name",
          "isAvailable": "Y"
        },
        {
          "attribute_ID": "hhaAuthId",
          "fieldName": "hhaAuthId",
          "label": "HHA Auth ID",
          "type": "input",
          "mandatory": "N",
          "validator": null,
          "tooltip": "Please enter consumer Last Name",
          "isAvailable": "Y"
        },
        {
          "attribute_ID": "consumerPlanId",
          "fieldName": "consumerPlanId",
          "label": "Consumer Plan ID",
          "type": "select",
          "mandatory": "N",
          "validator": null,
          "tooltip": "Please enter PPl Id",
          "isAvailable": "Y"
        },
        {
          "attribute_ID": "isDeleted",
          "fieldName": "isDeleted",
          "label": "Is Deleted",
          "type": "input",
          "mandatory": "Y",
          "validator": null,
          "tooltip": "Please enter Client Auth Number",
          "isAvailable": "Y"
        },
        {
          "attribute_ID": "comments",
          "fieldName": "comments",
          "label": "Comments",
          "type": "input",
          "mandatory": "Y",
          "validator": null,
          "tooltip": "Please select Service Code",
          "isAvailable": "Y"
        },
        {
          "attribute_ID": "medicaidId",
          "fieldName": "medicaidId",
          "label": "CIN Number (Medicaid ID)",
          "type": "input",
          "mandatory": "N",
          "validator": null,
          "tooltip": "Please enter your Medicaid Id #",
          "isAvailable": "Y",
          "placeholder": "CIN Number"
        }
      ],
      "validations": [
        {
          "whatToCheck": "atLeastXEntered",
          "clientOrServer": "C",
          "attributes": [ ]
        },
        {
          "whatToCheck": "checkFieldValue",
          "clientOrServer": "C",
          "attributes": []
        },
        {
          "whatToCheck": "conditionalCheck",
          "clientOrServer": "C",
          "attributes": []
        },
        {
          "whatToCheck": "customValidator",
          "clientOrServer": "C",
          "attributes": []
        }
      ],
      "actions": [
        {
          "Name": "Previous",
          "Validate": null
        },
        {
          "Name": "Submit",
          "Validate": [
            {
              "checkType": "atLeastXEntered",
              "validatorid": []
            },
            {
              "checkType": "checkFieldValue",
              "validatorid": []
            },
            {
              "checkType": "conditionalCheck",
              "validatorid": []
            },
            {
              "checkType": "customValidator",
              "validatorid": []
            }
          ]
        }
      ]
      }
  }
  