import {
  DeleteAssociationModel,
  PrtcpntPrvdrAssociationModel,
} from '../../../../shared/interfaces/participant-association.model';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  OnChanges,
  signal,
  WritableSignal,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { differenceInCalendarDays } from 'date-fns';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { LookupService } from '../../../../services/lookup.service';
import { SectionsService } from '../../../../services/sections.service';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import {
  ASSOCIATION_TYPE_UI_MAPPINGS,
  ASSOCIATIONS_ADD_BUTTON,
  RADIO_YES_NO_OPTIONS,
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { LoggerService } from '../../../../shared/services/logger.service';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { AssociationDetailsAddressComponent } from '../association-details-address/association-details-address.component';
import { EnrollmentDataService } from '../../../../services/enrollment-data.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { ProviderSearchAndSelectComponent } from '../../provider-search-and-select/provider-search-and-select.component';
import { AddAssociationFormComponent } from '../../add-association-form/add-association-form.component';
import { SearchProvidersDetailsItem } from '../../../../shared/interfaces/provider.model';
import { TableRowAction } from '../../../../shared/interfaces/list.interface';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';
import { DialogConfig, DialogService } from '../../../../shared/services/dialog.service';

enum Mode {
  Add = 'add',
  Edit = 'edit',
  Read = 'read',
  Search = 'search',
}
@Component({
  selector: 'ppl-association-details',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    CommonModule,
    NgZorroModule,
    ButtonComponent,
    ListTableComponent,
    AssociationDetailsAddressComponent,
    ProviderSearchAndSelectComponent,
    AddAssociationFormComponent
  ],
  templateUrl: './association-details.component.html',
  styleUrl: './association-details.component.css',
})
export class AssociationDetailsComponent
  extends BaseComponent
  implements OnInit, OnChanges {
  radioOptions = RADIO_YES_NO_OPTIONS;
  mode: Mode = Mode.Read;
  sectionDataPath = '';
  steps: any;
  entry = 'base';
  infoForm!: FormGroup;
  disabledDate!: (current: Date) => boolean;
  ssnVisible = false;
  gender: any[] = [];
  statesList: any;
  validationErrorsPermanentAdd: any = {};
  participantRegisterMode: any[] = [];
  addBtnLabel = '';
  program: any;
  formReadonly = false;
  disableSearch = false;

  @Input() sectionName = '';
  @Input() gridData: any;
  @Input() gridCols: any;
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() sectionId = '';

  actions: any = {
    view: true,
  };
  selectedAction: WritableSignal<TableRowAction | null> = signal(null);
  readOnly = false;
  relationshipOptions: any;
  eyeColors: any;
  hairColors: any;
  raceOptions: any;
  taxExemptOptions: any;
  isSectionEditable: boolean = false;
  userDetails: any;

  copyOfGridData: any = [
    {
      fname: 'Danny',
      lname: 'Rose',
      email: 'dannyrose@gmail.com',
      contactNumber: '7975453298',
    },
  ];

  selectedValue = null;
  listOfOptions: { value: string; text: string }[] = [];
  nzFilterOptions = (): boolean => true;
  fullAddress = '';
  licences: any[] = [];
  services: any[] = [];
  permanentAddressErrors: any = {};

  selectedProviderToAdd: WritableSignal<SearchProvidersDetailsItem | null> = signal(null);
  @ViewChild('confirmationDialog') confirmationDialog!: TemplateRef<any>;
  private dialogSvc = inject(DialogService);

  get providerType(): string {
    return ASSOCIATION_TYPE_UI_MAPPINGS[this.sectionName] ?? ''
  }

  constructor(
    private fb: FormBuilder,
    private logger: LoggerService,
    private sectionsService: SectionsService,
    private httpClient: HttpClient,
    private lookupService: LookupService,
    private enrollmentDataService: EnrollmentDataService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private authUtility: AuthorizationUtility
  ) {
    super();
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('user_details')!);
    if (this.userDetails?.usertype_lkp?.toLowerCase() == 'participant' || this.userDetails?.usertype_lkp?.toLowerCase() == 'provider') {
      this.disableSearch = true;
    }


    this.entry = localStorage.getItem('lead_entry')!;
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.sectionDataPath =
      STATIC_JSON_DATA_PATH.LEAD_DETAILS_PROVIDERS_SECTION_DATA;

    this.isSectionEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, this.sectionId);
    this.addBtnLabel = ASSOCIATIONS_ADD_BUTTON[this.sectionName];

    this.getSectionData();
    this.createForm();

    this.statesList = this.lookupService.getLookupValue('STATE');
    this.participantRegisterMode = this.getLookup('Participant_Register_Mode');
    this.relationshipOptions = this.getLookup('Relationship');
    this.eyeColors = this.getLookup('Eye Color');
    this.hairColors = this.getLookup('Hair Color');
    this.raceOptions = this.getLookup('Race');
    this.taxExemptOptions = this.getLookup('Tax Exemption');

    const today = new Date();
    this.disabledDate = (current: Date): boolean => {
      return differenceInCalendarDays(current, today) >= 0;
    };

    this.gender = this.getLookup('Gender');
    this.licences = this.getLookup('License_Type');
    this.services = this.getLookup('Service_Type');
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionName'] && changes['sectionName'].currentValue) {
        this.getSectionData();
      }
    } catch (e) {
      console.log('Error in getting section data and updating latest info', e);
    }
  }

  async getSectionData() {
    try {
      if (this.entry) {
        this.steps = await this.sectionsService.getJsonConfigurationData(
          this.sectionDataPath,
          this.entry,
          this.sectionName
        );

        this.secData = this.steps;
        /* if (this.sectionName === STATIC_DATA.NEW_EMPLOYER_INFO) {
          this.secData = this.steps[STATIC_DATA.NEW_EMPLOYER_INFO];
        } else if (this.sectionName === STATIC_DATA.AUTHORIZED_REPRESENTATIVE) {
          this.secData = this.steps[STATIC_DATA.AUTHORIZED_REPRESENTATIVE];
        } else if (this.sectionName === STATIC_DATA.INDEPENDENT_PROVIDER) {
          this.secData = this.steps[STATIC_DATA.INDEPENDENT_PROVIDER];
        } else if (this.sectionName === STATIC_DATA.NEW_VENDOR_DETAILS) {
          this.secData = this.steps[STATIC_DATA.NEW_VENDOR_DETAILS];
        } else if (
          this.sectionName === STATIC_DATA.INDEPENDENT_CONTRACTOR_DETAILS
        ) {
          this.secData = this.steps[STATIC_DATA.INDEPENDENT_CONTRACTOR_DETAILS];
        } else if (this.sectionName === STATIC_DATA.LEGAL_GUARDIAN) {
          this.secData = this.steps[STATIC_DATA.LEGAL_GUARDIAN];
        } else if (this.sectionName === STATIC_DATA.POWER_OF_ATTORNEY) {
          this.secData = this.steps[STATIC_DATA.POWER_OF_ATTORNEY];
        } */
      }
    } catch (e) {
      this.logger.debug('Error fetching section details data', e);
    }
  }

  createForm(): void {
    const formElements: any = {
      fname: new FormControl(''),
      lname: new FormControl(''),
      dob: new FormControl(null),
      email: new FormControl(''),
      mobile: new FormControl(''),
      permanentAddress: new FormGroup({
        leadId: new FormControl(),
        street1: new FormControl(''),
        street2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zipCode: new FormControl(''),
        countyOrRegion: new FormControl(''),
        psd_code: new FormControl(''),
        mailing: new FormControl(false),
      }),
    };

    if (
      this.sectionName === STATIC_DATA.ASSOCIATION_AUTHORIZED_REPRESENTATIVE
    ) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.relationToParticipant = new FormControl(null);
      formElements.additionalDemographics = new FormControl('');
      formElements.race = new FormControl('');
      formElements.heightFeet = new FormControl('');
      formElements.heightInches = new FormControl('');
      formElements.weight = new FormControl('');
      formElements.eyeColor = new FormControl('');
      formElements.hairColor = new FormControl('');
      formElements.birthPlace = new FormControl('');
      formElements.birthCity = new FormControl('');
      formElements.birthState = new FormControl('');
      formElements.birthCountry = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
      formElements.consent = new FormControl(false);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_PROVIDER) {
      formElements.mname = new FormControl('');
      formElements.maiden_name = new FormControl('');
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.relationToParticipant = new FormControl(null);
      formElements.NPI = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
      formElements.yearsInState = new FormControl('');
      formElements.emergencyContactFirstName = new FormControl('');
      formElements.emergencyContactLastName = new FormControl('');
      formElements.emergencyContactPhone = new FormControl('');
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_EMPLOYER) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR) {
      formElements.mname = new FormControl();
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
      formElements.taxExempt = new FormControl();
      formElements.nprftDocumentationReceived = new FormControl();
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_VENDOR) {
      formElements.businessName = new FormControl();
      formElements.taxExempt = new FormControl();
      formElements.nprftDocumentationReceived = new FormControl();
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.mname = new FormControl();
      formElements.altPhone = new FormControl('');
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR) {
      formElements.mname = new FormControl('');
      formElements.SSN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.taxExempt = new FormControl('');
      formElements.nprftDocumentationReceived = new FormControl('');
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_LEGAL_GUARDIAN) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_POWER_OF_ATTORNEY) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }

    this.infoForm = new FormGroup(formElements);
  }

  getFormGroup(controlName: string): FormGroup {
    return this.infoForm.get(controlName) as FormGroup;
  }

  addAssociation() {
    this.mode = Mode.Edit
  }

  searchAssociation(type: string) {
    this.mode = Mode.Search;
    this.sectionsService.setEnrollmentType(JSON.stringify([type]));
  }

  cancelEdit() {
    this.mode = Mode.Read;
    this.infoForm.reset();
    this.validationErrors = {};
    this.permanentAddressErrors = {};
    this.formReadonly = false;
    this.infoForm.enable();
    this.selectedProviderToAdd.set(null);
    this.readOnly = false;
  }

  save() {
    const formData = this.infoForm.value;

    try {
      const formErrors = performValidation(formData, this.secData, 'Next');
      delete formErrors.city;
      delete formErrors.countyOrRegion;
      delete formErrors.state;
      delete formErrors.street1;
      delete formErrors.zipCode;

      // this.permanentAddressErrors = this.getAddressValidations(
      //   formData.permanentAddress
      // ); //{street1, city, state, zipCode, countyOrRegion};
      this.validationErrors = { ...formErrors };
    } catch (e) {
      console.log('Error in validations', e);
    }

    if (Object.keys(this.validationErrors).length > 0) {
      return;
    } else {
      const payload = this.preperePayload();

      console.log('FINAL DATA', formData);

      this.enrollmentDataService
        .saveParticipantAssociations(payload, true)
        .subscribe((data: any) => {
          console.log('SAVE ASSOC', data);
          this.cancelEdit();
          this.enrollmentDataService.setParticipantAssocChanged(true);
        });
    }
  }

  action(event: any): void {
    this.logger.debug('Action', event);
    if (event.action === 'view') {
      this.mode = Mode.Edit;
      this.selectedAction.set(TableRowAction.View);
      this.readOnly = true;
      console.log(event.data);
      this.selectedProviderToAdd.set(event.data);
      this.formReadonly = true;
      this.infoForm.disable();
      // provider_id
      const payload = {
        programCode: this.program.program_code,
        providerId: event.data.providerId,
        providerType: null,
      };
      this.enrollmentDataService
        .fetchParticipantAssociations(payload)
        .subscribe((response) => {
          console.log('Edit response', response);
          const flattenedData = this.flattenResponse(response);
          console.log(flattenedData.responsedata);
          this.infoForm.patchValue(flattenedData.responsedata);
        });
    }
    if (event.action === 'disassociate') {
      this.openConfirmationPopup(event.data);
    }
  }

  disassociate(data: PrtcpntPrvdrAssociationModel){
      const payload: DeleteAssociationModel = {
        associationType: data.associationTypeCode,
        participantId: data.participantId,
        providerId: data.providerId,
        programCode: this.program.program_code,
      };

      /* this.mode = 'read'; */
      this.deleteAccociation(payload);
      /*  this.cancelEdit(); */
      this.enrollmentDataService.setParticipantAssocChanged(true);
  }

  deleteAccociation(payload: DeleteAssociationModel) {
    this.enrollmentDataService
      .deleteParticipantProviderAssociation(payload, true)
      .subscribe((response) => {
        console.log('Edit response', response);
      });
  }

  preperePayload() {
    const selectedId = localStorage.getItem('selected_lead');
    console.log(this.infoForm.value);
    const payload = {
      participantId: selectedId,
      providerId: 0,
      programCode: this.program.program_code,
      pageType: 'participantDetail',
      infoData: this.generateInfoDataPayload(),
      serviceDetails: this.generateServiceDataPayload(),
      communicationData: this.generateCommunicationDataPayload(),
      contactDetails: this.generateContactDataPayload(),
    };
    console.log('payload', payload);

    return payload;
  }

  generateInfoDataPayload() {
    const providerType = ASSOCIATION_TYPE_UI_MAPPINGS[this.sectionName];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { SSN, EIN, NPI, dob, permanentAddress, ...restInfoData } =
      this.infoForm.value;
    console.log('EIN', EIN);
    return {
      ...restInfoData,
      fname: restInfoData.fname.trim(),
      lname: restInfoData.lname.trim(),
      providerType: providerType,
      providerId: 0,
      // applicationStatus: 'In Progress',
      finalApplicantDisposition: 'Enrollment',
      programCode: this.program.program_code,
      dob: this.dateTimeFormatterService.formatDateToString(dob as Date),
      identifiers: this.generateIdentifiers(SSN, EIN, NPI),
    };
  }

  generateIdentifiers(SSN: string, EIN: string, NPI: string) {
    const identifiers = [
      SSN && { type: 'SSN', value: SSN },
      EIN && { type: 'EIN', value: EIN },
      NPI && { type: 'NPI', value: NPI },
    ].filter(Boolean);
    return identifiers;
  }
  generateContactDataPayload() {
    const {
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactPhone,
    } = this.infoForm.value;
    return {
      providerId: 0,
      programCode: this.program.program_code,
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactPhone,
    };
  }

  openConfirmationPopup(data: any) {
    const dialogConfig: DialogConfig = {
      title: 'Confirmation',
      isOkText: 'Ok',
      size: 'medium',
      position: 'top',
      showClose: true,
      showButtons: true,
    };

    this.dialogSvc.openDialog(
      this.confirmationDialog,
      dialogConfig,
      () => this.disassociate(data),
      () => this.handleCancel()
    );
  }

  handleCancel() {}

  generateCommunicationDataPayload() {
    const { permanentAddress, email, altPhone, mobile } = this.infoForm.value;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { leadId, mailing, ...restAddress } = permanentAddress;

    const contactMethods = [
      {
        providerId: 0,
        programCode: this.program.program_code,
        contactMethod: 'email',
        value: email,
      },
      {
        providerId: 0,
        programCode: this.program.program_code,
        contactMethod: 'mobile',
        value: mobile,
      },
    ];

    // Conditionally add altPhone if it is not null or an empty string
    if (altPhone) {
      contactMethods.push({
        providerId: 0,
        programCode: this.program.program_code,
        contactMethod: 'altPhone',
        value: altPhone,
      });
    }

    return {
      permanentAddress: {
        providerId: 0,
        programCode: this.program.program_code,
        mailing: true,
        ...restAddress,
      },
      contactMethods: contactMethods,
    };
  }

  generateServiceDataPayload() {
    const { registerPref, profLicences, servicesRendered } =
      this.infoForm.value;
    const profLicencesMOdified =
      profLicences &&
      profLicences.map((license: string) => ({ licenseType: license }));
    const servicesRenderedModified =
      servicesRendered &&
      servicesRendered.map((service: string) => ({ serviceType: service }));
    return {
      providerId: 0,
      programCode: this.program.program_code,
      profLicences: profLicencesMOdified,
      servicesRendered: servicesRenderedModified,
      registerPref,
    };
  }

  flattenResponse(data: any) {
    const flattened: any = { responsedata: {} };

    if (data.responsedata && data.responsedata.basicInfo) {
      Object.assign(flattened.responsedata, data.responsedata.basicInfo);
    }

    if (data.responsedata && data.responsedata.contactDetails) {
      Object.assign(flattened.responsedata, data.responsedata.contactDetails);
    }
    if (data.responsedata && data.responsedata.contactDetails) {
      const communicationData = data.responsedata.contactDetails || {};
      const contactMethods = communicationData.contactMethods || [];
      const contactValues = contactMethods.reduce((acc: any, item: any) => {
        acc[item.contactMethod] = item.value;
        return acc;
      }, {});

      Object.assign(flattened.responsedata, {
        altPhone: contactValues.altPhone || '',
        mobile: contactValues.mobile || '',
        email: contactValues.email || '',
      });
    }
    if (data.responsedata && data.responsedata.communicationPreferences) {
      Object.assign(
        flattened.responsedata,
        data.responsedata.communicationPreferences
      );
    }
    if (data.responsedata && data.responsedata.serviceDetails) {
      const profLicences: string[] =
        data.responsedata.serviceDetails?.profLicences?.map(
          (entry: any) => entry.licenseType
        ) || [];
      const servicesRendered: string[] =
        data.responsedata.serviceDetails?.servicesRendered?.map(
          (entry: any) => entry.serviceType
        ) || [];

      Object.assign(flattened.responsedata, {
        profLicences,
        servicesRendered,
      });
    }

    const identifiers = data.responsedata.basicInfo?.identifiers || [];
    const ssn =
      identifiers.find((item: any) => item.type === 'SSN')?.value || '';
    const ein =
      identifiers.find((item: any) => item.type === 'EIN')?.value || '';
    const NPI =
      identifiers.find((item: any) => item.type === 'NPI')?.value || '';
    Object.assign(flattened.responsedata, { SSN: ssn, EIN: ein, NPI: NPI });
    console.log('flattened data', flattened);

    return flattened;
  }

  getAddressValidations(permanentAddress: any) {
    // Initialize an empty object for permanentAddressErrors
    const permanentAddressErrors: any = {};

    const { street1, city, state, zipCode, countyOrRegion } = performValidation(
      permanentAddress,
      this.secData,
      'Next'
    );

    // Conditionally add keys to permanentAddressErrors if they have values
    if (street1) {
      permanentAddressErrors.street1 = street1;
    }
    if (city) {
      permanentAddressErrors.city = city;
    }
    if (zipCode) {
      permanentAddressErrors.zipCode = zipCode;
    }
    if (state) {
      permanentAddressErrors.state = state;
    }
    if (countyOrRegion) {
      permanentAddressErrors.countyOrRegion = countyOrRegion;
    }

    return permanentAddressErrors;
  }

  handleCancelSearch() {
    this.mode = Mode.Read;
    this.selectedProviderToAdd.set(null);
    this.enrollmentDataService.setParticipantAssocChanged(true);
  }

  handleProviderSelected(provider: SearchProvidersDetailsItem) {
    this.selectedProviderToAdd.set(provider);
    this.mode = Mode.Add;
  }

  handleFormUpdate(formGroup: FormGroup) {
    const formGroupValue = formGroup.value;
    this.infoForm.setValue({ ...formGroupValue });
  }

  handleAddNewProvider() {
    this.mode = Mode.Add;
    console.log("we are here------")
  }
}
