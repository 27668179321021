{
  "columns": [
    {
      "header_id": "clientAuth",
      "header_label": "Client Authorization Number",
      "jsonpath": "clientAuth",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "pplAuth",
      "header_label": "PPL Authorization Number",
      "jsonpath": "pplAuth",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "consumerFirstName",
      "header_label": "Consumer First Name",
      "jsonpath": "consumerFirstName",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "consumerLastName",
      "header_label": "Consumer Last Name",
      "jsonpath": "consumerLastName",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "medicaid",
      "header_label": "Medicaid Id",
      "jsonpath": "medicaid",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "consumerPPLId",
      "header_label": "Consumer PPL Id",
      "jsonpath": "consumerPPLId",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "serviceCode",
      "header_label": "HCPCS Service Code",
      "jsonpath": "serviceCode",
      "isDisplayedAtHeader": "n",
      "isSearchable": "y"
    },
    {
      "header_id": "serviceCodeDesc",
      "header_label": "HCPCS Service Code",
      "jsonpath": "serviceCodeDesc",
      "isDisplayedAtHeader": "n",
      "isSearchable": "n"
    },
    {
      "header_id": "modifier1",
      "header_label": "Modifier 1",
      "jsonpath": "modifier1",
      "isDisplayedAtHeader": "n",
      "isSearchable": "y"
    },
    {
      "header_id": "modifier1",
      "header_label": "Modifier 1",
      "jsonpath": "modifierCode1",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "header_id": "modifier2",
      "header_label": "Modifier 2",
      "jsonpath": "modifier2",
      "isDisplayedAtHeader": "n",
      "isSearchable": "y"
    },
    {
      "header_id": "modifier2",
      "header_label": "Modifier 2",
      "jsonpath": "modifierCode2",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "header_id": "units",
      "header_label": "Units",
      "jsonpath": "units",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
{
        "header_id": "utilizedUnits",
        "header_label": "Used Units",
        "jsonpath": "utilizedUnits",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
    {
      "header_id": "dollars",
      "header_label": "Dollars",
      "jsonpath": "dollars",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
{
        "header_id": "utilizedDollars",
        "header_label": "Used Dollars",
        "jsonpath": "utilizedDollars",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
    {
      "header_id": "startDate",
      "header_label": "Auth Start Date",
      "jsonpath": "startDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "endDate",
      "header_label": "Auth end Date",
      "jsonpath": "endDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "mcoId",
      "header_label": "MCO/Agency",
      "jsonpath": "orgName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    }
  ]
}
