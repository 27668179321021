import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../utils/base.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { LookupValueItem } from '../../../interfaces/lookup.interface';
import { LookupService } from '../../../services/lookup.service';
import { SectionsService } from '../../../services/sections.service';
import { STATIC_DATA } from '../../constants/static-data.constants';
import { NzTableModule } from 'ng-zorro-antd/table';
import { CDPAPResponseInterface } from '../../interfaces/cdpap.interface';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { CdpapService } from '../../../services/cdpap.service';
import { performValidation } from '../../utils/validation.util';
import { ButtonComponent } from "../button/button.component";

@Component({
  selector: 'ppl-cdpap-form',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    NzTableModule,
    NzButtonModule,
    ButtonComponent
],
  templateUrl: './cdpap-form.component.html',
  styleUrl: './cdpap-form.component.css'
})
export class CdpapFormComponent extends BaseComponent implements OnInit {
  @Input() cdpapAlgoList!: CDPAPResponseInterface[];
  @Output() stepBack = new EventEmitter<any>()
  form: FormGroup = this.fb.group({
    mco: [],
    lang: [],
    reg: [],
    accessibility: [],
    affiliations: []
  });
  cols = [
    // {label: 'MCO', key: 'mco'},
    {label: 'Language', key: 'lang'},
    {label: 'Current Capacity', key: 'currentCapacity'},
    {label: 'Current Capacity %', key: 'currentCapacityPercentage'},
    // {label: 'Community Affiliations', key: 'affiliations'},
    // {label: 'Special Communication', key: 'accessibility'},
    // {label: 'Registration Preference', key: 'reg'}
   ];
  step: 1 | 2 = 1;
  mcoEntries: any;
  flow: string | null = '';
  affiliations = [
    { label: 'African-American Culture Groups', value: 'African-American Culture Groups' },
    { label: 'Afghan-American Muslim cultural groups', value: 'Afghan-American Muslim cultural groups' },
    { label: 'American, Armenian cultural groups', value: 'American, Armenian cultural groups' },
    { label: 'Ashkenazi Culture Groups', value: 'Ashkenazi Culture Groups' },
    { label: 'Asian Culture groups', value: 'Asian Culture groups' },
    { label: 'Atheist', value: 'Atheist' },
    { label: 'Buddhist', value: 'Buddhist' },
    { label: 'Californian', value: 'Californian' },
    { label: 'Caribbean American Culture Groups', value: 'Caribbean American Culture Groups' },
    { label: 'Catholic', value: 'Catholic' },
    { label: 'Christian', value: 'Christian' },
    { label: 'Developmental Disabilities', value: 'Developmental Disabilities' },
    { label: 'Elderly community', value: 'Elderly community' },
    { label: 'European Cultural Heritage', value: 'European Cultural Heritage' },
    { label: 'Fon People', value: 'Fon People' },
    { label: 'Great Britain ancestry', value: 'Great Britain ancestry' },
    { label: 'Hispanic Culture', value: 'Hispanic Culture' },
    { label: 'Jewish', value: 'Jewish' },
    { label: 'Kosher Home', value: 'Kosher Home' },
    { label: 'Latino Culture', value: 'Latino Culture' },
    { label: 'Latter Day Saint (Mormon)', value: 'Latter Day Saint (Mormon)' },
    { label: 'LGBTQ+', value: 'LGBTQ+' },
    { label: 'Medically fragile community', value: 'Medically fragile community' },
    { label: 'Millennial Generation', value: 'Millennial Generation' },
    { label: 'Muslim', value: 'Muslim' },
    { label: 'New Americans Culture groups', value: 'New Americans Culture groups' },
    { label: 'New England Regional Identity', value: 'New England Regional Identity' },
    { label: 'Physically disabled community', value: 'Physically disabled community' },
    { label: 'Pioneer Heritage', value: 'Pioneer Heritage' },
    { label: 'Protestant', value: 'Protestant' },
    { label: 'Sephardic Culture Groups', value: 'Sephardic Culture Groups' },
    { label: 'Severe disabilities', value: 'Severe disabilities' },
    { label: 'Special Needs community', value: 'Special Needs community' },
    { label: 'Veterans or Veterans Administration', value: 'Veterans or Veterans Administration' },
    { label: 'Albanian Culture Groups', value: 'Albanian Culture Groups' },
    { label: 'Amharic Culture Groups', value: 'Amharic Culture Groups' },
    { label: 'Arabic Culture Groups', value: 'Arabic Culture Groups' },
    { label: 'Bengali Culture Groups', value: 'Bengali Culture Groups' },
    { label: 'Bosnian Culture Groups', value: 'Bosnian Culture Groups' },
    { label: 'Burmese Culture Groups', value: 'Burmese Culture Groups' },
    { label: 'Cantonese Culture Groups', value: 'Cantonese Culture Groups' },
    { label: 'Chinese Culture Groups', value: 'Chinese Culture Groups' },
    { label: 'Egyptian Culture Groups', value: 'Egyptian Culture Groups' },
    { label: 'Farsi Culture Groups', value: 'Farsi Culture Groups' },
    { label: 'Filipino Culture Groups', value: 'Filipino Culture Groups' },
    { label: 'French Culture Groups', value: 'French Culture Groups' },
    { label: 'Fukien Culture Groups', value: 'Fukien Culture Groups' },
    { label: 'German Culture Groups', value: 'German Culture Groups' },
    { label: 'Haitian Creole Culture Groups', value: 'Haitian Creole Culture Groups' },
    { label: 'Hebrew Culture Groups', value: 'Hebrew Culture Groups' },
    { label: 'Hindi Culture Groups', value: 'Hindi Culture Groups' },
    { label: 'Italian Culture Groups', value: 'Italian Culture Groups' },
    { label: 'Japanese Culture Groups', value: 'Japanese Culture Groups' },
    { label: 'Keren Culture Groups', value: 'Keren Culture Groups' },
    { label: 'Korean Culture Groups', value: 'Korean Culture Groups' },
    { label: 'Lakota Culture Groups', value: 'Lakota Culture Groups' },
    { label: 'Lao Culture Groups', value: 'Lao Culture Groups' },
    { label: 'Lingala Culture Groups', value: 'Lingala Culture Groups' },
    { label: 'Mandarin Culture Groups', value: 'Mandarin Culture Groups' },
    { label: 'Moroccan Culture Groups', value: 'Moroccan Culture Groups' },
    { label: 'Nepali Culture Groups', value: 'Nepali Culture Groups' },
    { label: 'Norwegian Culture Groups', value: 'Norwegian Culture Groups' },
    { label: 'Polish Culture Groups', value: 'Polish Culture Groups' },
    { label: 'Portuguese Culture Groups', value: 'Portuguese Culture Groups' },
    { label: 'Russian Culture Groups', value: 'Russian Culture Groups' },
    { label: 'Somali Culture Groups', value: 'Somali Culture Groups' },
    { label: 'Spanish Culture Groups', value: 'Spanish Culture Groups' },
    { label: 'Swahili Culture Groups', value: 'Swahili Culture Groups' },
    { label: 'Tagalog Culture Groups', value: 'Tagalog Culture Groups' },
    { label: 'Tajik Culture Groups', value: 'Tajik Culture Groups' },
    { label: 'Tamil Culture Groups', value: 'Tamil Culture Groups' },
    { label: 'Turkish Culture Groups', value: 'Turkish Culture Groups' },
    { label: 'Tygrinia Culture Groups', value: 'Tygrinia Culture Groups' },
    { label: 'Ukrainian Culture Groups', value: 'Ukrainian Culture Groups' },
    { label: 'Uzbek Culture Groups', value: 'Uzbek Culture Groups' },
    { label: 'Vietnamese Culture Groups', value: 'Vietnamese Culture Groups' },
    { label: 'Yiddish Culture Groups', value: 'Yiddish Culture Groups' }
  ];
  communication = [
    { label: 'Accessible technology', value: 'Accessible technology' },
    { label: 'Alternative Formats', value: 'Alternative Formats' },
    { label: 'Alzheimer\'s/Dementia', value: 'Alzheimer\'s/Dementia' },
    { label: 'ASL', value: 'ASL' },
    { label: 'Autistic', value: 'Autistic' },
    { label: 'Braille', value: 'Braille' },
    { label: 'Captioning', value: 'Captioning' },
    { label: 'Children/Pediatrics/Adolescents', value: 'Children/Pediatrics/Adolescents' },
    { label: 'Developmental Disabilities', value: 'Developmental Disabilities' },
    { label: 'Electronic Text', value: 'Electronic Text' },
    { label: 'Geriatrics', value: 'Geriatrics' },
    { label: 'Hearing impaired', value: 'Hearing impaired' },
    { label: 'Large Print', value: 'Large Print' },
    { label: 'Mute', value: 'Mute' },
    { label: 'Physical Disabilities', value: 'Physical Disabilities' },
    { label: 'Sign Language', value: 'Sign Language' },
    { label: 'Video Phone', value: 'Video Phone' }
  ];

  languages: LookupValueItem[] = [];
  registrationPreference = [
    {label: 'In-person group', value: 'in-person-group'},
    {label: 'In-person individual', value: 'in-person-individual'},
    {label: 'Online group', value: 'online-group'},
    {label: 'Phone individual', value: 'phone'}
  ];
  selectedCDPAP = new FormControl();

  constructor(
        private fb: FormBuilder,
        private lookupService: LookupService,
        private sectionsService: SectionsService,
        private cdpapService: CdpapService
  ) {
    super()
  }

  ngOnInit(): void {
    this.languages = this.lookupService.getLookupValue("Language");
    const program = JSON.parse(localStorage.getItem('selected_program')!);

    this.lookupService.getOrgData(program.program_code,).subscribe((data: any) => {
      if (data.status == 200) {
        this.mcoEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'MCO');
      }
    });

    let steps = this.sectionsService.getCDPAPData(program.program_code);
    this.secData = steps[STATIC_DATA.PARTICIPANT_INFO];
  }

  selectCDPAP(data: CDPAPResponseInterface) {
    this.selectedCDPAP.setValue(data.name);
    this.cdpapService.updateValue(data.name);
    this.reset();
  }

  nextStep() {
    this.step = this.step === 2 ? 1 : 2;
  }

  handleBack() {
    this.step = 1;
    this.stepBack.next(true)
  }

  reset() {
    this.step = 1;
    this.form.reset();
    this.selectedCDPAP.reset();
    this.validationErrors = {};
  }

  runErrorCheck() {
    this.validationErrors = {};
    this.validationErrors = performValidation(
      this.form.value,
      this.secData,
      'Next'
    );
  }
  
}
