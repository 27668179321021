/* src/app/components/lead-details/summary/lead-detail-summary/lead-detail-summary.component.css */
.chart-container {
  width: 500px;
  height: 500px;
}
.chart-box {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.ppl-form-item {
  width: 100% !important;
}
.filter-title {
  font-size: 1.714rem;
  font-weight: 600;
  color: #01796F;
  margin-bottom: 1rem;
  text-align: bottom;
}
.view-welcome-letter,
.download-welcome-letter {
  width: 100px;
  color: white !important;
}
.button-container {
  display: flex;
  justify-content: space-evenly;
}
/*# sourceMappingURL=lead-detail-summary.component-GAWQXCZJ.css.map */
