import { Component, computed, EventEmitter, Input, Output, Signal, signal, WritableSignal } from '@angular/core';
import { SearchProvidersDetailsItem, SearchProvidersPayload, SearchProvidersResponse } from '../../../shared/interfaces/provider.model';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { ColumnType } from '../../../interfaces/list.interface';
import { TableRowAction, TableRowActionsModel } from '../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../shared/interfaces/actions';
import { DateTimeFormatterService } from '../../../shared/utils/date-time-formatter.service';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SearchParticipantsDetailsItem, SearchParticipantsPayload, SearchParticipantsResponse } from '../../../shared/interfaces/participant.model';
import { LookupValueItem } from '../../../interfaces/lookup.interface';

const providerSearchAndSelectColumns: ColumnType[] = [
  {
    header: 'First Name',
    field: 'firstName',
  },
  {
    header: 'Last Name',
    field: 'lastName',
  },
  {
    header: 'PPL ID',
    field: 'pplId',
  },
  {
    header: 'Date of Birth',
    field: 'dob',
  },
  {
    header: 'SSN',
    field: 'ssn',
  },
];

@Component({
  selector: 'ppl-search-and-select-provider',
  standalone: true,
  imports: [
    ButtonComponent,
    ListTableComponent,
    NgZorroModule,
    NzDatePickerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './search-and-select-provider.component.html',
  styleUrl: './search-and-select-provider.component.css'
})
export class SearchAndSelectProviderComponent extends BaseComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() providerSelected = new EventEmitter<(SearchProvidersDetailsItem) & Record<string, any>>();
  @Output() participantSelected = new EventEmitter<(SearchParticipantsDetailsItem) & Record<string, any>>();
  @Output() addingNewProvider = new EventEmitter<void>();
  
  docTypeList: LookupValueItem[] = [];

  providers: WritableSignal<SearchProvidersDetailsItem[] | SearchParticipantsDetailsItem[] | null> = signal(null);
  showResultsList: Signal<boolean> = computed(() => {
    return this.providers() === null ? false : this.providers()?.length != 0;
  });

  selectedProgram = JSON.parse(localStorage.getItem('selected_program') ?? '');

  constructor(
    private dateTimeFormatterService: DateTimeFormatterService,
    private leadDetailsService: LeadDetailsService
  ) {
    super();
  }

  ngOnInit() {
    this.docTypeList = this.getLookup('License_Type')
  }

  searchQueryFormGroup = new FormGroup({
    searchType: new FormControl(),
    fname: new FormControl(''),
    lname: new FormControl(''),
    dob: new FormControl<Date | string>(''),
    pplId: new FormControl(''),
    ssn: new FormControl(''),
    docType: new FormControl(''),
    otherType: new FormControl(''),
  });

  actionButtons: TableRowActionsModel = new TableRowActions({add: true});

  cols = [...providerSearchAndSelectColumns];


  onAction(event: any) {
    if ((event.action === TableRowAction.Add)) {
      this.handleAddProvider(event.data);
    }
  }

  limitDigits(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if(value.length > 9) {
      value = value.slice(0,9);
    }

    event.target.value = value;
    this.searchQueryFormGroup.get('ssn')?.setValue(value);
  }

  handleAddProvider(provider: any) {
    this.searchQueryFormGroup.value.searchType === 'participant' ? this.participantSelected.emit({...provider, docType: this?.searchQueryFormGroup?.value?.otherType || this?.searchQueryFormGroup?.value?.docType}) :
      this.providerSelected.emit({...provider, docType: this?.searchQueryFormGroup?.value?.otherType || this?.searchQueryFormGroup?.value?.docType});
  }

  onSearch() {
    const formGroupValue = { ...this.searchQueryFormGroup.value };
    const dob = formGroupValue.dob;
    console.log(formGroupValue);
    if(formGroupValue?.searchType === 'participant') {
      const query: SearchParticipantsPayload = {
        firstName: formGroupValue.fname ?? '',
        lastName: formGroupValue.lname ?? '',
        participantPPLId: formGroupValue.pplId ?? '',
        ssn: formGroupValue.ssn ?? '',
        dob: dob
          ? this.dateTimeFormatterService.formatDateToUSDateString(dob as Date)
          : '',
        programCode: this.selectedProgram.program_code,
      }
      this.leadDetailsService.searchParticipantsByQuery(query).subscribe({
        next: (res: any) => {
          const searchRes = res as SearchParticipantsResponse;
          const returnedParticipants = searchRes.responsedata?.participantDetails;
          if (!returnedParticipants || !returnedParticipants.length) {
            this.providers.set([]);
            return;
          }
          this.providers.set([
            ...returnedParticipants.map((participant) => {
              return {
                ...participant,
                pplId: participant.participantPPLId,
              };
            }
            ),
          ]);
        },
      });
    } else {
      const query: SearchProvidersPayload = {
        firstName: formGroupValue.fname ?? '',
        lastName: formGroupValue.lname ?? '',
        providerPPLId: formGroupValue.pplId ?? '',
        ssn: formGroupValue.ssn ?? '',
        dob: dob
          ? this.dateTimeFormatterService.formatDateToUSDateString(dob as Date)
          : '',
        providerType: '',
        programCode: this.selectedProgram.program_code,
      }
      this.leadDetailsService.searchProvidersByQuery(query).subscribe({
        next: (res: any) => {
          const searchRes = res as SearchProvidersResponse;
          const returnedProviders = searchRes.responsedata?.providerDetails;
          if (!returnedProviders || !returnedProviders.length) {
            this.providers.set([]);
            return;
          }

          this.providers.set([
            ...returnedProviders.map((provider) => {
              return {
                ...provider,
                pplId: provider.providerPPLId,
              };
            }
            ),
          ]);
        },
      });
    }
  }

  onCancel() {
    this.cancel.emit();
  }
}
