/* src/app/components/leads/participant-information/participant-information.component.css */
.ant-picker {
  padding: 0 8px;
  width: 100%;
}
.ant-picker .ant-picker-input {
  border: 2px solid orange !important;
}
.ant-picker .ant-picker-input input {
  border: 2px solid red !important;
}
.ppl-radio-group {
  justify-content: start;
}
.nz-form .ant-form-item {
  display: flex;
  align-items: center;
}
.nz-form .ant-form-item-control {
  flex: 1;
}
.ant-picker-input {
  border: 2px solid orange !important;
}
.ant-picker-input input {
  border: 2px solid red !important;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.ant-radio-button-wrapper {
  margin: 0px;
  width: 100%;
}
.ant-input-affix-wrapper {
  margin-bottom: 0;
}
::ng-deep .ant-tooltip-inner {
  border-radius: 8px;
}
.notes {
  font-size: 0.929rem;
  margin-top: 4px;
}
.notes p {
  margin: 0;
}
.divider {
  border-top: 2px solid #cff1f04d;
}
.height-input-container {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 8px;
}
.height-input-container input {
  flex: 1;
  min-width: 80px;
  box-sizing: border-box;
}
.height-input-container span {
  margin-left: 4px;
}
.weight-input-container {
  display: flex;
  align-items: center;
}
.weight-input-container input {
  width: 64%;
  margin-right: 8px;
}
.unit-label {
  margin-left: 4px;
}
.ssn-visible {
  color: inherit;
  background: inherit;
  border: inherit;
  padding: 0;
}
.cdpap-button {
  background: transparent;
  border: none;
  margin-bottom: 24px;
}
/*# sourceMappingURL=participant-information.component-C4IZUVLC.css.map */
