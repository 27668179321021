import {Component, OnInit} from '@angular/core';
import {ButtonComponent} from '../../../../shared/components/button/button.component';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule,} from '@angular/forms';
import {NzFormModule} from 'ng-zorro-antd/form';
import {NzCardModule} from 'ng-zorro-antd/card';
import {NzInputModule} from 'ng-zorro-antd/input';
import {BaseComponent} from '../../../../shared/utils/base.component';
import {NzToolTipModule} from 'ng-zorro-antd/tooltip';
import {CommonModule} from '@angular/common';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {NzSelectModule} from 'ng-zorro-antd/select';
import {SectionsService} from '../../../../services/sections.service';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {ActivatedRoute, Router} from '@angular/router';
import {NzCollapseModule} from 'ng-zorro-antd/collapse';
import {AccordionComponent} from '../../../../shared/components/accordion/accordion.component';
import {EnrollmentDataService} from '../../../../services/enrollment-data.service';
import {ClaimsService} from '../../../../services/claims.service';
import {DateTimeFormatterService} from '../../../../shared/utils/date-time-formatter.service';
import {LookupService} from '../../../../services/lookup.service';
import {LookupValueItem} from '../../../../interfaces/lookup.interface';
import {County} from "../../../../interfaces/region-counties.interface";
import { firstValueFrom } from 'rxjs';
import { performValidation } from '../../../../shared/utils/validation.util';
import { RADIO_BOOLEAN_YES_NO_OPTIONS } from '../../../../shared/constants/static-data.constants';
import { BooleanYesNoPipe } from "../../../../shared/pipes/boolean-yes-no.pipe";

@Component({
  selector: 'ppl-add-billable-provider-rates',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    BooleanYesNoPipe
],
  templateUrl: './add-billable-provider-rates.component.html',
  styleUrl: './add-billable-provider-rates.component.css',
})
export class AddBillableProviderRatesComponent
  extends BaseComponent
  implements OnInit
{
  billablePARatesForm!: FormGroup;
  pageTitle = 'Billable - Personal Assistant Rate';
  mode = 'edit';
  savedData: any = {};
  isNew = true;
  serviceCodes: any;
  program: any;
  participants: any;
  providers: any;
  waiver: LookupValueItem[] = [];
  mcoEntries: any;
  counties: County[] = [];
  regions: any = [];
  modifiers: any;
  radioBooleanOptions = RADIO_BOOLEAN_YES_NO_OPTIONS;
  consumerTypes:LookupValueItem[] = [];
  consumerTypesHash:{[key:string]: string} = {};

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private enrollmentDataService: EnrollmentDataService,
    private claimsService: ClaimsService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private lookupService: LookupService,
    private route: ActivatedRoute
  ) {
    super();
  }

  async ngOnInit() {
    this.billablePARatesForm = this.fb.group({
      serviceCodeDetailId: [null],
      fromDate: [null],
      toDate: [null],
      rate: [null],
      serviceAdminName: [null],
	    mcoId: [null],
      regionId: [null],
	    countyId: [null],
	    modifierDetailId: [null]
      // consumerTypeLkp: [null],
      // union: [null],
      // grandFathered: [null]
    });
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    await this.getMCO();
    this.route.paramMap.subscribe((params: any) => {
      const action = params.get('action')!;
      this.pageTitle =
        action == 'update' ? 'Billable - Personal Assistant Rate Details' : this.pageTitle;
      this.isNew = action === 'add';
      this.mode = action == 'update' ? 'read' : 'edit';
    });

    this.sectionsService.setFlow('billablePARates');
    this.waiver = this.getLookup('Waiver');
    this.consumerTypes = this.getLookup('ConsType');
    this.consumerTypesHash = this.consumerTypes.reduce((acc, v) => {
      return {[v.lkpValueCode]: v.lkpValueName, ...acc}
    }, {});
    this.regions = this.lookupService.getTasLookupValue('regionDetailList');
    this.serviceCodes = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
    this.modifiers = this.lookupService.getTasLookupValue('modifierDetailList');
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );
    this.savedData = {...this.claimsService.getFormData('association_rates'),
      // mcoId: this.getOrgNameById(this.claimsService.getFormData('association_rates').mcoId)
    }
  }

  goBack() {
    this.router.navigate(['/home/billablePersonalAssistantRates']);
  }

  async getMCO() {
    try {
      const data: any = await firstValueFrom(
        this.lookupService.getOrgData(this.program.program_code)
      );
      if (data.status === 200) {
        this.mcoEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'MCO');
      }
    } catch (error) {
      console.error('Error fetching MCO data:', error);
    }
  }

  updateCountiesList(regionId: number): void {
    this.billablePARatesForm.get('countyId')?.setValue(null);
    this.counties = this.lookupService.getTasLookupValue('regionCountyDetailList')
      .filter((county: County) => county.payRegionId === regionId)
  }

  getOrgNameById(id: number): string | undefined {
    const org = this.mcoEntries.find((org:any) => org.orgId === id);
    return org ? org.orgName : undefined;
  }

  onEdit() {
    this.mode = 'edit';
    this.updateCountiesList(this.savedData.regionId);
    const updatedData = {
      ...this.savedData,
      fromDate: this.formatDateString(this.savedData.fromDate),
      toDate: this.formatDateString(this.savedData.toDate)
    }
    this.billablePARatesForm.patchValue({
      ...updatedData
    });
 }

  createPayload() {
    return {
      ...this.billablePARatesForm.getRawValue(),
      fromDate: this.billablePARatesForm.value.fromDate ? this.dateTimeFormatterService.formatToString(new Date(this.billablePARatesForm.value.fromDate)) : null,
      toDate: this.billablePARatesForm.value.toDate ? this.dateTimeFormatterService.formatToString(new Date(this.billablePARatesForm.value.toDate)) : null,
      programCode: this.program.program_code,
      paBillRateId: localStorage.getItem('paBillRateId')? Number(localStorage.getItem('paBillRateId')): 0
    };
  }

  onAdd() {
    const payload = this.createPayload();
    this.validationErrors = performValidation(
      this.billablePARatesForm.value,
      this.secData,
      'Next'
    );
    if (Object.keys(this.validationErrors).length === 0) {
    this.claimsService
    .addBillableProviderRates(payload, true)
    .subscribe((data: any) => {
      if (data.status == 200) {
        this.goBack();
      }
    });
  }
  else{
    return;
  }
}

  getTasLookupName(lookupArr: any, valueCode: string): string | undefined {
    const lookup = lookupArr.find((gen: any) => {
      return gen.serviceCodeDetailId === valueCode;
    });
    return lookup ? lookup.code : undefined;
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
    this.billablePARatesForm.reset();
  }

  onUpdate() {
    const payload = this.createPayload();
    const paBillRateId = localStorage.getItem('paBillRateId');
    this.validationErrors = performValidation(
      this.billablePARatesForm.value,
      this.secData,
      'Next'
    );
    if (Object.keys(this.validationErrors).length === 0) {
    this.claimsService
    .updateBillableProviderRates(payload, true).subscribe((data)=>{
      if (data.status == 200) {
        this.claimsService.getBillableProviderRates(this.program.program_code, paBillRateId, true)
          .subscribe((data: any) => {
            this.savedData = data.responsedata;
          });
        this.mode = 'read';
      }
    })
  }
  else{
    return;
  }
}

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.billablePARatesForm.get('toDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.billablePARatesForm.get('fromDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

}
