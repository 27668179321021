<ppl-accordion [title]="'Basic Information'" [isOpen]="true">
  <div class="wiz-body">
    <div class="ppl-mt24">
      <div class="custom-row ppl-mb24">
        @if((authData?.authFrequencyLkp === 'Weekly' || authData?.authFrequencyLkp === 'Monthly') && authData?.endDate){
          <ppl-button label="View Split Data" icon="fa fa-eye" [customClass]="'ghost-button'" [iconPosition]="'left'"
          (click)="onViewSplit()" style="margin-right: 8px;"></ppl-button>
        }
        @if(mode === "read" && authData?.statusLkp !== 'Approved' && authData?.statusLkp !== 'Rejected'){
        <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
          (click)="onEdit()"></ppl-button>
        }
      </div>
      <!-- @if(mode==="edit"){ -->
@if(authData){
  <ppl-auth-forms [formState]="'edit'" [authData]="authData" [from]="'details'" [disableForm]="disableForm" (authUpdate)="onAuthUpdate($event)"></ppl-auth-forms>
}


      <!-- }
      @else if (mode==="read") {
      <div nz-row>
        @if(isAttrAvailable('consumerFirstName')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("consumerFirstName").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.consumerFirstName}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('consumerLastName')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("consumerLastName").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.consumerLastName}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('medicaid')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("medicaid").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.medicaid}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('consumerPPLId')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("consumerPPLId").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.consumerPPLId}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('pplAuth')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("pplAuth").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.pplAuth}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('clientAuth')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("clientAuth").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.clientAuth}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('serviceAdminLkp')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("serviceAdminLkp").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.serviceAdminLkp}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('orgName')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("orgName").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.orgName}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('hiosIdLkp')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("hiosIdLkp").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.hiosIdLkp}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('planId')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("planId").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.planIdLkp}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('serviceCode')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("serviceCode").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.serviceCode}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('modifierCode1')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("modifierCode1").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.modifierCode1}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('diagnosisId')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("diagnosisId").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{filledDiagnosisValue}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('source')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("source").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.source}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('units')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("units").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.units}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('unittypeLkp')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("unittypeLkp").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.unittypeLkp}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('startDate')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("startDate").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.startDate}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('endDate')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("endDate").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.endDate}}</div>
          </div>
        </div>
        }

        @if(isAttrAvailable('sourceDetail')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("sourceDetail").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.sourceDetail}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('statusLkp')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("statusLkp").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.statusLkp}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('decisionComments')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("decisionComments").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.decisionComments}}</div>
          </div>
        </div>
        }
        @if(isAttrAvailable('decisionBy')){
        <div nz-col nzSpan="12">
          <div nz-row>
            <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("decisionBy").label }}
            </div>
            <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.decisionBy}}</div>
          </div>
        </div>
        }
      </div>
      } -->
    </div>
  </div>
</ppl-accordion>
<ppl-accordion title="Additional Details" [isOpen]="true">
  <ppl-auth-additional-details [authData]="authData"></ppl-auth-additional-details>
</ppl-accordion>
<ppl-accordion title="Documents" [isOpen]="true">
  <ppl-authorization-documents [id]="authData?.authorizationId"></ppl-authorization-documents>
</ppl-accordion>
<ppl-accordion title="Utilization" [isOpen]="true">
  <ppl-auth-utilization></ppl-auth-utilization>
</ppl-accordion>
<ppl-accordion title="Timesheets Utilizing this Authorization" [isOpen]="true">
  <ppl-auth-timesheets></ppl-auth-timesheets>
</ppl-accordion>