import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { AuthService } from '../../../../services/auth.service';
import { UserService } from '../../../../services/user.service';
import { Program, UserDetails } from '../../../../interfaces/user.interface';
import { LoggerService } from '../../../../shared/services/logger.service';
import { MenuDropButtonComponent } from "../../../../shared/menu-drop-button/menu-drop-button.component";
import { MenuService } from '../../../../services/menu.service';
import { SubMenuLinkItem, MainMenuItem } from '../../../../interfaces/menu.interface';
import { RoutingService } from '../../../../shared/services/routing.service';
import { AlertboxComponent } from "../../../../shared/components/alertbox/alertbox.component";
import { ToasterBackupComponent } from "../../../../shared/components/toaster-backup/toaster-backup.component";
import { SideDrawerComponent } from "../../../../shared/components/side-drawer/side-drawer.component";
import { NotificationsComponent } from "../../../../shared/components/notifications/notifications.component";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'ppl-header',
  standalone: true,
  imports: [CommonModule,/*  MatIconModule, */ ButtonComponent, MenuDropButtonComponent, AlertboxComponent, ToasterBackupComponent, SideDrawerComponent, NotificationsComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {
  isVisible = false;
  dropdownOpen = false;
  userDetails: UserDetails | undefined;

  isAlertVisible = false;
  isToasterVisible = false;
  isSideDrawerVisible = false;

  handleClose(): void {
    console.log("Alert was closed");
    this.isAlertVisible = false;
  }

  handleToasterClose(): void {
    console.log("Alert was closed");
    this.isToasterVisible = false;
  }

  handleSideDrawerClose(): void {
    console.log("Alert was closed");
    this.isSideDrawerVisible = false;
  }

  menuItemstoDisplay: MainMenuItem[] = [];
  menuSelected: string | undefined;
  selectedMenuItem?: MainMenuItem;
  selProgramCode?: string;
  selectedSubMenuItem?: SubMenuLinkItem;
  menu: MainMenuItem[] = []
  selectedProgram = '';
  isInternalApp = true;
  disableMenu: boolean = false;

  constructor(private userService: UserService,
    private authService: AuthService, private router: RoutingService, private logger : LoggerService, private menuService: MenuService){
      this.isInternalApp = !environment.isExternal;
  }

  ngOnInit() {
    this.authService.userDetailsSubject$.subscribe(
      (userDetails) =>{
        this.userDetails = userDetails?.responsedata
      }
      
    );
    
    this.userService.selectedProgramSubject.subscribe((program: Program) => {
      const restrictedMenus = JSON.parse(
        localStorage.getItem("restricted_menus")!
      );
      this.disableMenu = restrictedMenus.length > 0 ? restrictedMenus.indexOf("disableMenu") != -1 : false;
      this.menu = this.menuService.getMenu(program.program_code);
      this.selectedProgram = program.program_name
      this.selProgramCode = program.program_code;
      this.menuItemstoDisplay = this.filterMenuItems(this.menu, restrictedMenus);

      localStorage.setItem("first_menu", this.menuItemstoDisplay[0].menu_id as string);
      
      this.selectedMenuItem = this.menuItemstoDisplay[0];
      if (this.menuItemstoDisplay[0]?.links && this.menuItemstoDisplay[0]?.links?.length > 0) {
        this.selectedSubMenuItem = this.menuItemstoDisplay[0].links[0];
      }
      //this.router.navigate([`/home/${this.menuItemstoDisplay[0].landing_component}`]);
    })
    
    /*this.authService.getUsers().subscribe((data: UserDataResponse) => {
      localStorage.setItem("user_details", JSON.stringify(data.responsedata));
      const programs = data.responsedata.programs;
      this.userService.userPrograms(programs);
       if (programs.length > 1) {
        this.router.navigate(['/programs'])
      }
      else {
        this.router.navigate(['/home'])
      } 
    });*/

    /* this.userService.selectedProgramSubject.subscribe(data => {
      this.menuSelections();
      this.logger.info('HeaderComponent: menu selections', data);
    }); */
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  // Close dropdown when clicking outside of it
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    
    // Check if the clicked element is within the profileClick container or the dropdown menu
    const clickedInside = target.closest('.profileClick') || target.closest('.dropdown-menu');

    if (!clickedInside && this.dropdownOpen) {
      this.dropdownOpen = false;
    }
  }  

  manageProfile() {
    console.log('Manage Profile clicked');
  }

  logout(): void {
    this.authService.logout();
  }

  filterMenuItems = (menu: MainMenuItem[], restrictedMenus: string[]) => {
    // Filter the main menu items
    const menuItems = menu.filter((menuItem) => {
      // First check if the menu item is visible
      if (menuItem.display !== "y") return false;
  
      // Check if the parent menu item is restricted
      if (restrictedMenus.indexOf(menuItem.menu_id) !== -1) return false;
  
      // If it passes both checks, keep it
      return true;
    });
  
    // Now filter sub-menu items
    const filteredMenuItems = menuItems.map((menuItem) => {
      if (menuItem.links) {
        // Filter sub-menu items
        menuItem.links = menuItem.links.filter(
          (subMenuItem) =>
            subMenuItem.display === "y" && restrictedMenus.indexOf(subMenuItem.sub_menu_id) === -1
        );
      }
  
      // Return the filtered menu item
      return menuItem;
    });
  
    // Filter out any menu items where the links array is empty
    return filteredMenuItems.filter(
      (menuItem) => menuItem.links && menuItem.links.length > 0 || !menuItem.links
    );
  };

  homeRedirection(){
    this.menu = [];
    this.selectedProgram = '';
    this.menuItemstoDisplay = [];
    this.selectedSubMenuItem = undefined;
    localStorage.removeItem("selected_menu");
    this.router.navigate(`/`);
  }
}
