<div id="providerSearchForm" class="ppl-mt20">
  <form nz-form [formGroup]="searchQueryFormGroup" class="provider-search-form">
    <div nz-row nzGutter="48">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label
            [nzXs]="24"
            [nzSm]="6"
            nzFor="Search Type"
          >Search Type</nz-form-label>
          <nz-form-control nzFor="searchType" [nzXs]="24" [nzSm]="18">
            <nz-select
              nzId="Search Type"
              formControlName="searchType"
              id="searchType"
              nzAllowClear
              nzPlaceHolder="Select a type"
            >
              <nz-option nzLabel="Consumer" nzValue="participant"></nz-option>
              <nz-option nzLabel="Provider" nzValue="provider"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    @if(searchQueryFormGroup.value.searchType) {
    <div nz-row nzGutter="48">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="ssn" [nzXs]="24" [nzSm]="6">SSN</nz-form-label>
          <nz-form-control [nzXs]="24" [nzSm]="18">
            <input nz-input formControlName="ssn" id="ssn" (input)="limitDigits($event)" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="pplId" [nzXs]="24" [nzSm]="6">PPL ID</nz-form-label>
          <nz-form-control [nzXs]="24" [nzSm]="18">
            <input nz-input formControlName="pplId" id="pplId" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="48">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="firstName" [nzXs]="24" [nzSm]="6">First Name</nz-form-label>
          <nz-form-control [nzXs]="24" [nzSm]="18">
            <input nz-input formControlName="fname" id="firstName" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="lastName" [nzXs]="24" [nzSm]="6">Last Name</nz-form-label>
          <nz-form-control [nzXs]="24" [nzSm]="18">
            <input nz-input formControlName="lname" id="lastName" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div class="form-actions-container">
      <div>
        @if (providers() && providers()?.length === 0) {
        <h6>No results found with the provided search criteria</h6>
        }
      </div>

      <div>
        <ppl-button label="Cancel" type="button" [customClass]="'ghost-button'" (handleClick)="onCancel()"></ppl-button>
        <ppl-button label="Search" type="submit" icon="fa fa-check-circle" [customClass]="'primary-button'"
          [iconPosition]="'right'" (handleClick)="onSearch()"></ppl-button>
      </div>
    </div>
    }
    @if (providers() && providers()?.length !== undefined && providers()!.length > 0) {
      <div nz-row nzGutter="48" class="select-doc-type">
        <div nz-col nzSpan="12">
          <nz-form-item>
            <nz-form-label [nzXs]="24" [nzSm]="6">File Type</nz-form-label>
            <nz-form-control nzFor="docType" [nzXs]="24" [nzSm]="18" nzExtra="  Set optional document type for selected file(s)">
              <nz-select nzShowSearch nzAllowClear formControlName="docType" id="docType"
                nzPlaceHolder="Select a document type">
                @for(opt of docTypeList; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
                <nz-option [nzLabel]="'Other'" [nzValue]="'other'"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          @if(searchQueryFormGroup.value.docType === 'other') {
          <nz-form-item class="ppl-form-item">
            <nz-form-control class="">
              <div nz-tooltip nzTooltipPlacement="topRight">
                <input class="type-input" nz-input placeholder="Document Name" formControlName="otherType" />
              </div>
            </nz-form-control>
          </nz-form-item>
          }
        </div>
      </div>

      }
  </form>
</div>

@if (showResultsList()) {
<section id="providerSearchResults">
  <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="providers() ?? []"
    [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="true" [rowsToDisplay]="10"
    [loadPage]="true" (gridAction)="onAction($event)" [actionButtons]="actionButtons" [tableScrollable]="true"
    tableScrollHeight="250px">
  </ppl-list-table>
</section>
}