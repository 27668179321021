<div class="ppl-mt8">
  <h2>Forms</h2>
</div>
@if(checkListData && checkListData.length > 0) {
<div class="ppl-mt16 btn-group d-flex flex-row-reverse" style="display: flex; gap: 8px;">

  <ppl-button label="Download All" icon="fa fa-download" [customClass]="'alt-button'" [iconPosition]="'right'"
    (click)="downloadAll()"></ppl-button>

  <ppl-button label="Expand All" icon="fa fa-expand" [customClass]="'alt-button'" [iconPosition]="'right'"
    (click)="expandAll()"></ppl-button>

  <ppl-button label="Collapse All" icon="fa fa-compress" [customClass]="'alt-button'" [iconPosition]="'right'"
    (click)="hideAll()"></ppl-button>

  @if(!readonly && !isNewReferral) {
  <ppl-button label="Mail All" icon="fa fa-envelope" customClass="alt-button" iconPosition='right'
    (click)="showMailAllDialog()"></ppl-button>
  }
</div>
}
<div class="ppl-mt16 flex-col" style="gap: 8px;">
  @if(checkListData && checkListData.length > 0 || prvdrAssociations && prvdrAssociations.length > 0) {
  @for(checkList of checkListData; track $index) {
  <div class="mb-2">
    <nz-collapse [nzExpandIconPosition]="'end'" (click)="updateChecklistActivity(checkList)"
      [ngClass]="checkList.isCompleted ? 'completed completed-ant-collapse' : 'pending pending-ant-collapse'">
      <nz-collapse-panel [nzActive]="isPanelActive(checkList)" [nzHeader]="associationHeader">

        <ng-template #associationHeader>
          <div class="checklist-header-item">
            <div>
              @if(checkList.isCompleted) {<i class="fa fa-check-circle medium-icon-size" aria-hidden="true"></i> }
              {{checkList.checklistName}}
            </div>
            <div>
              {{checkList.totalItemsCompleted +'/' +checkList.totalItems}} Completed
            </div>
          </div>
        </ng-template>
        @if(actions) {
        <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="checkList.items"
          [disableSelection]="true" [cols]="participantCols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
          [actionButtons]="actions" (gridAction)="action($event, false)"></ppl-list-table>
        }
      </nz-collapse-panel>
    </nz-collapse>
  </div>
  }

  @if(prvdrAssociations && prvdrAssociations.length > 0) {
  <div class="ppl-mt16 flex-col" style="gap: 8px;">

    <nz-collapse [nzExpandIconPosition]="'end'"
      [ngClass]="'pending pending-ant-collapse'">
      <nz-collapse-panel [nzActive]="isPanelActive(prvdrAssociations)" [nzHeader]="'Consumer Associations'">
        <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="prvdrAssociations"
          [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
          (gridAction)="action($event, true)" [actionButtons]="participantAssociationActions">
        </ppl-list-table>
      </nz-collapse-panel></nz-collapse>
  </div>

  }
  }
  @else if (isSelf){
  <nz-alert nzType="info" nzMessage="No Self forms available for you." nzShowIcon></nz-alert>
  }
  @else{
  <nz-alert nzType="info" nzMessage="No forms available for you." nzShowIcon></nz-alert>
  }
</div>


<div class="filter-panel">

  <ng-template #pdfDialog let-cancel="cancel">
    @if (!readonly) {
    <div class="action-panel">
      <div class="error-message" *ngIf="errors && errors.length > 0 && customError == ''">
        <i class="fa fa-exclamation-triangle" style="color: red;" aria-hidden="true"></i>
        {{getErrorMessage(errors[0])}}
      </div>
      <div class="error-message" *ngIf="customError">
        <i class="fa fa-exclamation-triangle" style="color: red;" aria-hidden="true"></i>
        {{customError}}
      </div>
      <ppl-button label="Save Form" icon="fa fa-check-circle" [customClass]="'primary-button'" [disabled]="disableSave"
        [iconPosition]="'left'" (handleClick)="handleSave()" />
    </div>
    }
    <div class="" *ngIf="pdfConfig && pdfFile">
      <ppl-editpdf (fieldsUpdated)="fieldsUpdated($event)" [readonly]="readonly" [pdfConfig]="pdfConfig" #viewEditPDFComponent
        [signature]="signature" [pdfBlob]="pdfBlob" [pdfFile]="pdfFile" [signatureId]="signatureId"
        [isParticipantFlow]="isParticipantFlow" [selectedParticipantId]="selectedParticipantId" [isPdfSubmitted]="isPdfSubmitted"
        [selectedProviderType]="selectedProviderType" [isSelf]="isSelf"></ppl-editpdf>
    </div>
  </ng-template>
  <ng-template #associationsDialog>
    @if(providerAssociationChecklist && providerAssociationChecklist.length > 0) {
    @for(checkList of providerAssociationChecklist; track $index) {
    <div class="p-4 mb-2">
      <nz-collapse [nzExpandIconPosition]="'end'" (click)="updateChecklistActivity(checkList)"
        [ngClass]="checkList.isCompleted ? 'completed completed-ant-collapse' : 'pending pending-ant-collapse'">
        <nz-collapse-panel [nzActive]="true" [nzHeader]="associationHeader">

          <ng-template #associationHeader>
            <div class="checklist-header-item">
              <div>
                @if(checkList.isCompleted) {<i class="fa fa-check-circle medium-icon-size" aria-hidden="true"></i> }
                {{checkList.checklistName}}
              </div>
              <div>
                {{checkList.totalItemsCompleted +'/' +checkList.totalItems}} Completed
              </div>
            </div>
          </ng-template>
          <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true"
            [data]="checkList.items" [disableSelection]="true" [cols]="participantCols" [gridTitle]="false"
            [pagination]="false" [loadPage]="true" [actionButtons]="actions"
            (gridAction)="action($event, true)"></ppl-list-table>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    }
    } @else {
      <nz-alert class="mb-2 p-4" nzType="info" nzMessage="No forms available for you." nzShowIcon></nz-alert>
    }
  </ng-template>

</div>

<ppl-popup-dialog [(isVisible)]="showConfirmationDialog" title="Confirm Mail All" isOkText="Mail All Forms"
  (cancel)="onCloseDialog()" [showButtons]="true" position="top" size="medium" (confirm)="handleConfirmMailAll()">
  <div>
    <h3 class="ppl-gray-text mb-4">
      Are you sure you wish to mail all forms?
    </h3>
    <p>
      This action cannot be undone.
    </p>
  </div>
</ppl-popup-dialog>

<ng-template #confirmationDialog2 let-confirm="confirm" let-cancel="cancel">
  <div>
    <h3 class="ppl-gray-text mb-4">
      Are you sure you wish to mail form?
    </h3>
    <p>
      This action cannot be undone.
    </p>
  </div>
</ng-template>