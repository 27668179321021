import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { ButtonModule } from 'primeng/button';
import { ChecklistGroup, ChecklistItem, ChecklistResponse } from '../../../interfaces/checklist.model';
import { PDFFormConfig } from '../../../interfaces/config';
import { CheckListService } from '../../../services/check-list.service';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { PROVIDER_TYPES, RADIO_YES_NO_OPTIONS, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { LoggerService } from '../../../shared/services/logger.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { EditpdfComponent } from '../../editpdf/editpdf.component';
import { ChecklistViewComponent } from "../checklist-view/checklist-view.component";
import { CommPreferenceComponent } from '../comm-preference/comm-preference.component';
import { ChecklistHelperService } from '../../../services/checklist.helper.service';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';

@Component({
  selector: 'ppl-provider-checklist',
  standalone: true,
  imports: [ListTableComponent, CommonModule, NgZorroModule, ReactiveFormsModule, AccordionComponent,
    ButtonComponent, NzCollapseModule,
    CommPreferenceComponent,
    CommonModule, ButtonModule,
    NzToolTipModule,
    NzRadioModule,
    NzButtonModule,
    ButtonComponent,
    ModalComponent,
    EditpdfComponent,
    ChecklistViewComponent,
    FormsModule,
    PopupDialogComponent
  ],
  templateUrl: './provider-checklist.component.html',
  styleUrl: './provider-checklist.component.css'
})
export class ProviderChecklistComponent extends BaseComponent implements OnInit {
  prvdrAssociations: any = [];
  associationDetailsData: any = {};

  cols: any[] = [];
  selectedOption = '';
  date = '';
  checklistData: ChecklistGroup[] = [];
  providerAssociationChecklist?: ChecklistGroup[] = [];
  radioOptions = RADIO_YES_NO_OPTIONS;
  showAssociationModal = false;
  showPdfViewerModal = false;
  modalBody = {
    associationModal: '',
    pdfViewerModal: ''
  };
  program: any;
  pdfFile?: string;
  pdfBlob?: Blob;
  pdfConfig?: PDFFormConfig;
  leadId = '';
  associatedPPLId?: string;
  selectedParticipantId?: number;
  selectedProviderType?: string;
  selectedForm: any;
  @Input() isEditable = true;
  selectedparticipant: any;

  actions: any = {
    "edit": false,
    "delete": false,
    "view": true,
    "download": false,
    "verify": false,
    "add": false
  }
  entry: string | null = '';

  constructor(
    private sectionsService: SectionsService,
    private referralDataService: ReferralDataService,
    private enrollmentDataService: EnrollmentDataService,
    private logger: LoggerService,
    private referralService: ReferralDataService,
    private checkListService: CheckListService,
    private checklistHelperService: ChecklistHelperService
  ) {
    super();
  }

  activeKeys: string[] = ['1', '2', '3', '4', '5', '6', '7'];
  allPanelsExpanded = false;
  isParticipantFlow = false;
  userId = '';
  source = '';
  signature: any;
  signatureId: any;
  isSignAdded = false;
  showSignature: boolean = false;

  ngOnInit(): void {
    this.entry = localStorage.getItem('lead_entry');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.leadId = localStorage.getItem('selected_lead') || '';
    const steps = this.sectionsService.getSectionData('NY');
    this.secData = steps['associations'];
    this.userId = localStorage.getItem('participantId') || localStorage.getItem('providerId') || localStorage.getItem('selected_lead') || '';
    this.isParticipantFlow = localStorage.getItem('lead_entry') == 'participantEnrollment' || localStorage.getItem('lead_submission_flow') == 'participantEnrollment';
    this.source = this.isParticipantFlow ? 'participant' : 'provider';

    this.getGridHeaders();
    this.getChecklist();
    this.getSignature(this.program.program_code, this.source, this.userId);
    this.enrollmentDataService.getParticipantAssocChanged().subscribe(() => {
      this.getAssociationDetails();
    })
  }

  getSignature(program_code: string, type: string, id: string | null) {
    this.referralService.getSignature(program_code, type, id).subscribe((data: any) => {
      if (data?.responsedata && data?.responsedata.length) {
        const activeSign = data?.responsedata.find((data: any) => data.isActive);
        this.isSignAdded = true;
        this.signature = `data:image/png;base64,${activeSign?.signatureImageBase64}`;
        this.signatureId = this.isParticipantFlow ? activeSign.participantSignatureId : activeSign.providerSignatureId;
      }
    })
  }

  getChecklist() {
    this.checkListService.getChecklist(this.program?.program_code, this.leadId || '', false).subscribe((data: any) => {
      if (data.responsedata) {
        const checklist: ChecklistResponse = data.responsedata;
        this.checklistData = this.checklistHelperService.buildChecklistDataForProvider(checklist.checkList, null, false, true);
      }
    });
  }

  getAssociationDetails() {
    const isParticipant = this.entry === 'participantEnrollment';
    this.enrollmentDataService.getAssociationsDetails(this.program.program_code, this.leadId, isParticipant).subscribe((response: any) => {
      console.log(response.responsedata);
      const data = response.responsedata;
      if (isParticipant) {
        for (const key in PROVIDER_TYPES) {
          this.associationDetailsData[key] = data[key] || [];
        }
      } else {
        this.prvdrAssociations = data.participantAssociations;

        // for (const checklist of this.prvdrAssociations) {
        //   if (checklist.checklistStatus == 'C') {
        //     checklist.checlistStatusText = "Completed"
        //   } else {
        //     checklist.checlistStatusText = "Not Completed"
        //   }
        // }

        this.prvdrAssociations = this.prvdrAssociations.filter((association: any) => association.associationStatus != 'Disassociated');

        console.log('this.prvdrAssociations', this.prvdrAssociations);
      }
    });
  }

  isAssociationsCompleted() {
    let isCompleted = true;
    for (const checklist of this.prvdrAssociations) {
      if (checklist.checklistStatus != 'C') {
        isCompleted = false;
        break;
      }
    }

    return isCompleted;
  }

  getProviderAssoc(program_code: string, leadId: any) {
    this.enrollmentDataService.fetchProviderAssociations(program_code, leadId).subscribe((response: any) => {
      console.log(response);
    })
  }

  async getGridHeaders() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          this.entry === 'participantEnrollment' ? STATIC_JSON_DATA_PATH.ASSOCIATIONS_DETAILS_HEADERS : STATIC_JSON_DATA_PATH.PROVIDER_ASSOCIATIONS_HEADERS, this.entry
        );
        console.log(tempCols.columns);
        this.cols = tempCols.columns;
      }
    } catch (e) {
      this.logger.debug('Communication logs - Error in getting column data', e);
    }
  }

  getProviderData(providerCode: string) {
    return this.associationDetailsData[providerCode] || [];
  }

  onRadioChange(controlName: string, value: string) {
    if (value === 'Yes') {
      this.referralDataService.setFlowForAssociation({ val: true, flow: controlName });
    }
  }

  add(control: string) {
    this.referralDataService.setFlowForAssociation({ val: true, flow: control });
  }

  fieldsUpdated($event: any) {
    console.log($event);
  }

  action(event: { action: string; data: any; }) {
    if (event.action == 'view') {
      this.selectedparticipant = event.data;
      this.handleParticipantAssociationSelection(event.data)
    }
  }

  handleCheckListClick(form: any) {
    this.selectedForm = form;
    const currentuser = this.selectedForm.details.filter((d: any) => d.associationId == -1);
    this.selectedProviderType = currentuser.length > 0 ? currentuser[0].type : '',

      this.showPdfViewerModal = true;
    this.modalBody.pdfViewerModal = form.formDesc;
    this.loadPDF(form);
  }

  handleParticipantAssociationSelection(selectedParticipant: any) {
    this.showAssociationModal = true;
    this.modalBody.associationModal = 'Consumer Association - ' + selectedParticipant.participantFirstName + ' ' + selectedParticipant.participantLastName + ' (' + selectedParticipant.participantPplId + ')';
    this.selectedParticipantId = selectedParticipant.participantId;
    this.selectedProviderType = selectedParticipant.providerType;

    this.checkListService.getChecklistForParticipantAssociation(this.program?.program_code, selectedParticipant.providerId, selectedParticipant.participantId, selectedParticipant.providerType).subscribe((data: any) => {
      if (data.responsedata) {
        const checklist: ChecklistResponse = data.responsedata;
        this.associatedPPLId = checklist.pplId;
        selectedParticipant.providerPPLID = checklist.pplId;
        this.providerAssociationChecklist = this.checklistHelperService.buildChecklistDataForProvider(checklist.checkList, selectedParticipant, false, false);
      }
    });
  }

  handleAssociationModalCancel() {
    this.showAssociationModal = false;
    this.associatedPPLId = undefined;
    this.providerAssociationChecklist = undefined;
    this.selectedParticipantId = undefined;
    this.selectedProviderType = undefined;
    this.selectedparticipant = undefined;
  }

  handlePdfViewerModalCancel() {
    this.showPdfViewerModal = false;
    this.pdfConfig = undefined;
    this.pdfFile = undefined;
  }


  saveChecklist() {
    const checklistSaveResponse = this.generateSaveChecklistResponses(this.checklistData, -1);
    const payload = {
      type: "provider",
      checklistItems: checklistSaveResponse.filter(e => e != null)
    };
    this.checkListService.saveChecklist(this.program?.program_code,
      this.leadId || '',
      false, payload).subscribe(response => {
        console.log(response, 'response')
        this.getChecklist();
      });
  }

  saveChecklistForParticipant() {
    if (this.providerAssociationChecklist && this.selectedParticipantId) {
      const checklistSaveResponse = this.generateSaveChecklistResponses(this.providerAssociationChecklist, this.selectedParticipantId);
      const payload = {
        type: "participantProvider",
        checklistItems: checklistSaveResponse.filter(e => e != null)
      };
      this.checkListService.saveChecklistForParticipantAssociation(this.program?.program_code,
        this.leadId || '',
        this.selectedParticipantId, this.selectedProviderType || '', payload).subscribe(response => {
          console.log(response, 'response')
          if (this.selectedparticipant) {
            this.handleParticipantAssociationSelection(this.selectedparticipant);
          }

          this.getAssociationDetails();
        });
    }
  }

  generateSaveChecklistResponses(data: ChecklistGroup[], participantId: number) {
    const checklist: any[] = [];
    data.forEach(e => {
      if (e.children && e.children.length > 0) {
        e.children.forEach(firstChild => {
          this.generateEachChecklistItemResponse(firstChild, checklist, participantId);

          firstChild.children.forEach(secondChild => {
            this.generateEachChecklistItemResponse(secondChild, checklist, participantId);
          })
        })
      }
    });

    return checklist;
  }

  generateEachChecklistItemResponse(e: ChecklistGroup, checklist: any[], participantId: number) {
    if (e.items && e.items.length > 0) {
      e.items.forEach(i => {
        const currentuser = i.details.filter(d => d.associationId == -1);
        checklist.push(new Object({
          checklistId: i.checklistItemId,
          checklistDetailId: i.checklistItemDetailId,
          value: i.value,
          fromDate: i.fromDate,
          toDate: i.toDate,
          participantId: participantId,
          providerId: this.leadId,
          providerType: currentuser.length > 0 ? currentuser[0].type : '',
          associationId: i.details.filter(d => d.associationId != -1).map(d => d.associationId),
        }));
      });
    }
  }

  loadPDF(form: ChecklistItem) {
    this.showSignature = form.formStatus == 'C';

    this.checkListService.getPDF(this.program?.program_code, form.formMasterId, 'eng').subscribe((response: HttpResponse<Blob>) => {
      console.log('pdf response' + response);
      if (response.body) {
        const blob = new Blob([response.body], { type: response.body.type });
        this.pdfBlob = blob;
        this.pdfFile = window.URL.createObjectURL(blob);
        console.log('blob file' + this.pdfFile);
      }
    });

    this.loadPDFData(form);
  }

  loadPDFData(form: ChecklistItem) {
    this.checkListService.getFormData(this.program?.program_code,
      form,
      "provider", this.associatedPPLId || '').subscribe(response => {
        console.log(response, 'response')
        if (response?.responsedata) {
          this.pdfConfig = {
            fields: response?.responsedata.Form_Fields,
            form_description: '',
            form_id: form.formMasterId + '',
            form_name: form.form_name,
            is_mandatory: false
          };
        }
      });
  }

  isPanelActive(group: ChecklistGroup): boolean {
    return group.isActive;
  }

  hideAll() {
    this.checklistData.forEach(group => {
      group.isActive = false;

      this.toggleCollapsePanel(group, false);
    });

    this.toggleCollapsePanel(this.prvdrAssociations, false);
  }

  expandAll() {
    this.checklistData.forEach(group => {
      group.isActive = true;

      this.toggleCollapsePanel(group, true);
    });

    this.toggleCollapsePanel(this.prvdrAssociations, true);
  }

  toggleCollapsePanel(group: ChecklistGroup, activate: boolean) {
    group.isActive = activate;
    if (group.children && group.children.length > 0) {
      group.children.forEach(c => {
        this.toggleCollapsePanel(c, activate);
      })
    }
  }
}
