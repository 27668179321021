@if(mode==='read'){
<div>
    <div nz-row>
        <div nz-col nzSpan="8">Address Line 1</div>
        <div nz-col nzSpan="16">{{ data?.street1}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">Address Line 2</div>
        <div nz-col nzSpan="16">{{ data?.street2}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">City</div>
        <div nz-col nzSpan="16">{{ data?.city}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">State</div>
        <div nz-col nzSpan="16">{{ data?.state}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">Zip Code</div>
        <div nz-col nzSpan="16">{{ data?.zipCode}}</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8">County</div>
        <div nz-col nzSpan="16">{{ data?.countyOrRegion}}</div>
    </div>
    @if(is_permanent){
    <div nz-row style="margin-top:8px">
        <div nz-col nzSpan="11" class="ppl-read-label" style="margin-right:8px">Is the Mailing address the same as the Physical Address?</div>
        <div nz-col nzSpan="12">{{data?.mailing? "Yes" : "No"}}</div>
    </div>
    }
</div>
}
@else{
<div nz-row style="margin-top:8px;margin-bottom:25px">
    <div nz-col nzSpan="8" [nzSm]="8" [nzXs]="24" style="font-weight: 600;">Search Address</div>
    <div nz-col nzSpan="16">
        <nz-select 
            nzShowSearch 
            nzServerSearch 
            nzPlaceHolder="Type your address" 
            [(ngModel)]="selectedValue"
            [nzShowArrow]="false" 
            [nzFilterOption]="nzFilterOptions" 
            (nzOnSearch)="onSearch($event)"
            (ngModelChange)="validateAddress($event)"
            pplNzAriaLabel
            nzAriaLabel="Type address">
            @for(option of listOfOptions; track $index){
            <nz-option [nzValue]="option.value" [nzLabel]="option.text"></nz-option>
            }
        </nz-select>
    </div>
</div>
<div [formGroup]="addressForm">
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street1" class="ppl-form-label">Address Line 1<span>*</span></nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input [ngClass]="{'error': (permanentValidationErrors['street1'] || mailingValidationErrors['street1']) }" nz-input formControlName="street1" id="street1" />
            @if (permanentValidationErrors['street1'] || mailingValidationErrors['street1']) {
                <span class="pl-1 error-message">{{(permanentValidationErrors['street1'] || mailingValidationErrors['street1'])}}</span>
              }
        </nz-form-control>
       
        
    </nz-form-item>
    <!-- <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street2" class="ppl-form-label">Address Line 2</nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input nz-input formControlName="street2" id="street2" />
        </nz-form-control>
    </nz-form-item> -->
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="city" class="ppl-form-label">City  <span>*</span></nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input [ngClass]="{'error': (permanentValidationErrors['city'] || mailingValidationErrors['city']) }" nz-input formControlName="city" id="city" />
            @if (permanentValidationErrors['city'] || mailingValidationErrors['city']) {
                <span class="pl-1 error-message">{{(permanentValidationErrors['city'] ||  mailingValidationErrors['city'])}}</span>
              }
        </nz-form-control>
    </nz-form-item>
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="state" class="ppl-form-label">State  <span>*</span></nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input [ngClass]="{'error': (permanentValidationErrors['state'] || mailingValidationErrors['state'])  }" nz-input formControlName="state" id="state" />
            @if (permanentValidationErrors['state'] || mailingValidationErrors['state']) {
                <span class="pl-1 error-message">{{(permanentValidationErrors['state'] || mailingValidationErrors['state'])}}</span>
              }
        </nz-form-control>
    </nz-form-item>
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="zipCode" class="ppl-form-label">Zip Code  <span>*</span></nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input [ngClass]="{'error': (permanentValidationErrors['zipCode'] || mailingValidationErrors['zipCode'])  }" nz-input formControlName="zipCode" id="zipCode" />
            @if (permanentValidationErrors['zipCode'] || mailingValidationErrors['zipCode']) {
                <span class="pl-1 error-message">{{permanentValidationErrors['zipCode'] || mailingValidationErrors['zipCode']}}</span>
              }
        </nz-form-control>
    </nz-form-item>
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="region" class="ppl-form-label">County @if(isParticipant){<span>*</span>}</nz-form-label>
        <nz-form-control class="ppl-form-control">
            <input  [ngClass]="{'error': (permanentValidationErrors['countyOrRegion'] || mailingValidationErrors['countyOrRegion']) }" nz-input formControlName="countyOrRegion" id="countyOrRegion" aria-label="County or Region"/>
            @if (permanentValidationErrors['countyOrRegion'] || mailingValidationErrors['countyOrRegion']) {
                <span class="pl-1 error-message">{{permanentValidationErrors['countyOrRegion'] || mailingValidationErrors['countyOrRegion'] }}</span>
              }
        </nz-form-control>
    </nz-form-item>
    @if(fullAddress && fullAddress.length > 0){
        <div nz-row style="margin-top:8px;margin-bottom:25px">
            <div nz-col nzSpan="8" [nzSm]="8" [nzXs]="24" style="font-weight: 600;"></div>
            <div nz-col nzSpan="6" style="border: 1px solid #ddd; padding: 5px 10px;border-radius: 5px;">
                {{fullAddress}}
            </div>
        </div>
    }
    @if(is_permanent){
    <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="14" [nzXs]="24" nzFor="mailing" class="ppl-form-label">Is the Mailing address the same
            as the Physical Address?</nz-form-label>
        <nz-form-control class="ppl-form-control">
            <nz-switch 
                nzId="mailing" 
                formControlName="mailing"
                pplNzAriaLabel
                nzAriaLabel="Mailing"></nz-switch>
        </nz-form-control>
    </nz-form-item>
    }
</div>
}