/* src/app/components/payroll/authorizations/auth-forms/auth-forms.component.css */
.ant-picker {
  width: 100%;
  border-radius: 8px;
  padding: 0 8px;
}
::ng-deep .ant-picker-input > input {
  border: none;
}
::ng-deep nz-input-group > .ant-input {
  height: 30px;
}
.step-header {
  font-weight: bold;
}
.hours-box {
  margin-right: 4px;
}
/*# sourceMappingURL=auth-forms.component-72NWCJUL.css.map */
