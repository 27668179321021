import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SectionsService } from '../../../services/sections.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';
import { CommonModule } from '@angular/common';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { LookupService } from '../../../services/lookup.service';
import { ClaimsService } from '../../../services/claims.service';
import { Router } from '@angular/router';
import { ListService } from '../../../shared/services/list.service';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { performValidation } from '../../../shared/utils/validation.util';
import { LookupValueItem } from '../../../interfaces/lookup.interface';

@Component({
  selector: 'ppl-case-manager-details',
  standalone: true,
  imports: [
    ButtonComponent,
    AccordionComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NzGridModule,
    NzToolTipModule,
    NzSelectModule,
    NzInputModule,
    NzFormModule,
    NzCardModule,
  ],
  templateUrl: './case-manager-details.component.html',
  styleUrl: './case-manager-details.component.css',
})
export class CaseManagerDetailsComponent
  extends BaseComponent
  implements OnInit
{
  caseManagerForm!: FormGroup;
  program: any;
  mode = 'read';
  savedData: any;
  mcoEntries: any;
  caseManagerId = '';
  radioOptions: LookupValueItem[] = [];
  ldssOfficeValues: LookupValueItem[] = [];

  constructor(
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private lookupService: LookupService,
    private router: Router,
    private claimsService: ClaimsService,
    private listService: ListService
  ) {
    super();
  }

  ngOnInit(): void {
    this.caseManagerForm = this.fb.group({
      caseManagerId: [null],
      firstName: [null],
      lastName: [null],
      email: [''],
      mobile: [''],
      orgName: [''],
      active: [true],
      ldssCode: [null]
    });

    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'case-manager'
    );

    this.lookupService
      .getOrgData(this.program.program_code)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.mcoEntries = data.responsedata.filter(
            (item: any) => item.orgTypeLookup === 'MCO'
          );
        }
      });
    this.savedData = this.claimsService.getFormData('case_manager');
    this.savedData.active = 
    this.savedData?.active === true
      ? 'Yes'
      : this.savedData?.active === false
      ? 'No'
      : this.savedData?.active;
    this.radioOptions = this.getLookup('Yes_No');
    this.ldssOfficeValues = this.getLookup('LDSS_Office');
    this.caseManagerId = this.savedData.caseManagerId;
  }

  goBack() {
    this.router.navigate(['/home/caseManagerList']);
  }

  onEdit() {
    this.mode = 'edit';
    const formValuesToPatch = {
      ...this.savedData,
      active: 
    this.savedData?.active === true
      ? 'Yes'
      : this.savedData?.active === false
      ? 'No'
      : this.savedData?.active
    }

    this.caseManagerForm.patchValue(formValuesToPatch);
  }

  onUpdate() {
    const formData = this.caseManagerForm.value;
    this.validationErrors = performValidation(
      this.caseManagerForm.value,
      this.secData,
      'Next'
    );
    if (Object.keys(this.validationErrors).length === 0) {
      const mcoOrgId = formData?.orgName
        ? this.mcoEntries?.find(
            (entry: any) => entry.orgName === formData.orgName
          ).orgId
        : null;
      const payload = {
        ...formData,
        caseManagerMcoId: mcoOrgId,
        programCode: this.program.program_code,
        active:
        formData.active === 'Yes'
          ? true
          : formData.active === 'No'
          ? false
          : formData.active,
      };

      this.claimsService.updateCaseManager(payload).subscribe((data: any) => {
        if (data.status === 200) {
          this.mode = 'read';
          const payload = this.createPayload();
          this.listService
            .getListData(payload, 'case-manager')
            .subscribe((data: any) => {
              this.savedData = data.responsedata[0];
              this.savedData.active = this.savedData?.active === true
                ? 'Yes'
                : this.savedData?.active === false
                ? 'No'
                : this.savedData?.active
            });
        }
      });
    } else {
      return;
    }
  }

  createPayload() {
    const payload: any = {
      pageName: 'casemanagerlist',
      programCode: this.program.program_code,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: '1',
      searchParam: [
        {
          jsonPath: 'caseManagerId',
          value: this.caseManagerId,
          operator: 'equal',
        },
      ],
      orderByColumn: ['caseManagerId'],
      orderBy: ['desc'],
    };
    return payload;
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
  }
}
