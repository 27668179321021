import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from '../../../shared/utils/base.component';
import {
  RADIO_YES_NO_OPTIONS,
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../shared/constants/static-data.constants';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { differenceInCalendarDays } from 'date-fns';
import { AssociationDetailsAddressComponent } from '../associations/association-details-address/association-details-address.component';
import { LookupService } from '../../../services/lookup.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { SectionsService } from '../../../services/sections.service';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import {
  ProviderEnrollmentDataItem,
  ProviderEnrollmentDataResponse,
} from '../../../shared/interfaces/provider.model';
import { parse } from 'date-fns';
import { ReferralDataService } from '../../../services/referral-data.service';

@Component({
  selector: 'ppl-add-association-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgZorroModule,
    CommonModule,
    NzDatePickerModule,
    AssociationDetailsAddressComponent,
  ],
  templateUrl: './add-association-form.component.html',
  styleUrl: './add-association-form.component.css',
})
export class AddAssociationFormComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  private lookupService = inject(LookupService);
  private logger = inject(LoggerService);
  private sectionsService = inject(SectionsService);
  private enrollmentDataSvc = inject(EnrollmentDataService);

  @Input() providerId: number | null = null;
  @Input({ required: true }) sectionName!: string;
  @Input() override validationErrors: any;
  @Input() readonly = false;
  @Input() flowOrigin: 'create' | 'details' = 'details';
  @Input({required: true}) providerType!: string;
  @Output() formUpdated = new EventEmitter<FormGroup>();

  providerEnrollmentDetails: WritableSignal<any | null> = signal(null);
  isReadonly = computed(() => {
    return !!this.providerEnrollmentDetails();
  });

  infoForm!: FormGroup;
  formReadonly = false;
  permanentAddressErrors: any = {};
  disabledDate!: (current: Date) => boolean;
  gender: any[] = [];
  radioOptions = RADIO_YES_NO_OPTIONS;
  ssnVisible = false;
  relationshipOptions: any;
  eyeColors: any;
  hairColors: any;
  raceOptions: any;
  statesList: any;
  participantRegisterMode: any[] = [];
  taxExemptOptions: any;
  licences: any[] = [];
  services: any[] = [];
  entry: any;
  steps: any;
  sectionDataPath = '';
  selectedProgram = signal(
    JSON.parse(localStorage.getItem('selected_program') ?? '')
  );
  flow!: string;

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.entry = this.sectionsService.getFlow();
    this.sectionDataPath =
      STATIC_JSON_DATA_PATH.LEAD_DETAILS_PROVIDERS_SECTION_DATA;
    this.getSectionData();
    this.createForm();
    this.disabledDate = (current: Date): boolean => {
      return differenceInCalendarDays(current, new Date()) >= 0;
    };
    this.gender = this.getLookup('Gender');
    this.relationshipOptions = this.getLookup('Relationship');
    this.raceOptions = this.getLookup('Race');
    this.eyeColors = this.getLookup('Eye Color');
    this.hairColors = this.getLookup('Hair Color');
    this.statesList = this.lookupService.getLookupValue('STATE');
    this.participantRegisterMode = this.getLookup('Participant_Register_Mode');
    this.licences = this.getLookup('License_Type');
    this.services = this.getLookup('Service_Type');
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log("FORM CHANGES", changes)
    if (changes['providerId'] && changes['providerId'].currentValue) {
      this.getProviderEnrollmentDetails();
    }
    if (changes['readonly'] && changes['readonly'].currentValue && this.infoForm) {
      this.infoForm.disable();
    }
  }

  getFormControlValue(formControlName: string) {
    return this.infoForm.get(formControlName)?.value ?? null
  }

  getProviderEnrollmentDetails() {
    if (!this.providerId || !this.selectedProgram) return;
    this.enrollmentDataSvc
      .getAssociationsDetails(
        this.selectedProgram().program_code,
        this.providerId,
        false,
        false
      )
      .subscribe({
        next: (res) => {
          const providerEnrollmentDetailsRes =
            res as ProviderEnrollmentDataResponse;
          const providerData = providerEnrollmentDetailsRes.responsedata;
          this.providerEnrollmentDetails.set({ ...providerData });
          this.populateFormWithDetailsData(this.providerEnrollmentDetails());
        },
      });
  }

  populateFormWithDetailsData(details: ProviderEnrollmentDataItem) {
    const newFormValue = this.mapDetailsDataToForm(details);
    this.infoForm.patchValue(newFormValue);
  }

  createForm(): void {
    const formElements: any = {
      fname: new FormControl(''),
      lname: new FormControl(''),
      dob: new FormControl(null),
      email: new FormControl(''),
      mobile: new FormControl(''),
      permanentAddress: new FormGroup({
        leadId: new FormControl(),
        street1: new FormControl(''),
        street2: new FormControl(''),
        city: new FormControl(''),
        state: new FormControl(''),
        zipCode: new FormControl(''),
        countyOrRegion: new FormControl(''),
        psd_code: new FormControl(''),
        mailing: new FormControl(false),
      }),
    };

    if (
      this.sectionName === STATIC_DATA.ASSOCIATION_AUTHORIZED_REPRESENTATIVE
    ) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.relationToParticipant = new FormControl(null);
      formElements.additionalDemographics = new FormControl('');
      formElements.race = new FormControl('');
      formElements.heightFeet = new FormControl('');
      formElements.heightInches = new FormControl('');
      formElements.weight = new FormControl('');
      formElements.eyeColor = new FormControl('');
      formElements.hairColor = new FormControl('');
      formElements.birthPlace = new FormControl('');
      formElements.birthCity = new FormControl('');
      formElements.birthState = new FormControl('');
      formElements.birthCountry = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
      formElements.consent= new FormControl(this.readonly);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_PROVIDER) {
      formElements.mname = new FormControl('');
      formElements.maiden_name = new FormControl('');
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.relationToParticipant = new FormControl(null);
      formElements.NPI = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
      formElements.yearsInState = new FormControl('');
      formElements.emergencyContactFirstName = new FormControl('');
      formElements.emergencyContactLastName = new FormControl('');
      formElements.emergencyContactPhone = new FormControl('');
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_EMPLOYER) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR) {
      formElements.mname = new FormControl();
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
      formElements.taxExempt = new FormControl();
      formElements.nprftDocumentationReceived = new FormControl();
    }
    if (this.sectionName === STATIC_DATA.ASSOCIATION_VENDOR) {
      formElements.businessName = new FormControl();
      formElements.taxExempt = new FormControl();
      formElements.nprftDocumentationReceived = new FormControl();
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.mname = new FormControl();
      formElements.altPhone = new FormControl('');
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_INDEPENDENT_CONTRACTOR) {
      formElements.mname = new FormControl('');
      formElements.SSN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.taxExempt = new FormControl('');
      formElements.nprftDocumentationReceived = new FormControl('');
      formElements.profLicences = new FormControl([]);
      formElements.servicesRendered = new FormControl([]);
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_LEGAL_GUARDIAN) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }

    if (this.sectionName === STATIC_DATA.ASSOCIATION_POWER_OF_ATTORNEY) {
      formElements.gender = new FormControl(null);
      formElements.SSN = new FormControl('');
      formElements.EIN = new FormControl('');
      formElements.altPhone = new FormControl('');
      formElements.registerPref = new FormControl(null);
    }

    this.infoForm = new FormGroup(formElements);
    this.infoForm.valueChanges.subscribe({
      next: (formGroupValue: FormGroup['value']) => {
        this.formUpdated.emit(this.infoForm);
      },
    });
  }

  async getSectionData() {
    try {
      if (this.entry && this.sectionName) {
        // if (this.flowOrigin === 'create') {
        //   this.sectionDataPath = STATIC_JSON_DATA_PATH.CREATE_CONSUMER_ASSOCIATION;
        //   this.steps = await this.sectionsService.getJsonConfigurationData(
        //     this.sectionDataPath,
        //     this.sectionName
        //   );
        // } else {
      
        // }

        this.steps = await this.sectionsService.getJsonConfigurationData(
          this.sectionDataPath,
          this.entry,
          this.sectionName
        );

  
        this.secData = this.steps;
        /* if (this.sectionName === STATIC_DATA.NEW_EMPLOYER_INFO) {
          this.secData = this.steps[STATIC_DATA.NEW_EMPLOYER_INFO];
        } else if (this.sectionName === STATIC_DATA.AUTHORIZED_REPRESENTATIVE) {
          this.secData = this.steps[STATIC_DATA.AUTHORIZED_REPRESENTATIVE];
        } else if (this.sectionName === STATIC_DATA.INDEPENDENT_PROVIDER) {
          this.secData = this.steps[STATIC_DATA.INDEPENDENT_PROVIDER];
        } else if (this.sectionName === STATIC_DATA.NEW_VENDOR_DETAILS) {
          this.secData = this.steps[STATIC_DATA.NEW_VENDOR_DETAILS];
        } else if (
          this.sectionName === STATIC_DATA.INDEPENDENT_CONTRACTOR_DETAILS
        ) {
          this.secData = this.steps[STATIC_DATA.INDEPENDENT_CONTRACTOR_DETAILS];
        } else if (this.sectionName === STATIC_DATA.LEGAL_GUARDIAN) {
          this.secData = this.steps[STATIC_DATA.LEGAL_GUARDIAN];
        } else if (this.sectionName === STATIC_DATA.POWER_OF_ATTORNEY) {
          this.secData = this.steps[STATIC_DATA.POWER_OF_ATTORNEY];
        } */
      }
    } catch (e) {
      this.logger.debug('Error fetching section details data', e);
    }
  }

  limitDigits(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if(value.length > 9) {
      value = value.slice(0,9);
    }

    event.target.value = value;
    this.infoForm.get('SSN')?.setValue(value);
  }
  
  cancelEdit() {
    this.infoForm.reset();
    this.validationErrors = {};
    this.permanentAddressErrors = {};
    this.formReadonly = false;
    this.infoForm.enable();
    this.readonly = false;
  }

  getFormGroup(controlName: string): FormGroup {
    return this.infoForm.get(controlName) as FormGroup;
  }

  private mapDetailsDataToForm(details: ProviderEnrollmentDataItem): any {
    const basicInfo = details.basicInfo;
    const identifiers = details.basicInfo.identifiers;
    const contactDetails = details.contactDetails;
    const dob = basicInfo.dob;
    return {
      ...basicInfo,
      ...details.communicationPreferences,
      dob: dob 
      ? parse(dob, 'MM/dd/yyyy', new Date())
      : '',
      SSN: this.parseIdentifierValueByType(identifiers, 'SSN'),
      NPI: this.parseIdentifierValueByType(identifiers, 'NPI'),
      email: this.parseContactValueByType(contactDetails, 'email'),
      altPhone: this.parseContactValueByType(contactDetails, 'altPhone'),
      mobile: this.parseContactValueByType(contactDetails, 'mobile'),
      permanentAddress: details.contactDetails?.permanentAddress
    };
  }

  private parseIdentifierValueByType(
    identifiers: ProviderEnrollmentDataItem['basicInfo']['identifiers'] | null,
    type: 'SSN' | 'NPI'
  ): string {
    return identifiers 
    ? identifiers.find((idf) => idf.type === type)?.value ?? ''
    : '';
  }

  private parseContactValueByType(
    contactDetails: ProviderEnrollmentDataItem['contactDetails'] | null,
    type: 'email' | 'altPhone' | 'mobile'
  ): string {
    return contactDetails 
    ? contactDetails.contactMethods.find((cm) => cm.contactMethod === type)?.value ?? ''
    : '';
  }

  getAccessibleInputId(field: string) {
    return `${field} for ${this.sectionName}`;
  }

}
