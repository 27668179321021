import {
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import {
  TableRowAction,
  TableRowActionsModel,
  ColumnType,
} from '../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../shared/interfaces/actions';
import { DateTimeFormatterService } from '../../../shared/utils/date-time-formatter.service';
import {
  SearchProvidersDetailsItem,
  SearchProvidersPayload,
  SearchProvidersResponse,
} from '../../../shared/interfaces/provider.model';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS, STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../services/sections.service';

@Component({
  selector: 'ppl-provider-search-and-select',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NzDatePickerModule,
    NgZorroModule,
    ButtonComponent,
    ListTableComponent,
  ],
  templateUrl: './provider-search-and-select.component.html',
  styleUrl: './provider-search-and-select.component.css',
})
export class ProviderSearchAndSelectComponent implements OnInit {
  private dateTimeFormatterSvc = inject(DateTimeFormatterService);
  private leadDetailsSvc = inject(LeadDetailsService);
  @Input() providerType!: string;
  @Output() cancel = new EventEmitter<void>();
  @Output() providerSelected = new EventEmitter<SearchProvidersDetailsItem>();
  @Output() addingNewProvider = new EventEmitter<void>();

  providers: WritableSignal<SearchProvidersDetailsItem[] | null> = signal(null);

  showResultsList: Signal<boolean> = computed(() => {
    return this.providers() === null ? false : this.providers()?.length != 0;
  });

  searchQueryFormGroup = new FormGroup({
    fname: new FormControl(''),
    lname: new FormControl(''),
    dob: new FormControl<Date | string>(''),
    pplId: new FormControl(''),
    providerType: new FormControl(''),
    selectedProgramCode: new FormControl(''),
    ssn: new FormControl('')
  },
  { validators: this.pplIdOrSsnValidator() });

  actionButtons: TableRowActionsModel = new TableRowActions({ add: true });

  cols: ColumnType[] = [
    {
      header: 'PPL ID',
      field: 'providerPPLId',
    },
    {
      header: 'First Name',
      field: 'firstName',
    },
    {
      header: 'Last Name',
      field: 'lastName',
    },
    {
      header: 'Date of Birth',
      field: 'dob',
    },
  ];

  selectedProgram = signal(
    JSON.parse(localStorage.getItem('selected_program') ?? '')
  );

  constructor(private sectionsService: SectionsService){
  }

  ngOnInit(): void {
    this.initForm();
  }

  pplIdOrSsnValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const pplId = control.get('pplId')?.value;
      const ssn = control.get('ssn')?.value;

      return (pplId || ssn) ? null : { pplIdOrSsnOrDobRequired: true };
    };
  }

  initForm() {
    if (this.providerType) {
      this.searchQueryFormGroup.controls['providerType'].setValue(
        this.providerType
      );
    }
    if (this.selectedProgram() && this.selectedProgram().program_code) {
      this.searchQueryFormGroup.controls['selectedProgramCode'].setValue(
        this.selectedProgram().program_code
      );
    }
  }

  action(event: any) {
    if ((event.action = TableRowAction.Add)) {
      this.handleAddProvider(event.data);
    }
  }

  limitDigits(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if(value.length > 9) {
      value = value.slice(0,9);
    }

    event.target.value = value;
    this.searchQueryFormGroup.get('ssn')?.setValue(value);
  }

  handleAddProvider(provider: any) {
    // this.providerSelected.emit(provider);
    const enrollmentTypes = this.sectionsService.getEnrollmentType();
    const fieldName = enrollmentTypes && enrollmentTypes.length > 0 ? enrollmentTypes[0] : "provider";
    const id = localStorage.getItem('participantId') || localStorage.getItem(STATIC_DATA.SELECTED_LEAD);
    const payload = {
      programCode: provider.programCode,
      providerId: provider.providerId,
      participantId: id,
      pageType: 'participantDetail',
      providerType: ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS[fieldName]
    };
    this.leadDetailsSvc
      .addParticipantProviderAssociation(payload, true)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.cancel.emit();
        }
      });
  }

  search() {
    if (this.searchQueryFormGroup.invalid) {
      // If the form is invalid, do not proceed with the search
      return;
    }
    const formGroupValue = { ...this.searchQueryFormGroup.value };
    const dob = formGroupValue.dob;
    const query = {
      firstName: formGroupValue.fname,
      lastName: formGroupValue.lname,
      providerPPLId: formGroupValue.pplId,
      dob: dob
        ? this.dateTimeFormatterSvc.formatDateToUSDateString(dob as Date)
        : '',
      providerType: formGroupValue.providerType,
      programCode: formGroupValue.selectedProgramCode,
      ssn: formGroupValue.ssn
    } as SearchProvidersPayload;
    this.leadDetailsSvc.searchProvidersByQuery(query).subscribe({
      next: (res: any) => {
        const searchRes = res as SearchProvidersResponse;
        if (
          !searchRes.responsedata ||
          searchRes.responsedata.providerDetails.length === 0
        ) {
          this.providers.set([]);
          return;
        }
        this.providers.set([
          ...(searchRes?.responsedata?.providerDetails ?? null),
        ]);
      },
    });
  }
}
