import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { TableRowActionsModel } from '../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../shared/interfaces/actions';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { Program } from '../../../interfaces/user.interface';
import { BaseComponent } from '../../../shared/utils/base.component';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { AdditionalAttribute, AdditionalAttributeResponse } from '../../../interfaces/additional-attribute.model';
import { GridAction, AdditionalAttributeGridData } from '../../../shared/components/list-table/grid-action.model';
import { NzAriaLabelDirective } from '../../../shared/directives/nz-aria-label.directive';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { MEMBER_VIEW_PAGE_IDS, TABS } from '../../../shared/constants/page.constants';
import { differenceInCalendarDays } from 'date-fns';

enum Mode {
  Read = 'read',
  Edit = 'edit'
}
@Component({
  selector: 'ppl-additional-attributes',
  standalone: true,
  imports: [
    AccordionComponent,
    ListTableComponent,
    NgZorroModule,
    ButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    NzDatePickerModule,
    PopupDialogComponent,
    NzAriaLabelDirective
  ],
  templateUrl: './additional-attributes.component.html',
  styleUrl: './additional-attributes.component.css',
})
export class AdditionalAttributesComponent extends BaseComponent implements OnInit {
  //attributeForm: any;
  selectedAttributeType!: string;
  attributeValue!: Date | string;
  startDate!: Date | string;
  endDate!: Date | string;
  mode: Mode = Mode.Read;

  responseData!: AdditionalAttribute[];
  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.AdditionalAttributes;

  actionButtons: TableRowActionsModel = new TableRowActions();
  gridData: AdditionalAttributeGridData[] = [];
  showConfirmationDialog = false;
  disabledDate!: (current: Date) => boolean;

  cols = [
    {
      field: 'attribute_text',
      header: 'Field Name',
      jsonpath: 'attribute_text',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'value',
      header: 'Field Value',
      jsonpath: 'value',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'effective_from_date',
      header: 'Effective From Date',
      jsonpath: 'effective_from_date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
    {
      field: 'effective_to_date',
      header: 'Effective To Date',
      jsonpath: 'effective_to_date',
      isDisplayedAtHeader: 'y',
      isSearchable: 'n',
    },
  ];
  index!: number;
  displayTypeError = false;
  displayValueError = false;
  id !: number;
  entry !: string;
  program !: Program;
  options: (AdditionalAttribute & { attribute_id: number | null, disabled: boolean })[] = [];
  displayDates = false;
  lookupData: Record<string, AdditionalAttribute['lookup']> = {};
  fieldType = '';
  dataType = '';
  selectedAttribute!: string;
  displayStartDateError !: boolean;
  displayEndDateError!: boolean;
  attributeId!: string | null;
  additionalAttribute!: AdditionalAttributeGridData | null;
  enrollment_id!: number;
  isSectionEditable: boolean = false;

  constructor(private leadDetailService: LeadDetailsService, private cd: ChangeDetectorRef, private datePipe: DatePipe,
    private authUtility: AuthorizationUtility

  ) {
    super();
  }

  ngOnInit(): void {
    this.isSectionEditable = this.authUtility.isSectionEditable(this.pageId, this.tabId, 'AdditionalAttributes');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.entry = localStorage.getItem('lead_entry')!;

    this.enrollment_id = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    this.disabledDate = (current: Date): boolean => {
      if (this.selectedAttribute === 'Date of Death') {
        return differenceInCalendarDays(current, new Date()) > 0;
      }
      return false;
    };
    this.getAdditionalAttributes(false);
  }



  addAttributes() {

    const isEmpty = (value: string | Date) => !value || value === "";

    this.displayTypeError = isEmpty(this.selectedAttributeType);
    this.displayValueError = isEmpty(this.attributeValue);

    if (this.displayDates) {
      this.displayStartDateError = isEmpty(this.startDate);
      this.displayEndDateError = isEmpty(this.endDate);
    } else {
      this.displayStartDateError = false;
      this.displayEndDateError = false;
    }

    if ([this.displayEndDateError, this.displayStartDateError, this.displayValueError, this.displayTypeError].includes(true)) {
      return;
    }


    this.save();

  }


  validateSelectedAttribute(event: any) {
    console.log(event);

    this.initializeFormFields();

    // get the selected attribute details
    this.fieldType = event?.field_type as string;
    this.dataType = event?.data_type as string;
    this.displayDates = event?.show_date_range;
    this.selectedAttribute = event?.attribute_text;
    this.attributeId = event.id;
  }

  initializeFormFields(): void {
    //initialize attribute values
    this.attributeValue = '';
    this.startDate = '';
    this.endDate = '';

    // remove selected attributes error messages
    this.displayTypeError = false;
    this.displayValueError = false;
    this.displayStartDateError = false;
    this.displayEndDateError = false;
  }

  initializeSelectedAttribute() {
    this.fieldType = '';
    this.dataType = '';
    this.displayDates = false;
    this.selectedAttribute = '';
    this.attributeId = null;
  }

  validateAttributeValue() {
    this.displayValueError = false;
  }

  action(event: GridAction) {
    this.additionalAttribute = this.gridData.find((x: AdditionalAttributeGridData) => x.attribute_text == event.data?.attribute_text) ?? null;
    if (!this.additionalAttribute) return
    this.index = this.additionalAttribute.attribute_id;

    if (event && event.action === 'edit') {
      this.mode = Mode.Edit;
      this.validateSelectedAttribute(event.data);
      this.selectedAttributeType = this.additionalAttribute?.attribute_text;

      if (this.dataType === 'date') {
        this.attributeValue = new Date(this.additionalAttribute.value ?? '');
      } else {
        this.attributeValue = this.additionalAttribute.value ?? '';
      }


      if (this.additionalAttribute.show_date_range) {
        this.startDate = new Date(this.additionalAttribute.effective_from_date ? this.additionalAttribute.effective_from_date : '');
        this.endDate = new Date(this.additionalAttribute.effective_to_date ? this.additionalAttribute.effective_to_date : '');
      }

    }
    else if (event && event.action === 'delete') {
      this.showConfirmationDialog = true;
    }
  }

  cancelEdit() {
    this.mode = Mode.Read;
    this.selectedAttributeType = '';
    this.initializeFormFields();
    setTimeout(() => this.cd.detectChanges(), 1000);
  }


  save() {
    const payload = this.preparePayload();
    this.cd.detectChanges();

    this.leadDetailService.saveUpdateAttribute(payload).subscribe(() => {
      this.getAdditionalAttributes(true);

      this.cancelEdit();
    })

  }

  handleConfirmDelete() {
    this.showConfirmationDialog = false;
    if (!this.additionalAttribute) return;
    this.deleteAdditionalAttributes(this.additionalAttribute.attribute_id)
  }


  deleteAdditionalAttributes(id: number) {
    this.leadDetailService.deleteAdditionalAttributes(this.program.program_code, this.entry, id).subscribe(() => {
      this.getAdditionalAttributes(true);
      this.initializeSelectedAttribute();
    })
  }

  preparePayload() {
    const payload: any = {
      id: this.enrollment_id,
      enrollmentType: this.entry,
      programCode: this.program.program_code,
      additional_attribute_id: this.attributeId,
      value: this.dataType === 'date'
        ? this.datePipe.transform(this.attributeValue, 'MM/dd/yyyy')
        : this.attributeValue
    }

    if (this.displayDates) {
      payload.effective_from_date = this.datePipe.transform(this.startDate, 'MM/dd/yyyy'); //super.formatDateString(this.startDate),
      payload.effective_to_date = this.datePipe.transform(this.endDate, 'MM/dd/yyyy'); //super.formatDateStringNoMs(this.endDate)
    } else {
      payload.effective_from_date = null;
      payload.effective_to_date = null;
    }

    if (this.mode === 'edit' && this.additionalAttribute) {
      payload.attribute_id = this.additionalAttribute.attribute_id;
    }

    return payload;
  }


  getAdditionalAttributes(isAfterUpdate: boolean) {
    this.leadDetailService.getAdditionalAttributes(this.program.program_code, this.entry, this.enrollment_id, isAfterUpdate).subscribe((data: any) => {
      const resdata = data.responsedata as AdditionalAttributeResponse;
      this.responseData = resdata.attributes;
      this.transformData(this.responseData)
    });
  }

  transformData(data: AdditionalAttribute[]) {

    // initialize grid data and options
    this.gridData = [];
    this.options = [];


    data?.map((x: AdditionalAttribute) => {
      if (x.data !== null) {
        const Obj = {
          ...x,
          attribute_id: x.data.id,
          value: x.data.value,
          effective_from_date: x.data.effective_from_date ? this.datePipe.transform(x.data.effective_from_date, 'MM/dd/yyyy') : x.data.effective_from_date,
          effective_to_date: x.data.effective_to_date ? this.datePipe.transform(x.data.effective_to_date, 'MM/dd/yyyy') : x.data.effective_to_date
        }
        this.gridData.push(Obj);
        this.gridData = Object.assign([], this.gridData);
      }
      else {
        this.options.push({ ...x, attribute_id: null, disabled: false });
        this.options = Object.assign([], this.options);
      }

      if (x.lookup) {
        this.lookupData[x.attribute_text] = x.lookup;
      }
      this.actionButtons.edit = true;
      this.actionButtons.delete = true;
    });
  }

  onClose() {
    this.showConfirmationDialog = false;
  }
}

