<form nz-form nz-row [formGroup]="authorizationsForm" class="ppl-p0">
    @if (!isConsumerSelected || mode==='edit'){
    @if(mode==='add') {
    <div class="row pb-3 mt-0" style="width: 100%">
        <h4 class="step-header">Step 1: Search Consumer</h4>
    </div>

    }
    <div class="row pb-3 mt-0 ml-0" style="width: 100%">
        @if(isAttrAvailable('consumerFirstName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerFirstName"
                    class="ppl-form-label">{{getAttributes('consumerFirstName').label}}
                    @if(getAttributes('consumerFirstName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerFirstName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerFirstName" id="consumerFirstName"
                            placeholder="{{getAttributes('consumerFirstName').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerFirstName'] }" />
                    </div>
                    @if (validationErrors['consumerFirstName']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerFirstName']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('consumerLastName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerLastName"
                    class="ppl-form-label">{{getAttributes('consumerLastName').label}}
                    @if(getAttributes('consumerLastName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerLastName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerLastName" id="consumerLastName"
                            placeholder="{{getAttributes('consumerLastName').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerLastName'] }" />
                    </div>
                    @if (validationErrors['consumerLastName']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerLastName']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('medicaid')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="medicaid"
                    class="ppl-form-label">{{getAttributes('medicaid').label}}
                    @if(getAttributes('medicaid').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('medicaid')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="medicaid" id="medicaid"
                            placeholder="{{getAttributes('medicaid').placeholder}}"
                            [ngClass]="{'error': validationErrors['medicaid'] }" />
                    </div>
                    @if (validationErrors['medicaid']) {
                    <span class="pl-1 error-message">{{validationErrors['medicaid']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }

        @if(isAttrAvailable('consumerPPLId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerPPLId"
                    class="ppl-form-label">{{getAttributes('consumerPPLId').label}}
                    @if(getAttributes('consumerPPLId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('consumerPPLId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="consumerPPLId" id="consumerPPLId"
                            placeholder="{{getAttributes('consumerPPLId').placeholder}}"
                            [ngClass]="{'error': validationErrors['consumerPPLId'] }" />
                    </div>
                    @if (validationErrors['consumerPPLId']) {
                    <span class="pl-1 error-message">{{validationErrors['consumerPPLId']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
    </div>
    }
    @if(isConsumerSelected) {
    <div class="row pb-3 mt-0" style="width: 100%">
        <h4 class="step-header">Step 3: Authorization Details</h4>
    </div>
    <div class="row pb-3 mt-0 mb-1" style="width: 100%">
        <h5>Consumer Info:</h5>
    </div>
    <div nz-row style="width: 100%" class="ml-3">
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerFirstName" class="ppl-form-label">
                    {{getAttributes('consumerFirstName').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerFirstName}}
                </nz-form-label>
            </nz-row>

        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerLastName" class="ppl-form-label">
                    {{getAttributes('consumerLastName').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerLastName}}
                </nz-form-label>
            </nz-row>

        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">

            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="medicaid" class="ppl-form-label">
                    {{getAttributes('medicaid').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.medicaid}}
                </nz-form-label>
            </nz-row>


        </div>
        <div nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="consumerPPLId" class="ppl-form-label">
                    {{getAttributes('consumerPPLId').label}}
                </nz-form-label>
                <nz-form-label [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    {{selectedConsumer.consumerPPLId}}
                </nz-form-label>
            </nz-row>
        </div>
    </div>
    }

    @if(isConsumerSelected || mode==='edit') {
    @if(mode==='add') {
    <div class="row pb-3 mt-5 mb-3" style="width: 100%; margin-top: 5px">
        <h5>Auth Details:</h5>
    </div>
    }
    <div class="row" style="width: 100%; margin-left: 0">
        @if (mode==='edit') {
        @if(isAttrAvailable('pplAuth')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="pplAuth"
                    class="ppl-form-label">{{getAttributes('pplAuth').label}}
                    @if(getAttributes('pplAuth').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('pplAuth')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="pplAuth" id="pplAuth"
                            placeholder="{{getAttributes('pplAuth').placeholder}}"
                            [ngClass]="{'error': validationErrors['pplAuth'] }" />
                    </div>
                    @if (validationErrors['pplAuth']) {
                    <span class="pl-1 error-message">{{validationErrors['pplAuth']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        }
        @if(isAttrAvailable('clientAuth')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="clientAuth"
                    class="ppl-form-label">{{getAttributes('clientAuth').label}}
                    @if(getAttributes('clientAuth').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('clientAuth')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="clientAuth" id="clientAuth"
                            placeholder="{{getAttributes('clientAuth').tooltip}}"
                            [ngClass]="{'error': validationErrors['clientAuth'] }" />
                    </div>
                    @if (validationErrors['clientAuth']) {
                    <span class="pl-1 error-message">{{validationErrors['clientAuth']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('serviceAdminLkp')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="serviceAdminLkp"
                    class="ppl-form-label">{{getAttributes('serviceAdminLkp').label}}
                    @if(getAttributes('serviceAdminLkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceAdminLkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="serviceAdminLkp" nzMode='default' class="ppl-select"
                            id="serviceAdminLkp" nzPlaceHolder="Select Service Administration" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['serviceAdminLkp'] }">
                            @for(opt of svcAdminList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['serviceAdminLkp']) {
                    <span class="pl-1 error-message">{{validationErrors['serviceAdminLkp']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('orgName')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="orgName"
                    class="ppl-form-label">{{getAttributes('orgName').label}}
                    @if(getAttributes('orgName').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('orgName')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="orgName" nzMode='default' class="ppl-select" id="orgName"
                            nzPlaceHolder="Select MCO ID" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['orgName'] }">
                            @for(opt of mcoEntries; track $index){
                            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['orgName']) {
                    <span class="pl-1 error-message">{{validationErrors['orgName']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('planId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="planId"
                    class="ppl-form-label">{{getAttributes('planId').label}}
                    @if(getAttributes('planId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('planId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="planId" nzMode='default' class="ppl-select" id="planId"
                            nzPlaceHolder="Select Plan ID" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['planId'] }">
                            @for(opt of planIdList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['planId']) {
                    <span class="pl-1 error-message">{{validationErrors['planId']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('serviceCode')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="serviceCode"
                    class="ppl-form-label">{{getAttributes('serviceCode').label}}
                    @if(getAttributes('serviceCode').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('serviceCode')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="serviceCode" nzMode='default' class="ppl-select" id="serviceCode"
                            nzPlaceHolder="Select Service code" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['serviceCode'] }">
                            @for(opt of serviceCodesList; track $index){
                            <nz-option [nzLabel]="opt.code" [nzValue]="opt.serviceCodeDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['serviceCode']) {
                    <span class="pl-1 error-message">{{validationErrors['serviceCode']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('modifierCode1')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="modifierCode1"
                    class="ppl-form-label">{{getAttributes('modifierCode1').label}}
                    @if(getAttributes('modifierCode1').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('modifierCode1')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="modifierCode1" nzMode='default' class="ppl-select"
                            id="modifierCode1" nzPlaceHolder="Select Modifier" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['modifierCode1'] }">
                            @for(opt of modifiersList; track $index){
                            <nz-option [nzLabel]="opt.code" [nzValue]="opt.modifierDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['modifierCode1']) {
                    <span class="pl-1 error-message">{{validationErrors['modifierCode1']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('diagnosisId')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="diagnosisId"
                    class="ppl-form-label">{{getAttributes('diagnosisId').label}}
                    @if(getAttributes('diagnosisId').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('diagnosisId')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="diagnosisId" nzMode='default' class="ppl-select" id="diagnosisId"
                            nzPlaceHolder="Select Primary Diagnosis code" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['diagnosisId'] }">
                            @for(opt of diagnosisCodesList; track $index){
                            <nz-option [nzLabel]="opt.description" [nzValue]="opt.diagnosisDetailId"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['diagnosisId']) {
                    <span class="pl-1 error-message">{{validationErrors['diagnosisId']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('primaryDiagnosisId1')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="primaryDiagnosisId1"
                        class="ppl-form-label">{{getAttributes('primaryDiagnosisId1').label}}
                        @if(getAttributes('primaryDiagnosisId1').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('primaryDiagnosisId1')?.tooltip || ''"
                            nzTooltipPlacement="topRight">
                            <nz-select formControlName="primaryDiagnosisId1" nzMode='default' class="ppl-select" id="primaryDiagnosisId1"
                                nzPlaceHolder="Select Primary Diagnosis code" nzShowSearch nzAllowClear
                                [ngClass]="{'error': validationErrors['primaryDiagnosisId1'] }">
                                @for(opt of diagnosisCodesList; track $index){
                                <nz-option [nzLabel]="opt.description" [nzValue]="opt.diagnosisDetailId"></nz-option>
                                }
                            </nz-select>
                        </div>
                        @if (validationErrors['primaryDiagnosisId1']) {
                        <span class="pl-1 error-message">{{validationErrors['primaryDiagnosisId1']}}</span>
                        }
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
            @if(isAttrAvailable('primaryDiagnosisId2')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                    <nz-row>
                        <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="primaryDiagnosisId2"
                            class="ppl-form-label">{{getAttributes('primaryDiagnosisId2').label}}
                            @if(getAttributes('primaryDiagnosisId2').mandatory === 'Y'){
                            <span>*</span>
                            }
                        </nz-form-label>
                        <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                            <div nz-tooltip [nzTooltipTitle]="getAttributes('primaryDiagnosisId2')?.tooltip || ''"
                                nzTooltipPlacement="topRight">
                                <nz-select formControlName="primaryDiagnosisId2" nzMode='default' class="ppl-select" id="primaryDiagnosisId2"
                                    nzPlaceHolder="Select Primary Diagnosis code" nzShowSearch nzAllowClear
                                    [ngClass]="{'error': validationErrors['primaryDiagnosisId2'] }">
                                    @for(opt of diagnosisCodesList; track $index){
                                    <nz-option [nzLabel]="opt.description" [nzValue]="opt.diagnosisDetailId"></nz-option>
                                    }
                                </nz-select>
                            </div>
                            @if (validationErrors['primaryDiagnosisId2']) {
                            <span class="pl-1 error-message">{{validationErrors['primaryDiagnosisId2']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }
                @if(isAttrAvailable('primaryDiagnosisId3')){
                    <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                        <nz-row>
                            <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="primaryDiagnosisId3"
                                class="ppl-form-label">{{getAttributes('primaryDiagnosisId3').label}}
                                @if(getAttributes('primaryDiagnosisId3').mandatory === 'Y'){
                                <span>*</span>
                                }
                            </nz-form-label>
                            <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                                <div nz-tooltip [nzTooltipTitle]="getAttributes('primaryDiagnosisId3')?.tooltip || ''"
                                    nzTooltipPlacement="topRight">
                                    <nz-select formControlName="primaryDiagnosisId3" nzMode='default' class="ppl-select" id="primaryDiagnosisId3"
                                        nzPlaceHolder="Select Primary Diagnosis code" nzShowSearch nzAllowClear
                                        [ngClass]="{'error': validationErrors['primaryDiagnosisId3'] }">
                                        @for(opt of diagnosisCodesList; track $index){
                                        <nz-option [nzLabel]="opt.description" [nzValue]="opt.diagnosisDetailId"></nz-option>
                                        }
                                    </nz-select>
                                </div>
                                @if (validationErrors['primaryDiagnosisId3']) {
                                <span class="pl-1 error-message">{{validationErrors['primaryDiagnosisId3']}}</span>
                                }
                            </nz-form-control>
                        </nz-row>
                    </nz-form-item>
                    }
        <!-- </div> -->
        <!-- <div class="row" style="width: 100%; margin-left: 0">
    </div> -->
        <!-- <div class="row" style="width: 100%; margin-left: 0"> -->
        @if(isAttrAvailable('source')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="source"
                    class="ppl-form-label">{{getAttributes('source').label}}
                    @if(getAttributes('source').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('source')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="source" nzMode='default' class="ppl-select" id="source"
                            nzPlaceHolder="Select Source" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['source'] }">
                            @for(opt of authSourceList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['source']) {
                    <span class="pl-1 error-message">{{validationErrors['source']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('authFrequencyLkp')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="authFrequencyLkp"
                        class="ppl-form-label">{{getAttributes('authFrequencyLkp').label}}
                        @if(getAttributes('authFrequencyLkp').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('authFrequencyLkp')?.tooltip || ''"
                            nzTooltipPlacement="topRight">
                            <nz-select formControlName="authFrequencyLkp" nzMode='default' class="ppl-select" id="authFrequencyLkp"
                            nzPlaceHolder="{{getAttributes('authFrequencyLkp').placeholder}}" zShowSearch nzAllowClear
                                [ngClass]="{'error': validationErrors['authFrequencyLkp'] }">
                                @for(opt of authFrequencyTypeList; track $index){
                                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueName"></nz-option>
                                }
                            </nz-select>
                        </div>
                        @if (validationErrors['authFrequencyLkp']) {
                        <span class="pl-1 error-message">{{validationErrors['authFrequencyLkp']}}</span>
                        }
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
        
        @if(authorizationsForm.get('authFrequencyLkp')?.value === 'Daily'){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                  <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="dailyHours" class="ppl-form-label">
                    {{getAttributes('dailyHours').label}}
                    <span *ngIf="getAttributes('dailyHours').mandatory === 'Y'">*</span>
                  </nz-form-label>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="sunHrs" class="ppl-form-label">{{getAttributes('sunHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('sunHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="sunHrs" id="sunHrs" placeholder="{{getAttributes('sunHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['sunHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['sunHrs']" class="pl-1 error-message">{{validationErrors['sunHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="monHrs" class="ppl-form-label">{{getAttributes('monHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('monHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="monHrs" id="monHrs" placeholder="{{getAttributes('monHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['monHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['monHrs']" class="pl-1 error-message">{{validationErrors['monHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="tuesHrs" class="ppl-form-label">{{getAttributes('tuesHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('tuesHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="tuesHrs" id="tuesHrs" placeholder="{{getAttributes('tuesHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['tuesHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['tuesHrs']" class="pl-1 error-message">{{validationErrors['tuesHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="wedHrs" class="ppl-form-label">{{getAttributes('wedHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('wedHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="wedHrs" id="wedHrs" placeholder="{{getAttributes('wedHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['wedHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['wedHrs']" class="pl-1 error-message">{{validationErrors['wedHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="thuHrs" class="ppl-form-label">{{getAttributes('thuHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('thuHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="thuHrs" id="thuHrs" placeholder="{{getAttributes('thuHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['thuHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['thuHrs']" class="pl-1 error-message">{{validationErrors['thuHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="friHrs" class="ppl-form-label">{{getAttributes('friHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('friHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="friHrs" id="friHrs" placeholder="{{getAttributes('friHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['friHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['friHrs']" class="pl-1 error-message">{{validationErrors['friHrs']}}</span>
                  </nz-form-control>
                  <nz-form-control [nzSm]="2" [nzXs]="24" class="ppl-form-control hours-box">
                    <nz-form-label nzFor="satHrs" class="ppl-form-label">{{getAttributes('satHrs').label}}</nz-form-label>
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('satHrs')?.tooltip || ''" nzTooltipPlacement="topRight">
                      <input nz-input formControlName="satHrs" id="satHrs" placeholder="{{getAttributes('satHrs').placeholder}}"
                        [ngClass]="{'error': validationErrors['satHrs']}" />
                    </div>
                    <span *ngIf="validationErrors['satHrs']" class="pl-1 error-message">{{validationErrors['satHrs']}}</span>
                  </nz-form-control>
              <nz-col [nzSm]="2" [nzXs]="24"></nz-col>
                </nz-row>
              </nz-form-item>
              
        }
        @else{
            @if(isAttrAvailable('units')){
                <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                    <nz-row>
                        <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="units"
                            class="ppl-form-label">{{getAttributes('units').label}}
                            @if(getAttributes('units').mandatory === 'Y'){
                            <span>*</span>
                            }
                        </nz-form-label>
                        <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                            <div nz-tooltip [nzTooltipTitle]="getAttributes('units')?.tooltip || ''"
                                nzTooltipPlacement="topRight">
                                <input nz-input formControlName="units" id="units"
                                    placeholder="{{getAttributes('units').tooltip}}"
                                    [ngClass]="{'error': validationErrors['units'] }" />
                            </div>
                            @if (validationErrors['units']) {
                            <span class="pl-1 error-message">{{validationErrors['units']}}</span>
                            }
                        </nz-form-control>
                    </nz-row>
                </nz-form-item>
                }
        }
        @if(isAttrAvailable('unittypeLkp')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="unittypeLkp"
                    class="ppl-form-label">{{getAttributes('unittypeLkp').label}}
                    @if(getAttributes('unittypeLkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>

                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('unittypeLkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="unittypeLkp" nzMode='default' class="ppl-select" id="unitType"
                            nzPlaceHolder="Select Unit Type" nzShowSearch nzAllowClear
                            [ngClass]="{'error': validationErrors['unittypeLkp'] }">
                            @for(opt of unitTypeList; track $index){
                            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                            }
                        </nz-select>
                    </div>
                    @if (validationErrors['unittypeLkp']) {
                    <span class="pl-1 error-message">{{validationErrors['unittypeLkp']}}</span>
                    }
                </nz-form-control>

            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('dollars')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="dollars"
                    class="ppl-form-label">{{getAttributes('dollars').label}}
                    @if(getAttributes('dollars').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('dollars')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="dollars" id="dollars"
                            placeholder="{{getAttributes('dollars').tooltip}}"
                            [ngClass]="{'error': validationErrors['dollars'] }" />
                    </div>
                    @if (validationErrors['dollars']) {
                    <span class="pl-1 error-message">{{validationErrors['dollars']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('startDate')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="startDate"
                    class="ppl-form-label">{{getAttributes('startDate').label}}
                    @if(getAttributes('startDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('startDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="startDate" id="startDate" [nzFormat]="'MM/dd/yyyy'"
                            [nzPlaceHolder]="getAttributes('startDate').placeholder"
                            [nzDisabledDate]="disabledStartDate"
                            [ngClass]="{'error': validationErrors['startDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['startDate']) {
                    <span class="pl-1 error-message">{{validationErrors['startDate']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('endDate')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="endDate"
                    class="ppl-form-label">{{getAttributes('endDate').label}}
                    @if(getAttributes('endDate').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('endDate')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <nz-date-picker formControlName="endDate" id="endDate" [nzFormat]="'MM/dd/yyyy'"
                            [nzPlaceHolder]="getAttributes('endDate').placeholder" [nzDisabledDate]="disabledEndDate"
                            [ngClass]="{'error': validationErrors['endDate'] }"></nz-date-picker>
                    </div>
                    @if (validationErrors['endDate']) {
                    <span class="pl-1 error-message">{{validationErrors['endDate']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('carryoverUnits')){
            <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
                <nz-row>
                    <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="carryoverUnits"
                        class="ppl-form-label">{{getAttributes('carryoverUnits').label}}
                        @if(getAttributes('carryoverUnits').mandatory === 'Y'){
                        <span>*</span>
                        }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                        <div nz-tooltip [nzTooltipTitle]="getAttributes('carryoverUnits')?.tooltip || ''"
                            nzTooltipPlacement="topRight">
                            <nz-select formControlName="carryoverUnits" nzMode='default' class="ppl-select" id="carryoverUnits"
                                nzPlaceHolder="" nzShowSearch nzAllowClear
                                [ngClass]="{'error': validationErrors['carryoverUnits'] }">
                                @for(opt of radioOptions; track $index){
                                    <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                                    }
                            </nz-select>
                        </div>
                        @if (validationErrors['carryoverUnits']) {
                        <span class="pl-1 error-message">{{validationErrors['carryoverUnits']}}</span>
                        }
                    </nz-form-control>
                </nz-row>
            </nz-form-item>
            }
        @if(mode === 'edit'){
        @if(isAttrAvailable('sourceDetail')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="sourceDetail"
                    class="ppl-form-label">{{getAttributes('sourceDetail').label}}
                    @if(getAttributes('sourceDetail').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('sourceDetail')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="sourceDetail" id="sourceDetail"
                            placeholder="{{getAttributes('sourceDetail').placeholder}}"
                            [ngClass]="{'error': validationErrors['sourceDetail'] }" />
                    </div>
                    @if (validationErrors['sourceDetail']) {
                    <span class="pl-1 error-message">{{validationErrors['sourceDetail']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('statusLkp')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="statusLkp"
                    class="ppl-form-label">{{getAttributes('statusLkp').label}}
                    @if(getAttributes('statusLkp').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('statusLkp')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="statusLkp" id="statusLkp"
                            placeholder="{{getAttributes('statusLkp').placeholder}}"
                            [ngClass]="{'error': validationErrors['statusLkp'] }" />
                    </div>
                    @if (validationErrors['statusLkp']) {
                    <span class="pl-1 error-message">{{validationErrors['statusLkp']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('decisionComments')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="decisionComments"
                    class="ppl-form-label">{{getAttributes('decisionComments').label}}
                    @if(getAttributes('decisionComments').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('decisionComments')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="decisionComments" id="decisionComments"
                            placeholder="{{getAttributes('decisionComments').placeholder}}"
                            [ngClass]="{'error': validationErrors['decisionComments'] }" />
                    </div>
                    @if (validationErrors['decisionComments']) {
                    <span class="pl-1 error-message">{{validationErrors['decisionComments']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        @if(isAttrAvailable('decisionBy')){
        <nz-form-item nz-col nzSpan="12" class="ppl-form-item nz-padding-left">
            <nz-row>
                <nz-form-label [nzSm]="10" [nzXs]="24" nzFor="decisionBy"
                    class="ppl-form-label">{{getAttributes('decisionBy').label}}
                    @if(getAttributes('decisionBy').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('decisionBy')?.tooltip || ''"
                        nzTooltipPlacement="topRight">
                        <input nz-input formControlName="decisionBy" id="decisionBy"
                            placeholder="{{getAttributes('decisionBy').placeholder}}"
                            [ngClass]="{'error': validationErrors['decisionBy'] }" />
                    </div>
                    @if (validationErrors['decisionBy']) {
                    <span class="pl-1 error-message">{{validationErrors['decisionBy']}}</span>
                    }
                </nz-form-control>
            </nz-row>
        </nz-form-item>
        }
        }
    </div>
    }


</form>
@if(authAdded){
<!-- <ppl-accordion [title]="'Upload Documents'" [isOpen]="true"> -->
<div class="row pb-3 mt-8" style="width: 100%">
    <h4 class="step-header">Step 4: Upload Documents</h4>
</div>
<ppl-file-upload [filedInfo]="filedInfo" [authId]="savedAuthId" [sectionData]="" [entry]="'auth'"
    (filesUpdated)="filesUpdated()" [readonly]="false"></ppl-file-upload>
<!-- </ppl-accordion> -->
}
@if(isNew){
@if (!isConsumerSelected && mode==='add') {
<div class="custom-row">
    <ppl-button label="Search" icon="fa fa-check-circle" [customClass]="'primary-button'" [iconPosition]="'right'"
        (click)="onConsumerSearch()"></ppl-button>
</div>
<!-- <div class="row">
    <p>* Please search and select a consumer to add an authorization.</p>
</div> -->
@if(showSearchError) {
<p class="pl-1 error-message">Please enter atleast one search criteria.</p>
}
}
}

@if (showConsumersList) {
<div class="row" style="width: 100%">
    <h4 class="step-header">Step 2: Select Consumer</h4>
</div>
<div class="table-style">
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true" [data]="gridData"
        [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="true" [rowsToDisplay]="10"
        [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
    </ppl-list-table>
</div>
}

@if(isNew){
@if(isConsumerSelected) {
<div class="custom-row">
    @if(!authAdded){
    <ppl-button label="Submit" icon="fa fa-check-circle" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="onAdd()"></ppl-button>
    }
    <!-- <ppl-button label="Submit & Add more" icon="fa fa-check-circle" [customClass]="'alt-button'"
            [iconPosition]="'right'" (click)="onAddMoreAuths()"></ppl-button>
        <ppl-button label="Cancel and select User" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'right'"
            (click)="onCancelUser()"></ppl-button> -->
</div>
}
@if (mode==='edit' && !disableForm) {
<div class="custom-row">
    @if(mode==="edit" ){
    <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onCancelUpdate()"></ppl-button>
    <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
        (click)="onUpdate()"></ppl-button>
    }
</div>
}

@if (showAddedAuths) {
<div class="row" style="width: 100%">
    <h5>Authorizations Added</h5>
</div>
<div class="table-style">
    <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="false" [data]="authsInSession"
        [disableSelection]="true" [cols]="authCols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
        (gridAction)="action($event)" [actionsToBeHidden]="actionsToBeHidden">
    </ppl-list-table>
</div>
}

}