{
  "columns": [
    {
      "header_id": "serviceCode",
      "header_label": "Service Code",
      "jsonpath": "serviceCode",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "rate",
      "header_label": "Rate",
      "jsonpath": "rate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "regionName",
      "header_label": "Region",
      "jsonpath": "regionName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "countyName",
      "header_label": "County",
      "jsonpath": "countyName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "grandFathered",
      "header_label": "GrandFathered",
      "jsonpath": "grandFathered",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "union",
      "header_label": "Union",
      "jsonpath": "union",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "cbatypeLkp",
      "header_label": "CBA Type",
      "jsonpath": "cbatypeLkp",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "consumerTypeLkp",
      "header_label": "Consumer Type",
      "jsonpath": "consumerTypeLkp",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "modifierCode",
      "header_label": "Modifier Code",
      "jsonpath": "modifierCode",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "fromDate",
      "header_label": "From Date",
      "jsonpath": "fromDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "toDate",
      "header_label": "To Date",
      "jsonpath": "toDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    }
  ],
  "buttons": [],
  "apiDetails": "list_payable_personal_assistant_rates.json",
  "pageName": "pa-rate-list",
  "orderByColumn": ["payRateId"],
  "orderBy": ["desc"]
}
