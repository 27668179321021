import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { ParticipantFormData } from '../../../interfaces/referralSections.interface';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { LookupService } from '../../../services/lookup.service';
import { performValidation } from '../../../shared/utils/validation.util';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { RADIO_YES_NO_OPTIONS, STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { differenceInCalendarDays } from 'date-fns';
import { DateTimeFormatterService } from '../../../shared/utils/date-time-formatter.service';
import { LookupValueItem } from '../../../interfaces/lookup.interface';
import { PopupDialogComponent } from "../../../shared/components/popup-dialog/popup-dialog.component";
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { CdpapFormComponent } from "../../../shared/components/cdpap-form/cdpap-form.component";
import { CdpapService } from '../../../services/cdpap.service';

@Component({
  selector: 'ppl-participant-info',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzRadioModule,
    NzDatePickerModule,
    CommonModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzSelectModule,
    UploadComponent,
    NzDividerModule,
    PopupDialogComponent,
    ButtonComponent,
    CdpapFormComponent
],
  templateUrl: './participant-information.component.html',
  styleUrl: './participant-information.component.css',
})
export class ParticipantInformationComponent
  extends BaseComponent
  implements OnInit, OnDestroy {
  @Input() mode = 'edit';
  @Input() source?: string = '';
  filledInfo: ParticipantFormData = {};
  private navigationSubscription: Subscription | undefined;
  infoForm!: FormGroup;
  ssnVisible = false;
  gender: any[] = [];
  formHasBeenFilled = false;
  radioOptions = RADIO_YES_NO_OPTIONS;
  ssnWith9 = false;
  flow: string | null = '';
  waiver: any[] = [];
  state: any;
  states: any[] = [];
  fiEntries: any;
  mcoEntries: any;
  relationshipOptions: any;
  eyeColors: any;
  hairColors: any;
  raceOptions: any;
  taxExemptOptions: any;
  formReadonly = false;
  services: any;
  ldssOfficeValues: LookupValueItem[] =[];
  currentFIValues: LookupValueItem[] = [];
  disabledDate: any;

  constructor(
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private sectionsService: SectionsService,
    private referralDataService: ReferralDataService,
    private datePipe: DatePipe,
    private lookupService: LookupService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private cdpapService: CdpapService
  ) {
    super();
  }

  ngOnInit(): void {
    this.infoForm = this.fb.group({
      fname: [''],
      lname: [''],
      dob: [null],
      gender: [null],
      SSN: [''],
    });
    this.flow = this.sectionsService.getFlow();
    const associationFlows = ['employer', 'authRepresentative', 'provider', 'vendor', 'contractor', 'legalGuardian', 'poa'];
    console.log("this.flow", this.flow);
    if (this.flow == 'participants') {
      this.infoForm.addControl('mname', this.fb.control(''));
      this.infoForm.addControl('maiden_name', this.fb.control(''));
      this.infoForm.addControl('CIN', this.fb.control(''));
    }
    else if (this.flow == 'providers') {
      this.infoForm.addControl('mname', this.fb.control(''));
      this.infoForm.addControl('maiden_name', this.fb.control(''));
      this.infoForm.addControl('isProvidingServices', this.fb.control(''));
      this.infoForm.addControl('showOnDirectory', this.fb.control(null));
      this.infoForm.addControl('servicesRendered', this.fb.control(null));
    }
    else if (this.flow == 'referral') {
      this.infoForm.addControl('mname', this.fb.control(''));
      this.infoForm.addControl('maiden_name', this.fb.control(''));
      this.infoForm.addControl('waiver', this.fb.control(''));
      this.infoForm.addControl('CIN', this.fb.control(''));
      this.infoForm.addControl('mcoOrgName', this.fb.control(''));
      this.infoForm.addControl('fiOrgName', this.fb.control(''));
      this.infoForm.addControl('eligiblityStartDate', this.fb.control(null));
      this.infoForm.addControl('eligiblityEndDate', this.fb.control(null));
      this.infoForm.addControl('ldssOffice', this.fb.control(''));
    }
    else if (this.flow == 'participantEnrollment') {
      this.infoForm.addControl('mname', this.fb.control(''));
      this.infoForm.addControl('maiden_name', this.fb.control(''));
      this.infoForm.addControl('waiver', this.fb.control(''));
      this.infoForm.addControl('mcoOrgName', this.fb.control(''));
      this.infoForm.addControl('fiOrgName', this.fb.control(''));
      this.infoForm.addControl('eligiblityStartDate', this.fb.control(null));
      this.infoForm.addControl('eligiblityEndDate', this.fb.control(null));
      this.infoForm.addControl('CIN', this.fb.control(''));
      this.infoForm.addControl('ldssOffice', this.fb.control(''));
      this.infoForm.addControl('currentFI', this.fb.control(''));
      this.infoForm.addControl('referralId', this.fb.control(''));
    }
    else {
      this.infoForm.addControl('businessName', this.fb.control(''));
      this.infoForm.addControl('mname', this.fb.control(''));
      this.infoForm.addControl('maiden_name', this.fb.control(''));
      this.infoForm.addControl('CIN', this.fb.control(''));
      this.infoForm.addControl('EIN', this.fb.control(''));
      this.infoForm.addControl('isProvidingServices', this.fb.control(null));
      this.infoForm.addControl('waiver', this.fb.control(''));
      this.infoForm.addControl('relationToParticipant', this.fb.control(''));
      this.infoForm.addControl('NPI', this.fb.control(''));
      this.infoForm.addControl('race', this.fb.control(''));
      this.infoForm.addControl('heightFeet', this.fb.control(''));
      this.infoForm.addControl('heightInches', this.fb.control(''));
      this.infoForm.addControl('weight', this.fb.control(''));
      this.infoForm.addControl('eyeColor', this.fb.control(''));
      this.infoForm.addControl('hairColor', this.fb.control(''));
      this.infoForm.addControl('birthCity', this.fb.control(''));
      this.infoForm.addControl('birthState', this.fb.control(''));
      this.infoForm.addControl('birthCountry', this.fb.control(''));
      this.infoForm.addControl('showOnDirectory', this.fb.control(''));
      this.infoForm.addControl('mcoOrgName', this.fb.control(''));
      this.infoForm.addControl('fiOrgName', this.fb.control(''));
      this.infoForm.addControl('taxExempt', this.fb.control(''));
      this.infoForm.addControl('nprftDocumentationReceived', this.fb.control(''));
      this.infoForm.addControl('yearsInState', this.fb.control(''));
    }
    this.formReadonly = this.referralDataService.getAssociationsEditable();

    if (this.formReadonly) {
      this.infoForm.disable()
    } else {
      this.infoForm.enable()
    }

    const today = new Date();
    this.disabledDate = (current: Date): boolean => {
      return differenceInCalendarDays(current, today) >= 0;
    }
    this.states = this.getLookup("STATE");
    this.relationshipOptions = this.getLookup('Relationship');
    this.eyeColors = this.getLookup('Eye Color');
    this.hairColors = this.getLookup('Hair Color');
    this.raceOptions = this.getLookup('Race');
    this.taxExemptOptions = this.getLookup('Tax Exemption');
    this.services = this.getLookup('Service_Type');
    this.ldssOfficeValues = this.getLookup('LDSS_Office');
    this.currentFIValues = this.getLookup('CFI');

    this.navigationSubscription = this.infoForm.valueChanges.subscribe(
      () => {
        // if (!this.formHasBeenFilled && this.hasAnyValue(this.infoForm)) {
        const hasValue = this.hasAnyValueInForm(this.infoForm);
        this.formHasBeenFilled = hasValue;
        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isInfoFormUpdated',
            this.formHasBeenFilled
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isInfoFormUpdated',
            this.formHasBeenFilled
          );
        }

        this.infoForm.value.dob =
          this.datePipe.transform(this.infoForm.value.dob, 'MM/dd/yyyy') || '';
        if (this.infoForm.value.eligiblityStartDate && this.infoForm.value.eligiblityStartDate instanceof Date) {
          this.infoForm.value.eligiblityStartDate = this.dateTimeFormatterService.formatDateToString(this.infoForm.value.eligiblityStartDate as Date);
        }
        if (this.infoForm.value.eligiblityEndDate && this.infoForm.value.eligiblityEndDate instanceof Date) {
          this.infoForm.value.eligiblityEndDate = this.dateTimeFormatterService.formatDateToString(this.infoForm.value.eligiblityEndDate as Date);
        }
        if (this.flow === 'referral' || this.flow === 'participantEnrollment') {
          let mcoOrgId = null;
          let fiOrgId = null;
          if (this.infoForm.value.mcoOrgName) {
            mcoOrgId = this.mcoEntries?.find((entry: any) => entry.orgName === this.infoForm.value.mcoOrgName)?.orgId || null;
          }
          if (this.infoForm.value.fiOrgName) {
            fiOrgId = this.fiEntries?.find((entry: any) => entry.orgName === this.infoForm.value.fiOrgName)?.orgId || null;
          }
          const formDataModified = {
            ...this.infoForm.value,
            mcoOrgId,
            fiOrgId
          };
          this.referralDataService.setReferralFormData(
            'infoData',
            formDataModified
          );
        } else {
          if (!associationFlows.includes(this.flow ?? '')) {
            this.referralDataService.setReferralFormData(
              'infoData',
              this.infoForm.value
            );
          } else {
            this.referralDataService.setAssociationFormData(
              'infoData',
              this.infoForm.value
            );
          }
        }
        this.referralDataService.setChanged(!hasValue ? false : this.infoForm.dirty);
        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isInfoFormDirty',
            !hasValue ? false : this.infoForm.dirty
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isInfoFormDirty',
            !hasValue ? false : this.infoForm.dirty
          );
        }
      }
    );

    // if (this.mode == "read") {
    //   this.filledInfo = this.referralDataService.getReferralFormData().infoData;
    // }
    if (!associationFlows.includes(this.flow ?? '')) {
      console.log('getting data in component')
      if (this.referralDataService.getReferralFormData().infoData) {
        this.filledInfo = this.referralDataService.getReferralFormData().infoData;
        this.state = this.referralDataService.getReferralFormData()?.state?.state;
        this.infoForm.patchValue(this.filledInfo);
      }
    } else {
      console.log('patching form');
      if (this.referralDataService.getAssociationFormData().infoData) {
        this.filledInfo = this.referralDataService.getAssociationFormData().infoData;
        this.state = this.referralDataService.getAssociationFormData()?.state?.state;
        this.infoForm.patchValue(this.filledInfo);
      }
    }

    this.waiver = this.getLookup('Waiver');
    this.i18n.setLocale(en_US);
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    let steps: any;
    if (this.flow === 'providerEnrollment') {
      steps = this.sectionsService.getProviderSectionData(
        program.program_code,
        STATIC_DATA.PARTICIPANT_INFO
      );
      this.secData = steps;
    } else {
      steps = this.sectionsService.getSectionData(
        program.program_code,
        this.source
      );
      this.secData = steps[STATIC_DATA.PARTICIPANT_INFO];
    }

    if (associationFlows.includes(this.flow ?? '')) {
      this.referralDataService.associationSaveClicked.subscribe(() => {
        
        this.validationErrors = performValidation(
          this.infoForm.value,
          this.secData,
          'Next'
        );
        if (this.referralDataService?.referralFormData?.infoData && (this.infoForm.value.relationToParticipant == 'PRSP' || this.infoForm.value.relationToParticipant == 'SPSE')) {
          let dob = this.dateTimeFormatterService.formatStringToDate(this.referralDataService?.referralFormData?.infoData?.dob);
          if (this.getAge(dob) < 21) {
            this.validationErrors['relationToParticipant'] = "Parent or spouse cannot be a Personal Assistant";
          }
        }
        this.referralDataService.setError(this.validationErrors);
        this.referralDataService.setAssociationFormData(
          'isInfoFormValid',
          Object.keys(this.validationErrors).length == 0
        );
      });
    }

    this.gender = this.getLookup('Gender');
    this.infoForm.get('SSN')?.valueChanges.subscribe((value) => {
      this.checkSSN(value);
    });

    if (associationFlows.includes(this.flow ?? '')) {
      const filledData = this.referralDataService.getAssociationDetails(
        'infoData'
      )!;
      if (filledData) {
        this.infoForm.patchValue(filledData);
      }
    }

    if (((this.flow == 'referral' && localStorage.getItem('lead_entry') == 'referral') || this.flow === 'participantEnrollment' || this.flow === 'providerEnrollment') && !this.referralDataService.getReferralFormData().infoData) {
      const filledData = this.referralDataService.getReferralDetails(
        'infoData', this.flow
      )!;
      if (filledData) {
        this.infoForm.patchValue(filledData);
      }
      this.referralDataService.setReferralFormData('stage', filledData.applicationStage);
    }

    this.referralDataService.nextClicked.subscribe((val: any) => {
      if (val === STATIC_DATA.PARTICIPANT_INFO) {
        this.validationErrors = performValidation(
          this.infoForm.value,
          this.secData,
          'Next'
        );
        this.referralDataService.setError(this.validationErrors);
      }
    });

    this.lookupService.getOrgData(program.program_code,).subscribe((data: any) => {
      if (data.status == 200) {
        this.fiEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'FI');
        this.mcoEntries = data.responsedata.filter((item: any) => item.orgTypeLookup === 'MCO');
      }
    })

    this.cdpapService.cdPapFormValueChangeSub$.pipe().subscribe(v => {
      this.infoForm.get('fiOrgName')!.setValue(v);
    })
  }

  openCDPAPPopup() {
    this.cdpapService.openPopup();
  }

  checkSSN(value: string): void {
    if (value && value.startsWith('9')) {
      this.ssnWith9 = true;
    } else {
      this.ssnWith9 = false;
    }
  }

  // hasAnyValue(form: FormGroup): boolean {
  //   return Object.values(form.controls).some(control => control?.value?.trim() !== undefined && control?.value?.trim() !== '' && control?.value?.trim() !== null);
  // }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  limitDigits(event: any): void {
    let value = event.target.value.replace(/\D/g, '');
    if(value.length > 9) {
      value = value.slice(0,9);
    }

    event.target.value = value;
    this.infoForm.get('SSN')?.setValue(value);
  }

  getAge(DOB: Date) {
    var today = new Date();
    var birthDate = DOB;
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  disabledStartDate = (startValue: Date): boolean => {
    const datePickerValue = this.infoForm.get('eligiblityEndDate')?.value;
    const endDate = typeof datePickerValue === 'string' ? new Date(datePickerValue) : datePickerValue;
    if (!startValue || !endDate) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const datePickerValue = this.infoForm.get('eligiblityStartDate')?.value;
    const startDate = typeof datePickerValue === 'string' ? new Date(datePickerValue) : datePickerValue;
    if (!endValue || !startDate) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

}
