
export const ERROR_MESSAGES = {
    NO_DATA_FOUND : 'No data found.',
    AUTH_FAILED : 'User Authentication failure. Please try to login again, or, contact support.',
    DOWNLOAD_FAILED : 'Failed to download. Please try again.',
    DOWNLOAD_SUCCESS : 'File downloaded successfully.',
    FAILED_SEARCH_CRITERIA : 'No data found. Try again with updated search criteria.',
    USER_ASSIGNMENT_FAILURE : 'User assignment failed. Please retry, or, check if user exists.',
    PROGRAM_CHANGE_FAILURE : "Unable to fetch Program details. Please try again later.",
    PARTICIPANT_DETAILS_NOT_FOUND: "Unable to fetch Consumer Details. Please try again",
    FAILED_TO_CREATE_PARTICIPANT_LEAD: "Unable to create Consumer Lead. Please try again.",
    FAILED_TO_GET_LOOKUP_DATA: "Could not fetch Lookup Data. Please try again.",
    FAILED_FETCH_PAGINATION_DATA: "Could not fetch data of the next page. Please try again.",
    
    SUCCESS_ADD_LEAD_COMMENTS : "Comment added successfully",
    SUCCESS_UPDATE_LEAD_COMM_INFO : "Communication information updated successfully",
    NO_UPDATE_LEAD_COMM_INFO : "Atleast some data is required to save.",
    SUCCESS_ADD_UPDATE_LEAD_COMM_METHODS : "Contact Details updated successfully",
    SUCCESS_DELETE_LEAD_COMM_METHODS :"Contact method deleted successfully",
    SUCCESS_DELETE_LEAD_IDENTIFIER :"Identifier deleted successfully",
    SUCCESS_UPDATE_LEAD_ADDRESS : "Address updated successfully",
    SUCCESS_UPDATE_LEAD_DEMOGRAPHICS :"Demographics updated successfully",

    SUCCESS_ADD_UPDATE_LEAD_IDENTIFIER : "Identifier data updated successfully",
    SUCCESS_UPDATE_SERVICES : "Services rendered updated successfully",
    SUCCESS_UPDATE_PROF_LICENCES : "Professional services updated successfully",

    SUCCESS_PROSPECTIVE_PARTICIPANTS_DELETE : "Prospective Consumer deleted successfully",
    SUCCESS_PROSPECTIVE_PARTICIPANTS_ADD : "Prospective Consumer add/update success",
    SUCCESS_PROSPECTIVE_PARTICIPANT_VERIFY: "Prospective Consumer verified successfully",
    SUCCESS_PARTICIPANT_VIEWER: "New user added to visibility permissions",
    SUCCESS_PARTICIPANT_VIEWER_UPDATE: "Visibility permission updated successfully",
    SUCCESS_PARTICIPANT_VIEWER_INACTIVE: "Visibility permission disabled successfully",

    SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_SAVE: "New status added successfully",
    SUCCESS_PARTICIPANT_ENROLLMENT_STATUS_UPDATE: "Status updated successfully",
    
    SUCCESS_PARTICIPANT_PROVIDER_ASSOC: "Association removed successfully",

    SUCCESS_FILE_UPLOAD: "File uploaded successfully",
    SUCCESS_FILE_DELETE:"File deleted successfully",
    
    SUCCESS_SAVE_PART_PROVIDERS_FORMS_COMMENTS : "Changes save successfully",
    SUCCESS_REFRESH_FORMS: "Refresh successfully",

    SUCCESS_ADDITIONAL_ATTRIBUTE_DELETE : "Additional Attribute deleted successfully",
    SUCCESS_ADDITIONAL_ATTRIBUTE_ADD : "Additional Attribute add/update success",
    SUCCESS_ADDITIONAL_ATTRIBUTE_UPDATE: "Prospective Consumer verified successfully",
    SUCCESS_PARTICIPANT_PROVIDER_ASSOCIATION: "Personal Assistant added successfully",

    ERROR_401 : "Your are not authenticated",
    ERROR_500 : "Request failed. Please try again.",
    ERROR_502 : "We're experiencing some technical difficulties. Try reloading the page in a few moments.",
    ERROR_504 : "Request timeout. Please try again.",
    ERROR_0 : "Please check your connectivity."


}


