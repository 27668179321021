/* src/app/components/leads/enrollment/enrollment-service-details/enrollment-service-details.component.css */
.ppl-form-item {
  margin: 4px 8px !important;
}
.ppl-step-main {
  padding: 0px !important;
  border-radius: 0 !important;
  background-color: #eaeaea;
}
::ng-deep .ant-card-body {
  padding: 0px !important;
}
.checkbox {
  width: auto !important;
  height: auto !important;
}
.bullet-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 0px;
}
.bullet-item {
  margin-bottom: 2px;
}
/*# sourceMappingURL=enrollment-service-details.component-ZQUD5X4R.css.map */
