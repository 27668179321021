<div class="ppl-container-body">
  <div class="ppl-container-head">
    Create New {{getTitle()}}

    <div class="action">
      @if(showTrackingInfo){
      <div class="tracking-info">
        @if(trackingNumber){
        <div class="tracking-number">
          <strong class="primary-color-text">Tracking Number: </strong>{{trackingNumber}}
        </div>
        }

        @if(providerPPLId || participantPPLId){
        <div class="tracking-ppl-id">
          <strong class="primary-color-text">PPL ID: </strong> {{providerPPLId || participantPPLId}}
        </div>
        }
      </div>
      }
      <ppl-button label="" ariaLabel="Close" icon="fa fa-close" [customClass]="'rounded'" [iconPosition]="'left'"
        (handleClick)="returnClicked()">
        ></ppl-button>
    </div>
  </div>

  <div class="wiz-body">
    <div class="Sidebar">
      <ppl-steps class="steps" (stepSelected)=stepSelected($event)
        (showTrackingInformation)=showTrackingInformation($event)
        (showConfirmation)=showConfirmation($event)></ppl-steps>
    </div>
    <div class="wiz-container" #wizContainer>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<ppl-popup-dialog [(isVisible)]="show" [title]="'Confirmation'" [isOkText]="'Close'" (cancel)="onClose()"
  [showButtons]="false" [position]="'top'" size="medium" [showOkButton]="true">
  @if(!isApproved){
  <div class="ppl-p24">
    <p class="ppl-gray-text ppl-mt20">
      Thank you for your submission!<br>
      Your tracking number is <b>{{trackingNumber}}</b>.<br>
      @if(pplId){
        Your PPL ID is <b>{{pplId}}</b>.<br>
      }
      One of our team members will be in touch with you within 24 to 48 hours.
    </p>
  </div>
  }
  @else {
  <div class="ppl-p24">
    <p class="ppl-gray-text ppl-mt20">
      Thank you for your submission!<br>
      Your tracking number is <b>{{trackingNumber}}</b>.<br>
    </p>
    <p>
      An email/SMS has been sent to you with a registration link to proceed.
    </p>
    <!-- <div class="ppl-p8">
      <p class="options" (click)="sendComm('email')"><span class="btn-color" nz-icon nzType="laptop"
          nzTheme="outline"></span><strong>Email</strong></p>
      <p class="options" (click)="sendComm('sms')"><span class="btn-color" nz-icon nzType="shake"
          nzTheme="outline"></span><strong>SMS</strong></p>
    </div> -->
  </div>
  }
</ppl-popup-dialog>


<ppl-popup-dialog [(isVisible)]="showAssociation" [title]="getEnrollmentPageTitle(currentFlow)" [isOkText]="'Save'"
  (confirm)="handleSaveAssociations()" (cancel)="closePopup()" [showButtons]="true" [position]="'top'" size="x-large" [showClose]="true"
  [showOkButton]="false" [hideSaveButton]="hideSaveBtn()">
  @if(showAssociation){
  <div class="p-2">
    <ppl-new-enrollment [mode]="editAssociationMode" [flow]="currentFlow" (confirm)="handleSaveAssociations()"></ppl-new-enrollment>
  </div>
  }
</ppl-popup-dialog>

<ppl-popup-dialog [(isVisible)]="showCDPAPModal" title="CDPAP Facilitator" [isOkText]="isAlgoMode ? 'Search Facilitator' : 'Save'"
  (confirm)="handleConfirmCDPAP()" (cancel)="onCloseCDPAPPopup()" [showButtons]="isAlgoMode" [position]="'top'" [size]="cdpapModalSize" [showClose]="true"
  [showOkButton]="false" [hideSaveButton]="formReadonly">
    <ppl-cdpap-form #cdPapFormRef (stepBack)="handleCDPAPStepBack()"></ppl-cdpap-form>
</ppl-popup-dialog>