{
  "sectionId": "case_manager",
  "header": "Case Manager",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "orgName",
      "fieldName": "orgName",
      "label": "MCO",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select MCO",
      "isAvailable": "Y",
      "placeholder": "MCO"
    },
    {
      "attribute_ID": "firstName",
      "fieldName": "firstName",
      "label": "First Name",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter First Name",
      "isAvailable": "Y",
      "placeholder": "First Name"
    },
    {
      "attribute_ID": "lastName",
      "fieldName": "lastName",
      "label": "Last Name",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter Last Name",
      "isAvailable": "Y",
      "placeholder": "Last Name"
    },
    {
      "attribute_ID": "email",
      "fieldName": "email",
      "label": "Email Address",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter Email Address",
      "isAvailable": "Y",
      "placeholder": "Email Address"
    },
    {
      "attribute_ID": "mobile",
      "fieldName": "mobile",
      "label": "Mobile Number",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter Mobile Number",
      "isAvailable": "Y",
      "placeholder": "Mobile Number"
    },
    {
      "attribute_ID": "active",
      "fieldName": "active",
      "label": "Is Active",
      "type": "select",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select is active",
      "isAvailable": "Y",
      "placeholder": "Mobile Number"
    },
    {
      "attribute_ID": "ldssCode",
      "fieldName": "ldssCode",
      "label": "LDSS Office",
      "type": "select",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select LDSS Office",
      "isAvailable": "Y",
      "placeholder": "LDSS Office"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": [
        {
          "validatorid": 1,
          "attribute_ID": ["firstName"],
          "functionParams": {
            "ErrMsg": "Please enter First Name"
          }
        },
        {
          "validatorid": 2,
          "attribute_ID": ["lastName"],
          "functionParams": {
            "ErrMsg": "Please enter Last Name"
          }
        },
        {
          "validatorid": 3,
          "attribute_ID": ["email", "mobile"],
          "functionParams": {
            "minNeeded": 1,
            "ErrMsg": "Please Enter email/mobile"
          }
        },
        {
          "validatorid": 4,
          "attribute_ID": ["ldssCode", "orgName"],
          "functionParams": {
            "minNeeded": 1,
            "ErrMsg": "Please Enter MCO/LDSS Office"
          }
        },
        {
          "validatorid": 5,
          "attribute_ID": ["active"],
          "functionParams": {
            "ErrMsg": "Please select a value"
          }
        }
      ]
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": [
        {
          "validatorid": 1,
          "attribute_ID": ["firstName"],
          "functionParams": {
            "isNullAllowed": "N",
            "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
            "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
          }
        },
        {
          "validatorid": 2,
          "attribute_ID": ["lastName"],
          "functionParams": {
            "isNullAllowed": "N",
            "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
            "ErrMsg": "Valid characters are A-z, periods (.), hyphens (-), spaces, and apostrophes (')"
          }
        },
        {
          "validatorid": 3,
          "attribute_ID": ["email"],
          "functionParams": {
            "isNullAllowed": "N",
            "Format": "^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$",
            "ErrMsg": "Please Enter valid email address"
          }
        },
        {
          "validatorid": 4,
          "attribute_ID": ["mobile"],
          "functionParams": {
            "isNullAllowed": "N",
            "Format": "^\\d{10}$",
            "ErrMsg": "Please enter mobile number in the format 9999999999"
          }
        }
      ]
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": [1, 2, 3, 4, 5]
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": [1, 2, 3, 4]
        }
      ]
    }
  ]
}
