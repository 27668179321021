{
  "columns": [
    {
      "header_id": "caseManagerId",
      "header_label": "ID",
      "jsonpath": "caseManagerId",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "firstName",
      "header_label": "First Name",
      "jsonpath": "firstName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "lastName",
      "header_label": "Last name",
      "jsonpath": "lastName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "email",
      "header_label": "Email",
      "jsonpath": "email",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "mobile",
      "header_label": "Mobile Number",
      "jsonpath": "mobile",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "orgName",
      "header_label": "MCO",
      "jsonpath": "orgName",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "ldssOffice",
      "header_label": "LDSS Office",
      "jsonpath": "ldssOffice",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    },
    {
      "header_id": "active",
      "header_label": "Active",
      "jsonpath": "active",
      "isDisplayedAtHeader": "y",
      "isSearchable": "y"
    }
  ],
  "buttons": [],
  "apiDetails": "caseManager.json",
  "pageName": "casemanagerlist",
  "orderByColumn": ["caseManagerId"],
  "orderBy": ["desc"]
}
