import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FileUploadComponent } from '../../../lead-details/documents/file-upload/file-upload/file-upload.component';
import { SharedService } from '../../../../shared/services/shared.service';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-authorization-documents',
  standalone: true,
  imports: [FileUploadComponent],
  templateUrl: './authorization-documents.component.html',
  styleUrl: './authorization-documents.component.css'
})
export class AuthorizationDocumentsComponent implements OnInit, OnChanges{
  entry = 'auth';
  filedInfo: any;
  program: any;
  readonly = false;
  @Input() id: any;

  constructor(private sharedService: SharedService){

  }

  ngOnInit(): void {
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
    if (changes['id'] && this.id) {
      this.getDocumentsUploaded(false);
    }
  }

  filesUpdated() {
    console.log('Files List update');
    this.getDocumentsUploaded(true);
  }

  getDocumentsUploaded(hasUpdatedEntry?: boolean) {
    this.sharedService.getUploadedFileDetails(this.program.program_code, this.id, this.entry, hasUpdatedEntry).subscribe((data: any) => {
      console.log(data);
      this.filedInfo = data?.responsedata;
    })
  }

}
