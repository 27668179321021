import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { AddressComponent } from '../../../../shared/components/address/address.component';
import { LookupService } from '../../../../services/lookup.service';
import { SectionsService } from '../../../../services/sections.service';

@Component({
  selector: 'ppl-association-details-address',
  standalone: true,
  imports: [NgZorroModule, ReactiveFormsModule, FormsModule, CommonModule],
  templateUrl: './association-details-address.component.html',
  styleUrl: './association-details-address.component.css'
})
export class AssociationDetailsAddressComponent extends AddressComponent {

     @Input() set statesList(data: any) {
      this.states = data;
     };
  
     @Input() override addressForm!: FormGroup;
  
     @Input() validationErrors : any = {};
     @Input() formReadonly = false;
    
     states: any;
    
  
    constructor(private lookupService : LookupService, httpClient: HttpClient, sectionsService: SectionsService) {
      super(httpClient, sectionsService);
    }
  
   
  getValue(code: string) {
    try{
      return this.lookupService.getLookupName(this.states, code);
    } catch {
      return code
    }
    
  }
    }