@if(mode==="edit"){
  @if(source !== 'enrollment'){
    <div class="ppl-mt8">
      @for(header of extractValues('header'); track $index){
      <h2>{{header}}</h2>
      }
      @for(text of extractValues('text'); track $index){
      <p class="ppl-description">{{text}}</p>
      }
    </div>
  }
  <div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
      <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-p24 ppl-step-main" [nzBordered]="false" >
      <form nz-form [formGroup]="preferncesForm" class="ppl-p0">
        @if(isAttrAvailable('contactType')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactType"
            class="ppl-form-label">{{getAttributes('contactType').label}}
            @if(getAttributes('contactType').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-radio-group [ngClass]="{'error': validationErrors['contactType'] }" nz-tooltip nzTooltipTitle="{{getAttributes('contactType').tooltip}}"
              nzTooltipPlacement="topRight" formControlName="contactType" id="contactType" class="ppl-radio-group">
              @for(opt of contactType; track $index){
              <label nz-radio-button [nzValue]="opt.lkpValueCode">{{opt.lkpValueName}}</label>
              }
            </nz-radio-group>
            @if (validationErrors['contactType']) {
            <span class="pl-1 error-message">{{validationErrors['contactType']}}</span>
          }
          </nz-form-control>
        </nz-form-item>
        }
        @if(preferncesForm.get('contactType')?.value === 'OTH'){
        @if(isAttrAvailable('contactFirstName')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactFirstName" class="ppl-form-label"></nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactFirstName').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactFirstName" id="contactFirstName" [ngClass]="{'error': validationErrors['contactFirstName'] }"
              placeholder="{{getAttributes('contactFirstName').placeholder}}" />
              @if (validationErrors['contactFirstName']) {
                <span class="pl-1 error-message">{{validationErrors['contactFirstName']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactLastName')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactLastName" class="ppl-form-label"></nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactLastName').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactLastName" id="contactLastName" [ngClass]="{'error': validationErrors['contactLastName'] }"
              placeholder="{{getAttributes('contactLastName').placeholder}}" />
              @if (validationErrors['contactLastName']) {
                <span class="pl-1 error-message">{{validationErrors['contactLastName']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactEmail')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactEmail" class="ppl-form-label"></nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactEmail').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactEmail" id="contactEmail" [ngClass]="{'error': validationErrors['contactEmail'] }"
              placeholder="{{getAttributes('contactEmail').placeholder}}" />
              @if (validationErrors['contactEmail']) {
                <span class="pl-1 error-message">{{validationErrors['contactEmail']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
        @if(isAttrAvailable('contactPhone')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contactPhone" class="ppl-form-label"></nz-form-label>
          <nz-form-control class="ppl-form-control">
            <input nz-tooltip nzTooltipTitle="{{getAttributes('contactPhone').tooltip}}" nzTooltipPlacement="topRight"
              nz-input formControlName="contactPhone" id="contactPhone" [ngClass]="{'error': validationErrors['contactPhone'] }"
              placeholder="{{getAttributes('contactPhone').placeholder}}" />
              @if (validationErrors['contactPhone']) {
                <span class="pl-1 error-message">{{validationErrors['contactPhone']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
  
        }
        @if(isAttrAvailable('spokenLanguages')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="spokenLanguages"
              class="ppl-form-label">{{getAttributes('spokenLanguages').label}}
              @if(getAttributes('spokenLanguages').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('spokenLanguages').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="spokenLanguages" nzMode="multiple" class="ppl-select" nzId="spokenLanguages" nzShowArrow
                nzPlaceHolder="{{getAttributes('spokenLanguages').label}}" [ngClass]="{'error': validationErrors['spokenLanguages'] }">
                @for(opt of languages; track $index){
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['spokenLanguages']) {
                <span class="pl-1 error-message">{{validationErrors['spokenLanguages']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
          @if(isAttrAvailable('primaryLanguage')){
            <nz-form-item class="ppl-form-item">
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="primaryLanguage"
                class="ppl-form-label">{{getAttributes('primaryLanguage').label}}
                @if(getAttributes('primaryLanguage').mandatory === 'Y'){
                <span>*</span>
                }
              </nz-form-label>
              <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('primaryLanguage').tooltip}}"
                nzTooltipPlacement="topRight">
                <nz-select formControlName="primaryLanguage" class="ppl-select" nzId="primaryLanguage" nzShowArrow
                  nzPlaceHolder="{{getAttributes('primaryLanguage').label}}" [ngClass]="{'error': validationErrors['primaryLanguage'] }">
                  @for(opt of languages; track $index){
                    <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                  }
                </nz-select>
                @if (validationErrors['primaryLanguage']) {
                  <span class="pl-1 error-message">{{validationErrors['primaryLanguage']}}</span>
                }
              </nz-form-control>
            </nz-form-item>
            }
            @if(isAttrAvailable('formLanguage')){
              <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="formLanguage"
                  class="ppl-form-label">{{getAttributes('formLanguage').label}}
                  @if(getAttributes('formLanguage').mandatory === 'Y'){
                  <span>*</span>
                  }
                </nz-form-label>
                <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('formLanguage').tooltip}}"
                  nzTooltipPlacement="topRight">
                  <nz-select formControlName="formLanguage" nzMode="default" class="ppl-select" nzId="formLanguage" nzShowArrow
                    nzPlaceHolder="{{getAttributes('formLanguage').label}}" [ngClass]="{'error': validationErrors['formLanguage'] }">
                    @for(opt of formLanguages; track $index){
                      <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                    }
                  </nz-select>
                  @if (validationErrors['formLanguage']) {
                    <span class="pl-1 error-message">{{validationErrors['formLanguage']}}</span>
                  }
                </nz-form-control>
              </nz-form-item>
              }
          @if(isAttrAvailable('emergencyContact')){
            <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="birthPlace"
                    class="ppl-form-label">{{getAttributes('emergencyContact').label}}
                    @if(getAttributes('emergencyContact').mandatory === 'Y'){
                    <span>*</span>
                    }
                </nz-form-label>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                        <input nz-input formControlName="emergencyContactFirstName" id="emergencyContactFirstName"
                            placeholder="First Name" />
                    </div>
                </nz-form-control>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                        <input nz-input formControlName="emergencyContactLastName" id="emergencyContactLastName"
                            placeholder="Last Name" />
                    </div>
                </nz-form-control>
                <nz-form-control class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
                        <input nz-input formControlName="emergencyContactPhone" id="emergencyContactPhone"
                            placeholder="Contact Number" />
                    </div>
                </nz-form-control>
            </nz-form-item>
            }
          @if(isAttrAvailable('registerPref')){
          <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="registerPref"
              class="ppl-form-label">{{getAttributes('registerPref').label}}
              @if(getAttributes('registerPref').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('registerPref').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="registerPref" class="ppl-select" nzId="registerPref" nzShowArrow
                [ngClass]="{'error': validationErrors['registerPref'] }">
                @for (opt of participantRegisterMode; track $index) {
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
              </nz-select>
              @if (validationErrors['registerPref']) {
              <span class="pl-1 error-message">{{validationErrors['registerPref']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
        @if(isAttrAvailable('daysToContact')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="daysToContact" class="ppl-form-label">{{
            getAttributes('daysToContact').label }}
            @if(getAttributes('daysToContact').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('daysToContact').tooltip}}"
            nzTooltipPlacement="topRight">
            <div [formGroup]="preferncesForm">
              <div formArrayName="daysToContact">
                <ng-container>
                  @for(day of daysOfWeek; track $index){
                  <label nz-checkbox [formControlName]="$index">
                    {{ day }}
                  </label>
                  }
                </ng-container>
              </div>
            </div>
            @if (validationErrors['daysToContact']) {
              <span class="pl-1 error-message">{{validationErrors['daysToContact']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="ppl-form-item">
          <div nz-col [nzSm]="8" [nzXs]="24" class="ppl-form-label"></div>
          <div class="time-picker" [ngClass]="{'error': validationErrors['dob'] }">
            <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="startContactTime"
              id="startContactTime" [nzPlaceHolder]="getAttributes('startContactTime').placeholder"
              nzBorderless></nz-time-picker>
            <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15" formControlName="endContactTime" id="endContactTime"
              [nzPlaceHolder]="getAttributes('endContactTime').placeholder" nzBorderless></nz-time-picker>
          </div>
         
        </nz-form-item>
        }
        @if(isAttrAvailable('otherDetails')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="otherDetails"
            class="ppl-form-label">{{getAttributes('otherDetails').label}}
            @if(getAttributes('otherDetails').mandatory === 'Y'){
            <span>*</span>
            }
          </nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('otherDetails').tooltip}}"
            nzTooltipPlacement="topRight">
            <textarea rows="4" nz-input formControlName="otherDetails" id="otherDetails" [ngClass]="{'error': validationErrors['otherDetails'] }"
              placeholder="{{getAttributes('otherDetails').placeholder}}"></textarea>
              @if (validationErrors['otherDetails']) {
                <span class="pl-1 error-message">{{validationErrors['otherDetails']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
        @if(isAttrAvailable('specialAccomdation')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="specialAccomdation"
            class="ppl-form-label">{{getAttributes('specialAccomdation').label}}
            @if(getAttributes('specialAccomdation').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('specialAccomdation').tooltip}}" nzTooltipPlacement="topRight">
            <nz-select formControlName="specialAccomdation" class="ppl-select" nzId="specialAccomdation" 
            [ngClass]="{'error': validationErrors['specialAccomdation'] }" nzShowSearch nzAllowClear>
              @for(opt of accommodations; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['specialAccomdation']) {
              <span class="pl-1 error-message">{{validationErrors['specialAccomdation']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
        }
        @if(preferncesForm.get('specialAccomdation')?.value === 'OTH'){
        @if(isAttrAvailable('specialAccomdationOther')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" zFor="specialAccomdationOther" class="ppl-form-label"></nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip
            nzTooltipTitle="{{getAttributes('specialAccomdationOther').tooltip}}" nzTooltipPlacement="topRight">
            <textarea [ngClass]="{'error': validationErrors['specialAccomdationOther'] }" nz-tooltip rows="4" nz-input formControlName="specialAccomdationOther" id="specialAccomdationOther"
              placeholder="{{getAttributes('specialAccomdationOther').placeholder}}"></textarea>
              @if (validationErrors['specialAccomdationOther']) {
                <span class="pl-1 error-message">{{validationErrors['specialAccomdationOther']}}</span>
              }
          </nz-form-control>
        </nz-form-item>
        }
      }
        @if(isAttrAvailable('nonProfitStatus')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="nonProfitStatus"
              class="ppl-form-label">{{getAttributes('nonProfitStatus').label}}
              @if(getAttributes('nonProfitStatus').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
              </nz-form-item>
              <nz-form-item class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="taxExempt"
                    class="ppl-form-label">{{getAttributes('taxExempt').label}}
                    @if(getAttributes('taxExempt').mandatory === 'Y'){
                    <span>*</span>
                    }</nz-form-label>
            <nz-form-control class="ppl-form-control">
              <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['taxExempt'] }"
                nzPlaceHolder="{{getAttributes('taxExempt').placeholder}}" nzId="taxExempt" formControlName="taxExempt"
                style="width:100%; margin-bottom: 16px" nzShowArrow>
                @for(opt of taxExemptOptions; track $index){
                  <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                  }
              </nz-select>
              @if (validationErrors['services']) {
              <span class="pl-1 error-message">{{validationErrors['services']}}</span>
              }
            </nz-form-control>
            </nz-form-item>
            <nz-form-item class="ppl-form-item">
              <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="nprftDocumentationReceived"
                class="ppl-form-label">{{getAttributes('nprftDocumentationReceived').label}}
                @if(getAttributes('nprftDocumentationReceived').mandatory === 'Y'){
                <span>*</span>
                }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['nprftDocumentationReceived'] }"
            nzPlaceHolder="{{getAttributes('nprftDocumentationReceived').placeholder}}" nzId="nprftDocumentationReceived" formControlName="nprftDocumentationReceived"
            style="width:100%; margin-bottom: 16px" nzShowArrow>
            <nz-option [nzValue]="'yes'" [nzLabel]="'Yes'"></nz-option>
            <nz-option [nzValue]="'no'" [nzLabel]="'No'"></nz-option>
          </nz-select>
          @if (validationErrors['nprftDocumentationReceived']) {
          <span class="pl-1 error-message">{{validationErrors['nprftDocumentationReceived']}}</span>
          }
        </nz-form-control>
        </nz-form-item>
        }
        @if(isAttrAvailable('availabilityDays')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="availabilityDays"
              class="ppl-form-label">{{getAttributes('availabilityDays').label}}
              @if(getAttributes('availabilityDays').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip
              nzTooltipTitle="{{getAttributes('availabilityDays').tooltip}}" nzTooltipPlacement="topRight">
              <div [formGroup]="preferncesForm">
                <div formArrayName="availabilityDays">
                  <ng-container>
                    @for(day of daysOfWeek; track $index){
                    <label nz-checkbox [formControlName]="$index">
                      {{ day }}
                    </label>
                    }
                  </ng-container>
                </div>
              </div>
              @if (validationErrors['availabilityDays']) {
              <span class="pl-1 error-message">{{validationErrors['availabilityDays']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
    
          }
          @if(isAttrAvailable('availabilityTiming')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="availabilityTiming"
              class="ppl-form-label">{{getAttributes('availabilityTiming').label}}
              @if(getAttributes('availabilityTiming').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <div class="time-picker" [ngClass]="{'error': validationErrors['availabilityTiming'] }">
              <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                formControlName="availabilityTimingStart" id="availabilityTimingStart"
                [nzPlaceHolder]="getAttributes('availabilityTiming').placeholder" nzBorderless></nz-time-picker>
              <nz-time-picker [nzSize]="'small'" [nzUse12Hours]="true" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                formControlName="availabilityTimingEnd" id="availabilityTimingEnd"
                [nzPlaceHolder]="getAttributes('availabilityTiming').placeholder" nzBorderless></nz-time-picker>
            </div>
            @if (validationErrors['availabilityTiming']) {
            <span class="pl-1 error-message">{{validationErrors['availabilityTiming']}}</span>
            }
          </nz-form-item>
          }
          @if(isAttrAvailable('distanceWillingToTravel')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="distanceWillingToTravel"
              class="ppl-form-label">{{getAttributes('distanceWillingToTravel').label}}
              @if(getAttributes('distanceWillingToTravel').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <nz-form-control class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('distanceWillingToTravel')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <input nz-input formControlName="distanceWillingToTravel" id="distanceWillingToTravel"
                  placeholder="{{getAttributes('distanceWillingToTravel').placeholder}}"
                  [ngClass]="{'error': validationErrors['distanceWillingToTravel'] }" />
              </div>
              @if (validationErrors['distanceWillingToTravel']) {
              <span class="pl-1 error-message">{{validationErrors['distanceWillingToTravel']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
          @if(isAttrAvailable('isAvailableForEmergencies')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="isAvailableForEmergencies"
              class="ppl-form-label">{{getAttributes('isAvailableForEmergencies').label}}
              @if(getAttributes('isAvailableForEmergencies').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control [nzSm]="16" class="ppl-form-control">
              <div nz-tooltip [nzTooltipTitle]="getAttributes('isAvailableForEmergencies')?.tooltip || ''"
                nzTooltipPlacement="topRight">
                <nz-radio-group [ngClass]="{'error': validationErrors['isAvailableForEmergencies'] }"
                  formControlName="isAvailableForEmergencies" class="ppl-radio-group" id="isAvailableForEmergencies">
                  @for(opt of radioOptions; track $index){
                    <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
                  }
                </nz-radio-group>
              </div>
              @if (validationErrors['isAvailableForEmergencies']) {
              <span class="pl-1 error-message">{{validationErrors['isAvailableForEmergencies']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
          @if(isAttrAvailable('dayEmergencyAvailablity')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="dayEmergencyAvailablity"
              class="ppl-form-label">{{getAttributes('dayEmergencyAvailablity').label}}
              @if(getAttributes('dayEmergencyAvailablity').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <div class="time-picker" [ngClass]="{'error': validationErrors['dayEmergencyAvailablity'] }">
              <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                formControlName="dayEmergencyAvailablityStart" id="dayEmergencyAvailablityStart"
                [nzDisabledHours]="disabledNightHours"
                [nzPlaceHolder]="getAttributes('dayEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
              <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzDisabledHours]="disabledNightHours"
                [nzMinuteStep]="15" formControlName="dayEmergencyAvailablityEnd" id="dayEmergencyAvailablityEnd"
                [nzPlaceHolder]="getAttributes('dayEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
            </div>
            @if (validationErrors['dayEmergencyAvailablity']) {
            <span class="pl-1 error-message">{{validationErrors['dayEmergencyAvailablity']}}</span>
            }
          </nz-form-item>
          }
          @if(isAttrAvailable('nightEmergencyAvailablity')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="nightEmergencyAvailablity"
              class="ppl-form-label">{{getAttributes('nightEmergencyAvailablity').label}}
              @if(getAttributes('nightEmergencyAvailablity').mandatory === 'Y'){
              <span>*</span>
              }
            </nz-form-label>
            <div class="time-picker" [ngClass]="{'error': validationErrors['nightEmergencyAvailablity'] }">
              <nz-time-picker [nzSize]="'small'" [nzFormat]="'h:mm a'" [nzMinuteStep]="15"
                formControlName="nightEmergencyAvailablityStart" id="nightEmergencyAvailablityStart"
                [nzDisabledHours]="disabledDayHours"
                [nzPlaceHolder]="getAttributes('nightEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
              <nz-time-picker [nzSize]="'small'" [nzDisabledHours]="disabledDayHours" [nzFormat]="'h:mm a'"
                [nzMinuteStep]="15" formControlName="nightEmergencyAvailablityEnd" id="nightEmergencyAvailablityEnd"
                [nzPlaceHolder]="getAttributes('nightEmergencyAvailablity').placeholder" nzBorderless></nz-time-picker>
            </div>
            @if (validationErrors['nightEmergencyAvailablity']) {
            <span class="pl-1 error-message">{{validationErrors['nightEmergencyAvailablity']}}</span>
            }
          </nz-form-item>
          }
      </form>
    </nz-card>
  </div>
  }
  @else if(mode==="read"){
  @if(isAttrAvailable('contactType')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('contactType').label}} :</div>
    <div nz-col nzSpan="16"> {{ getLookupValue(contactType, filledInfo['contactType'])}}</div>
  </div>
  }
  @if(preferncesForm.get('contactType')?.value === 'OTH'){
  @if(isAttrAvailable('contactFirstName')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('contactFirstName').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['contactFirstName']}}</div>
  </div>
  }
  @if(isAttrAvailable('contactLastName')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('contactLastName').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['contactLastName']}}</div>
  </div>
  }
  @if(isAttrAvailable('contactEmail')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('contactEmail').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['contactEmail']}}</div>
  </div>
  }
  @if(isAttrAvailable('contactPhone')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('contactPhone').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['contactPhone']}}</div>
    </div>
    }
  }
  @if(isAttrAvailable('registerPref')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('registerPref').label}} :</div>
      <div nz-col nzSpan="16"> {{ getLookupValue(participantRegisterMode,filledInfo['registerPref'])}}</div>
    </div>
  }
  @if(isAttrAvailable('spokenLanguages')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('spokenLanguages').label}} :</div>
    <div nz-col nzSpan="16"> {{ getLookupValue(languages,filledInfo['spokenLanguages'])}}</div>
  </div>
  }
  @if(isAttrAvailable('primaryLanguage')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('primaryLanguage').label}} :</div>
      <div nz-col nzSpan="16"> {{ getLookupValue(languages,filledInfo['primaryLanguage'])}}</div>
    </div>
    }
    @if(isAttrAvailable('formLanguage')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('formLanguage').label}} :</div>
        <div nz-col nzSpan="16"> {{ getLookupValue(languages,filledInfo['formLanguage'])}}</div>
      </div>
      }
  @if(isAttrAvailable('daysToContact')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('daysToContact').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['daysToContact'] }} {{ (filledInfo['startContactTime'] | date:'h:mm a')}} {{
      (filledInfo['endContactTime'] | date:'h:mm a') }}</div>
  </div>
  }
  @if(isAttrAvailable('otherDetails')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('otherDetails').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['otherDetails'] }}</div>
  </div>
  }
  @if(isAttrAvailable('specialAccomdation')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('specialAccomdation').label}} :</div>
    <div nz-col nzSpan="16">{{ getLookupValue(accommodations, filledInfo['specialAccomdation'])}}</div>
  </div>
  }
  @if(preferncesForm.get('specialAccomdation')?.value === 'OTH'){
  @if(isAttrAvailable('specialAccomdationOther')){
  <div nz-row>
    <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('specialAccomdationOther').label}} :</div>
    <div nz-col nzSpan="16">{{ filledInfo['specialAccomdationOther'] }}</div>
  </div>
  }
  }
  @if(isAttrAvailable('emergencyContact')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('emergencyContact').label}} :</div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="8" class="">First Name :</div>
        <div nz-col nzSpan="16">{{ filledInfo['emergencyContactFirstName']}}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="">Last Name :</div>
        <div nz-col nzSpan="16">{{ filledInfo['emergencyContactLastName']}}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="">Contact Number :</div>
        <div nz-col nzSpan="16">{{ filledInfo['emergencyContactPhone']}}</div>
      </div>
  }
  @if(isAttrAvailable('availabilityDays')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('availabilityDays').label}} :</div>
      <div nz-col nzSpan="16">{{filledInfo['availabilityDays']}}</div>
    </div>
    }
    @if(isAttrAvailable('availabilityTiming')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('availabilityTiming').label}} :</div>
      <div nz-col nzSpan="16">{{filledInfo['availabilityTimingStart'] | date:'h:mm a'}}
        {{filledInfo['availabilityTimingEnd'] |date:'h:mm a'}}</div>
    </div>
    }
    @if(isAttrAvailable('distanceWillingToTravel')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('distanceWillingToTravel').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['distanceWillingToTravel']}}</div>
    </div>
    }
    @if(isAttrAvailable('isAvailableForEmergencies')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('isAvailableForEmergencies').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['isAvailableForEmergencies']}}</div>
    </div>
    }
    @if(isAttrAvailable('dayEmergencyAvailablity')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('dayEmergencyAvailablity').label}} :</div>
      @if(filledInfo['dayEmergencyAvailablityStart'] || filledInfo['dayEmergencyAvailablityEnd']){
      <div nz-col nzSpan="16">{{filledInfo['dayEmergencyAvailablityStart'] | date:'h:mm a'}} - 
        {{filledInfo['dayEmergencyAvailablityEnd'] | date:'h:mm a'}}</div>
    }
    </div>
  }
    @if(isAttrAvailable('nightEmergencyAvailablity')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('nightEmergencyAvailablity').label}} :</div>
      @if(filledInfo['nightEmergencyAvailablityStart'] || filledInfo['nightEmergencyAvailablityEnd']){
      <div nz-col nzSpan="16">{{filledInfo['nightEmergencyAvailablityStart'] | date:'h:mm a'}} - 
        {{filledInfo['nightEmergencyAvailablityEnd'] | date:'h:mm a'}}</div>
      }
    </div>
    }
  }