<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Case Managers / LDSS Office
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table [pageConfig]="programName" [sharedDataKey]="sharedDataKey"
                        (rowDetailEvent)="rowClicked($event)" [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden" [actionsToBeHidden]="actionsToBeHidden"
                        [jsonList]="'list_case_manager'" [from]="'case_managaer'"
                        rowDataAccessibilityKey="caseManagerId">
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>