import { Injectable } from '@angular/core';
import { STATIC_DATA } from '../shared/constants/static-data.constants';
import { NotificationService } from '../shared/services/notification.service';
import { formatUrl } from '../shared/utils/format-url-util';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  flow = '';
  constructor(private notificationService: NotificationService) { }

  getStep1(code: any) {
    const flow = this.getFlow();
    return require(`../../assets/data/programs/${code}/lead_logic/leadLogic_${flow}.json`);
  }

  getAllSteps(code: any) {
    const flow = this.getFlow();
    return require(`../../assets/data/programs/${code}/forms/${flow}/steps.json`);
  }

  getProviderSteps(code: any) {
    const enrollmentType = this.getEnrollmentType();
      let enrollType: string ='';
      if(enrollmentType?.length > 0) {
        enrollType = enrollmentType[0];
    } 
    return require(`../../assets/data/programs/${code}/forms/providerEnrollment/steps/${enrollType}.json`);
  }

  getEnrollmentAdditionData(code: any) {
    const flow = this.getFlow();
    return require(`../../assets/data/programs/${code}/enrollment/${flow}.json`);
  }

  getCDPAPData(code: any) {
    return require(`../../assets/data/programs/${code}/forms/cdpap/form.json`);
  }

  getSectionData(code: any, source?: any) {
    const flow = this.getFlow();
    if (source == 'enrollment') {
      return require(`../../assets/data/programs/${code}/forms/participantEnrollment/associations/${flow}.json`);
    }
    if (source == 'payroll') {
      return require(`../../assets/data/programs/${code}/forms/payroll/${flow}/form.json`);
    }
    if (source === 'case-manager'){
      return require(`../../assets/data/programs/${code}/forms/caseManager/form.json`);
    }
    return require(`../../assets/data/programs/${code}/forms/${flow}/form.json`);
  }

  getProviderSectionData(code: any, section: string) {
    const enrollmentType = this.getEnrollmentType();
    const sectionData: Record<string, any> = {};
    for (const [i, enrollType] of enrollmentType.entries()) {
      const config = require(`../../assets/data/programs/${code}/forms/providerEnrollment/associations/${enrollType}.json`);
      sectionData[enrollType] = config[section];
    }

    const secData = Object.values(sectionData);
    const finalSection = secData[0];
    const attributes = [...new Set(finalSection.attributes.map((e: any) => e.attribute_ID))];
    const validations = [...new Set(finalSection.validations.map((e: any) => e.whatToCheck))];

    if (secData.length > 0) {
      secData.forEach(section => {
        section.attributes.forEach((a: any) => {
          if(attributes.indexOf(a.attribute_ID) == -1) {
            finalSection.attributes.push(a);
          }
        });

        section.validations.forEach((a: any) => {
          if(validations.indexOf(a.whatToCheck) == -1) {
            finalSection.validations.push(a);
          }
        });
      });
    }

    return finalSection;
  }

  setFlow(flow: any) {
    localStorage.setItem('lead_submission_flow', flow);
  }

  getFlow() {
    return localStorage.getItem('lead_submission_flow');
  }


  setEnrollmentType(type: any) {
    localStorage.setItem('enrollment_type', type);
  }

  removeEnrollmentType() {
    localStorage.removeItem('enrollment_type');
  }

  getEnrollmentType() {
    const enrollmentTypes = localStorage.getItem('enrollment_type');
    return enrollmentTypes ? JSON.parse(enrollmentTypes) : [];
  }

  async getJsonConfigurationData(urlTeamplate: string, entry?: any, comp?: any, ) {
    const code = this.getProgramCode();
    let url = '';
    const flow = (entry === 'auth') ? 'authorizations' : entry;
    url = formatUrl(urlTeamplate, { code, flow, comp });
    /* if (flow) {
      url = formatUrl(urlTeamplate, { code, flow });
    } else {
      url = formatUrl(urlTeamplate, { code });
    } */

    if (url) {
      const resp = await fetch(url)
        .then((response) => response.json())
        .catch(() =>
          this.notificationService.alert(
            'error',
            'Error Fetching Data',
            'Error fetching configuration data'
          )
        );

      return await resp;
    } else {
      return false;
    }
  }

  getProgramCode(): string {
    try {
      return localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)
        ? JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!)
          .program_code
        : '';
    } catch (e) {
      console.log(e);
      return 'base';
    }
  }
}
