import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { BaseComponent } from '../../../shared/utils/base.component';
import { SectionsService } from '../../../services/sections.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { PreferencesFormData } from '../../../interfaces/referralSections.interface';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { performValidation } from '../../../shared/utils/validation.util';
import { RADIO_YES_NO_OPTIONS, DAYS_OF_WEEK, STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { NzAriaLabelDirective } from '../../../shared/directives/nz-aria-label.directive';

@Component({
  selector: 'ppl-comm-preference',
  standalone: true,
  imports: [
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzGridModule,
    NzCardModule,
    NzRadioModule,
    NzSelectModule,
    NzCheckboxModule,
    NzInputModule,
    NzTimePickerModule,
    NzToolTipModule,
    CommonModule,
    NzAriaLabelDirective
  ],
  templateUrl: './comm-preference.component.html',
  styleUrl: './comm-preference.component.css',
})
export class CommPreferenceComponent extends BaseComponent implements OnInit, OnDestroy {
  preferncesForm!: FormGroup;
  private navigationSubscription: Subscription | undefined;
  @Input() mode = 'edit';
  filledInfo: PreferencesFormData = {};
  participantRegisterMode: any[] = [];
  override daysOfWeek: string[] = DAYS_OF_WEEK;
  contactType: any[] = [];
  languages: any[] = [];
  accommodations: any[] = [];
  formHasBeenFilled = false;
  @Input() source?: string = '';
  radioOptions = RADIO_YES_NO_OPTIONS;
  flow: string | null = '';
  relationshipOptions: any;
  taxExemptOptions:any;
  formReadonly = false;
  formLanguages = [
    {
        "lkpValueCode": "AR",
        "lkpValueName": "Arabic"
    },
    {
        "lkpValueCode": "BN",
        "lkpValueName": "Bangla"
    },
    {
        "lkpValueCode": "CMN",
        "lkpValueName": "Mandarin"
    },
    {
        "lkpValueCode": "EN",
        "lkpValueName": "English"
    },
    {
        "lkpValueCode": "FR",
        "lkpValueName": "French"
    },
    {
        "lkpValueCode": "HT",
        "lkpValueName": "Haitian Creole"
    },
    {
        "lkpValueCode": "IT",
        "lkpValueName": "Italian"
    },
    {
        "lkpValueCode": "JI",
        "lkpValueName": "Yiddish"
    },
    {
        "lkpValueCode": "KO",
        "lkpValueName": "Korean"
    },
    {
        "lkpValueCode": "PL",
        "lkpValueName": "Polish"
    },
    {
        "lkpValueCode": "RU",
        "lkpValueName": "Russian"
    },
    {
        "lkpValueCode": "SP",
        "lkpValueName": "Spanish"
    },
    {
        "lkpValueCode": "UR",
        "lkpValueName": "Urdu"
    },
    {
        "lkpValueCode": "YUE",
        "lkpValueName": "Cantonese"
    }
]


  constructor(
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private referralDataService: ReferralDataService,
    private sectionService: SectionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.preferncesForm = this.fb.group({
      contactType: [''],
      contactFirstName: [''],
      contactLastName: [''],
      contactEmail: [''],
      contactPhone: [''],
      spokenLanguages: [[]],
      formLanguage: [null],
      primaryLanguage: [null],
      daysToContact: this.fb.array(
        this.daysOfWeek.map(() => this.fb.control(false))
      ),
      startContactTime: [null],
      endContactTime: [null],
      otherDetails: [''],
      specialAccomdation: [''],
      specialAccomdationOther: [''],
      registerPref: [''],
      emergencyContactFirstName: [''],
      emergencyContactLastName: [''],
      emergencyContactPhone: [''],
      taxExempt: [''],
      nprftDocumentationReceived: [''],
      availabilityDays: this.fb.array(
        this.daysOfWeek.map(() => this.fb.control(false))
      ),
      dayEmergencyAvailablityStart: [null],
      dayEmergencyAvailablityEnd: [null],
      nightEmergencyAvailablityStart: [null],
      nightEmergencyAvailablityEnd: [null],
      distanceWillingToTravel: [null],
      availabilityTimingStart: [null],
      availabilityTimingEnd: [null],
      isAvailableForEmergencies: [null],
    });
    this.flow = this.sectionService.getFlow();
    this.formReadonly = this.referralDataService.getAssociationsEditable();

    if(this.formReadonly) {
      this.preferncesForm.disable();
    } else {
      this.preferncesForm.enable();
    }

    const associationFlows = ['employer', 'authRepresentative', 'provider', 'vendor', 'contractor', 'legalGuardian', 'poa'];
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const flow = this.sectionService.getFlow();

    let steps: any;
    if (flow === 'providerEnrollment') {
      steps = this.sectionService.getProviderSectionData(
        program.program_code,
        STATIC_DATA.COMMUNICATION_PREFERENCES
      );
      this.secData = steps;
    } else {
      steps = this.sectionService.getSectionData(
        program.program_code,
        this.source
      );
      this.secData = steps[STATIC_DATA.COMMUNICATION_PREFERENCES];
    }

    this.navigationSubscription = this.preferncesForm.valueChanges.subscribe(
      () => {
        const hasValue = this.hasAnyValueInForm(this.preferncesForm);
        this.formHasBeenFilled = hasValue;

        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isCommFormUpdated',
            this.formHasBeenFilled
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isCommFormUpdated',
            this.formHasBeenFilled
          );
        }
        // this.referralDataService.setReferralFormData(
        //   'isCommFormUpdated',
        //   this.formHasBeenFilled
        // );
        const availableDays = this.getDaysSelected(this.preferncesForm.value.availabilityDays);
        const selectedDays = this.getDaysSelected(this.preferncesForm.value.daysToContact);
        this.preferncesForm.value.daysToContact = selectedDays;
        this.preferncesForm.value.availabilityDays = availableDays;
        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'commPreferencesData',
            this.preferncesForm.value
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'commPreferencesData',
            this.preferncesForm.value
          );
        }

        this.referralDataService.setChanged(!hasValue ? false : this.preferncesForm.dirty)
        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isCommFormDirty',
            !hasValue ? false : this.preferncesForm.dirty
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isCommFormDirty',
            !hasValue ? false : this.preferncesForm.dirty
          );
        }

      }
    );

    if(associationFlows.includes(this.flow ?? '')) {
      const filledData = this.referralDataService.getAssociationDetails(
        'communicationData'
      )!;
      if (filledData) {
        this.preferncesForm.patchValue(filledData);
      }
    }
    if (((flow == 'referral' && localStorage.getItem('lead_entry') == 'referral') || flow === 'participantEnrollment' || flow === 'providerEnrollment') && !this.referralDataService.getReferralFormData().commPreferencesData) {
      const filledData =
        this.referralDataService.getReferralDetails('communicationData', flow)!;
      if (filledData) {
        if (flow === 'providerEnrollment') {
          const emergencyAvailabilityDay = super.getStartAndEndTimes(filledData?.emergencyAvailabilityDay);
          const emergencyAvailabilityNight = super.getStartAndEndTimes(filledData?.emergencyAvailabilityNight);
          const finalData = {
            ...filledData,
            dayEmergencyAvailablityStart: emergencyAvailabilityDay?.startTime,
            dayEmergencyAvailablityEnd: emergencyAvailabilityDay?.endTime,
            nightEmergencyAvailablityStart: emergencyAvailabilityNight?.startTime,
            nightEmergencyAvailablityEnd: emergencyAvailabilityNight?.endTime,
          };
          this.preferncesForm.patchValue(finalData);
        } else {
          this.preferncesForm.patchValue(filledData);
        }
      }
    }

    if (!associationFlows.includes(this.flow ?? '')) {
      if (this.referralDataService.getReferralFormData().commPreferencesData) {
        this.filledInfo =
        this.referralDataService.getReferralFormData().commPreferencesData;
        const selectedDays = this.filledInfo.daysToContact;
        const days = this.daysOfWeek.map((day: any) => selectedDays?.includes(day));
        const availabilityDays = this.daysOfWeek.map((day: any) => this.filledInfo.availabilityDays?.includes(day));
        const updatedValues = {
          ...this.filledInfo,
          daysToContact: days,
          availabilityDays
        };
        this.preferncesForm.patchValue(updatedValues);
      }
    }

    this.i18n.setLocale(en_US);
    const contactData = this.referralDataService.getReferralFormData()?.contactDetailsData;
    const participantRegisterMode = this.getLookup('Participant_Register_Mode');
    this.participantRegisterMode = this.filterPrefLookupValues(contactData, participantRegisterMode);

    this.languages = this.getLookup('Language');
    this.contactType = this.getLookup('Contact_Type');
    this.accommodations = this.getLookup('Special_Accomdation');
    this.taxExemptOptions = this.getLookup('Tax Exemption');


    this.referralDataService.nextClicked.subscribe((val) => {
      if(val === STATIC_DATA.COMMUNICATION_PREFERENCES){
      this.validationErrors = performValidation(
        this.preferncesForm.value,
        this.secData,
        'Next'
      );
      this.referralDataService.setError(this.validationErrors);
    }
    });

    if (associationFlows.includes(this.flow ?? '')) {
      this.referralDataService.associationSaveClicked.subscribe(() => {
        this.validationErrors = performValidation(
          this.preferncesForm.value,
          this.secData,
          'Next'
        );
        this.referralDataService.setError(this.validationErrors);
        this.referralDataService.setAssociationFormData(
          'isCommFormValid',
          Object.keys(this.validationErrors).length == 0
        );
      });
    }
  }

  // hasAnyValue(form: FormGroup): boolean {
  //   return Object.values(form.controls).some(control => control.value !== '');
  // }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  filterPrefLookupValues(data: any, lookupValues: any) {
    if (data && data.email && !data.mobile) {
        return lookupValues.filter((item:any) => item.lkpValueCode !== "MOB");
    }
    if (data && data.mobile && !data.email) {
        return lookupValues.filter((item:any) => item.lkpValueCode !== "EML");
    }
    return lookupValues;
  }

  disabledNightHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.night;
  };

  disabledDayHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.day;
  };
}
