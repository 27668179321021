[
  {
    "menu_label": "Global Consumer Search",
    "menu_id": "globalConsumerSearch",
    "display": "y",
    "landing_component": "globalConsumerSearch"
  },
  {
    "menu_label": "Consumer Referral",
    "menu_id": "consumerReferral",
    "display": "y",
    "landing_component": "referralList"
  },
  {
    "menu_label": "Registration",
    "menu_id": "enrollment",
    "display": "y",
    "landing_component": "enrollmentList",
    "links": [
      {
          "sub_menu_label": "Consumer Registration",
          "sub_menu_id": "consumerRegistration",
          "landing_component": "participantEnrollmentList",
          "display": "y"
      },
      {
          "sub_menu_label": "Personal Assistant Registration",
          "sub_menu_id": "personalAssistantRegistration",
          "landing_component":"providerEnrollmentList",
          "display": "y"
      }
  ]
  },
  {
    "menu_label": "Case Managers / LDSS Office",
    "menu_id": "caseManagers",
    "display": "y",
    "landing_component": "caseManagerList"
  },
  {
    "menu_label": "Process Codes",
    "menu_id": "processCodes",
    "display": "y",
    "landing_component": "processCodeList"
  },
  {
    "menu_label": "Service Codes",
    "menu_id": "serviceCodes",
    "display": "y",
    "landing_component": "serviceCodeList"
  },
  {
    "menu_label": "Consumer - Personal Assistant Rates",
    "menu_id": "participantProviderRates",
    "display": "y",
    "landing_component": "participantProviderRatesList"
  },
  {
    "menu_label": "Payable - Personal Assistant Rates",
    "menu_id": "payableProviderRates",
    "display": "y",
    "landing_component": "payablePersonalAssistantRates"
  },
  {
    "menu_label": "Billable - Personal Assistant Rates",
    "menu_id": "billableProviderRates",
    "display": "y",
    "landing_component": "billablePersonalAssistantRates"
  },
  {
    "menu_label": "Authorizations",
    "menu_id": "authorizations",
    "display": "y",
    "landing_component": "authorizations"
  },
  {
    "menu_label": "Timesheets",
    "menu_id": "timesheets",
    "display": "y"
  },
  {
    "menu_label": "File Triage",
    "menu_id": "fileTriage",
    "display": "y",
    "landing_component": "fileTriage"
  },
  {
    "menu_label": "File Upload Notification",
    "menu_id": "fileUploadNotification",
    "display": "y",
    "landing_component": "fileUploadNotification"
  }
]