import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ActivatedRoute, Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from '../../../../shared/components/accordion/accordion.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
import { ClaimsService } from '../../../../services/claims.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { DateTimeFormatterService } from '../../../../shared/utils/date-time-formatter.service';
import { LookupService } from '../../../../services/lookup.service';

@Component({
  selector: 'ppl-add-service-code-basic-details',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    ListTableComponent,
    NgZorroModule,
    NzRadioModule,
  ],
  templateUrl: './add-service-code-basic-details.component.html',
  styleUrl: './add-service-code-basic-details.component.css',
})
export class AddServiceCodeBasicDetailsComponent
  extends BaseComponent
  implements OnInit
{
  serviceCodeForm!: FormGroup;
  radioOptions: any;
  providers: any;
  pageTitle = 'Add New Service Code';
  mode = 'edit';
  rateDetailsmode = 'read';
  savedData: any = {};
  isNew = true;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  programCodes: any;
  counties: LookupValueItem[] = [];
  cities: LookupValueItem[] = [];
  measureUnits: LookupValueItem[] = [];
  unitIncrements: LookupValueItem[] = [];
  serviceGroups: LookupValueItem[] = [];
  medicaidBillingCodes: LookupValueItem[] = [];
  program: any;
  modifiers: any;

  @Output() serviceCodeAdded = new EventEmitter();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private lookupService: LookupService,
    private dateTimeFormatterService: DateTimeFormatterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.serviceCodeForm = this.fb.group({
      code: [null],
      description: [''],
      effectiveFromDate: [null],
      effectiveEndDate: [null],
      modifierDetailId1: [null],
      modifierDetailId2: [null],
      modifierDetailId3: [null],
      modifierDetailId4: [null],
      comments: [null],
      docEligible: [null],
      otEligible: [null],
      countyLkp: [null],
      cityLkp: [null],
      unitIncrementLkp: [null],
      servicegroupLkp: [[]],
      medicaidBillable: [null],
      medicaidBillingCode: [null],
      maxBillableRate: [null],
      groupService: [null],
      providerTypes: [[]],
      measureUnitLkp: [null],
    });

    this.sectionsService.setFlow('serviceCode');
    this.radioOptions = this.getLookup('Yes_No');
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.secData = this.sectionsService.getSectionData(
      program.program_code,
      'payroll'
    );
    this.programName = program.program_code;
    this.providers = this.lookupService.getProviderLookupValue();
    this.serviceGroups = this.getLookup('Service_Group');
    this.measureUnits = this.getLookup('Measurement_Units');
    this.unitIncrements = this.getLookup('Increment_Units');
    this.medicaidBillingCodes = this.getLookup('Medicaid_Billing_Code');
    this.counties = this.getLookup('County');
    this.cities = this.getLookup('City');
    this.modifiers = this.lookupService.getTasLookupValue('modifierDetailList');

    if (localStorage.getItem('selected_id')) {
      this.serviceCodeAdded.emit(true);
    }

    this.route.paramMap.subscribe((params: any) => {
      const action = params.get('action')!;
      this.pageTitle =
        action == 'update' ? 'Service Code Details' : this.pageTitle;
      this.isNew = action === 'add';
      this.mode = action == 'update' ? 'read' : 'edit';
    });

    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    const serviceCodes = this.claimsService.getFormData('service_code');
    this.savedData = this.formatProcessCodeData(serviceCodes);
  }

  returnClicked() {
    this.router.navigate(['/home/serviceCodeList']);
  }

  getProvLookupValue(lookupArr: any[], valueCheck: string[]): string[] {
    return valueCheck?.map((valueCode) => {
      const lookup = lookupArr?.find((gen: any) => gen.code === valueCode);
      return lookup ? lookup.name : undefined;
    });
  }

  getTasLookupName(lookupArr: any, valueCode: string): string | undefined {
    const lookup = lookupArr?.find((gen: any) => {
      return gen.modifierDetailId === valueCode;
    });
    return lookup ? lookup.code : undefined;
  }

  formatProcessCodeData(serviceCodeData: any) {
    return {
      ...serviceCodeData,
      medicaidBillable: serviceCodeData?.medicaidBillable ? 'Yes' : 'No',
      otEligible: serviceCodeData?.otEligible ? 'Yes' : 'No',
      docEligible: serviceCodeData?.docEligible ? 'Yes' : 'No',
      groupService: serviceCodeData?.groupService ? 'Yes' : 'No',
      providerTypes: serviceCodeData?.providerTypes? this.getProviderTypes(serviceCodeData.providerTypes): []
    };
  }
  
  getProviderTypes = (providers: any) => {
    return providers?.map((providerType: any) => {
      const provider = this.providers?.find(
        (p: any) => p.code === providerType.providerTypeLkp
      );
      return provider ? provider.code : undefined;
    });
  };

  goBack() {
    this.router.navigate(['/home/serviceCodeList']);
  }

  onEdit() {
    this.mode = 'edit';
    const data = {
      ...this.savedData,
      effectiveFromDate: this.formatDateString(this.savedData.effectiveFromDate),
      effectiveEndDate: this.formatDateString(this.savedData.effectiveEndDate)
    }
    this.serviceCodeForm.patchValue(data);
  }

  onAdd() {
    this.validationErrors = performValidation(
      this.serviceCodeForm.value,
      this.secData,
      'Next'
    );
    const payload = this.createPayload();
    if (Object.keys(this.validationErrors).length === 0) {
      this.claimsService.addServiceCode(payload).subscribe(
        (data) => {
          if (data.status == 200) {
            this.serviceCodeAdded.emit(true);
            this.mode = 'read';
            localStorage.setItem(
              'selected_id',
              data.responsedata.serviceCodeDetailId
            );
            this.claimsService
              .fetchClaimsData(
                this.program.program_code,
                data.responsedata.serviceCodeDetailId,
                'serviceCode',
                true
              )
              .subscribe((data: any) => {
                this.claimsService.setFormData('service_code', data.responsedata);
                this.savedData = this.formatProcessCodeData(data.responsedata);
              });
          }
        },
        (error) => {
          console.error('Error while adding a process code: ', error);
        }
      );
    }
  }

  convertProviderTypes(providerType: string[]): { providerTypeLkp: string }[] {
    return providerType?.map((type) => ({
      providerTypeLkp: type,
    }));
  }

  createPayload() {
    return {
      ...this.serviceCodeForm.value,
      programCode: this.program.program_code,
      medicaidBillable: this.getBoolean(
        this.serviceCodeForm.value.medicaidBillable
      ),
      otEligible: this.getBoolean(this.serviceCodeForm.value.otEligible),
      docEligible: this.getBoolean(this.serviceCodeForm.value.docEligible),
      groupService: this.getBoolean(this.serviceCodeForm.value.groupService),
      effectiveFromDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeForm.value.effectiveFromDate)
      ),
      effectiveEndDate: this.dateTimeFormatterService.formatToString(
        new Date(this.serviceCodeForm.value.effectiveEndDate)
      ),
      providerTypes: this.convertProviderTypes(
        this.serviceCodeForm.value.providerTypes
      ),
    };
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.serviceCodeForm.get('effectiveEndDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.serviceCodeForm.get('effectiveFromDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  getBoolean(val: string) {
    return val === 'Yes';
  }

  onCancel() {
    this.mode = 'read';
    this.validationErrors = {};
    this.serviceCodeForm.reset();
  }

  onUpdate() {
    this.validationErrors = performValidation(
      this.serviceCodeForm.value,
      this.secData,
      'Next'
    );
    const payload = this.createPayload();
    const id =
      this.claimsService.getFormData('service_code').serviceCodeDetailId;
    if (Object.keys(this.validationErrors).length === 0) {
      console.log("paylad", payload);
      this.claimsService.updateServiceCode(payload, id).subscribe(
        (data) => {
          if (data.status == 200) {
            // this.confirmationMessage = `Process code ${this.processCodeForm.value.code} added successfully.`;
            // this.show = true;
            // this.serviceCodeAdded.emit(true);
            this.mode = 'read';
            // localStorage.setItem(
            //   'selected_id',
            //   data.responsedata.serviceCodeDetailId
            // );
            this.claimsService
              .fetchClaimsData(
                this.program.program_code,
                data.responsedata.serviceCodeDetailId,
                'serviceCode',
                true
              )
              .subscribe((data: any) => {
                this.claimsService.setFormData('service_code', data.responsedata);
                this.savedData = this.formatProcessCodeData(data.responsedata);
              });
          }
        },
        (error) => {
          console.error('Error while adding a process code: ', error);
        }
      );
    }
  }
}
