import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SectionsService } from '../../../services/sections.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { ReferralDataService } from '../../../services/referral-data.service';
import { StepInfo } from '../../../interfaces/referralSections.interface';
import { LookupService } from '../../../services/lookup.service';
import {
  NzNotificationService,
  NzNotificationModule,
} from 'ng-zorro-antd/notification';
import { debounceTime } from 'rxjs';
import { DateTimeFormatterService } from '../../../shared/utils/date-time-formatter.service';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { NotificationService } from '../../../shared/services/notification.service';
import { ENROLLMENT_TITLE_MAPPINGS, ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS } from '../../../shared/constants/static-data.constants';

@Component({
  selector: 'ppl-steps',
  standalone: true,
  imports: [
    NzCardModule,
    NzStepsModule,
    NzButtonModule,
    NzNotificationModule,
    ButtonComponent,
  ],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.css',
})
export class StepsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() stepSelected = new EventEmitter<any>();
  @Output() showConfirmation = new EventEmitter<any>();
  @Output() showTrackingInformation = new EventEmitter<any>();

  currentStep = 0;
  availableSteps: StepInfo[] = [];
  mandatorySteps: any[] = [];
  onSummary = false;
  identifier: any[] = [];
  communicationType: any[] = [];
  error: any = {};
  step = '';
  selectedStep: any;
  isStepSelected = false;
  program: any;
  flow: any;

  constructor(
    private sectionsService: SectionsService,
    private referralDataService: ReferralDataService,
    private lookupService: LookupService,
    private notification: NzNotificationService,
    private dateTimeFormatterService: DateTimeFormatterService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.currentStep = 0;
  }

  ngOnInit(): void {
    this.currentStep = 0;
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    const code = this.program.program_code;
    const flow = this.sectionsService.getFlow();    
    if(flow === 'providerEnrollment') {
      this.data = this.sectionsService.getProviderSteps(code);
    } else {
      this.data = this.sectionsService.getAllSteps(code);
    }
    this.availableSteps = this.data.filter(
      (step) => step.isAvailable === 'Y'
    );
    this.mandatorySteps = this.data.filter(
      (step) => step.isMandatory === 'Y'
    );
    this.flow = this.sectionsService.getFlow();
    this.stepSelected.emit(this.availableSteps[0]);
    this.identifier = this.lookupService.getLookupValue(
      'Identifier_type'
    );
    this.communicationType =
      this.lookupService.getLookupValue('Communication_Type');

    if (localStorage.getItem('trackingNumber') && localStorage.getItem('providerPPLId')) {
      this.showTrackingInformation.emit({
        trackingNumber: localStorage.getItem('trackingNumber'),
        providerPPLId: localStorage.getItem('providerPPLId')
      })
    }

    if (localStorage.getItem('trackingNumber') && localStorage.getItem('participantPPLId')) {
      this.showTrackingInformation.emit({
        trackingNumber: localStorage.getItem('trackingNumber'),
        participantPPLId: localStorage.getItem('participantPPLId')
      })
    }

    this.referralDataService.validationError
      .pipe(debounceTime(500))
      .subscribe((val: any) => {
        if (Object.keys(val).length !== 0) {
          event?.preventDefault();
          return;
        } else {
          this.error[this.availableSteps[this.currentStep].step_id] = true;
          if (
            this.currentStep == 0 && ['participants', 'providers', 'referral'].includes(this.flow)
          ) {
            const load =
              this.sectionsService.getFlow() === 'participants'
                ? 'participantInfoData'
                : this.sectionsService.getFlow() === 'providers'
                  ? 'providerInfoData'
                  : 'referralInfoData';
            const payload = {
              [load]: {
                ...this.formatInfoData(
                  this.referralDataService.getReferralFormData().infoData
                ),
                state:
                  this.referralDataService.getReferralFormData().state?.state ||
                  '',
                ...((this.sectionsService.getFlow() !== 'participants' || this.sectionsService.getFlow() !== 'providers') && {
                  programCode: this.program.program_code,
                }),
              },
            };
            const type =
              this.sectionsService.getFlow() === 'participants'
                ? 'participant'
                : this.sectionsService.getFlow() === 'providers'
                  ? 'provider'
                  : 'referral_participant';
            this.referralDataService
              .checkDups(type, payload)
              .subscribe(() => {
                this.saveData();
              });
          } else {
            if (this.referralDataService.getChanged()) {
              this.saveData();
            }
            else {
              this.setStep();
            }
          }
        }
      });
      this.referralDataService.stepValidation
      .pipe(debounceTime(500))
      .subscribe((val: any) => {
        this.error[val.step] = val.val;
        this.setStep();
      })
  }

  checkIfNext(isDirty: any, isUpdated: any) {
    if (
      !this.referralDataService.getReferralFormData()[isDirty] &&
      !this.referralDataService.getReferralFormData()[isUpdated]
    ) {
      this.error[this.availableSteps[this.currentStep].step_id] = false;
      if (this.flow === 'participantEnrollment' && !localStorage.getItem('participantId')) {
        this.notificationService.alert('error', "Error", 'Please save basic information before going forward.')
      }
      else if (this.flow === 'providerEnrollment' && !localStorage.getItem('providerId')) {
        this.notificationService.alert('error', "Error", 'Please save basic information before going forward.')
      }
      else {
        this.setStep();
      }
    } else {
      this.referralDataService.setClick(this.availableSteps[this.currentStep].step_id);
    }
  }


  checkPageValidation(){
    this.referralDataService.setClick(this.availableSteps[this.currentStep].step_id);
  }

  next(): void {
    this.step = 'next';
    this.checkIfRedirect();
  }

  submitReferral() {
    console.log('Submitting Referral');
  }

  isFormValid() {
    // const stepsToValidate = this.availableSteps.filter(
    //   (stepId) =>
    //     stepId.step_id !== 'summary' && stepId.step_id !== 'upload_docs'
    //     && stepId.step_id !== 'signatures' && stepId.step_id !== 'checklist'
    //     && stepId.step_id !== 'forms' && stepId.step_id !== 'associations'
    // );
    return this.mandatorySteps.every(
      (stepId) => this.error[stepId.step_id] === true
    );
  }

  checkIfRedirect(): void {
    const stepId = this.availableSteps[this.currentStep].step_id;
    switch (stepId) {
      case 'participant_info':
        this.checkIfNext('isInfoFormDirty', 'isInfoFormUpdated');
        break;
      case 'service_details':
        this.checkIfNext('isServiceFormDirty', 'isServiceFormUpdated');
        break;
      case 'contact_details':
        this.checkIfNext('isContactFormDirty', 'isContactFormUpdated');
        break;
      case 'comm_preferences':
        this.checkIfNext('isCommFormDirty', 'isCommFormUpdated');
        break;
      case 'upload_docs':
        this.checkIfNext('isUploadFormDirty', 'isUploadFormUpdated');
        break;
      case 'associations':
        this.checkPageValidation();
        break;
      case 'signatures':
        this.checkPageValidation();
        break;
      case 'forms':
        this.checkPageValidation();
        break;
      case 'checklist':
        this.checkPageValidation();
        break;
      case 'payment_details':
        this.checkPageValidation();
        break;
      case 'summary':
        this.checkIfNext(false, false);
        break;
      default:
        console.error(`Unhandled step ID: ${stepId}`);
        break;
    }
  }

  saveData() {
    const onStep = this.availableSteps[this.currentStep].step_id;
    if (this.flow === 'participantEnrollment') {
      if (onStep == 'participant_info') {
        const infoData = this.referralDataService.getReferralFormData().infoData;
        const formattedInfoData = infoData && this.formatInfoData(infoData);
        const finalInfoPayload = {
          ...formattedInfoData,
          participantId: localStorage.getItem('participantId'),
          programCode: this.program.program_code,
          trackingNumber: localStorage.getItem('trackingNumber'),
          participantPPLId: localStorage.getItem('participantPPLId')
        };
        this.referralDataService.saveInfoData(finalInfoPayload, true, true).subscribe((data) => {
          if (data.status == 200) {
            this.setStep();
            localStorage.setItem('participantId', data.responsedata.participantId);
            localStorage.setItem('participantPPLId', data.responsedata.participantPPLId);
            localStorage.setItem('trackingNumber', data.responsedata.trackingNumber);
            this.showTrackingInformation.emit({
              trackingNumber: data.responsedata.trackingNumber,
              participantPPLId: data.responsedata.participantPPLId
            })
          }
        })
      }
      else if (onStep == 'comm_preferences') {
        const commPreferencesData = this.referralDataService.getReferralFormData().commPreferencesData;
        const formattedCommPreferencesData = commPreferencesData && this.formatCommPreferencesData(commPreferencesData);
        const finalCommPreferencesPayload = {
          ...formattedCommPreferencesData,
          programCode: this.program.program_code,
          participantId: localStorage.getItem('participantId')
        }
        this.referralDataService.saveCommPref(finalCommPreferencesPayload, true, true).subscribe((data) => {
          if (data.status === 200) {
            this.setStep();
          }
        })
      }
      else if (onStep == 'contact_details') {
        const contactDetailsData = this.referralDataService.getReferralFormData().contactDetailsData;
        const formattedContactDetailsData = contactDetailsData && this.formatContactDetailsData(contactDetailsData);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { preferredMethod, email, altPhone, mobile, residentGt18, yearsInState, ...communicationData } = formattedContactDetailsData;
        const finalContactDetailsPayload = {
          communicationData,
          programCode: this.program.program_code,
          participantId: localStorage.getItem('participantId')
        }
        this.referralDataService.saveContactDetails(finalContactDetailsPayload, true, true).subscribe((data: any) => {
          if (data.status == 200) {
            this.setStep();
          }
        })
      }
      else {
        this.setStep();
      }
    }
    else if (this.flow === 'providerEnrollment') {
      if (onStep == 'participant_info') {
        const infoData = this.referralDataService.getReferralFormData().infoData;
        const formattedInfoData = infoData && this.formatInfoData(infoData);
        // formattedInfoData["applicationStatus"] = "In Progress";
        const providerTypes = this.sectionsService.getEnrollmentType();
        formattedInfoData["providerType"] = ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS[providerTypes[0]];
        const finalInfoPayload = {
          ...formattedInfoData,
          providerId: localStorage.getItem('providerId'),
          trackingNumber: localStorage.getItem('trackingNumber'),
          programCode: this.program.program_code,
          providerPPLId: localStorage.getItem('providerPPLId')
        };
        this.referralDataService.saveInfoData(finalInfoPayload, false, true).subscribe((data) => {
          if (data.status == 200) {
            this.setStep();
            localStorage.setItem('providerId', data.responsedata.providerId);
            localStorage.setItem('trackingNumber', data.responsedata.trackingNumber);
            localStorage.setItem('providerPPLId', data.responsedata.providerPPLId);
            this.showTrackingInformation.emit({
              trackingNumber: data.responsedata.trackingNumber,
              providerPPLId: data.responsedata.providerPPLId
            })
          }
        })
      }
      else if (onStep == 'service_details') {
        const serviceDetailsData = this.referralDataService.getReferralFormData().serviceDetailsData;
        const serviceDetailsFormattedData = serviceDetailsData && this.formatServiceDetailsData(serviceDetailsData);
        const serviceDetailsPayload = {
          ...serviceDetailsFormattedData,
          programCode: this.program.program_code,
          providerId: localStorage.getItem('providerId'),
          trackingNumber: localStorage.getItem('trackingNumber'),
        }
        this.referralDataService.saveServiceDetails(serviceDetailsPayload, true).subscribe((data) => {
          if (data.status === 200) {
            this.setStep();
          }
        })
      }
      else if (onStep == 'comm_preferences') {
        const commPreferencesData = this.referralDataService.getReferralFormData().commPreferencesData;
        const formattedCommPreferencesData = commPreferencesData && this.formatCommPreferencesData(commPreferencesData);
        const finalCommPreferencesPayload = {
          ...formattedCommPreferencesData,
          programCode: this.program.program_code,
          providerId: localStorage.getItem('providerId'),
          trackingNumber: localStorage.getItem('trackingNumber'),
        }
        this.referralDataService.saveCommPref(finalCommPreferencesPayload, false, true).subscribe((data) => {
          if (data.status === 200) {
            this.setStep();
          }
        })
      }
      else if (onStep == 'contact_details') {
        const contactDetailsData = this.referralDataService.getReferralFormData().contactDetailsData;
        const formattedContactDetailsData = contactDetailsData && this.formatContactDetailsData(contactDetailsData);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { preferredMethod, email, altPhone, mobile, residentGt18, yearsInState, ...communicationData } = formattedContactDetailsData;
        const finalContactDetailsPayload = {
          communicationData,
          programCode: this.program.program_code,
          providerId: localStorage.getItem('providerId'),
          trackingNumber: localStorage.getItem('trackingNumber'),
        }
        this.referralDataService.saveContactDetails(finalContactDetailsPayload, false, true).subscribe((data) => {
          if (data.status === 200) {
            this.setStep();
          }
        })
      }
      else {
        this.setStep();
      }
    }
    else {
      this.setStep();
    }
  }

  setStep() {
    if (this.step == 'next') {
      this.currentStep++;
    } else if (this.step == 'prev') {
      this.currentStep--;
    }
    this.onSummary =
      this.currentStep >= this.availableSteps.length - 1 ? true : false;
    let step;
    if (this.isStepSelected) {
      step = this.availableSteps.find(
        (step) => step.step_id == this.selectedStep
      );
      this.currentStep = this.getStepIndex(this.selectedStep);
      this.onSummary = this.selectedStep == 'summary' ? true : false;
    } else {
      step = this.availableSteps[this.currentStep];
    }
    this.stepSelected.emit(step);
    if(this.onSummary){
      this.mandatorySteps.forEach(step => {
        if (!this.error[step.step_id]) {
          this.error[step.step_id] = 'noVal';
        } else if (this.error[step.step_id] !== true) {
          this.error[step.step_id] = 'noVal';
        } else {
          this.error[step.step_id] = true;
        }
    });
    }
    this.isStepSelected = false;
    this.step = '';
  }

  goToStep(selectedStep: string) {
    this.isStepSelected = true;
    this.selectedStep = selectedStep;
    // this.onSummary = (selectedStep == 'summary') ? true : false;
    this.checkIfRedirect();
  }

  getStepStatus(step: string) {
    return this.availableSteps[this.currentStep].step_id == step
      ? 'process'
      : this.error[step] == true
        ? 'finish'
         : this.error[step] == 'noVal'
        ? 'error'
        : 'wait';
  }

  getStepIndex(id: string): number {
    return this.availableSteps.map((step) => step.step_id).indexOf(id);
  }

  prev(): void {
    this.onSummary = false;
    this.step = 'prev';
    this.checkIfRedirect();
  }

  formatDateTimes(serviceDetailsData: any) {
    return {
      availabilityTimingStart:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.availabilityTimingStart,
          'hh:mm a'
        ) || '',
      availabilityTimingEnd:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.availabilityTimingEnd,
          'hh:mm a'
        ) || '',
      dayEmergencyAvailablityStart:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.dayEmergencyAvailablityStart,
          'hh:mm a'
        ) || '',
      dayEmergencyAvailablityEnd:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.dayEmergencyAvailablityEnd,
          'hh:mm a'
        ) || '',
      nightEmergencyAvailablityStart:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.nightEmergencyAvailablityStart,
          'hh:mm a'
        ) || '',
      nightEmergencyAvailablityEnd:
        this.dateTimeFormatterService.formatDate(
          serviceDetailsData.nightEmergencyAvailablityEnd,
          'hh:mm a'
        ) || '',
    };
  }

  formatServiceDetailsData(serviceDetailsData: any) {
    serviceDetailsData.prospectiveParticipants= serviceDetailsData.prospectiveParticipants.filter((participant: any)=>{
      return !(participant.firstName.trim() === ''
       && participant.lastName.trim() === '' 
       && participant.phoneNum === '')
    });
    serviceDetailsData.prospectiveParticipants.forEach((participant: any) => {
      participant.firstName = participant.firstName?.trim();
      participant.lastName = participant.lastName?.trim();
    });
    return{
      ...serviceDetailsData,
      ...this.formatDateTimes(serviceDetailsData),
      profLicences: serviceDetailsData.profLicences.map((licence: string) => ({
        licenseType: licence,
      })),
      servicesRendered: serviceDetailsData.servicesRendered?.map(
        (service: string) => ({ serviceType: service })
      ),
      isAvailableForEmergencies:
        serviceDetailsData.isAvailableForEmergencies?.toLowerCase() === 'yes',
      filesUploaded: serviceDetailsData.filesUploaded.map((file: any) => ({
        leadFileId: file.id,
      })),
    };
  }

  formatCommPreferencesData(commPreferencesData: any) {
    if (!commPreferencesData.formLanguage) {
      commPreferencesData.formLanguage = 'EN';
    }
    return {
      ...commPreferencesData,
      ...this.formatDateTimes(commPreferencesData),
      emergencyContactFirstName: commPreferencesData.emergencyContactFirstName?.trim(),
      emergencyContactLastName: commPreferencesData.emergencyContactLastName?.trim(),
      spokenLanguages: commPreferencesData.spokenLanguages.map(
        (lang: string) => ({ language: lang })
      ),
      isAvailableForEmergencies: commPreferencesData.isAvailableForEmergencies?.toLowerCase() === 'yes',
      startContactTime:
        this.dateTimeFormatterService.formatDate(
          commPreferencesData.startContactTime,
          'hh:mm a'
        ) || '',
      endContactTime:
        this.dateTimeFormatterService.formatDate(
          commPreferencesData.endContactTime,
          'hh:mm a'
        ) || '',
    };
  }

  formatInfoData(infoData: any) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isProvidingServices, showOnDirectory, SSN, CIN, EIN, ...restInfoData } = infoData;
    return {
      ...restInfoData,
      fname : infoData.fname?.trim(),
      lname : infoData.lname?.trim(),
      identifiers: this.getIdentifiers(infoData, this.identifier),
      ...(isProvidingServices !== undefined && {
        isProvidingServices: isProvidingServices?.toLowerCase() === 'yes',
      }),
      ...(showOnDirectory !== undefined && {
        isShowOnDirectory: showOnDirectory?.toLowerCase() === 'yes',
      })
    };
  }

  formatContactDetailsData(contactDetailsData: any) {
    return {
      ...contactDetailsData,
      contactMethods: this.getContactMethods(
        contactDetailsData,
        this.communicationType
      ),
    };
  }

  submit(actionPerformed?: string) {
    const flow = this.sectionsService.getFlow();

    const {
      infoData,
      contactDetailsData,
      serviceDetailsData,
      commPreferencesData,
      filesUploaded
    } = this.referralDataService.getReferralFormData();
    console.log('FORM DATA ===== ', this.referralDataService.getReferralFormData());
    const updatedServiceDetailsData =
      serviceDetailsData && this.formatServiceDetailsData(serviceDetailsData);
    const updatedCommPreferncesData =
      commPreferencesData &&
      this.formatCommPreferencesData(commPreferencesData);
    const updatedInfoData = infoData && this.formatInfoData(infoData);
    const updatedContactDetailsData =
      contactDetailsData && this.formatContactDetailsData(contactDetailsData);
    // const { SSN, CIN, ...formattedInfoData } = updatedInfoData;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, altPhone, mobile, preferredMethod, ...formattedcontactDetails } = updatedContactDetailsData;

    let dataToSave;
    
    if (flow == 'participants') {
      const updatedInfo = {
        ...updatedInfoData,
        state: this.referralDataService.getReferralFormData().state.state,
        ...(actionPerformed ? { action: actionPerformed } : {}),
      };
      dataToSave = {
        infoData: updatedInfo,
        communicationData: formattedcontactDetails,
        contactDetailsData: updatedCommPreferncesData,
      };
    } else if (flow == 'providers') {
      const { servicesRendered, ...updatedData } = updatedInfoData;
      const updatedInfo = {
        ...updatedData,
        state: this.referralDataService.getReferralFormData().state.state,
        ...(actionPerformed ? { action: actionPerformed } : {}),
      };
      const updatedService = {
        ...updatedServiceDetailsData,
        servicesRendered: updatedInfoData.servicesRendered?.map(
          (service: string) => ({ serviceType: service })
        ),
      }

      dataToSave = {
        infoData: updatedInfo,
        serviceDetailsData: updatedService,
        communicationData: formattedcontactDetails,
        contactDetailsData: updatedCommPreferncesData,
      };
    }
    else if (flow === 'participantEnrollment') {
      const updatedInfo = {
        ...updatedInfoData,
        programCode: this.program.program_code,
        residentGt18: formattedcontactDetails.residentGt18,
        applicationStage:
          this.referralDataService.getReferralDetails('infoData')
            .applicationStage,
        participantId:
          localStorage.getItem('participantId'),
        applicationSource:
          this.referralDataService.getReferralDetails('infoData')
            .applicationSource,
        trackingNumber: localStorage.getItem('trackingNumber'),
        participantPPLId: localStorage.getItem('participantPPLId'),
        finalApplicantDisposition:
          this.referralDataService.getReferralDetails('infoData').finalApplicantDisposition,
        isRegistrationLinkSent: this.referralDataService.getReferralDetails('infoData').isRegistrationLinkSent || false,
        ...(actionPerformed ? { action: actionPerformed } : {}),
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { residentGt18, yearsInState, ...filteredContactDetails } = formattedcontactDetails;
      const files = filesUploaded?.filesUploaded.map((file: any) => ({
        referralFileId: file.id,
      }));

      dataToSave = {
        infoData: updatedInfo,
        communicationData: filteredContactDetails,
        contactDetailsData: updatedCommPreferncesData,
        filesUploaded: files || [],
      };
    }
    else if (flow === 'providerEnrollment') {
      const providerTypes = this.sectionsService.getEnrollmentType();
      updatedInfoData["providerType"] = ENROLLMENT_TYPE_UI_TO_BACKEND_MAPPINGS[providerTypes[0]];
      const updatedInfo = {
        ...updatedInfoData,
        programCode: this.program.program_code,
        providerTypeName: ENROLLMENT_TITLE_MAPPINGS[providerTypes[0]],
        isRegistrationLinkSent: this.referralDataService.getReferralDetails('infoData').isRegistrationLinkSent || false,
        providerId: localStorage.getItem('providerId'),
        trackingNumber: localStorage.getItem('trackingNumber'),
        providerPPLId: localStorage.getItem('providerPPLId'),
        ...(actionPerformed ? { action: actionPerformed } : {}),
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { residentGt18, yearsInState, ...filteredContactDetails } = formattedcontactDetails;

      // const files = filesUploaded?.filesUploaded.map((file: any) => ({
      //   referralFileId: file.id,
      // }));

      dataToSave = {
        pageType:"providerCreateWizard",
        infoData: updatedInfo,
        communicationData: filteredContactDetails,
        serviceDetailsData: updatedServiceDetailsData,
        contactDetails: updatedCommPreferncesData
      };
    }
    else {
      const updatedInfo = {
        ...updatedInfoData,
        programCode: this.program.program_code,
        residentGt18: formattedcontactDetails.residentGt18,
        applicationStage:
          this.referralDataService.getReferralDetails('infoData')
            .applicationStage,
        referralId:
          this.referralDataService.getReferralDetails('infoData').referralId,
        applicationSource:
          this.referralDataService.getReferralDetails('infoData')
            .applicationSource,
        trackingNumber:
          this.referralDataService.getReferralDetails('infoData')
            .trackingNumber,
        ...(actionPerformed ? { action: actionPerformed } : {}),
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { residentGt18, yearsInState, ...filteredContactDetails } =
        formattedcontactDetails;
      const files = filesUploaded?.filesUploaded.map((file: any) => ({
        referralFileId: file.id,
      }));

      dataToSave = {
        infoData: updatedInfo,
        communicationData: filteredContactDetails,
        contactDetailsData: updatedCommPreferncesData,
        filesUploaded: files || [],
      };
    }

    console.log('final Data to save', dataToSave);

    this.referralDataService.saveLead(flow, dataToSave, true).subscribe(
      (resp) => {
        if (resp.status == 200 && resp.responsedata?.trackingNumber) {
          // this.router.navigate(['/confirmation'], {
          //   state: { num: trackingNumber, id: id },
          // });
          this.showConfirmation.emit({
            trackingNumber: resp.responsedata.trackingNumber,
            id: resp.responsedata.participantId || resp.responsedata.providerId || resp.responsedata.referralId,
            pplId: localStorage.getItem('participantPPLId') || localStorage.getItem('providerPPLId'),
            isApproved: actionPerformed == 'SUBMIT_APPROVE',
          });
          this.referralDataService.clearReferrralData();
        } else {
          if(resp.status !== 200){
            this.notification.error('API Call Failed', 'Please try again later.');
          }
          else {
            return;
          }
        }
      },
      (error) => {
        console.error('Error while submitting lead sumbmission: ', error);
      }
    );
  }

  getIdentifiers(values: any, lookupArray: any[]) {
    const referralIdentifiers =
      this.referralDataService.getReferralDetails('infoData').identifiers;
    const ids = this.flow == 'participantEnrollment' ? ['SSN', 'CIN'] : ['SSN', 'EIN', 'NPI', 'CIN'];
    return lookupArray
      .filter((lookup) => ids.includes(lookup.lkpValueCode))
      .map((lookup) => {
        const match = referralIdentifiers?.find(
          (ref: any) =>
            ref.type === lookup.lkpValueCode &&
            ref.value === values[lookup.lkpValueCode]
        );
        const result: any = {
          type: lookup.lkpValueCode,
          value: values[lookup.lkpValueCode] || '',
        };
        if (match) {
          result.referralIdentifierId = match.referralIdentifierId;
        }
        return result;
      });
  }

  getContactMethods(values: any, lookupArray: any[]) {
    return lookupArray.map((lookup) => {
      return {
        contactMethod: lookup.lkpValueCode,
        value: values[lookup.lkpValueCode] || '',
        preferred:
          values.preferredMethod === lookup.lkpValueCode ? 'true' : 'false',
        isSMSConsentGiven: false,
      };
    });
  }
}
