import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
//import { ReferralDataService } from '../../../services/referral-data.service';
//import { SectionsService } from '../../../services/sections.service';
//import { BaseComponent } from '../../../shared/utils/base.component';
//import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { UploadComponent } from '../../../../shared/components/upload/upload.component';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { RADIO_YES_NO_OPTIONS } from '../../../../shared/constants/static-data.constants';
import { performValidation } from '../../../../shared/utils/validation.util';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { LoggerService } from '../../../../shared/services/logger.service';
//import { performValidation } from '../../../shared/utils/validation.util';
//import { RADIO_YES_NO_OPTIONS } from '../../../shared/constants/static-data.constants';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { AuthorizationUtility } from '../../../../shared/authorization/auth.utility';
import { NotificationService } from '../../../../shared/services/notification.service';

@Component({
  selector: 'ppl-lead-detail-services',
  standalone: true,
  imports: [
    NgZorroModule,
    ReactiveFormsModule,
    UploadComponent,
    CommonModule,
    ButtonComponent,
  ],
  templateUrl: './lead-detail-services.component.html',
  styleUrl: './lead-detail-services.component.css',
})
export class LeadDetailServicesComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  steps: any;
  lookupTransformed!: string;
  @Input() set sectionData(data: any) {
    this.steps = data;
    /* console.log(this.steps); */
  }
  servicesForm!: FormGroup;

  mode = 'read';

  @Output() leadUpdated = new EventEmitter<boolean>();
  @Input() pageId = '';
  @Input() tabId = '';
  @Input() entry = '';
  @Input() selectedLeadId = 0;
  @Input() programCode = '';

  filledInfo: any = {};
  services: any[] = [];
  formHasBeenFilled = false;
  radioOptions = RADIO_YES_NO_OPTIONS;
  fileList: any;
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  isSectionEditable: boolean = false;
  infoData: any;

  constructor(
    private fb: FormBuilder,
    private leadDetailsService: LeadDetailsService,
    private logger: LoggerService,
    private authUtility: AuthorizationUtility,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.services = this.getLookup('Service_Type');
    this.isSectionEditable = this.authUtility.isSectionEditable(
      this.pageId,
      this.tabId,
      'Services'
    );

    this.servicesForm = this.fb.group({
      servicesRendered: [[]],
      leadId: [],
    });
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
    });
    if (this.mode == 'read') {
      this.leadDetailsService.getUpdatedLeadData().subscribe((data) => {
        this.infoData = data?.responsedata?.infoData;
        if (this.entry === STATIC_DATA.PROVIDER_ENROLLMENT) {
          this.filledInfo =
            data?.responsedata?.serviceDetails?.servicesRendered;
        } else {
          this.filledInfo = data?.responsedata?.servicesRendered;
        }

        this.lookupValue();
      });
    }

    /* console.log(this.services); */
    /* this.licences = this.getLookup('License_Type'); */
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps['service_rendered'];
      }
    } catch {
      // this.logger.debug('Error in getting section data and updating latest info: ', err);
    }
  }

  hasAnyValue(form: FormGroup): boolean {
    return Object.values(form.controls).some((control) => control.value !== '');
  }

  cancelEdit() {
    this.mode = 'read';
    //need to rest the form
    this.servicesForm.reset();
  }

  save() {
    //add save logic
    const formData = this.servicesForm.value;
    if (
      this.entry === STATIC_DATA.PROVIDER &&
      this.infoData.isProvidingServices &&
      formData.servicesRendered.length < 1
    ) {
      this.notificationService.error(
        'Error',
        'Cannot delete Services if Providing CDPAP Services is Yes'
      );
    } else {
      try {
        this.validationErrors = performValidation(
          formData,
          this.secData,
          'Save'
        );
      } catch (e) {
        this.logger.debug('Error in validations', e);
      }

      if (Object.keys(this.validationErrors).length === 0) {
        if (
          Array.isArray(formData.servicesRendered)
        ) {
          const payload = this.preparePayload(formData);

          this.leadDetailsService
            .updateLeadServicesRendered(payload, this.entry)
            .subscribe({
              next: () => {
                this.logger.info(
                  'Lead LeadServicesRendered saved successfully'
                );
                this.leadUpdated.emit(true);
                this.cancelEdit();
              },
              error: () => {
                this.leadUpdated.emit(true);
                this.cancelEdit();
              },
            });
        }
      }
    }
  }

  editInfo() {
    this.mode = 'edit';
    const servicesRenderedData = this.filledInfo?.map(
      (license: any) => license.serviceType
    );
    const leadId = this.selectedLeadId;
    this.servicesForm.patchValue({
      servicesRendered: servicesRenderedData || [],
      leadId: leadId,
    });
  }

  lookupValue() {
    const lookupTran: any[] = [];

    try {
      this.filledInfo?.forEach((service: any) => {
        const transform = this.helperService.getLookupValue(
          this.services,
          service.serviceType
        );

        if (transform) {
          lookupTran.push(transform);
        }
      });

      this.lookupTransformed = lookupTran.join(', ');
    } catch (e: any) {
      this.logger.error('error in transformation', e.message);
    }
  }

  preparePayload(source: any) {
    const payload: any = {};
    const transformSelection = this.getPayloadTransformation(
      source.servicesRendered
    );
    if (
      this.entry === STATIC_DATA.PARTICIPANT_ENROLLMENT ||
      this.entry === STATIC_DATA.PROVIDER
    ) {
      payload.servicesRendered = transformSelection;
    } else {
      payload.prvdrEnrlservicesRendered = transformSelection;
    }
    return {
      id: source.leadId,
      programCode: this.programCode,
      ...payload,
      /* servicesRendered: this.getPayloadTransformation(source.servicesRendered) */
    };
  }

  getPayloadTransformation(selectedValues: string[]): any {
    const selectedData = selectedValues?.map((service: any) => {
      const row = this.filledInfo?.find(
        (data: any) => data.serviceType === service
      );
      return {
        serviceType: service,
        modifiedDate: row?.modifiedDate,
      };
    });

    return selectedData;
  }
}
