import { Component } from '@angular/core';
import { AccordionComponent } from '../../../shared/components/accordion/accordion.component';

@Component({
  selector: 'ppl-benefits',
  standalone: true,
  imports: [AccordionComponent],
  templateUrl: './benefits.component.html',
  styleUrl: './benefits.component.css'
})
export class BenefitsComponent {

}
