import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, throwError } from 'rxjs';
import { ENDPOINTS } from '../shared/constants/url.constants';
import { GlobalErrorHandlerService } from '../shared/services/global-error-handler.service';
import { HttpRequestService } from '../shared/services/http-request.service';
import { formatUrl } from '../shared/utils/format-url-util';

@Injectable({
  providedIn: 'root'
})
export class CdpapService {
  triggerOpenPopup = new Subject();
  private cdPapFormValue = new Subject();
  cdPapFormValueChangeSub$ = this.cdPapFormValue.asObservable();

  constructor(private http: HttpRequestService,
      private errorHandler: GlobalErrorHandlerService,) { }

  openPopup() {
    this.triggerOpenPopup.next(true);
  }

  updateValue(v: any) {
    this.cdPapFormValue.next(v);
  }

  getCDPAPAlgo(payload : any, programCode: string): Observable<any> {
    const url = formatUrl(ENDPOINTS.POST_CDPAP, {programCode});
    return this.http.post(url, payload).pipe(
      catchError((err) => {
        return throwError(() => this.errorHandler.handleError(err));
      })
    );
  }
}
