{
    "columns": [
      {
        "header_id": "clientAuth",
        "header_label": "Authorization Number",
        "jsonpath": "clientAuth",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "consumerFirstName",
        "header_label": "First Name",
        "jsonpath": "consumerFirstName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "consumerLastName",
        "header_label": "Last Name",
        "jsonpath": "consumerLastName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "medicaid",
        "header_label": "CIN",
        "jsonpath": "medicaid",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "consumerPPLId",
        "header_label": "PPL Id",
        "jsonpath": "consumerPPLId",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "serviceCode",
        "header_label": "Service Code",
        "jsonpath": "serviceCode",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "serviceCodeDesc",
        "header_label": "Service Code",
        "jsonpath": "serviceCodeDesc",
        "isDisplayedAtHeader": "n",
        "isSearchable": "n"
      },
      {
        "header_id": "modifierCode1",
        "header_label": "Modifier",
        "jsonpath": "modifierCode1",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "authFrequencyLkp",
        "header_label": "Authorization Type",
        "jsonpath": "authFrequencyLkp",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "units",
        "header_label": "Units",
        "jsonpath": "units",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "unittypeLkp",
        "header_label": "Unit Type",
        "jsonpath": "unittypeLkp",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "utilizedUnit",
        "header_label": "Used Unit %",
        "jsonpath": "utilizedUnit",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "startDate",
        "header_label": "Auth Start Date",
        "jsonpath": "startDate",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "endDate",
        "header_label": "Auth End Date",
        "jsonpath": "endDate",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "source",
        "header_label": "Source",
        "jsonpath": "source",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "orgName",
        "header_label": "MCO/Agency",
        "jsonpath": "orgName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "header_id": "statusLkp",
        "header_label": "Status",
        "jsonpath": "statusLkp",
        "isDisplayedAtHeader": "y",
        "isSearchable": "y"
      },
      {
        "header_id": "modifiedDate",
        "header_label": "Modified Date",
        "jsonpath": "modifiedDate",
        "isDisplayedAtHeader": "n",
        "isSearchable": "n"
      }
    ],
    "buttons": [
      {
        "button_id":"Approve",
        "button_label":"Approve",
        "buttonAction" : "approve",
        "className": "primary-btn-color"
      },
      {
        "button_id":"Deny",
        "button_label":"Deny",
        "buttonAction" : "Deny",
        "className": "primary-btn-color"
      }
    ],
    "apiDetails": "authorizationsList.json",
    "pageName": "consumer-auth",
    "orderByColumn": ["modifiedDate"],
    "orderBy": ["desc"]
  }
  