import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PDFDocument } from 'pdf-lib';
import { PDFFormConfig, KeyValuePair, PDFFieldMapConfig, PDFCheckBoxField } from '../../interfaces/config';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'ppl-editpdf',
  standalone: true,
  imports: [
    NgxExtendedPdfViewerModule,
    CommonModule
  ],
  templateUrl: './editpdf.component.html',
  styleUrl: './editpdf.component.css'
})
export class EditpdfComponent implements OnInit, AfterViewInit {

  @Input() pdfConfig?: PDFFormConfig;
  @Input() pdfFile?: string;
  @Input() readonly?: boolean = false;
  @Input() showSignature?: boolean = true;
  @Input() signature?: string;
  @Input() signatureId?: string;
  @Input() pdfBlob?: Blob;

  @Input() isParticipantFlow?: boolean;
  @Input() isSelf?: boolean;
  @Input() selectedParticipantId?: number;
  @Input() selectedProviderType?: string;
  @Input() isPdfSubmitted?: boolean;

  signedField: any;

  @Output() fieldsUpdated = new EventEmitter();

  isLoaded = false;
  customError: string = '';

  form?: FormGroup;
  formKeyMapping: KeyValuePair = {};
  formBusinessMapping: KeyValuePair = {};
  formFieldPrepulatedMapping: KeyValuePair = {};
  formFieldRequiredMapping: KeyValuePair = {};
  showErrors = false;
  errors: any = [];
  checkBoxFields: Record<string, any> = [];
  radioBoxFields: Record<string, any> = [];
  processedFields: string[] = [];
  concernedInputs = ['line 1', 'line 2', 'line 3', 'line 4', 'line 5'];
  signField: any;
  signCordinates: any;
  isSignButtonPlaced: boolean = false;
  private _zoomSetting: number | string | undefined = '125%';
  public minZoom = 1;
  public maxZoom = 1;

  constructor(private fb: FormBuilder, private router: Router) {
  }

  public get zoomSetting() {
    return String(this._zoomSetting);
  }

  public set zoomSetting(zoom: string) {
    if (isNaN(Number(zoom))) {
      this._zoomSetting = zoom;
    } else {
      this._zoomSetting = zoom + '%';
    }
  }

  ngOnInit(): void {
    const config: KeyValuePair = {};

    if (this.pdfConfig) {
      this.pdfConfig?.fields.forEach((field: PDFFieldMapConfig) => {
        let isFieldRequired = false;

        if (this.isParticipantFlow) {
          isFieldRequired = field.source == 'PTC' ? field.mandatory : false;
        }
        else if (this.isSelf) {
          isFieldRequired = field.mandatory;
        }
        else if (field.source == '' || field.source == this.selectedProviderType) {
          isFieldRequired = field.mandatory;
        }

        if (field.fieldType == "PDCheckBox") {
          this.initCheckBoxes(config, field, isFieldRequired);
        }
        else if (field.fieldType == "PDRadioButton") {
          this.initRadioButtons(config, field, isFieldRequired);
        }
        else {
          const id = field.businessName;
          this.formKeyMapping[field.fieldId] = id;
          this.formBusinessMapping[id] = id;
          this.formFieldPrepulatedMapping[field.fieldId] = field.isPrepopulated;
          this.formFieldRequiredMapping[field.fieldId] = isFieldRequired;
          config[id] = [field.value, isFieldRequired ? Validators.required : null]
        }
      });
    }

    console.log('form initialized');

    this.form = this.fb.group(config);
    this.form.valueChanges.subscribe(fld => {
      if (!this.readonly && this.isPdfSubmitted && !this.isSignButtonPlaced) {
        this.form?.get(this.signField.businessName)?.setValue(undefined, {
          emitEvent: false
        });
        this.addSignHereButton(this.signCordinates, this.signField, this.pdfBlob, this.signature);
      }
      this.validateForm();
      this.fieldsUpdated.emit({ data: fld, errors: this.errors, customError: this.customError });
    })
  }

  initCheckBoxes(config: KeyValuePair, field: PDFFieldMapConfig, isFieldRequired: boolean) {
    if (field.fieldType == "PDCheckBox") {
      field.childFields?.forEach((checkbox: PDFCheckBoxField) => {
        const id = checkbox.fieldId;
        this.formKeyMapping[checkbox.fieldId] = id;
        this.formBusinessMapping[id] = checkbox.fieldId;
        this.formFieldPrepulatedMapping[checkbox.fieldId] = field.isPrepopulated;
        this.formFieldRequiredMapping[checkbox.fieldId] = isFieldRequired;
        config[id] = [checkbox.value, null]
      });

      if (field.mandatory) {
        const checkboxElementId = field.businessName;
        config[checkboxElementId] = ['', null]
        if (!this.checkBoxFields[checkboxElementId]) {
          this.checkBoxFields[checkboxElementId] = [];
        }
        this.checkBoxFields[checkboxElementId].push(field);
        this.formBusinessMapping[checkboxElementId] = checkboxElementId;
      }
    }
  }

  initRadioButtons(config: KeyValuePair, field: PDFFieldMapConfig, isFieldRequired: boolean) {
    if (field.fieldType == "PDRadioButton") {
      field.childFields?.forEach((checkbox: PDFCheckBoxField) => {
        const id = checkbox.fieldId;
        this.formKeyMapping[checkbox.fieldId] = id;
        this.formBusinessMapping[id] = id;
        this.formFieldPrepulatedMapping[checkbox.fieldId] = field.isPrepopulated;
        this.formFieldRequiredMapping[checkbox.fieldId] = isFieldRequired;
        config[id] = [checkbox.value, null]
      });

      if (field.mandatory) {
        const checkboxElementId = field.businessName;
        config[checkboxElementId] = ['', null]
        if (!this.radioBoxFields[checkboxElementId]) {
          this.radioBoxFields[checkboxElementId] = [];
        }
        this.radioBoxFields[checkboxElementId].push(field);
        this.formBusinessMapping[checkboxElementId] = checkboxElementId;
      }
    }
  }

  onFormSubmission() {
    this.isPdfSubmitted = true;
    this.isSignButtonPlaced = false;
  }

  ngAfterViewInit() {
    if (this.pdfConfig) {
      setTimeout(() => {
        this.handleSign();
      }, 1000);
    } else {
      this.isLoaded = true;
    }
  }

  attachFormFieldListeners(event: any) {
    setTimeout(() => {
      const inputFields = document.querySelectorAll(
        '.pdfViewer .annotationLayer input, .pdfViewer .annotationLayer select, .pdfViewer .annotationLayer textarea'
      );
      inputFields.forEach((inputField: any) => {
        inputField.setAttribute('aria-label', inputField.name);
        inputField.setAttribute('tabindex', 0)
        if (this.processedFields.indexOf(inputField.name) == -1 || inputField.type == "checkbox" || inputField.type == "radio") {
          this.processedFields.push(inputField.name);
          if (this.signedField && inputField.name == this.signedField.fieldId) {
            inputField.style.display = 'none';
            return;
          }

          const angularFieldName = this.form?.get([this.formKeyMapping[inputField.name]]);
          // if field exsit in form
          if (angularFieldName) {
            // register change event listner (works for all the fields) 
            if (this.formFieldPrepulatedMapping[inputField.name] != 'Y') {
              this.registerEventToInputField(inputField, angularFieldName)
            }

            //ignore for signature textbox
            if (inputField.type == 'text' && inputField.name != 'Signature of Employee') {
              if (this.formFieldPrepulatedMapping[inputField.name] == 'Y') {
                inputField.style.backgroundColor = '#b3e7ff';
              } else if (this.formFieldRequiredMapping[inputField.name]) {
                inputField.style.backgroundColor = '#FFEA00';
              }
            }

            // get the initial value and put it in form
            const angularValue = angularFieldName.value;
            if (angularValue) {
              // handle checkbox
              if (inputField.type == "checkbox") {
                const exportValue = inputField.getAttribute('exportvalue');
                inputField.checked = exportValue == angularValue ? true : false;
              }
              else if (inputField.type == "radio") {
                const exportValue = inputField.getAttribute('exportvalue');
                inputField.checked = exportValue == angularValue ? true : false;
              } else {
                inputField.value = angularValue;
              }

              //mark field readonly
              if (this.readonly || this.formFieldPrepulatedMapping[inputField.name] == 'Y') {
                if (inputField instanceof HTMLInputElement || inputField instanceof HTMLTextAreaElement) {
                  inputField.readOnly = true;
                } else if (inputField instanceof HTMLSelectElement) {
                  inputField.disabled = true
                }
              }
            }
          }
        }
      });

      this.isLoaded = true;

      if (!this.readonly && !this.isPdfSubmitted) {
        this.addSignHereButton(this.signCordinates, this.signField, this.pdfBlob, this.signature);
      }
    }, 1000);
  }

  registerEventToInputField(inputField: any, angularFieldName: any) {
    if (this.pdfConfig?.form_description.trim().toLowerCase() == 'ny it form' && this.concernedInputs.indexOf(inputField.name) != -1) {
      inputField.addEventListener('keyup', () => {
        this.handleEventFromInput(inputField, angularFieldName);
      });
    }

    inputField.addEventListener('input', () => {
      this.handleEventFromInput(inputField, angularFieldName);
    });
  }

  handleEventFromInput(inputField: any, angularFieldName: any) {
    if (inputField.type == "checkbox") {
      const isChecked = inputField.checked;

      if (isChecked) {
        //check if checkbox exist in radio buttons
        Object.entries(this.radioBoxFields).forEach(([key, values]) => {
          if (values.length > 0) {
            const isTheValidFiled = values[0].childFields.filter((e: any) => e.fieldId == inputField.name);
            if (isTheValidFiled && isTheValidFiled.length > 0) {
              values[0].childFields.forEach((radio: any) => {
                if (radio.fieldId !== inputField.name) {
                  var formEle = this.form?.get(radio.fieldId);
                  if (formEle) {
                    formEle.setValue('Off');
                  }
                }
              });
            }
          }
        });
      }

      console.log(inputField);
      angularFieldName.markAsTouched();
      const exportValue = inputField.getAttribute('exportvalue');
      angularFieldName.setValue(isChecked ? exportValue : 'Off');
    } else if (inputField.type == "radio") {
      angularFieldName.markAsTouched();
      const exportValue = inputField.getAttribute('exportvalue');
      angularFieldName.setValue(exportValue);
    }
    else {
      console.log(inputField);
      angularFieldName.markAsTouched();
      angularFieldName.setValue(inputField.value);
    }
  }

  onPageRendered(event: any) {
    console.log(`Page ${event.pageNumber} rendered`);
    this.attachFormFieldListeners(event);
  }

  navigateToPdfList() {
    this.router.navigate(['/']);
  }

  validateForm() {
    this.calculateErrors();

    this.customError = '';
    Object.entries(this.checkBoxFields).forEach(([key, values]) => {
      let value = '';
      values.forEach((v: any) => {
        v.childFields.forEach((cf: any) => {
          const inputValue = this.form?.get(cf.fieldId)?.value;
          if (value == '' && inputValue != 'Off') {
            value = inputValue;
          }
        });
      });

      if (value == '') {
        this.customError = 'Please select ' + key + ' details';
      }
    });
    this.showErrors = this.customError != '' || !this.form?.valid;

    this.customError = '';
    Object.entries(this.radioBoxFields).forEach(([key, values]) => {
      let value = '';
      values.forEach((v: any) => {
        v.childFields.forEach((cf: any) => {
          const inputValue = this.form?.get(cf.fieldId)?.value;
          if (value == '' && inputValue != 'Off') {
            value = inputValue;
          }
        });
      });

      if (value == '') {
        this.customError = 'Please select ' + key + ' details';
      }
    });
    this.showErrors = this.customError != '' || !this.form?.valid;

    if (!this.showErrors) {
      this.validateCustomLogic();
    }
  }

  validateCustomLogic() {
    if (this.pdfConfig?.form_description.trim().toLowerCase() == 'USCIS Form I9'.toLowerCase()) {
      // rules for PART A or PART B & C 
      let isPartAInValid = !this.form?.value['List A - Document Issuing Authority 1'] || !this.form?.value['List A - Document Title 1'];
      let isPartBCInValid = !this.form?.value['List B - Document Title'] ||
        !this.form?.value['List B - Document Issuing Authority'] ||
        !this.form?.value['List C - Document Title'] || !this.form?.value['List C - Document Issuing Authority'];

      let isPartBCTouched = this.form?.get('lb_doc_title')?.touched || this.form?.get('List B - Document Issuing Authority')?.touched ||
        this.form?.get('List C - Document Title')?.touched || this.form?.get('List C - Document Issuing Authority')?.touched;

      if (isPartAInValid && isPartBCInValid) {
        this.showErrors = true;

        if (isPartBCTouched) {
          this.customError = "Document Title 1 and Issuing Authority are required in LIST B & C";
        } else {
          this.customError = "LIST A - Document Title 1 and Issuing Authority are required";
        }
      }

      // rules for immigration or citizen status

      if (this.form?.value['CB_3'] !== 'Off') {
        // uscis number is required
        if (!this.form?.value['USCIS A-Number 3(a)']) {
          this.showErrors = true;
          this.customError = "USCIS or A Number is required";
        }
      } else if (this.form?.value['CB_4'] !== 'Off') {
        //one of these values are required
        let hasEnteredAnyInfo = this.form?.value['USCIS A-Number'] || this.form?.value['Form I94 Admission Number'] || this.form?.value['Foreign Passport Number'];
        if (!hasEnteredAnyInfo) {
          this.showErrors = true;
          this.customError = "One of the USCIS - A Number, Form I-94 Admission Number, Foreign Passport Number are required";
        }
      }
    }
  }


  calculateErrors() {
    this.errors = [];
    Object.keys(this.form?.value).forEach(field => {
      const control = this.form?.get(field);
      if (control) {
        const controlErrors = control.errors;
        if (controlErrors !== null) {
          Object.keys(controlErrors).forEach(keyError => {
            this.errors.push({
              controlName: field,
              errorName: keyError,
              errorValue: controlErrors[keyError],
              businessName: this.formBusinessMapping[field]
            });
          });
        }
      }
    });

    // This removes duplicates
    this.errors = this.errors.filter((error: any, index: any, self: any) => self.findIndex((t: any) => {
      return t.controlName === error.controlName && t.errorName === error.errorName;
    }) === index);
    if (this.errors.length > 0) {
      this.showErrors = true;
    }
  }

  async handleSign() {
    if (!this.showSignature || !this.signature) {
      this.isLoaded = true;
      return;
    }

    if (this.pdfBlob && this.pdfConfig && this.showSignature) {
      let coordinates: any;
      let field: any;
      if (this.isParticipantFlow) {
        const requiredFields = this.pdfConfig.fields.filter(e => e.source == "PTC" && e.fieldType == "CustomSignature");
        if (requiredFields.length > 0) {
          field = requiredFields[0];
          coordinates = field.coordinates;
        }
      }
      else if (!this.isParticipantFlow && !this.selectedProviderType) {
        const requiredFields = this.pdfConfig.fields.filter(e => e.fieldType == "CustomSignature");
        if (requiredFields.length > 0) {
          field = requiredFields[0];
          coordinates = field.coordinates;
        }
      }
      else {
        const requiredFields = this.pdfConfig.fields.filter(e => e.source == this.selectedProviderType && e.fieldType == "CustomSignature");
        if (requiredFields.length > 0) {
          // const fieldWithCoordinate = requiredFields.filter(e => e.coordinates != null);
          field = requiredFields[0];
          coordinates = field.coordinates;
        }
      }

      if (!this.readonly) {
        if (coordinates && this.pdfBlob) {
          this.signCordinates = coordinates;
          this.signField = field;
          // Add the signature to the PDF
          await this.addImageToPdf(
            this.pdfBlob,
            this.signature,
            coordinates.pageId,
            coordinates.xPos,
            coordinates.yPos,
            coordinates.signatureWidth,
            coordinates.signatureHeight
          );

        } else {
          console.log("Coordinates couldn't be found for signature");
        }
      }
      else {
        if (coordinates) {
          await this.addImageToPdf(
            this.pdfBlob,
            this.signature,
            coordinates.pageId,           // Page number where the image will be placed
            coordinates.xPos,         // X position (from the bottom-left of the page)
            coordinates.yPos,         // Y position (from the bottom-left of the page)
            coordinates.signatureWidth,         // Width of the image
            coordinates.signatureHeight           // Height of the image
          );

          this.form?.get(field.businessName)?.setValue(this.signatureId);
          this.signedField = field;
        } else {
          console.log("coordinates couldn't found to sign");
        }
      }
    }
  }

  addSignHereButton(coordinates: any, field: any, pdfFile: any, signature: any) {
    if (this.isSignButtonPlaced) {
      return;
    }

    if (!signature) {
      this.errors.push({
        controlName: field,
        errorName: 'Signature not found, Please add your signature in Signature Tab',
        errorValue: 'Signature not found, Please add your signature in Signature Tab',
        businessName: this.formBusinessMapping[field]
      });

      return;
    }

    const annotationLayer = document.querySelector(`.pdfViewer .page[data-page-number="${coordinates.pageId}"] .annotationLayer`);

    if (!annotationLayer) {
      console.log('annotation layer in specific page not found')
      return;
    }
    const scaleFactor = 1.34;

    const button = document.createElement('button');
    const buttonXPos = coordinates.xPos * scaleFactor;
    const buttonYPos = coordinates.yPos * scaleFactor;

    button.innerText = "Click Here to Sign";
    button.style.position = 'absolute';
    button.style.left = `${buttonXPos - 10}px`;
    button.style.bottom = `${buttonYPos - 3}px`;
    button.style.zIndex = '2147483647'; // Ensure the button is above the PDF content
    button.style.padding = '13px';
    button.style.background = 'repeating-linear-gradient(45deg, yellow, #f4f4ee 10px)';
    button.style.borderRadius = '5px';
    button.style.cursor = 'pointer';
    button.style.pointerEvents = 'auto';
    button.style.width = '140px';
    button.style.color = 'red';
    button.style.fontWeight = '700';
    button.style.border = '2px solid red';

    // Add click event to the button to trigger the signature action
    button.addEventListener('click', () => {
      // Hide the button once clicked
      button.style.display = 'none';

      // Set the form value for the signature field
      this.form?.get(field.businessName)?.setValue(this.signatureId);
      this.signedField = field;
    });

    if (!this.isSignButtonPlaced) {
      // Append the button to the annotation layer
      annotationLayer?.appendChild(button);
      this.isSignButtonPlaced = true;
    }
  }

  async addImageToPdf(pdfFile: Blob, sign: string, pageNumber: number, xPos: number, yPos: number, width: number, height: number) {
    try {
      const pdfBytes = await pdfFile.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
      const base64 = sign.split(',')[1];
      const signBytes = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
      const image = await pdfDoc.embedPng(signBytes);
      const pages = pdfDoc.getPages();
      const page = pages[pageNumber - 1];
      page.drawImage(image, {
        x: xPos,
        y: yPos + 5,
        width: width,
        height: height,
      });
      const modifiedPdfBytes = await pdfDoc.save();
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      this.pdfFile = URL.createObjectURL(blob);
    }
    catch (error) {
      console.error('error signing the PDF', error)
    }
    this.isLoaded = true;
  }
}