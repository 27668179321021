{
    "participant_info": {
        "sectionId": "participant_info",
        "header": "Registration Information",
        "available_actions": null,
        "attributes": [
            {
                "attribute_ID": "lang",
                "fieldName": "lang",
                "label": "Preferred Language",
                "type": "select",
                "mandatory": "Y",
                "validator": null,
                "tooltip": "Please select Preferred Language",
                "isAvailable": "Y",
                "placeholder": "Preferred Language"
            },
            {
                "attribute_ID": "fiOrgName",
                "fieldName": "fiOrgName",
                "label": "CDPAP Facilitator",
                "type": "input",
                "mandatory": "N",
                "validator": null,
                "tooltip": "Please select CDPAP Facilitator",
                "isAvailable": "Y",
                "placeholder": "CDPAP Facilitator"
            }
        ],
        "validations": [
            {
                "whatToCheck": "atLeastXEntered",
                "clientOrServer": "C",
                "attributes": [
                      {
                        "validatorid": 1,
                        "attribute_ID": ["lang"],
                        "functionParams": {
                          "ErrMsg": "Please select Preferred Language"
                        }
                      }
                ]
            }
        ],
        "actions": [
            {
                "Name": "Previous",
                "Validate": null
            },
            {
                "Name": "Next",
                "Validate": [
                    {
                        "checkType": "atLeastXEntered",
                        "validatorid": [
                            1
                        ]
                    }
                ]
            }
        ]
    }
}