/* src/app/components/lead-details/documents/file-upload/file-upload/file-upload.component.css */
[nz-icon] {
  margin-right: 6px;
  font-size: 1.429rem;
  color: #3c86f5;
  cursor: pointer;
}
.auth-table-width {
  width: 55rem;
}
/*# sourceMappingURL=file-upload.component-AAF6MGWD.css.map */
