import { Component, OnInit } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { AuthorizeButtonDirective } from '../../../shared/authorization/authorizeButton.directive';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import {
  ENROLLMENT_TYPE_BACKEND_MAPPINGS,
  STATIC_DATA,
} from '../../../shared/constants/static-data.constants';
import { SharedDataService } from '../../../shared/services/shared-data-service';
import { ParticipantLeadsListComponent } from '../../participant-leads-list/participant-leads-list.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { ListService } from '../../../shared/services/list.service';

@Component({
  selector: 'ppl-provider-enrollment-list',
  standalone: true,
  imports: [
    ListTableComponent,
    NzIconModule,
    NzButtonModule,
    RouterModule,
    AuthorizeButtonDirective,
    ButtonComponent,
    NzDropDownModule,
  ],
  providers: [ListService],
  templateUrl: './provider-enrollment-list.component.html',
  styleUrl: './provider-enrollment-list.component.css',
})
export class ProviderEnrollmentListComponent extends ParticipantLeadsListComponent implements OnInit {
  override sharedDataKey: string = STATIC_DATA.SHARED_PROVIDER_ENROLLMENT_DATA;
  override pageId: string = PAGE_CONSTANTS.PROVIDER_ENROLLMENT_DATA_GRID_PAGE;
  providerTypes: any[] = [];


  constructor(
    router: Router,
    sectionService: SectionsService,
    referralDataService: ReferralDataService,
    sharedDataService: SharedDataService,
    authUtility: AuthorizationUtility,
    authService: AuthorizationService,
    private leadDetailsService: LeadDetailsService,
    private listService: ListService,
  ) {
    super(
      router,
      sectionService,
      referralDataService,
      sharedDataService,
      authUtility,
      authService
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.listService.getProgramTypes(this.programName).subscribe((data: any) => {
      if(data.responsedata) {
        this.providerTypes = data.responsedata.providerTypes;
      }
    })
  }

  override onNewLead(): void {
    this.router.navigate(['/referralLogic']);
    this.sectionService.setFlow('providers');
    this.sectionService.setEnrollmentType(JSON.stringify([]));
    this.referralDataService.clearReferrralData();
  }

  onNewEnrollment(type: string) {
    // this.router.navigate([`/home/newEnrollmentForm/${type}`]);
    this.router.navigate(['/newReferral']);
    this.sectionService.setFlow('providerEnrollment');
    this.sectionService.setEnrollmentType(JSON.stringify([ENROLLMENT_TYPE_BACKEND_MAPPINGS[type]]));
    localStorage.setItem('lead_entry', '');
    this.referralDataService.clearReferrralData();
  }

  override rowClicked(rowDetail: any) {
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    localStorage.setItem('lead_entry', 'providerEnrollment');
    this.sectionService.setFlow('providerEnrollment');
    this.referralDataService.clearReferrralData();

    localStorage.setItem('selected_lead', rowDetail.provider_id); //rowDetail.referral_participant_id

    this.leadDetailsService
      .fetchLeadDetailsApi(
        'providerEnrollment',
        program.program_code,
        rowDetail.provider_id
      )
      .subscribe((data: any) => {
          
        this.sectionService.setEnrollmentType(
          
          JSON.stringify([
            ENROLLMENT_TYPE_BACKEND_MAPPINGS[
            data?.responsedata?.basicInfo?.providerType
            ],
           ])
        );

        if (
          rowDetail.application_status_lkp == 'New Application' ||
          rowDetail.application_status_lkp == 'In Progress'
        ) {


          this.referralDataService.setReferralDetails(data.responsedata);
          setTimeout(() => {

            localStorage.setItem('providerId', data?.responsedata?.basicInfo?.providerId);
            localStorage.setItem('trackingNumber', data.responsedata?.basicInfo?.trackingNumber);
            localStorage.setItem('providerPPLId', data.responsedata?.basicInfo?.providerPPLId);
            this.sectionService.setFlow('providerEnrollment');
            // const providerTypes = [...new Set(data?.responsedata?.participantAssociations.map((e: any) => ENROLLMENT_TYPE_BACKEND_MAPPINGS[e.providerType]))];

            this.router.navigate(['/newReferral']);
          }, 200);
        } else {
          setTimeout(() => {
            this.router.navigate(['/home/providerEnrollmentList/details/'], { state: { navigateTo: '/home/providerEnrollmentList' } });
          }, 200);
        }
      });
  }
}
