import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { SectionsService } from '../../../../services/sections.service';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ppl-auth-additional-details',
  standalone: true,
  imports: [NzGridModule,
    CommonModule
  ],
  templateUrl: './auth-additional-details.component.html',
  styleUrl: './auth-additional-details.component.css'
})
export class AuthAdditionalDetailsComponent extends BaseComponent implements OnInit{
  program: any;
  @Input() authData: any;

  constructor(private sectionsService: SectionsService){
      super();
  }

  ngOnInit(): void {
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    const secData = this.sectionsService.getSectionData(
      this.program.program_code,
      'payroll'
    );
    this.secData = secData.additionalDetails;
  }

}
