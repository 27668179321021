@if(filledInfo && sectionData){
<div nz-row>
  @if(isAttrAvailable('trackingNumber')){
    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'trackingNumber']">
      <div nz-row>
  
        <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("trackingNumber").label }}
        </div>
        <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
          {{ filledInfo?.trackingNumber ? filledInfo?.trackingNumber : '-' }}
        </div>
      </div>
    </div>
    }
    @if(isAttrAvailable('id')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'id']">
        <div nz-row>
    
          <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("id").label }}
          </div>
          <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
            {{ id ? id : '-' }}
          </div>
        </div>
      </div>
      }
      @if(isAttrAvailable('participantProgramCode')){
        <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'participantProgramCode']">
          <div nz-row>
            <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
              {{ getAttributes("participantProgramCode").label }}
            </div>
            <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
              {{ programCode ? programCode : '-' }}
            </div>
          </div>
        </div>
        }
  @if(isAttrAvailable('decisionComments')){

  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'decisionComments']">
    <div nz-row>
      <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("decisionComments").label }}
      </div>
      <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
        {{ filledInfo?.decisionComments ? filledInfo?.decisionComments : '-'}}
      </div>
    </div>
  </div>
  } @if(isAttrAvailable('applicationAssignedToUser')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'applicationAssignedToUser']">
    <div nz-row>

      <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("applicationAssignedToUser").label }}
      </div>
      <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
        {{ filledInfo?.applicationAssignedToUser ? filledInfo?.applicationAssignedToUser : '-' }}
      </div>
    </div>
  </div>
  } 
    @if(isAttrAvailable('applicationStage')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'applicationStage']">
        <div nz-row>
    
          <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("applicationStage").label }}
          </div>
          <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
            {{ filledInfo?.applicationStage ? getLookupValue(getLookup('Lead_Application_Stage'),filledInfo?.applicationStage) : '-' }}
          </div>
        </div>
      </div>
      }
    @if(isAttrAvailable('finalApplicantDisposition')){
      <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'finalApplicantDisposition']">
        <div nz-row>
    
          <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
            {{ getAttributes("finalApplicantDisposition").label }}
          </div>
          <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
            {{ filledInfo?.finalApplicantDisposition ? filledInfo?.finalApplicantDisposition : '-' }}
          </div>
        </div>
      </div>
      }
      @if(isAttrAvailable('applicationSubmissionDate')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'applicationSubmissionDate']">
    <div nz-row>

      <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("applicationSubmissionDate").label }}
      </div>
      <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
        {{
        (filledInfo?.applicationSubmissionDate)
        |
        date:'MM/dd/yyyy'
        }}
      </div>
    </div>
  </div>

  }@if(isAttrAvailable('applicationSource')){
  <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'applicationSource']">
    <div nz-row>

      <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
        {{ getAttributes("applicationSource").label }}
      </div>
      <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
        {{ filledInfo?.applicationSource }}
      </div>
    </div>
  </div>
  }
  @if(isAttrAvailable('providerType')){
    <div nz-col nzSpan="12" *authorizeFieldView="[pageId, tabId, 'ApplicationDetails', 'providerType']">
      <div nz-row>
  
        <div nz-col nzSpan="12" ngClass="font-weight-600 pt-2 pb-2">
          {{ getAttributes("providerType").label }}
        </div>
        <div nz-col nzSpan="12" ngClass="pt-2 pb-2">
          {{ providerType }}
        </div>
      </div>
    </div>
    }
</div>
}@else{
<nz-row>
  <p>No Data found</p>
</nz-row>
}