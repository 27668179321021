<div class="pdf-container" *ngIf="pdfFile && form && isLoaded">
    <ngx-extended-pdf-viewer [src]="pdfFile" 
    useBrowserLocale="true" 
    [showToolbar]="false"
    [disableMouseWheel]="true"
    [height]="'90vh'"
    [(zoom)]="zoomSetting"
    [minZoom]="minZoom" 
    [maxZoom]="maxZoom"
    (pagesLoaded)="attachFormFieldListeners($event)" 
    (pageRendered)="onPageRendered($event)">
    </ngx-extended-pdf-viewer>
</div>