import { Component, OnInit } from '@angular/core';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { PAGE_CONSTANTS } from '../../../shared/constants/page.constants';
import { Router } from '@angular/router';
import { ClaimsService } from '../../../services/claims.service';
import { SectionsService } from '../../../services/sections.service';
import { PageConfig } from '../../../shared/authorization/auth.config';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { SharedDataService } from '../../../shared/services/shared-data-service';

@Component({
  selector: 'ppl-case-manager-list',
  standalone: true,
  imports: [ListTableComponent],
  templateUrl: './case-manager-list.component.html',
  styleUrl: './case-manager-list.component.css',
})
export class CaseManagerListComponent implements OnInit {
  sharedDataKey: string = STATIC_DATA.SHARED_CASE_MANAGER_DATA;
  pageId: string = PAGE_CONSTANTS.SERVICE_CODE_GRID_PAGE;
  columnsToBeHidden: string[] = [];
  actionsToBeHidden: string[] = [];
  isPageRestrictionLoaded = false;
  programName!: string;
  lazyLoad = true;

  constructor(
    private router: Router,
    private sectionService: SectionsService,
    private sharedDataService: SharedDataService,
    private authUtility: AuthorizationUtility,
    private authService: AuthorizationService,
    private claimsService: ClaimsService
  ) {}

  ngOnInit() {
    const selectedProgram = JSON.parse(
      localStorage.getItem('selected_program')!
    );
    this.programName = selectedProgram.program_code;
    this.setPageAccess();
  }

  rowClicked(rowDetail: any) {
    localStorage.setItem('lead_entry', 'caseManager');
    this.sectionService.setFlow('caseManager');
    localStorage.setItem('selected_id', rowDetail.caseManagerId);
    this.claimsService.setFormData('case_manager', rowDetail);
    this.router.navigate(['/home/caseManagerDetails']);
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if (!pageConfig || !pageConfig.pageName) {
      this.authService
        .fetchPageAuthorization(this.programName, this.pageId, 'LIST')
        .subscribe((response) => {
          this.setRestrictions(response);
        });
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: PageConfig) {
    if (pageConfig) {
      this.columnsToBeHidden = this.authUtility.getHiddenColumns(
        pageConfig,
        this.pageId
      );
      this.actionsToBeHidden = this.authUtility.getHiddenActions(
        pageConfig,
        this.pageId
      );
    }
    this.isPageRestrictionLoaded = true;
  }

  clearData() {
    this.sharedDataService.clearData(this.sharedDataKey);
  }
}
