@if (leadStatus !== approvedStatus && showUpload) {
  <div class="ppl-mt20">
    @if(!readonly){
    <nz-form-item class="ppl-form-item pt-2">
      <nz-form-control class="ppl-form-control" nzSpan="8">
        <p style="font-size:0.929rem;">Please upload supporting documents</p>
        <ppl-upload (filesUploaded)="filesUploaded($event)" [fileList]="fileList" [flow]="entry" [leadId]="leadId"
          [docTypeList]="licences" [showFileList]="false"></ppl-upload>
      </nz-form-control>
    </nz-form-item>
    }
  </div>
  }
  @if(entry === 'provider' && filedInfo?.providerDocumentDetails.length > 0){
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="!readonly" [data]="fileListInfo"
  [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
  (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>

}
@else if (entry === 'referral' && filedInfo?.referralDocumentDetails.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="!readonly" [data]="fileListInfo"
  [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false" [loadPage]="true"
  (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (entry === 'participantEnrollment' && filedInfo?.participantEnrlDocumentDetails?.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [tableWidth]="tableWidth" [enableActions]="!!showButtons && !readonly"
  [data]="fileListInfo" [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (entry === 'providerEnrollment' && filedInfo?.providerEnrlDocumentDetails?.length > 0) {
<ppl-list-table [disableSort]="true" [disableSearch]="true" [tableWidth]="tableWidth" [enableActions]="!!showButtons && !readonly"
  [data]="fileListInfo" [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
  [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
</ppl-list-table>
}
@else if (entry === 'auth' && filedInfo?.consumerAuthDocumentDetailsList?.length > 0) {
  <div class="auth-table-width">
  <ppl-list-table [disableSort]="true" [disableSearch]="true" [tableWidth]="tableWidth" [enableActions]="!!showButtons && !readonly"
    [data]="fileListInfo" [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
    [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actionButtons">
  </ppl-list-table>
</div>
}
@else if (summary){
<nz-row>
  <p>No Files Uploaded</p>
</nz-row>
}
