import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LookupService } from '../../../services/lookup.service';
import { AddressComponent } from '../address/address.component';
import { HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgZorroModule } from '../../modules/ng-zorro/ng-zorro.module';
import { LookupValueItem } from '../../../interfaces/lookup.interface';
import { NzAriaLabelDirective } from '../../directives/nz-aria-label.directive';
import { SectionsService } from '../../../services/sections.service';

@Component({
  selector: 'ppl-lead-details-address',
  standalone: true,
  imports: [NgZorroModule, ReactiveFormsModule, FormsModule, CommonModule, NzAriaLabelDirective],
  templateUrl: './lead-details-address.component.html',
  styleUrl: './lead-details-address.component.css'
})
export class LeadDetailsAddressComponent extends AddressComponent {
  @Input() set statesList(data: LookupValueItem[]) {
    this.states = data;
  };

  @Input() override addressForm!: FormGroup;

  @Input() validationErrors = {};

  /*@Input() mode = 'edit';
  @Input() data: any;
  @Input() is_permanent: boolean = true; */
  states: LookupValueItem[] = [];

  /* selectedValue = null;
  listOfOptions: Array<{ value: string; text: string }> = [];
  nzFilterOptions = (): boolean => true;
  fullAddress: string = ''; */



  constructor(private lookupService: LookupService, httpClient: HttpClient, sectionsService: SectionsService) {
    super(httpClient, sectionsService);
  }

  getValue(code: string) {
    try {
      return this.lookupService.getLookupName(this.states, code);
    } catch {
      return code
    }

  }
}

