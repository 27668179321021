@if (step === 1) {
    <form nz-form [formGroup]="form">

        @if(isAttrAvailable('mco')){
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mco"
              class="ppl-form-label">{{getAttributes('mco').label}}
              @if(getAttributes('mco').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
            <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('mco').tooltip}}"
              nzTooltipPlacement="topRight">
              <nz-select formControlName="mco" class="ppl-select" nzId="mco" nzPlaceHolder="{{getAttributes('mco').tooltip}}"
                [ngClass]="{'error': validationErrors['mco'] }" nzShowSearch nzAllowClear>
                @for(opt of mcoEntries; track $index){
                <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
                }
              </nz-select>
              @if (validationErrors['mco']) {
              <span class="pl-1 error-message">{{validationErrors['mco']}}</span>
              }
            </nz-form-control>
          </nz-form-item>
          }
    
            @if(isAttrAvailable('lang')){
              <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="lang"
                  class="ppl-form-label">{{getAttributes('lang').label}}
                  @if(getAttributes('lang').mandatory === 'Y'){
                  <span>*</span>
                  }</nz-form-label>
                <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('lang').tooltip}}"
                  nzTooltipPlacement="topRight">
                  <nz-select nzMode="multiple" formControlName="lang" class="ppl-select" nzId="lang" nzPlaceHolder="{{getAttributes('lang').tooltip}}"
                    [ngClass]="{'error': validationErrors['lang'] }" nzShowSearch nzAllowClear>
                    @for(opt of languages; track $index){
                    <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueName"></nz-option>
                    }
                  </nz-select>
                  @if (validationErrors['lang']) {
                  <span class="pl-1 error-message">{{validationErrors['lang']}}</span>
                  }
                </nz-form-control>
              </nz-form-item>
              }
    
    
              @if(isAttrAvailable('affiliations')){
                <nz-form-item class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="affiliations"
                    class="ppl-form-label">{{getAttributes('affiliations').label}}
                    @if(getAttributes('affiliations').mandatory === 'Y'){
                    <span>*</span>
                    }</nz-form-label>
                  <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('affiliations').tooltip}}"
                    nzTooltipPlacement="topRight">
                    <nz-select nzMode="multiple" formControlName="affiliations" class="ppl-select" nzId="affiliations" nzPlaceHolder="{{getAttributes('affiliations').tooltip}}"
                      [ngClass]="{'error': validationErrors['affiliations'] }" nzShowSearch nzAllowClear>
                      @for(opt of affiliations; track $index){
                      <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                      }
                    </nz-select>
                    @if (validationErrors['affiliations']) {
                    <span class="pl-1 error-message">{{validationErrors['affiliations']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
    
                @if(isAttrAvailable('accessibility')){
                  <nz-form-item class="ppl-form-item">
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="accessibility"
                      class="ppl-form-label">{{getAttributes('accessibility').label}}
                      @if(getAttributes('accessibility').mandatory === 'Y'){
                      <span>*</span>
                      }</nz-form-label>
                    <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('accessibility').tooltip}}"
                      nzTooltipPlacement="topRight">
                      <nz-select nzMode="multiple" formControlName="accessibility" class="ppl-select" nzId="accessibility" nzPlaceHolder="{{getAttributes('accessibility').tooltip}}"
                        [ngClass]="{'error': validationErrors['accessibility'] }" nzShowSearch nzAllowClear>
                        @for(opt of communication; track $index){
                        <nz-option [nzLabel]="opt.label" [nzValue]="opt.value"></nz-option>
                        }
                      </nz-select>
                      @if (validationErrors['accessibility']) {
                      <span class="pl-1 error-message">{{validationErrors['accessibility']}}</span>
                      }
                    </nz-form-control>
                  </nz-form-item>
                  }
    
                  @if(isAttrAvailable('reg')){
                    <nz-form-item class="ppl-form-item">
                      <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="reg"
                        class="ppl-form-label">{{getAttributes('reg').label}}
                        @if(getAttributes('reg').mandatory === 'Y'){
                        <span>*</span>
                        }</nz-form-label>
                      <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('reg').tooltip}}"
                        nzTooltipPlacement="topRight">
                        <nz-select formControlName="reg" class="ppl-select" nzId="reg" nzPlaceHolder="{{getAttributes('reg').tooltip}}"
                          [ngClass]="{'error': validationErrors['reg'] }" nzShowSearch nzAllowClear>
                          @for(opt of registrationPreference; track $index){
                          <nz-option [nzLabel]="opt.label" [nzValue]="opt.label"></nz-option>
                          }
                        </nz-select>
                        @if (validationErrors['reg']) {
                        <span class="pl-1 error-message">{{validationErrors['reg']}}</span>
                        }
                      </nz-form-control>
                    </nz-form-item>
                    }
      </form>
} @else {
    <div class="position-relative"><span *ngIf="cdpapAlgoList.length">{{'These are top' }} {{ cdpapAlgoList.length }} {{'choices'}}</span>
        <nz-table #basicTable [nzData]="cdpapAlgoList" [nzShowPagination]="false" [nzScroll]="{ x: '800px' }">
          <thead>
            <tr>
              <th>CDPAP</th>
              <th *ngFor="let col of cols">{{col.label}}</th>
              <th nzRight></th>
            </tr>
          </thead>
          <tbody>
              <tr *ngFor="let data of cdpapAlgoList">
                <td>{{data.name}}</td>
                <td *ngFor="let row of cols" [nzBreakWord]="row.key === 'lang' ? true : false">

                  @if (data[row.key] || data[row.key] === 0) {
                    <div>
                      <span class="data-wrapper">{{ data[row.key] }}</span>
                    </div>
                  } @else {
                    <div>
                      <span class="data-wrapper" *ngFor="let v of data.matching[row.key]">{{ v }}<span class="checkmark-icon" nz-icon nzType="check-circle" nzTheme="twotone" nzTwotoneColor="#52c41a"></span></span>
                      <span class="data-wrapper" *ngFor="let v of data.notMatching?.[row.key]">{{ v }}<span nz-icon nzType="close-circle" nzTheme="outline" class="error-message checkmark-icon"></span></span>
                    </div>
                  }

                </td>
                <td nzRight (click)="selectCDPAP(data)">
                  <button nz-button nzType="primary" nzShape="round">
                    Select
                  </button>
                </td>
              </tr>
          </tbody>
        </nz-table>

        <div class="back-btn-wrapper">        
          <ppl-button label="Back" (click)="handleBack()"
            icon="fa fa-chevron-left"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'">
          </ppl-button>
        </div>

      </div>
}


