@if(checklistData && checklistData.length > 0) {
<div class="ppl-mt16 btn-group d-flex flex-row-reverse" style="display: flex; gap: 8px;">
    <ppl-button label="Expand All" icon="fa fa-expand" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="expandAll()"></ppl-button>
    <ppl-button label="Collapse All" icon="fa fa-compress" [customClass]="'alt-button'" [iconPosition]="'right'"
        (click)="hideAll()"></ppl-button>
    @if(isEditable) {
    <ppl-button label="Save Checklist" icon="fa fa-floppy-o" [customClass]="'primary-button'" [iconPosition]="'right'"
        (click)="saveChecklist()"></ppl-button>
    }
</div>
}
<div class="ppl-mt16 flex-col" style="gap: 8px;">
    <ppl-checklist-view [checklistData]="checklistData" (viewPDF)="handleCheckListClick($event)"
        (showNoRecordsMesssage)="prvdrAssociations && prvdrAssociations.length === 0"></ppl-checklist-view>
    @if(prvdrAssociations && prvdrAssociations.length > 0) {
    <nz-collapse [nzExpandIconPosition]="'end'"
        [ngClass]="'pending pending-ant-collapse'">
        <nz-collapse-panel [nzActive]="isPanelActive(prvdrAssociations)" [nzHeader]="'Consumer Associations'">
            <ppl-list-table [disableSort]="true" [disableSearch]="true" [enableActions]="true"
                [data]="prvdrAssociations" [disableSelection]="true" [cols]="cols" [gridTitle]="false"
                [pagination]="false" [loadPage]="true" (gridAction)="action($event)" [actionButtons]="actions">
            </ppl-list-table>
        </nz-collapse-panel></nz-collapse>
    }
</div>

<div class="filter-panel">
    <!-- <ppl-modal [showModal]="showModal" [title]="modalBody" [isOkText]="'Save'" (confirm)="handleCancel()"
        (cancel)="handleCancel()">
        <div class="ppl-mt16 btn-group d-flex flex-row-reverse mb-3" style="display: flex; gap: 8px;"  *ngIf="prvdrAssociations && providerAssociationChecklist && providerAssociationChecklist.length > 0">
            <ppl-button label="Save Checklist" icon="fa fa-floppy-o" [customClass]="'primary-button'"
                [iconPosition]="'right'" (click)="saveChecklistForParticipant()"></ppl-button>
        </div>
        <div class="" *ngIf="pdfConfig && pdfFile">
            <ppl-editpdf (fieldsUpdated)="fieldsUpdated($event)" [readonly]="true" [pdfConfig]="pdfConfig"
                [pdfFile]="pdfFile"></ppl-editpdf>
        </div>

        <div class="" *ngIf="prvdrAssociations && providerAssociationChecklist">
            <ppl-checklist-view [checklistData]="providerAssociationChecklist"
                (onViewPDF)="handleCheckListClick($event)"></ppl-checklist-view>
        </div>
    </ppl-modal> -->
    <ppl-popup-dialog [(isVisible)]="showAssociationModal" [title]="modalBody.associationModal" [isOkText]="'Save'"
        (confirm)="handleAssociationModalCancel()" (cancel)="handleAssociationModalCancel()" [size]="'xx-large'"
        [position]="'middle'" [showClose]="true" [showButtons]="false">
        <div class="ppl-mt16 btn-group d-flex flex-row-reverse mb-3" style="display: flex; gap: 8px;"
            *ngIf="prvdrAssociations && providerAssociationChecklist && providerAssociationChecklist.length > 0">
            @if(isEditable) {
            <ppl-button label="Save Checklist" icon="fa fa-floppy-o" [customClass]="'primary-button'"
                [iconPosition]="'right'" (click)="saveChecklistForParticipant()"></ppl-button>
            }
        </div>
        <div *ngIf="prvdrAssociations && providerAssociationChecklist">
            <ppl-checklist-view [checklistData]="providerAssociationChecklist" [isSelf]="false"
                (viewPDF)="handleCheckListClick($event)"></ppl-checklist-view>
        </div>
    </ppl-popup-dialog>
    <ppl-popup-dialog [(isVisible)]="showPdfViewerModal" [title]="modalBody.pdfViewerModal" [isOkText]="'Save'"
        (confirm)="handlePdfViewerModalCancel()" (cancel)="handlePdfViewerModalCancel()" [size]="'xx-large'"
        [position]="'middle'" [styleClass]="'pdf-viewer'" [showClose]="true" [showButtons]="false">
        <div *ngIf="pdfConfig && pdfFile">
            <ppl-editpdf (fieldsUpdated)="fieldsUpdated($event)" [readonly]="true" [pdfConfig]="pdfConfig"
                [pdfFile]="pdfFile" [pdfBlob]="pdfBlob" [signature]="signature" [signatureId]="signatureId"
                [isParticipantFlow]="false" [selectedProviderType]="selectedProviderType"
                [selectedParticipantId]="selectedParticipantId" [showSignature]="showSignature"
                [isSelf]="selectedProviderType == undefined"></ppl-editpdf>
        </div>
    </ppl-popup-dialog>
</div>