<div class="wiz-body">
    <div class="ppl-mt24">
        <div nz-row>
            @if(isAttrAvailable('hhaId')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("hhaId").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.hhaId}}</div>
                </div>
            </div>
            }

            @if(isAttrAvailable('hhaAuthId')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("hhaAuthId").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.hhaAuthId}}</div>
                </div>
            </div>
            }

            @if(isAttrAvailable('consumerPlanId')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("consumerPlanId").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.consumerPlanId}}</div>
                </div>
            </div>
            }

            @if(isAttrAvailable('isDeleted')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("isDeleted").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.isDeleted}}</div>
                </div>
            </div>
            }

            @if(isAttrAvailable('comments')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("comments").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.comments}}</div>
                </div>
            </div>
            }
            @if(isAttrAvailable('medicaidId')){
            <div nz-col nzSpan="12">
                <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("medicaidId").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{authData?.medicaidId}}</div>
                </div>
            </div>
            }
        </div>
    </div>
</div>