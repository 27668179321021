import { environment } from './../../../../environments/environment';
import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StepsComponent } from '../steps/steps.component';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { RouterModule } from '@angular/router';
import { SectionsService } from '../../../services/sections.service';
import { StepInfo } from '../../../interfaces/referralSections.interface';
import { ButtonComponent } from '../../../shared/components/button/button.component';
import { RoutingService } from '../../../shared/services/routing.service';
import {
  ENROLLMENT_TITLE_MAPPINGS,
  ENROLLMENT_TYPE_BACKEND_MAPPINGS,
  ENROLLMENT_TYPE_MAPPINGS,
  FLOW_MAPPINGS,
  STATIC_DATA,
} from '../../../shared/constants/static-data.constants';
import { ReferralDataService } from '../../../services/referral-data.service';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { ListService } from '../../../shared/services/list.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FlowDialogComponent } from '../../../shared/components/flow-dialog/flow-dialog.component';
import { NewEnrollmentComponent } from '../../enrollment/new-enrollment/new-enrollment.component';
import { ParticipantInformationComponent } from '../participant-information/participant-information.component';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { BaseComponent } from '../../../shared/utils/base.component';
import { EditMode } from '../../../interfaces/edit-mode.model';
import { CdpapFormComponent } from "../../../shared/components/cdpap-form/cdpap-form.component";
import { CdpapService } from '../../../services/cdpap.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { UserService } from '../../../services/user.service';
import { UserDetails } from '../../../interfaces/user.interface';

@Component({
  selector: 'ppl-new-referral',
  standalone: true,
  imports: [
    StepsComponent,
    NzGridModule,
    RouterModule,
    ButtonComponent,
    PopupDialogComponent,
    NzIconModule,
    NzSelectModule,
    FlowDialogComponent,
    NewEnrollmentComponent,
    ParticipantInformationComponent,
    CdpapFormComponent,
],
  providers: [ListService],
  templateUrl: './new-referral.component.html',
  styleUrl: './new-referral.component.css',
})
export class NewReferralComponent
  extends BaseComponent
  implements OnInit {
  destroyRef = inject(DestroyRef)
  currentStep = 0;
  steps: StepInfo[] = [];
  flow: any = '';
  id: any;
  show = false;
  showTrackingInfo = false;
  trackingNumber: any;
  providerPPLId: any;
  participantPPLId: any;
  isApproved = false;
  pplId: any;
  showAssociation = false;
  editAssociationMode: EditMode = EditMode.Add;
  currentFlow: any;
  selectedId: any;
  program: any;
  identifier: any[] = [];
  formReadonly = false;

  showCDPAPModal = false;
  isAlgoMode = true;
  cdpapModalSize: 'large' | 'x-large' = 'large';
  @ViewChild('wizContainer') wizContainer?: ElementRef;
  @ViewChild('cdPapFormRef') cdpapFormComponent?: CdpapFormComponent;
  userDetails: UserDetails | undefined;

  isInternalUser = true;
  selectedNavigation !: string;

  constructor(
    private router: RoutingService,
    private userService: UserService,
    private referralDataService: ReferralDataService,
    private listService: ListService,
    private notificationService: NotificationService,
    private sectionService: SectionsService,
    private enrollmentDataService: EnrollmentDataService,
    private leadDetailsService: LeadDetailsService,
    private cdpapService: CdpapService) {
    super();
    this.isInternalUser = !environment.isExternal;
  }

  ngOnInit(): void {
    this.userDetails = this.userService.getUserDetails();
    this.flow = this.sectionService.getFlow();
    this.selectedId = localStorage.getItem('selected_lead');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.enrollmentDataService.clearEnrollmentData();
    this.referralDataService.flowForAssociation.subscribe((data: any) => {
      this.showAssociation = data.val;
      this.currentFlow = this.getEnrollmentType(data.flow);
      this.formReadonly = this.referralDataService.getAssociationsEditable();
      if (data.editMode) {
        this.editAssociationMode = data.editMode;
      }
    });

    this.selectedNavigation = localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!
    this.cdpapService.triggerOpenPopup.asObservable().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.showCDPAPModal = true;
    });

    this.cdpapService.cdPapFormValueChangeSub$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => {
      this.onCloseCDPAPPopup();
    })
  }

  handleConfirmCDPAP() {
    const {mco, reg, lang, accessibility, affiliations} = this.cdpapFormComponent?.form.value
    const payload = {
      // ...(mco ? {mco: [mco]} : {}),
      // ...(reg ? {reg: [reg]} : {}),
      ...(lang ? {lang} : {}),
      // ...(accessibility ? {accessibility} : {}),
      // ...(affiliations ? {affiliations} : {})
    }

    this.cdpapFormComponent?.runErrorCheck();
    if (Object.keys(this.cdpapFormComponent?.validationErrors).length === 0) {
      this.cdpapService.getCDPAPAlgo(payload, this.program.program_code).subscribe((r) => {
          this.cdpapFormComponent!.cdpapAlgoList = r.responsedata;
          this.cdpapFormComponent?.nextStep();
          this.cdpapModalSize = 'x-large';
          this.isAlgoMode = !this.isAlgoMode
        });
      }
  }

  handleCDPAPStepBack() {
    this.cdpapModalSize = 'large';
    this.isAlgoMode = !this.isAlgoMode
  }

  onCloseCDPAPPopup() {
    this.cdpapFormComponent?.reset();
    this.showCDPAPModal = false;
    this.isAlgoMode = true;
    this.cdpapModalSize = 'large';
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   try {
  //     if (changes['sectionName'] && changes['sectionName'].currentValue) {
  //       this.getSectionData();
  //     }
  //   } catch (e) {
  //     console.log('Error in getting section data and updating latest info', e);
  //   }
  // }

  closePopup() {
    this.sectionService.setFlow('participantEnrollment');
    this.referralDataService.setFlowForAssociation({
      val: false,
      flow: 'participantEnrollment',
    });
    this.referralDataService.clearAssociationsFormData();
  }

  hideSaveBtn() {
    const assocType = localStorage.getItem('lead_submission_flow');
    if(assocType === 'provider' && this.editAssociationMode === 'search') {
      return true;
    }
    if(this.formReadonly) {
      return true;
    }
    return false;
  }

  handleSaveAssociations() {
    const filledData = this.referralDataService.getAssociationFormData();
    const participantId = localStorage.getItem('participantId');
    this.referralDataService.setAssociationFormClick('button clicked');

    if (filledData.infoData && (filledData.isInfoFormDirty && filledData.isInfoFormValid) && (filledData.isContactFormDirty && filledData.isContactFormValid) && (filledData.isServiceFormValid)) {
      const finalPayload = this.generateProviderAssociationPayload(filledData);

      this.enrollmentDataService
        .saveParticipantAssociations(finalPayload, true)
        .subscribe((data: any) => {
          console.log('SAVE ASSOC', data);
          this.closePopup();
          this.enrollmentDataService.setParticipantAssocChanged(true);
          this.refreshEnrlData(participantId);
        });
    }
  }

  generateProviderAssociationPayload(filledData: any) {
    console.log('Final data', filledData);
    const participantId = localStorage.getItem('participantId');

    return {
      participantId: participantId,
      programCode: this.program.program_code,
      pageType: "participantAssociation",
      infoData: this.generateInfoDataPayload(filledData),
      ...(filledData.contactDetailsData && {
        communicationData: this.generateCommunicationDataPayload(filledData),
      }),
      ...(filledData.contactDetailsData && {
        contactDetails: this.generateContactDataPayload(filledData),
      }),
      ...(filledData.serviceDetailsData && {
        serviceDetails: this.generateServiceDataPayload(filledData),
      }),
    };
  }

  generateInfoDataPayload(filledData: any) {
    const providerType = Object.keys(ENROLLMENT_TYPE_BACKEND_MAPPINGS).find(
      (keyVal) => ENROLLMENT_TYPE_BACKEND_MAPPINGS[keyVal] == this.currentFlow
    );
    const InfoData = {
      ...filledData.infoData,
      fname: filledData.infoData.fname.trim(),
      lname: filledData.infoData.lname.trim()
    }
    return {
      ...InfoData,
      providerType: providerType,
      providerId: 0,
      // applicationStatus: 'In Progress',
      finalApplicantDisposition: 'Enrollment',
      programCode: this.program.program_code,
      identifiers: [
        {
          type: 'SSN',
          value: filledData.infoData.SSN,
        },
        {
          type: 'CIN',
          value: filledData.infoData.CIN,
        },
        {
          type: 'EIN',
          value: filledData.infoData.EIN,
        },
        {
          type: 'NPI',
          value: filledData.infoData.NPI,
        },
      ],
      nprftDocumentationReceived: filledData?.commPreferencesData?.nprftDocumentationReceived,
      taxExempt: filledData?.commPreferencesData?.taxExempt
    };
  }

  generateContactDataPayload(filledData: any) {
    const {
      emergencyContactFirstName,
      emergencyContactLastName,
      emergencyContactPhone,
    } = filledData.commPreferencesData;

    return {
      providerId: 0,
      programCode: this.program.program_code,
      emergencyContactFirstName: emergencyContactFirstName?.trim(),
      emergencyContactLastName: emergencyContactLastName?.trim(),
      emergencyContactPhone,
      ...filledData.contactDetailsData
    };
  }

  generateCommunicationDataPayload(filledData: any) {
    const { permanentAddress, email, altPhone, mobile } = filledData.contactDetailsData;

    // Initialize the contactMethods array with email and mobile entries
    const contactMethods = [
        {
            providerId: 0,
            programCode: this.program.program_code,
            contactMethod: "email",
            value: email
        },
        {
            providerId: 0,
            programCode: this.program.program_code,
            contactMethod: "mobile",
            value: mobile
        }
    ];

    // Conditionally add altPhone if it is not null or an empty string
    if (altPhone) {
        contactMethods.push({
            providerId: 0,
            programCode: this.program.program_code,
            contactMethod: "altPhone",
            value: altPhone
        });
    }

    return {
            permanentAddress: {
                providerId: 0,
                programCode: this.program.program_code,
                ...permanentAddress
            },
            contactMethods: contactMethods,
            ...filledData.commPreferencesData
        }
  }

  generateServiceDataPayload(filledData: any) {
    const { profLicences, servicesRendered, ...restServiceData } = filledData.serviceDetailsData;
    const profLicencesMOdified = profLicences && profLicences.map((license: string) => ({ licenseType: license }));
    const servicesRenderedModified = servicesRendered && servicesRendered.map((service: string) => ({ serviceType: service }));
    return {
      providerId: 0,
      programCode: this.program.program_code,
      profLicences: profLicencesMOdified,
      servicesRendered: servicesRenderedModified,
      ...restServiceData
    };
  }

  refreshEnrlData(participantId: any) {
    this.leadDetailsService
      .fetchLeadDetailsApi(
        'participantEnrollment',
        this.program.program_code,
        participantId
      )
      .subscribe((data: any) => {
        console.log(data.responsedata);
        this.referralDataService.setReferralDetails(data.responsedata);
        console.log('setting data');
        setTimeout(() => {
          localStorage.setItem('participantId', data?.responsedata?.infoData.participantId)
          //this.router.navigate(['/newReferral']);
        }, 200);
      });
  }

  onClose() {
    this.show = false;
    this.referralDataService.clearReferrralData();
    this.goBack();
  }

  sendComm(type: string) {
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    const message = `Thank you for your submission! Your tracking number is ${this.trackingNumber}`;
    const payload = {
      communicationType: type.toUpperCase(),
      program: program.program_code,
      leadIds: [this.id?.toString()],
      type: 'referral',
      message: message,
    };
    console.log('payload', payload);
    this.listService
      .sendNotification('confirmation', payload)
      .subscribe((data: any) => {
        if (data.status == 200) {
          this.notificationService.alert(
            'success',
            'Success',
            `${type.toUpperCase()} sent successfully.`
          );
        }
      });
  }

  showConfirmation(data: any) {
    this.show = true;
    this.trackingNumber = data.trackingNumber;
    this.id = data.id;
    this.isApproved = data.isApproved;
    this.pplId = data.pplId;
  }

  showTrackingInformation(data: any) {
    this.showTrackingInfo = true;
    this.trackingNumber = data.trackingNumber != "null" ? data.trackingNumber : "";
    this.providerPPLId = data.providerPPLId != "null" ? data.providerPPLId : "";
    this.participantPPLId = data.participantPPLId != "null" ? data.participantPPLId : "";
  }

  stepSelected(step: StepInfo) {
    // if (this.flow == 'newEnrollment') {
    //   this.router.navigate(`/home/newEnrollment/${step.step_id}`);
    // } else {
    this.router.navigate(`/newReferral/${step.step_id}`);
    // }

    if (this.wizContainer) {
      this.wizContainer.nativeElement.scrollTop = 0;
    }
  }

  getTitle(): string {
    if (this.flow === 'providerEnrollment') {
      const enrollmentTypes = this.sectionService.getEnrollmentType();
      if (enrollmentTypes.length > 1) {
        return 'Associations';
      }

      return FLOW_MAPPINGS[enrollmentTypes[0]];
    }
    const type = this.flow;
    return FLOW_MAPPINGS[type] || type;
  }

  getEnrollmentPageTitle(flow: string): string {
    return ENROLLMENT_TITLE_MAPPINGS[flow] || flow;
  }

  getEnrollmentType(flow: string): string {
    return ENROLLMENT_TYPE_MAPPINGS[flow] || flow;
  }

  returnClicked(): void {
    this.referralDataService.clearReferrralData();
    this.goBack();
  }

  goBack() {

    if(!this.isInternalUser && this.userDetails?.usertype_lkp?.toLowerCase() != 'fi'){
      this.router.navigate('/');
      return;
    }
    
    const path = `/home/${this.selectedNavigation}`
   
    this.router.navigate(path);
  }
}
