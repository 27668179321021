import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { BaseComponent } from '../../../../shared/utils/base.component';
import {
  STATIC_DATA,
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { AuthorizeFieldDirective } from '../../../../shared/authorization/authorizeField.directive';
import { AuthorizeFieldViewDirective } from '../../../../shared/authorization/authorizeFieldView.directive';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { SectionsService } from '../../../../services/sections.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { performValidation } from '../../../../shared/utils/validation.util';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ListService } from '../../../../shared/services/list.service';
import { PopupDialogComponent } from '../../../../shared/components/popup-dialog/popup-dialog.component';

enum Mode {
  Read = 'read',
  Edit = 'edit',
  Add = 'add',
}

@Component({
  selector: 'ppl-prospective-info',
  standalone: true,
  imports: [
    NgZorroModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AuthorizeDirective,
    AuthorizeFieldDirective,
    AuthorizeFieldViewDirective,
    ListTableComponent,
    ButtonComponent,
    PopupDialogComponent,
  ],
  templateUrl: './prospective-info.component.html',
  styleUrl: './prospective-info.component.css',
})
export class ProspectiveInfoComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  prospectiveParticipant!: FormGroup;
  filledInfo: any = {};
  steps: any = {};
  pageId  = 'page';
  tabId  = 'page';
  mode: Mode = Mode.Read;
  participantList: any[] = [];
  cols: any;
  actionButtons: TableRowActionsModel = new TableRowActions();
  selectedProspectiveParticipant: any;
  participantData: any;
  approvedStatus: string = STATIC_DATA.APPROVED;
  leadStatus = '';
  showSearchResults = false;
  searchResultsList: any[] = [];
  programs: any[] = [];
  showSearchError = false;
  showDialog = false;
  selectedProviderType = '';
  providerTypes: any[] = [];
  selectedParticipantDetails: any = {};
  programName!: string;
  isSaveDisabled = true;
  selectProgramErr = false;

  @Input() set sectionData(data: any) {
    this.steps = data;
  }

  @Input() selectedLead = 0;
  @Input() entry = 'base';
  @Input() program: any = {};
  @Input() gridData: any;
  @Output() leadUpdated = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private leadDetailsService: LeadDetailsService,
    private sectionsService: SectionsService,
    private logger: LoggerService,
    private listService: ListService
  ) {
    super();
  }

  ngOnInit(): void {
    const selectedProgram: any = JSON.parse(
      localStorage.getItem('selected_program')!
    );
    this.programName = selectedProgram.program_code;
    this.prospectiveParticipant = this.fb.group({
      firstName: [''],
      lastName: [''],
      participantPPLId: [''],
      programCode: [''],
      providerTypeLpk: [''],
    });

    this.getFromData();
    this.leadDetailsService.getLeadStatus().subscribe((status: string) => {
      this.leadStatus = status;
      console.log('Lead Status', status);
      if (this.leadStatus === this.approvedStatus) {
        this.actionButtons.edit = false;
        this.actionButtons.delete = false;
      } else {
        this.actionButtons.edit = false;
        this.actionButtons.verify = true;
        this.actionButtons.delete = true;
      }
    });

    this.listService.getPrograms().subscribe((data: any) => {
      if (data.responsedata) {
        this.programs = data.responsedata.filter(
          (program: any) => program?.program_code !== STATIC_DATA.LEAD_PROGRAM
        );
      }
    });
    this.listService
      .getProgramTypes(this.programName)
      .subscribe((data: any) => {
        if (data.responsedata) {
          this.providerTypes = data.responsedata.providerTypes;
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      if (changes['sectionData'] && changes['sectionData'].currentValue) {
        this.secData = this.steps[STATIC_DATA.PROSPECTIVE_PARTICIPANTS_DATA];
      }
    } catch (e) {
      this.logger.info(
        'Error in getting section data and updating latest info',
        e
      );
    }

    try {
      if (changes['gridData'] && changes['gridData'].currentValue) {
        this.participantList = [];
        this.participantData =
          changes['gridData'].currentValue.length > 0
            ? changes['gridData'].currentValue[0]
            : [];
        if (this.participantData.length > 0) {
          this.participantList =
            changes['gridData'].currentValue[0]?.prvdrPrtcpntData;
        }
        this.participantList =
          changes['gridData'].currentValue[0]?.prvdrPrtcpntData;
        //this.logger.info( 'participantList',  changes['gridData'].currentValue[0].prvdrPrtcpntData);
      }
    } catch (e) {
      this.logger.info(
        'Error in getting section data and updating latest info',
        e
      );
    }
  }

  search() {
    console.log('serach clicked');
    this.actionButtons.add = true;
    this.actionButtons.edit = false;
    this.actionButtons.delete = false;
    this.actionButtons.verify = false;
    const formData = this.prospectiveParticipant.value;
    formData.firstName = formData.firstName.trim();
    formData.lastName = formData.lastName.trim();

    // if(this.mode === 'edit') {
    //   if(!formData.programCode && !formData.firstName && !formData.lastName && !formData.participantPPLId) {
    //     this.showSearchError = true;

    //   } else {
    //     this.showSearchError = false;
    //     this.leadDetailsService.searchProspectiveParticipantDetails(formData).subscribe((data: any) => {
    //       console.log('SEARCH DATA', data);
    //       this.showSearchResults = true;
    //       if(data?.responsedata?.prspctvPrtcpntDetails) {
    //         this.searchResultsList = data?.responsedata?.prspctvPrtcpntDetails;
    //       } else {
    //         this.searchResultsList = [];
    //       }
    //     })
    //   }
    // }

    if (!formData.programCode) {
      this.validationErrors.programCode = 'Please select the Program Name';
    }

    if (formData.programCode) {
      this.validationErrors = {};
      if (
        !formData.firstName &&
        !formData.lastName &&
        !formData.participantPPLId
      ) {
        console.log('enter at least one search criteria');
        this.showSearchError = true;
      } else {
        console.log('Form data', formData);
        this.showSearchError = false;
        this.leadDetailsService
          .searchProspectiveParticipantDetails(formData, true)
          .subscribe((data: any) => {
            console.log('SEARCH DATA', data);
            this.showSearchResults = true;
            if (data?.responsedata?.prspctvPrtcpntDetails) {
              this.searchResultsList =
                data?.responsedata?.prspctvPrtcpntDetails;
            } else {
              this.searchResultsList = [];
            }
          });
      }
    }
  }

  getProviderTypesList(programCode: string) {
    this.listService.getProgramTypes(programCode).subscribe((data: any) => {
      if (data.responsedata) {
        this.providerTypes = data.responsedata.providerTypes;
      }
    });
  }

  async getFromData() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_CONTACT_LIST_HEADERS,
          this.entry
        );
        this.cols = tempCols.prospective_participants.columns;
      }
    } catch (e) {
      this.log.debug('Error in getting column data', e);
    }
  }

  add(): void {
    this.mode = Mode.Add;
  }

  action(event: any): void {
    this.logger.debug('Action', event);
    if (event.action === 'edit') {
      this.mode = Mode.Edit;
      this.selectedProspectiveParticipant = event.data;
      this.prospectiveParticipant.patchValue(event.data);
    }
    if (event.action === 'delete') {
      this.mode = Mode.Read;
      /* this.prospectiveParticipant.patchValue(event.data); */
      this.deleteProspectiveParticipant(event?.data?.leadProviderParticipantId);
    }
    if (event.action === 'verify') {
      console.log('Verify clicked!', event);
      this.mode = Mode.Edit;
      this.selectedProspectiveParticipant = event.data;
      this.prospectiveParticipant.patchValue(event.data);
    }
    if (event.action === 'add') {
      console.log('Add clicked!', event);
      // if(this.mode === 'edit') {
      //   this.prospectiveParticipant.patchValue(event.data);
      //   this.isSaveDisabled = false;

      // } else {
      this.showDialog = true;
      this.selectedParticipantDetails = event.data;
      this.getProviderTypesList(this.selectedParticipantDetails.programCode);
      // }
    }
  }

  cancelEdit() {
    this.mode = Mode.Read;
    this.prospectiveParticipant.reset();
    this.showSearchResults = false;
    this.actionButtons.add = false;
    this.actionButtons.edit = false;
    this.actionButtons.delete = true;
    this.actionButtons.verify = true;
    this.showSearchError = false;
    this.validationErrors = {};
  }

  handleOk() {
    console.log(this.prospectiveParticipant.value);
    console.log('program type', this.selectedProviderType);
    if (this.selectedProviderType) {
      this.selectProgramErr = false;
      const payload = this.preparePayload(this.selectedParticipantDetails);
      this.leadDetailsService
        .addUpdateProspectiveParticipantDetails(payload)
        .subscribe(() => {
          this.logger.info('Consumer saved successfully');
          this.leadUpdated.emit(true);
          this.handleCancel();
          if (this.mode === 'edit' || this.mode === 'add') {
            this.cancelEdit();
          }
        });
    } else {
      this.selectProgramErr = true;
    }
  }

  handleCancel() {
    this.showDialog = false;
    this.selectedProviderType = '';
  }

  save() {
    const formData = this.prospectiveParticipant.value;

    try {
      this.validationErrors = performValidation(formData, this.secData, 'Save');
      console.log(this.validationErrors);
    } catch (e) {
      this.logger.debug('Error in validations', e);
    }

    if (Object.keys(this.validationErrors).length === 0) {
      const payload = this.preparePayload(formData);
      this.leadDetailsService
        .addUpdateProspectiveParticipantDetails(payload)
        .subscribe(() => {
          this.logger.info('Lead LeadServicesRendered saved successfully');
          this.leadUpdated.emit(true);
          this.cancelEdit();
        });
    }
  }

  preparePayload(source: any) {
    const leadDetailIdentifierId =
      this.mode === 'edit'
        ? this.selectedProspectiveParticipant.leadProviderParticipantId
        : null;

    if (this.mode === 'add') {
      source.isVerified = true;
      source.providerTypeLpk = this.selectedProviderType;
    } else if (this.mode == 'edit') {
      source = {
        ...source,
        providerTypeLpk: this.selectedProviderType,
        isVerified: this.selectedProspectiveParticipant.isVerified,
      };
    }
    return {
      id: this.selectedLead,
      providerParticipantId: leadDetailIdentifierId,
      prospectiveParticipant: source,
    };
  }

  deleteProspectiveParticipant(leadProviderParticipantId: number) {
    this.leadDetailsService
      .deleteProspectiveParticipantDetails(
        leadProviderParticipantId,
        this.program.program_code
      )
      .subscribe(() => {
        this.logger.info('Lead prospective participant deleted successfully');
        this.leadUpdated.emit(true);
        this.cancelEdit();
      });
  }
}
