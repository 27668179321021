<div class="ppl-container-body">
  <div class="ppl-container">
    <div class="ppl-content-container" style="padding: 8px; max-height: calc(100vh - 140px); box-sizing: border-box;">
      <div class="line1">
        <div class="action">
          <ppl-button [label]="'Back'" icon="fa fa-chevron-left" (click)="goBack()"
            [customClass]="'primary-button semi-rounded'" [iconPosition]="'left'"></ppl-button>
        </div>
      </div>
      <div class="line2">
        Details for ID: {{caseManagerId}}
      </div>
      <div style="width: 100%">
        <ppl-accordion [title]="'Basic Information'" [isOpen]="true">
          <div class="wiz-body">
            <div class="ppl-mt24">
              <div class="custom-row">
                @if(mode==="edit"){
                <ppl-button label="Cancel" icon="fa fa-ban" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onCancel()"></ppl-button>
                <ppl-button label="Save" icon="fa fa-floppy-o" [customClass]="'alt-button'" [iconPosition]="'left'"
                  (click)="onUpdate()"></ppl-button>
                }
                @if(mode==="read"){
                <ppl-button label="Edit" icon="fa fa-pencil" [customClass]="'ghost-button'" [iconPosition]="'left'"
                  (click)="onEdit()"></ppl-button>
                }
              </div>
              @if(mode==="edit"){
              <form nz-form [formGroup]="caseManagerForm" class="ppl-p0">
                @if(isAttrAvailable('firstName')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="firstName"
                    class="ppl-form-label">{{getAttributes('firstName').label}}
                    @if(getAttributes('firstName').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('firstName')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <input nz-input formControlName="firstName" id="firstName"
                        placeholder="{{getAttributes('firstName').placeholder}}"
                        [ngClass]="{'error': validationErrors['firstName'] }" />
                    </div>
                    @if (validationErrors['firstName']) {
                    <span class="pl-1 error-message">{{validationErrors['firstName']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('lastName')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="lastName"
                    class="ppl-form-label">{{getAttributes('lastName').label}}
                    @if(getAttributes('lastName').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <div nz-tooltip [nzTooltipTitle]="getAttributes('lastName')?.tooltip || ''"
                      nzTooltipPlacement="topRight">
                      <input nz-input formControlName="lastName" id="lastName"
                        placeholder="{{getAttributes('lastName').placeholder}}"
                        [ngClass]="{'error': validationErrors['lastName'] }" />
                    </div>
                    @if (validationErrors['lastName']) {
                    <span class="pl-1 error-message">{{validationErrors['lastName']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('email') && isAttrAvailable('mobile')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="email" class="ppl-form-label">
                    Please enter Email Address or Mobile Number
                    @if(getAttributes('email').mandatory === 'Y' && getAttributes('mobile').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}"
                      nzTooltipPlacement="topRight" formControlName="email" id="email"
                      [ngClass]="{'error': validationErrors['email'] }"
                      placeholder="{{getAttributes('email').placeholder}}" />
                    @if (validationErrors['email']) {
                    <span class="pl-1 error-message">{{validationErrors['email']}}</span>
                    }
                    <div style="margin-top: 24px;">
                      <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}"
                        nzTooltipPlacement="topRight" nz-input id="mobile" formControlName="mobile"
                        [ngClass]="{'error': validationErrors['mobile'] }"
                        placeholder="{{getAttributes('mobile').placeholder}}" />
                      @if (validationErrors['mobile']) {
                      <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
                      }
                    </div>
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('email') && !isAttrAvailable('mobile')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="email"
                    class="ppl-form-label">{{getAttributes('email').label}}
                    @if(getAttributes('email').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('email').tooltip}}"
                      nzTooltipPlacement="topRight" formControlName="email" id="email"
                      [ngClass]="{'error': validationErrors['email'] }"
                      placeholder="{{getAttributes('email').placeholder}}" />
                    @if (validationErrors['email']) {
                    <span class="pl-1 error-message">{{validationErrors['email']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('mobile') && !isAttrAvailable('email')){
                <nz-form-item nz-col class="ppl-form-item">
                  <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mobile"
                    class="ppl-form-label">{{getAttributes('mobile').label}}
                    @if(getAttributes('mobile').mandatory === 'Y'){
                    <span>*</span>
                    }
                  </nz-form-label>
                  <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                    <input nz-tooltip nzTooltipTitle="{{getAttributes('mobile').tooltip}}" nzTooltipPlacement="topRight"
                      nz-input id="mobile" formControlName="mobile" [ngClass]="{'error': validationErrors['mobile'] }"
                      placeholder="{{getAttributes('mobile').placeholder}}" />
                    @if (validationErrors['mobile']) {
                    <span class="pl-1 error-message">{{validationErrors['mobile']}}</span>
                    }
                  </nz-form-control>
                </nz-form-item>
                }
                @if(isAttrAvailable('orgName') && isAttrAvailable('ldssCode')){
                  <nz-form-item nz-col class="ppl-form-item">
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="orgName" class="ppl-form-label">
                      Please select MCO or LDSS Office
                      @if(getAttributes('orgName').mandatory === 'Y' && getAttributes('ldssCode').mandatory === 'Y'){
                      <span>*</span>
                      }
                    </nz-form-label>
                    <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control" nz-tooltip
                    nzTooltipTitle="{{getAttributes('orgName').tooltip}}" nzTooltipPlacement="topRight">
                    <nz-select formControlName="orgName" class="ppl-select" nzId="orgName"
                      nzPlaceHolder="{{getAttributes('orgName').tooltip}}"
                      [ngClass]="{'error': validationErrors['orgName'] }" nzShowSearch nzAllowClear>
                      @for(opt of mcoEntries; track $index){
                      <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
                      }
                    </nz-select>
                    @if (validationErrors['orgName']) {
                    <span class="pl-1 error-message">{{validationErrors['orgName']}}</span>
                    }
                      <div style="margin-top: 24px;">
                        <nz-select formControlName="ldssCode" class="ppl-select" nzId="ldssCode" nzPlaceHolder="Please select LDSS Office"
                        [ngClass]="{'error': validationErrors['ldssCode'] }" nzShowSearch nzAllowClear>
                        @for(opt of ldssOfficeValues; track $index){
                        <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                        }
                      </nz-select>
                      @if (validationErrors['ldssCode']) {
                      <span class="pl-1 error-message">{{validationErrors['ldssCode']}}</span>
                      }
                      </div>
                    </nz-form-control>
                  </nz-form-item>
                }
                @if(isAttrAvailable('active')){
                  <nz-form-item nz-col class="ppl-form-item">
                    <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="active"
                              class="ppl-form-label">{{getAttributes('active').label}}
                              @if(getAttributes('active').mandatory === 'Y'){
                              <span>*</span>
                              }
                          </nz-form-label>
                          <nz-form-control [nzSm]="12" [nzXs]="24" class="ppl-form-control">
                              <div nz-tooltip [nzTooltipTitle]="getAttributes('active')?.tooltip || ''"
                                  nzTooltipPlacement="topRight">
                                  <nz-select formControlName="active" nzMode='default' class="ppl-select" id="active"
                                      nzPlaceHolder="" nzShowSearch nzAllowClear
                                      [ngClass]="{'error': validationErrors['active'] }">
                                      @for(opt of radioOptions; track $index){
                                          <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                                          }
                                  </nz-select>
                              </div>
                              @if (validationErrors['active']) {
                              <span class="pl-1 error-message">{{validationErrors['active']}}</span>
                              }
                          </nz-form-control>
                  </nz-form-item>
                  }
              </form>
              }
              @else if (mode==="read") {
              <div nz-row>
                @if(isAttrAvailable('firstName')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("firstName").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.firstName }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('lastName')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("lastName").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.lastName }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('email')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("email").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.email }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('mobile')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("mobile").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.mobile }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('orgName')){
                <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{ getAttributes("orgName").label }}
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.orgName }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('ldssCode')){
                  <div nz-col nzSpan="12">
                  <div nz-row>
                    <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                      {{getAttributes('ldssCode').label}} 
                    </div>
                    <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.ldssOffice }}</div>
                  </div>
                </div>
                }
                @if(isAttrAvailable('active')){
                  <div nz-col nzSpan="12">
                    <div nz-row>
                      <div nz-col nzSpan="8" ngClass="font-weight-600 pt-2 pb-2">
                        {{ getAttributes("active").label }}
                      </div>
                      <div nz-col nzSpan="16" ngClass="pt-2 pb-2">{{ savedData?.active }}</div>
                    </div>
                  </div>
                  }
              </div>
              }
            </div>
          </div>
        </ppl-accordion>
      </div>
    </div>
  </div>
</div>