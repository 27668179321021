@if(mode==="edit"){
@if(source !== 'enrollment'){
<div class="ppl-mt8">
  @for(header of extractValues('header'); track $index){
  <h2>{{header}}</h2>
  }
  @for(text of extractValues('text'); track $index){
  <p class="ppl-description">{{text}}</p>
  }
</div>
}
<div [ngClass]="{'ppl-mt24': source !== 'enrollment'}">
  <nz-card nz-col [nzSpan]="source !== 'enrollment' ? 20 : 24" class="ppl-step-main" [nzBordered]="false">
    <form nz-form [formGroup]="infoForm" class="ppl-p0">
      @if(isAttrAvailable('businessName')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="businessName"
          class="ppl-form-label">{{getAttributes('businessName').label}}
          @if(getAttributes('businessName').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('businessName')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="businessName" id="businessName"
              placeholder="{{getAttributes('businessName').placeholder}}"
              [ngClass]="{'error': validationErrors['businessName'] }" />
          </div>
          @if (validationErrors['businessName']) {
          <span class="pl-1 error-message">{{validationErrors['businessName']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('fname')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="fname" class="ppl-form-label">{{getAttributes('fname').label}}
          @if(getAttributes('fname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('fname')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="fname" id="fname" placeholder="{{getAttributes('fname').placeholder}}"
              [ngClass]="{'error': validationErrors['fname'] }" />
          </div>
          @if (validationErrors['fname']) {
          <span class="pl-1 error-message">{{validationErrors['fname']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('mname')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mname" class="ppl-form-label">{{getAttributes('mname').label}}
          @if(getAttributes('mname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('mname')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="mname" id="mname" placeholder="{{getAttributes('mname').placeholder}}"
              [ngClass]="{'error': validationErrors['mname'] }" />
          </div>
          @if (validationErrors['mname']) {
          <span class="pl-1 error-message">{{validationErrors['mname']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('lname')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="lname" class="ppl-form-label">{{getAttributes('lname').label}}
          @if(getAttributes('lname').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('lname')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="lname" id="lname" placeholder="{{getAttributes('lname').placeholder}}"
              [ngClass]="{'error': validationErrors['lname'] }" />
          </div>
          @if (validationErrors['lname']) {
          <span class="pl-1 error-message">{{validationErrors['lname']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      @if(isAttrAvailable('maiden_name')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="maiden_name"
          class="ppl-form-label">{{getAttributes('maiden_name').label}}
          @if(getAttributes('maiden_name').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('maiden_name')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="maiden_name" id="maiden_name"
              placeholder="{{getAttributes('maiden_name').placeholder}}"
              [ngClass]="{'error': validationErrors['maiden_name'] }" />
          </div>
          @if (validationErrors['maiden_name']) {
          <span class="pl-1 error-message">{{validationErrors['maiden_name']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('dob')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="dob" class="ppl-form-label">{{getAttributes('dob').label}}
          @if(getAttributes('dob').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('dob')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-date-picker formControlName="dob" id="dob" [nzFormat]="'MM/dd/yyyy'"
              [nzPlaceHolder]="getAttributes('dob').placeholder" [nzDisabledDate]="disabledDate"
              [ngClass]="{'error': validationErrors['dob'] }"></nz-date-picker>
          </div>
          @if (validationErrors['dob']) {
          <span class="pl-1 error-message">{{validationErrors['dob']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      @if(isAttrAvailable('gender')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="gender" class="ppl-form-label">{{getAttributes('gender').label}}
          @if(getAttributes('gender').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('gender')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-radio-group formControlName="gender" class="ppl-radio-group" id="gender"
              [ngClass]="{'error': validationErrors['gender'] }">
              @for(opt of gender; track $index){
              <label nz-radio-button [nzValue]="opt.lkpValueCode">{{opt.lkpValueName}}</label>
              }
            </nz-radio-group>
          </div>
          @if (validationErrors['gender']) {
          <span class="pl-1 error-message">{{validationErrors['gender']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      @if(isAttrAvailable('isProvidingServices')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="gender"
          class="ppl-form-label">{{getAttributes('isProvidingServices').label}}
          @if(getAttributes('isProvidingServices').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('isProvidingServices')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-radio-group formControlName="isProvidingServices" class="ppl-radio-group" id="isProvidingServices"
              [ngClass]="{'error': validationErrors['isProvidingServices'] }">
              @for(opt of radioOptions; track $index){
              <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
              }
            </nz-radio-group>
          </div>
          @if (validationErrors['isProvidingServices']) {
          <span class="pl-1 error-message">{{validationErrors['isProvidingServices']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      @if(isAttrAvailable('SSN')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="SSN" class="ppl-form-label">{{getAttributes('SSN').label}}
          @if(getAttributes('SSN').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('SSN')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-input-group [nzSuffix]="suffixTemplate" [ngClass]="{'error': validationErrors['SSN'] }">
              <input nz-input [type]="ssnVisible ? 'text' : 'password'" formControlName="SSN" id="SSN"
                placeholder="{{getAttributes('SSN').placeholder}}" (input)="limitDigits($event)"/>
            </nz-input-group>
          </div>
          <ng-template #suffixTemplate>
            <!-- eslint-disable-next-line @angular-eslint/template/elements-content -->
            <button 
              class="ssn-visible" 
              type="button" 
              [attr.aria-label]="ssnVisible ? 'Hide SSN' : 'Show SSN'" 
              nz-icon 
              [nzType]="ssnVisible ? 'eye' : 'eye-invisible'" 
              (click)="ssnVisible = !ssnVisible">
          </button>
          </ng-template>
          @if (validationErrors['SSN']) {
          <span class="pl-1 error-message">{{validationErrors['SSN']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      @if(ssnWith9 && flow==="employer"){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" class="ppl-form-label">
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <p style="font-size:0.929rem;">Please upload ITIN documents</p>
          <ppl-upload></ppl-upload>
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('CIN')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="CIN" class="ppl-form-label">{{getAttributes('CIN').label}}
          @if(getAttributes('CIN').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('CIN')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input id="CIN" formControlName="CIN" placeholder="{{getAttributes('CIN').placeholder}}"
              [ngClass]="{'error': validationErrors['CIN'] }" />
          </div>
          @if (validationErrors['CIN']) {
          <span class="pl-1 error-message">{{validationErrors['CIN']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}

      @if(isAttrAvailable('EIN')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="EIN" class="ppl-form-label">{{getAttributes('EIN').label}}
          @if(getAttributes('EIN').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('EIN')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="EIN" id="EIN" placeholder="{{getAttributes('EIN').placeholder}}"
              [ngClass]="{'error': validationErrors['EIN'] }" />
          </div>
          @if (validationErrors['EIN']) {
          <span class="pl-1 error-message">{{validationErrors['EIN']}}</span>
          }
          <div class="notes">
            <strong>Notes:</strong>
            <p>
              1. If EIN is lost/misplaced, then obtain your existing EIN using "<a>How to ask the IRS
                for a
                147c letter</a>".
            </p>
            <p>
              2. If EIN doesn't exist, PPL will obtain an EIN on your behalf after completion and
              submission
              of registration paperwork.
            </p>
          </div>
        </nz-form-control>
      </nz-form-item>
      }


      @if(isAttrAvailable('waiver')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="waiver" class="ppl-form-label">{{getAttributes('waiver').label}}
          @if(getAttributes('waiver').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('waiver').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="waiver" class="ppl-select" nzId="waiver" nzPlaceHolder="Select Service Administration"
            [ngClass]="{'error': validationErrors['waiver'] }" nzShowSearch nzAllowClear>
            @for(opt of waiver; track $index){
            <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['waiver']) {
          <span class="pl-1 error-message">{{validationErrors['waiver']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('ldssOffice')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="ldssOffice" class="ppl-form-label">{{getAttributes('ldssOffice').label}}
            @if(getAttributes('ldssOffice').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('ldssOffice').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="ldssOffice" class="ppl-select" nzId="ldssOffice" nzPlaceHolder="Select LDSS Office"
              [ngClass]="{'error': validationErrors['ldssOffice'] }" nzShowSearch nzAllowClear>
              @for(opt of ldssOfficeValues; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['ldssOffice']) {
            <span class="pl-1 error-message">{{validationErrors['ldssOffice']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
        }
      @if(isAttrAvailable('mcoOrgName')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="mcoOrgName"
          class="ppl-form-label">{{getAttributes('mcoOrgName').label}}
          @if(getAttributes('mcoOrgName').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('mcoOrgName').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="mcoOrgName" class="ppl-select" nzId="mcoOrgName" nzPlaceHolder="{{getAttributes('mcoOrgName').tooltip}}"
            [ngClass]="{'error': validationErrors['mcoOrgName'] }" nzShowSearch nzAllowClear>
            @for(opt of mcoEntries; track $index){
            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
            }
          </nz-select>
          @if (validationErrors['mcoOrgName']) {
          <span class="pl-1 error-message">{{validationErrors['mcoOrgName']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('currentFI')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="currentFI" class="ppl-form-label">{{getAttributes('currentFI').label}}
            @if(getAttributes('currentFI').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('currentFI').tooltip}}"
            nzTooltipPlacement="topRight">
            <nz-select formControlName="currentFI" class="ppl-select" nzId="currentFI" nzPlaceHolder="Select Current FI"
              [ngClass]="{'error': validationErrors['currentFI'] }" nzShowSearch nzAllowClear>
              @for(opt of currentFIValues; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['currentFI']) {
            <span class="pl-1 error-message">{{validationErrors['currentFI']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
        }
      @if(isAttrAvailable('fiOrgName')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="fiOrgName"
          class="ppl-form-label">{{getAttributes('fiOrgName').label}}
          @if(getAttributes('fiOrgName').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('fiOrgName').tooltip}}"
          nzTooltipPlacement="topRight">
          <nz-select formControlName="fiOrgName" class="ppl-select" nzId="fiOrgName" nzPlaceHolder="{{getAttributes('fiOrgName').tooltip}}"
            [ngClass]="{'error': validationErrors['fiOrgName'] }" nzShowSearch nzAllowClear>
            @for(opt of fiEntries; track $index){
            <nz-option [nzLabel]="opt.orgName" [nzValue]="opt.orgName"></nz-option>
            }
          </nz-select>

          @if (validationErrors['fiOrgName']) {
          <span class="pl-1 error-message">{{validationErrors['fiOrgName']}}</span>
          }
        </nz-form-control>
          <button 
            type="button" 
            class="cdpap-button"
            [attr.aria-label]="'CDPAP'" 
            nz-icon 
            [nzType]="'setting'" 
            (click)="openCDPAPPopup()">
        </button>
      </nz-form-item>
      }
      @if(isAttrAvailable('eligiblityStartDate')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="eligiblityStartDate" class="ppl-form-label">{{getAttributes('eligiblityStartDate').label}}
            @if(getAttributes('eligiblityStartDate').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('eligiblityStartDate')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-date-picker [nzDisabledDate]="disabledStartDate" formControlName="eligiblityStartDate" id="eligiblityStartDate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('eligiblityStartDate').placeholder"
                [ngClass]="{'error': validationErrors['eligiblityStartDate'] }"></nz-date-picker>
            </div>
            @if (validationErrors['eligiblityStartDate']) {
            <span class="pl-1 error-message">{{validationErrors['eligiblityStartDate']}}</span>
            }
          </nz-form-control>
        </nz-form-item>}
      @if(isAttrAvailable('eligiblityEndDate')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="eligiblityEndDate" class="ppl-form-label">{{getAttributes('eligiblityEndDate').label}}
            @if(getAttributes('eligiblityEndDate').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <div nz-tooltip [nzTooltipTitle]="getAttributes('eligiblityEndDate')?.tooltip || ''" nzTooltipPlacement="topRight">
              <nz-date-picker formControlName="eligiblityEndDate" id="eligiblityEndDate" [nzFormat]="'MM/dd/yyyy'"
                [nzPlaceHolder]="getAttributes('eligiblityEndDate').placeholder"
                [ngClass]="{'error': validationErrors['eligiblityEndDate'] }"
                [nzDisabledDate]="disabledEndDate"
              ></nz-date-picker>
            </div>
            @if (validationErrors['eligiblityEndDate']) {
            <span class="pl-1 error-message">{{validationErrors['eligiblityEndDate']}}</span>
            }
          </nz-form-control>
        </nz-form-item>}
      @if(isAttrAvailable('relationToParticipant')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" zFor="relationToParticipant"
          class="ppl-form-label">{{getAttributes('relationToParticipant').label}}
          @if(getAttributes('relationToParticipant').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip
          nzTooltipTitle="{{getAttributes('relationToParticipant').tooltip}}" nzTooltipPlacement="topRight">
          <nz-select formControlName="relationToParticipant" class="ppl-select" nzId="relationToParticipant"
            nzPlaceHolder="Select relation to consumer"
            [ngClass]="{'error': validationErrors['relationToParticipant'] }" nzShowSearch nzAllowClear>
            @for(relation of relationshipOptions; track $index){
            <nz-option [nzLabel]="relation.lkpValueName" [nzValue]="relation.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['relationToParticipant']) {
          <span class="pl-1 error-message">{{validationErrors['relationToParticipant']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }

      @if(isAttrAvailable('showOnDirectory')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="showOnDirectory"
          class="ppl-form-label">{{getAttributes('showOnDirectory').label}}
          @if(getAttributes('showOnDirectory').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('showOnDirectory')?.tooltip || ''"
            nzTooltipPlacement="topRight">
            <nz-radio-group [ngClass]="{'error': validationErrors['showOnDirectory'] }"
              formControlName="showOnDirectory" class="ppl-radio-group" id="showOnDirectory">
              @for(opt of radioOptions; track $index){
              <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
              }
            </nz-radio-group>
          </div>
          @if (validationErrors['showOnDirectory']) {
          <span class="pl-1 error-message">{{validationErrors['showOnDirectory']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('yearsInState')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="yearsInState"
            class="ppl-form-label">{{getAttributes('yearsInState').label}}
            @if(getAttributes('yearsInState').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control [nzSm]="16" class="ppl-form-control">
            <input nz-input nz-tooltip nzTooltipTitle="{{getAttributes('yearsInState').tooltip}}" nzTooltipPlacement="topRight"
              formControlName="yearsInState" id="yearsInState" [ngClass]="{'error': validationErrors['yearsInState'] }"
              placeholder="{{getAttributes('yearsInState').placeholder}}" />
            @if (validationErrors['yearsInState']) {
            <span class="pl-1 error-message">{{validationErrors['yearsInState']}}</span>
            }
            <div class="notes">
              <p>
                  This response may require additional background checks during enrolment.
              </p>
          </div>
          </nz-form-control>
        </nz-form-item>
        }
      @if(isAttrAvailable('nonProfitStatus')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="nonProfitStatus"
            class="ppl-form-label">{{getAttributes('nonProfitStatus').label}}
            @if(getAttributes('nonProfitStatus').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
            </nz-form-item>
            <nz-form-item class="ppl-form-item">
                <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="taxExempt"
                  class="ppl-form-label">{{getAttributes('taxExempt').label}}
                  @if(getAttributes('taxExempt').mandatory === 'Y'){
                  <span>*</span>
                  }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['taxExempt'] }"
              nzPlaceHolder="{{getAttributes('taxExempt').placeholder}}" nzId="taxExempt" formControlName="taxExempt"
              style="width:100%; margin-bottom: 16px" nzShowArrow>
              @for(opt of taxExemptOptions; track $index){
                <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
                }
            </nz-select>
            @if (validationErrors['services']) {
            <span class="pl-1 error-message">{{validationErrors['services']}}</span>
            }
          </nz-form-control>
          </nz-form-item>
          <nz-form-item class="ppl-form-item">
            <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="nprftDocumentationReceived"
              class="ppl-form-label">{{getAttributes('nprftDocumentationReceived').label}}
              @if(getAttributes('nprftDocumentationReceived').mandatory === 'Y'){
              <span>*</span>
              }</nz-form-label>
      <nz-form-control class="ppl-form-control">
        <nz-select [nzMaxTagCount]="4" nzMode='default' [ngClass]="{'error': validationErrors['nprftDocumentationReceived'] }"
          nzPlaceHolder="{{getAttributes('nprftDocumentationReceived').placeholder}}" nzId="nprftDocumentationReceived" formControlName="nprftDocumentationReceived"
          style="width:100%; margin-bottom: 16px" nzShowArrow>
          <nz-option [nzValue]="'yes'" [nzLabel]="'Yes'"></nz-option>
          <nz-option [nzValue]="'no'" [nzLabel]="'No'"></nz-option>
        </nz-select>
        @if (validationErrors['nprftDocumentationReceived']) {
        <span class="pl-1 error-message">{{validationErrors['nprftDocumentationReceived']}}</span>
        }
      </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('NPI')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="NPI"
          class="ppl-form-label">{{getAttributes('NPI').label}}
          @if(getAttributes('NPI').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('NPI')?.tooltip || ''" nzTooltipPlacement="topRight">
            <input nz-input formControlName="NPI" id="NPI" placeholder="{{getAttributes('NPI').placeholder}}"
              [ngClass]="{'error': validationErrors['NPI'] }" />
          </div>
          @if (validationErrors['NPI']) {
          <span class="pl-1 error-message">{{validationErrors['NPI']}}</span>
          }
        </nz-form-control>
      </nz-form-item>

      }
      @if(isAttrAvailable('additionalDemographics')){
      <nz-divider class="divider"></nz-divider>
      <nz-form-label [nzXs]="24" nzFor="additionalDemographics" class="ppl-form-label"
        style="margin:8px 0 24px 0;">{{getAttributes('additionalDemographics').label}}
        @if(getAttributes('additionalDemographics').mandatory === 'Y'){
        <span>*</span>
        }
      </nz-form-label>
      @if(isAttrAvailable('race')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="race" class="ppl-form-label">{{getAttributes('race').label}}
          @if(getAttributes('race').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('race').tooltip}}"
          nzTooltipPlacement="topRight">

          <nz-select formControlName="race" class="ppl-select" nzId="race"
          nzPlaceHolder="Select relation to Consumer"
          [ngClass]="{'error': validationErrors['race'] }" nzShowArrow>
          @for(race of raceOptions; track $index){
          <nz-option [nzLabel]="race.lkpValueName" [nzValue]="race.lkpValueCode"></nz-option>
          }
        </nz-select>
          @if (validationErrors['race']) {
          <span class="pl-1 error-message">{{validationErrors['race']}}</span>
          }

        </nz-form-control>
      </nz-form-item>
      }

      @if(isAttrAvailable('height')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="height" class="ppl-form-label">{{getAttributes('height').label}}
          @if(getAttributes('height').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div nz-tooltip [nzTooltipTitle]="getAttributes('height')?.tooltip || ''" nzTooltipPlacement="topRight"
          class="height-input-container ppl-form-control">
          <input nz-input formControlName="heightFeet" id="heightFeet" placeholder="Feet"
            [ngClass]="{'error': validationErrors['heightFeet']}" />
          <span>Feet</span>
          <input nz-input formControlName="heightInches" id="heightInches" placeholder="Inches"
            [ngClass]="{'error': validationErrors['heightInches']}" />
          <span>Inches</span>
        </div>
      </nz-form-item>

      }
      @if(isAttrAvailable('weight')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="weight" class="ppl-form-label">
          {{getAttributes('weight').label}}
          @if(getAttributes('weight').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <div nz-tooltip [nzTooltipTitle]="getAttributes('weight')?.tooltip || ''" nzTooltipPlacement="topRight"
          class="weight-input-container ppl-form-control">
          <input nz-input formControlName="weight" id="weight" placeholder="{{getAttributes('weight').placeholder}}"
            [ngClass]="{'error': validationErrors['weight']}" />
          <span class="unit-label">Pounds</span>
        </div>
      </nz-form-item>
      }
      @if(isAttrAvailable('eyeColor')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="eyeColor"
          class="ppl-form-label">{{getAttributes('eyeColor').label}}
          @if(getAttributes('eyeColor').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('eyeColor').tooltip}}"
          nzTooltipPlacement="topRight">          
          <nz-select formControlName="eyeColor" class="ppl-select" nzId="eyeColor" nzShowArrow
            [ngClass]="{'error': validationErrors['eyeColor'] }">
            @for(color of eyeColors; track $index){
            <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['eyeColor']) {
          <span class="pl-1 error-message">{{validationErrors['eyeColor']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('hairColor')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="hairColor"
          class="ppl-form-label">{{getAttributes('hairColor').label}}
          @if(getAttributes('hairColor').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control" nz-tooltip nzTooltipTitle="{{getAttributes('hairColor').tooltip}}"
          nzTooltipPlacement="topRight">          
          <nz-select formControlName="hairColor" class="ppl-select" nzId="hairColor" nzShowArrow
            [ngClass]="{'error': validationErrors['hairColor'] }">
            @for(color of hairColors; track $index){
            <nz-option [nzLabel]="color.lkpValueName" [nzValue]="color.lkpValueCode"></nz-option>
            }
          </nz-select>
          @if (validationErrors['hairColor']) {
          <span class="pl-1 error-message">{{validationErrors['hairColor']}}</span>
          }
        </nz-form-control>
      </nz-form-item>
      }
      @if(isAttrAvailable('birthPlace')){
      <nz-form-item class="ppl-form-item" style="margin-bottom: 8px;">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="birthPlace"
          class="ppl-form-label">{{getAttributes('birthPlace').label}}
          @if(getAttributes('birthPlace').mandatory === 'Y'){
          <span>*</span>
          }
        </nz-form-label>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="birthCity" id="birthCity" placeholder="City" />
          </div>
        </nz-form-control>
        <nz-form-control class="ppl-form-control" style="margin: 0px 4px;">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="birthState" id="birthState" placeholder="State" />
          </div>
        </nz-form-control>
        <nz-form-control class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="" nzTooltipPlacement="topRight">
            <input nz-input formControlName="birthCountry" id="birthCountry" placeholder="Country" />
          </div>
        </nz-form-control>
      </nz-form-item>
      }
      <nz-divider class="divider"></nz-divider>
      }
      @if(isAttrAvailable('addToED')){
      <nz-form-item class="ppl-form-item">
        <nz-form-label [nzSm]="9" [nzXs]="24" nzFor="addToED" class="ppl-form-label">{{getAttributes('addToED').label}}
          @if(getAttributes('addToED').mandatory === 'Y'){
          <span>*</span>
          }</nz-form-label>
        <nz-form-control [nzSm]="16" class="ppl-form-control">
          <div nz-tooltip [nzTooltipTitle]="getAttributes('addToED')?.tooltip || ''" nzTooltipPlacement="topRight">
            <nz-radio-group formControlName="addToED" class="ppl-radio-group" id="addToED"
              [ngClass]="{'error': validationErrors['addToED'] }">
              @for(opt of radioOptions; track $index){
              <label nz-radio-button [nzValue]="opt.value">{{opt.label}}</label>
              }
            </nz-radio-group>
          </div>
          @if (validationErrors['addToED']) {
          <span class="pl-1 error-message">{{validationErrors['addToED']}}</span>
          }
        </nz-form-control>
      </nz-form-item>}
      
      @if(isAttrAvailable('servicesRendered')){
        <nz-form-item class="ppl-form-item">
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="servicesRendered"
            class="ppl-form-label">{{getAttributes('servicesRendered').label}}
            @if(getAttributes('servicesRendered').mandatory === 'Y'){
            <span>*</span>
            }</nz-form-label>
          <nz-form-control class="ppl-form-control">
            <nz-select [nzMaxTagCount]="4" nzMode='multiple' [ngClass]="{'error': validationErrors['servicesRendered'] }"
              nzPlaceHolder="{{getAttributes('servicesRendered').placeholder}}" nzId="servicesRendered" formControlName="servicesRendered"
              style="width:100%; margin-bottom: 16px" nzShowArrow>
              @for(opt of services; track $index){
              <nz-option [nzLabel]="opt.lkpValueName" [nzValue]="opt.lkpValueCode"></nz-option>
              }
            </nz-select>
            @if (validationErrors['servicesRendered']) {
            <span class="pl-1 error-message">{{validationErrors['servicesRendered']}}</span>
            }
          </nz-form-control>
        </nz-form-item>
        }
    </form>
  </nz-card>
</div>}
@else if(mode==="read"){
  @if(isAttrAvailable('businessName')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('businessName').label }} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['businessName']}}</div>
    </div>
    }
    @if(isAttrAvailable('fname')){
    <div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('fname').label }} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['fname']}}</div>
    </div>
    }
    @if(isAttrAvailable('mname')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('mname').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['mname']}}</div>
    </div>
    }
    @if(isAttrAvailable('lname')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('lname').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['lname']}}</div>
    </div>
    }
    @if(isAttrAvailable('maiden_name')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('maiden_name').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['maiden_name']}}</div>
    </div>
    }
    @if(isAttrAvailable('dob')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('dob').label}} :</div>
  <div nz-col nzSpan="16">{{ filledInfo['dob'] }}</div>
    </div>
    }
    @if(isAttrAvailable('gender')){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('gender').label}} :</div>
  <div nz-col nzSpan="16">{{(getLookupValue(gender, filledInfo['gender']))}}</div>
    </div>
    }
@if(isAttrAvailable('state') && this.state){
<div nz-row>
  <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('state').label }} :</div>
  <div nz-col nzSpan="16">{{ (getLookupValue(states, this.state))}}</div>
    </div>
    }
    @if(isAttrAvailable('isProvidingServices')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('isProvidingServices').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['isProvidingServices'] }}</div>
    </div>
    }
    @if(isAttrAvailable('SSN')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('SSN').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['SSN']}}</div>
    </div>
    }
    @if(isAttrAvailable('CIN')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('CIN').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['CIN']}}</div>
    </div>
    }
    @if(isAttrAvailable('EIN')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('EIN').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['EIN']}}</div>
    </div>
    }
    @if(isAttrAvailable('waiver')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('waiver').label}} :</div>
      <div nz-col nzSpan="16">{{ getLookupValue(waiver, filledInfo['waiver']) }}</div>
    </div>
    }
    @if(isAttrAvailable('ldssOffice')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('ldssOffice').label}} :</div>
        <div nz-col nzSpan="16">{{ getLookupValue(ldssOfficeValues, filledInfo['ldssOffice']) }}</div>
      </div>
    }
    @if(isAttrAvailable('mcoOrgName')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('mcoOrgName').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['mcoOrgName'] }}</div>
    </div>
    }
    @if(isAttrAvailable('currentFI')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('currentFI').label}} :</div>
        <div nz-col nzSpan="16">{{ getLookupValue(currentFIValues, filledInfo['currentFI']) }}</div>
      </div>
    }
    @if(isAttrAvailable('fiOrgName')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('fiOrgName').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['fiOrgName'] }}</div>
    </div>
    }
    @if(isAttrAvailable('eligiblityStartDate')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('eligiblityStartDate').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['eligiblityStartDate'] }}</div>
    </div>
    }
    @if(isAttrAvailable('eligiblityEndDate')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('eligiblityEndDate').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['eligiblityEndDate'] }}</div>
    </div>
    }
    @if(isAttrAvailable('relationToParticipant')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('relationToParticipant').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo.relationToParticipant ? getLookupValue(relationshipOptions, filledInfo['relationToParticipant']) : filledInfo.relationToParticipant}}</div>
    </div>
    }
    @if(isAttrAvailable('showOnDirectory')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('showOnDirectory').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['showOnDirectory']}}</div>
    </div>
    }
    @if(isAttrAvailable('yearsInState')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('yearsInState').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['yearsInState']}}</div>
      </div>
    }
    @if(isAttrAvailable('nonProfitStatus')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('nonProfitStatus').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['nonProfitStatus']}}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('taxExempt').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo.taxExempt ? getLookupValue(taxExemptOptions, filledInfo['taxExempt']) : filledInfo.taxExempt}}</div>
      </div>
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('nprftDocumentationReceived').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['nprftDocumentationReceived']}}</div>
      </div>
    }
    @if(isAttrAvailable('NPI')){
    <div nz-row>
      <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('NPI').label}} :</div>
      <div nz-col nzSpan="16">{{ filledInfo['NPI']}}</div>
    </div>
    }
    @if(isAttrAvailable('servicesRendered')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{ getAttributes('servicesRendered').label }} :</div>
        <div nz-col nzSpan="16">{{ getLookupValue(services, filledInfo['servicesRendered'])}}</div>
      </div>
    }
    @if(isAttrAvailable('additionalDemographics')){
      <div nz-row class="ppl-read-label">Additional Demographics :</div>
      @if(isAttrAvailable('race')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('race').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo.race ? getLookupValue(raceOptions, filledInfo['race']) : filledInfo.race}}</div>
      </div>
      }
      @if(isAttrAvailable('height')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('height').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['heightFeet']}} Feet {{ filledInfo['heightInches'] || 0}} Inches</div>
      </div>
      }
      @if(isAttrAvailable('weight')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('weight').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['weight']}} Pounds</div>
      </div>
      }
      @if(isAttrAvailable('eyeColor')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('eyeColor').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo.eyeColor ? getLookupValue(eyeColors, filledInfo['eyeColor']) : filledInfo.eyeColor}}</div>
      </div>
      }
      @if(isAttrAvailable('hairColor')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('hairColor').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo.hairColor ? getLookupValue(hairColors, filledInfo['hairColor']) : filledInfo.hairColor}}</div>
      </div>
      }
      @if(isAttrAvailable('birthPlace')){
      <div nz-row>
        <div nz-col nzSpan="8" class="ppl-read-label">{{getAttributes('birthPlace').label}} :</div>
        <div nz-col nzSpan="16">{{ filledInfo['birthCity']}} {{ filledInfo['birthState']}} {{ filledInfo['birthCountry']}}
        </div>
      </div>
      }
    }
    
}