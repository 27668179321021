import { Component, OnDestroy, OnInit } from '@angular/core';
import { AccordionComponent } from "../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../shared/authorization/authorize.directive.';
import { PDFFormComponent } from "../pdf-form/pdf-form.component";
import { ReferralDataService } from '../../../services/referral-data.service';
import { STATIC_DATA } from '../../../shared/constants/static-data.constants';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ListTableComponent } from "../../../shared/components/list-table/list-table.component";
import { MailFormService } from '../../../services/mail-form.service';
import { MailFormDetail } from '../../../interfaces/mailform.interface';
import { SectionsService } from '../../../services/sections.service';
import { MEMBER_VIEW_PAGE_IDS, TABS } from '../../../shared/constants/page.constants';
import { AuthorizationUtility } from '../../../shared/authorization/auth.utility';

@Component({
  selector: 'ppl-form-checklist',
  standalone: true,
  imports: [AccordionComponent, AuthorizeDirective, PDFFormComponent, ListTableComponent],
  templateUrl: './form-checklist.component.html',
  styleUrl: './form-checklist.component.css'
})
export class FormChecklistComponent implements OnInit, OnDestroy {

  pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
  tabId = TABS.Forms;
  

  readonly = false;
  formsChecked = false;
  routeSubscription!: Subscription;
  mailSummarySubscription: Subscription = new Subscription();
  refreshSubscription: Subscription = new Subscription();
  isNewReferral = false;
  mailFormsData: MailFormDetail[] = [] 
  cols = [
      {
        "field": "formName",
        "header": "Form Name",
        "jsonpath": "formName",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "field": "status",
        "header": "Mail Status",
        "jsonpath": "status",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
      {
        "field": "dateRequested",
        "header": "Date Requested",
        "jsonpath": "dateRequested",
        "isDisplayedAtHeader": "y",
        "isSearchable": "n"
      },
    ]

  constructor(
    private referralDataService: ReferralDataService, 
    private route: ActivatedRoute,
    private mailFormService: MailFormService,
    private sectionsService: SectionsService,
    private authUtility: AuthorizationUtility
  ) {
  }

  ngOnInit(): void {
    this.readonly = !this.authUtility.isSectionEditable(this.pageId, this.tabId, this.tabId);

    this.referralDataService.nextClicked.subscribe((val) => {
      if(val === STATIC_DATA.FORMS){
        this.referralDataService.setStepValidation({step: STATIC_DATA.FORMS, val: this.formsChecked});
      }
    });
    this.routeSubscription = this.route.data.subscribe({next: (data: any) => {
      // Determine route, data passed from app.routes
      this.isNewReferral = data.isNewReferral ?? false;
    }})

    this.refreshSubscription = this.mailFormService.refresh$.subscribe(() => {
      this.mailSummarySubscription.unsubscribe();
      this.getForms();
    })
    this.getForms();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.mailSummarySubscription.unsubscribe();
    this.refreshSubscription.unsubscribe();
  }

  isMandatoryFormsCompleted(value: boolean) {
    // use this flag to check if all mandatory forms has signed or not
    this.formsChecked = value;
  }

  private getForms(){
    const id = localStorage.getItem('selected_lead');
    const selectedProgram = localStorage.getItem('selected_program')
    const program_code: (string | null) = selectedProgram ? JSON.parse(selectedProgram).program_code : null
    const entityType = this.sectionsService.getFlow() === "providerEnrollment" ? "provider" : "participant"

    if(id && program_code && entityType && !this.isNewReferral){
      this.mailSummarySubscription.unsubscribe();
      this.mailSummarySubscription = this.mailFormService.getMailForms(program_code, id, entityType).subscribe(data => this.mailFormsData = data.responsedata?.mailForms)
    }
  }
}
