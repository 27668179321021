import { CommonModule } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output, signal, TemplateRef, ViewChild, WritableSignal } from '@angular/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { ChecklistGroup, ChecklistItem, ChecklistResponse, DownloadFormPayload, DownloadFormPayloadItem } from '../../../interfaces/checklist.model';
import { PDFFormConfig } from '../../../interfaces/config';
import { CheckListService } from '../../../services/check-list.service';
import { ReferralDataService } from '../../../services/referral-data.service';
import { ButtonComponent } from "../../../shared/components/button/button.component";
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';
import { ModalComponent } from '../../../shared/components/modal/modal.component';
import { PROVIDER_TYPES, RADIO_YES_NO_OPTIONS, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { EditpdfComponent } from "../../editpdf/editpdf.component";
import { ChecklistHelperService } from '../../../services/checklist.helper.service';
import { EnrollmentDataService } from '../../../services/enrollment-data.service';
import { SectionsService } from '../../../services/sections.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { Subject, Subscription } from 'rxjs';
import { PopupDialogComponent } from '../../../shared/components/popup-dialog/popup-dialog.component';
import { DialogComponent } from '../../../shared/components/dialog/dialog.component';
import { DialogConfig, DialogService } from '../../../shared/services/dialog.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { GridAction } from '../../../shared/components/list-table/grid-action.model';
import { MailFormService } from '../../../services/mail-form.service';
import { SendMailFormPostItem } from '../../../interfaces/mailform.interface';

@Component({
  selector: 'ppl-pdf-form',
  standalone: true,
  imports: [
    CommonModule,
    NzAlertModule,
    ButtonComponent,
    NzCollapseModule,
    NzRadioModule,
    NzInputModule,
    NzSelectModule,
    NzDatePickerModule,
    EditpdfComponent,
    ListTableComponent,
    PopupDialogComponent,
  ],
  providers: [CheckListService],
  templateUrl: './pdf-form.component.html',
  styleUrl: './pdf-form.component.css'
})
export class PDFFormComponent implements OnInit {
  private dialogSvc = inject(DialogService);
  @Input() readonly?: boolean = false;
  @Input() isNewReferral = false;
  @Output() isMandatoryFormsCompleted = new EventEmitter<boolean>();
  @ViewChild('associationsDialog') associationDialogRef!: TemplateRef<any>;
  @ViewChild('pdfDialog') pdfDialogRef!: TemplateRef<any>;
  @ViewChild('confirmationDialog2') confirmationDialog2!: TemplateRef<any>;
  @ViewChild(EditpdfComponent) viewEditPDFComponent!: EditpdfComponent;

  showConfirmationDialog = false;

  selectedItemToMail: WritableSignal<ChecklistItem | null> = signal(null);

  signForm = new Subject<object>();

  prvdrAssociations: any = [];
  associationDetailsData: any = {};

  date = '';
  radioOptions = RADIO_YES_NO_OPTIONS;
  showModal = false;
  showAssociationModal = false;
  modalBody = {
    associationModal: '',
    pdfViewer: ''
  };
  selectedForm: any;
  selectedAssociationForm: any;
  allPanelsExpanded = false;
  pdfConfig?: PDFFormConfig;
  pdfFile?: string;
  pdfBlob?: Blob;
  checkListData: ChecklistGroup[] = [];
  providerAssociationChecklist?: ChecklistGroup[] = [];
  formData: any;
  program: any;
  disableSave = true;
  disableSign = true;
  errors: any = [];
  signature: any;
  signatureId: any;
  isSignAdded = false;
  checklistResponse?: ChecklistResponse;
  isParticipantFlow = false;
  userId = '';
  source = '';
  pplId = '';
  entry: string | null = '';
  cols: any[] = [];
  associatedPPLId?: string;
  selectedParticipantId?: number;
  selectedProviderType?: string;
  isSelf: boolean = true;
  selectedParticipant: any;
  customError = '';
  actions: any;
  isPdfSubmitted: boolean = false;

  participantCols: any = [
    {
      "field": "formDesc",
      "header": "Form Name",
      "jsonpath": "formDesc",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "status",
      "header": "Status",
      "jsonpath": "status",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    }
  ]

  participantAssociationActions: any = {
    "view": true
  }

  downloadAll() {
    this.handleDownload(this.checklistResponse?.checkList || []);
  }

  constructor(
    private checkListService: CheckListService,
    private referralService: ReferralDataService,
    private sectionsService: SectionsService,
    private logger: LoggerService,
    private checklistHelperService: ChecklistHelperService,
    private enrollmentDataService: EnrollmentDataService,
    private notificationService: NotificationService,
    private mailFormService: MailFormService,
  ) {

  }

  ngOnInit(): void {
    this.actions = {
      "edit": true,
      "download": true,
      "mail": !this.readonly,
    }
    this.entry = localStorage.getItem('lead_entry');
    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.userId = localStorage.getItem('participantId') || localStorage.getItem('providerId') || localStorage.getItem('selected_lead') || '';
    this.isParticipantFlow = localStorage.getItem('lead_entry') == 'participantEnrollment' || localStorage.getItem('lead_submission_flow') == 'participantEnrollment';
    this.source = this.isParticipantFlow ? 'participant' : 'provider';

    this.loadData();
    this.getSignature(this.program.program_code, this.source, this.userId);
  }

  loadData() {
    this.checkListService.getChecklist(this.program?.program_code, this.userId || '', this.isParticipantFlow).subscribe((data: any) => {
      if (data.responsedata) {
        this.checklistResponse = data.responsedata;
        if (this.checklistResponse) {
          this.pplId = this.checklistResponse.pplId;
          if (this.isParticipantFlow) {
            this.checkListData = this.checklistHelperService.buildChecklistDataForParticipant(this.checklistResponse.checkList, true);
          } else {
            this.checkListData = this.checklistHelperService.buildChecklistDataForProvider(this.checklistResponse.checkList, null, true, true);
          }

          if (this.isParticipantFlow) {
            this.updateMandatoryFormsCompleteStatus();
          }
        }
      }
    });

    if (!this.isParticipantFlow) {
      this.getGridHeaders();
      this.enrollmentDataService.getParticipantAssocChanged().subscribe((data: any) => {
        this.getAssociationDetails();
      })
    }

  }

  async getGridHeaders() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          this.entry === 'participantEnrollment' ? STATIC_JSON_DATA_PATH.ASSOCIATIONS_DETAILS_HEADERS : STATIC_JSON_DATA_PATH.PROVIDER_ASSOCIATIONS_HEADERS, this.entry
        );
        // tempCols.columns.push({
        //   "field": "formStatusText",
        //   "header": "Form Status",
        //   "jsonpath": "formStatusText",
        //   "isDisplayedAtHeader": "y",
        //   "isSearchable": "n"
        // });
        console.log(tempCols.columns);
        this.cols = tempCols.columns;
      }
    } catch (e) {
      this.logger.debug('Communication logs - Error in getting column data', e);
    }
  }

  handleSign() {
    if (!this.disableSign) {
      this.signForm.next({
        isParticipant: this.isParticipantFlow,
        participantId: this.selectedParticipantId,
        providerType: this.selectedProviderType
      });
    }
    this.disableSign = true;
  }

  getSignature(program_code: string, type: string, id: string | null) {
    this.referralService.getSignature(program_code, type, id).subscribe((data: any) => {
      if (data?.responsedata && data?.responsedata.length) {
        const activeSign = data?.responsedata.find((data: any) => data.isActive);
        this.isSignAdded = true;
        this.signature = `data:image/png;base64,${activeSign?.signatureImageBase64}`;
        this.disableSign = false;
        this.signatureId = this.isParticipantFlow ? activeSign.participantSignatureId : activeSign.providerSignatureId;
      }
    })
  }

  getAssociationDetails() {
    this.enrollmentDataService.getAssociationsDetails(this.program.program_code, this.userId, this.isParticipantFlow).subscribe((response: any) => {
      const data = response.responsedata;
      if (this.isParticipantFlow) {
        for (const key in PROVIDER_TYPES) {
          this.associationDetailsData[key] = data[key] || [];
        }
      } else {
        this.prvdrAssociations = data.participantAssociations ?? [];
      }

      // for (const checklist of this.prvdrAssociations) {
      //   if (checklist.formStatus == 'C') {
      //     checklist.formStatusText = "Completed"
      //   } else {
      //     checklist.formStatusText = "Not Completed"
      //   }
      // }

      this.prvdrAssociations = this.prvdrAssociations.filter((association: any) => association.associationStatus != 'Disassociated');

      this.updateMandatoryFormsCompleteStatus();
    });
  }

  isAssociationsCompleted() {
    let isCompleted = true;
    for (const checklist of this.prvdrAssociations) {
      if (checklist.formStatus != 'C') {
        isCompleted = false;
        break;
      }
    }

    return isCompleted;
  }

  action(event: GridAction, isAssociation: boolean) {
    if (event.action == 'edit') {
      this.disableSave = true;
      if (isAssociation) {
        this.isSelf = false;
        this.handleAssociationCheckListClick(event.data)
      } else {
        this.isSelf = true;
        this.handleCheckListClick(event.data)
      }
    }
    else if (event.action == 'download') {
      this.handleDownload([event.data]);
    }
    else if (event.action == 'view') {
      this.disableSave = true;
      this.selectedParticipant = event.data;
      this.handleParticipantAssociationSelection(event.data)
    } else if (event.action === 'requestMail') {
      this.selectedItemToMail.set(event.data);
      this.handleRequestMail();
    }
  }

  handleRequestMail() {
    const dialogConfig: DialogConfig = {
      title: 'Confirm Mail',
      isOkText: 'Mail Form',
      size: 'medium',
      position: 'top',
      showButtons: true,
    }
    this.dialogSvc.openDialog(
      this.confirmationDialog2, 
      dialogConfig, 
      () => this.handleRequestMailClick(this.selectedItemToMail()),
      () => this.onCloseDialog()
    );
  }

  handleParticipantAssociationSelection(selectedParticipant: any, fromClosedDialog?: boolean) {
    this.showAssociationModal = true;
    this.modalBody.associationModal = 'Consumer Association - ' + selectedParticipant.participantFirstName + ' ' + selectedParticipant.participantLastName + ' (' + selectedParticipant.participantPplId + ')';
    this.selectedParticipantId = selectedParticipant.participantId;
    this.selectedProviderType = selectedParticipant.providerType;
    const dialogConfig: DialogConfig = {
      title: this.modalBody.associationModal,
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }

    if (!fromClosedDialog) {
      this.dialogSvc.openDialog(this.associationDialogRef, dialogConfig, () => this.handleCancelForAssociation(), () => this.handleCancelForAssociation());
    }

    this.checkListService.getChecklistForParticipantAssociation(this.program?.program_code, selectedParticipant.providerId, selectedParticipant.participantId, selectedParticipant.providerType).subscribe((data: any) => {
      if (data.responsedata) {
        const checklist: ChecklistResponse = data.responsedata;
        this.associatedPPLId = checklist.pplId;
        selectedParticipant.providerPPLID = checklist.pplId;
        this.providerAssociationChecklist = this.checklistHelperService.buildChecklistDataForProvider(checklist.checkList, selectedParticipant, true, false);
      }
    });
  }

  handleDownload(forms: ChecklistItem[]) {
    const pdfPayload: DownloadFormPayloadItem[] = [];
    forms.forEach(f => {
      if (f.formMasterId != 0) {
        const details = f.details.map(e => Object({ userType: e.type, primaryId: e.pplId }));
        if (this.source == 'participant' && details.filter((e: any) => e.userType == 'PTC').length == 0) {
          details.push(new Object({ userType: 'PTC', primaryId: this.pplId }));
        }
        const payload = {
          formId: f.formMasterId + '',
          checkListItemId: f.checklistItemId,
          checkListItemDtlsId: f.checklistItemDetailId,
          source: this.source,
          programCode: this.program?.program_code,
          languageCode: 'ENG',
          prtcpntPrvdrIdAndTypes: details
        };

        pdfPayload.push(payload);
      }
    });

    const formPayload: DownloadFormPayload = {
      forms: [...pdfPayload]
    };

    this.checkListService.downloadPDFForm(formPayload, this.source + '_forms.zip');
  }

  handleCheckListClick(form: ChecklistItem) {
    this.selectedForm = form;
    const currentuser = this.selectedForm.details.filter((d: any) => d.associationId == -1);
    this.selectedProviderType = currentuser.length > 0 ? currentuser[0].type : '';
    this.showModal = true;
    this.modalBody.pdfViewer = form.formDesc;
    const dialogConfig: DialogConfig = {
      title: this.modalBody.pdfViewer,
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }
    this.dialogSvc.openDialog(this.pdfDialogRef, dialogConfig, () => this.handleCancel(), () => this.handleCancel());

    this.loadPDF(form);
  }

  handleAssociationCheckListClick(form: ChecklistItem) {
    this.selectedAssociationForm = form;
    this.showModal = true;
    this.modalBody.pdfViewer = form.formDesc;
    const dialogConfig: DialogConfig = {
      title: this.modalBody.pdfViewer,
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }
    this.dialogSvc.openDialog(this.pdfDialogRef, dialogConfig, () => this.handleCancel(), () => this.handleCancel());
    this.loadPDF(form);
  }

  handleCancel() {
    this.showModal = false;
    this.pdfConfig = undefined;
    this.pdfFile = undefined;
    this.selectedForm = undefined;
    this.signForm = new Subject();
    this.disableSign = false;
    if (this.selectedParticipant) {
      this.handleParticipantAssociationSelection(this.selectedParticipant, true);
    } else {
      this.loadData();
    }
  }

  handleCancelForAssociation() {
    this.showModal = false;
    this.pdfConfig = undefined;
    this.pdfFile = undefined;
    this.selectedForm = undefined;
    this.selectedAssociationForm = undefined;
    this.showAssociationModal = false;
    this.providerAssociationChecklist = undefined;
    this.selectedParticipantId = undefined;
    this.selectedParticipant = undefined;
    this.associatedPPLId = undefined;
    this.signForm = new Subject();
    this.disableSign = false;
    this.loadData();
  }

  fieldsUpdated(event: any) {
    this.formData = event.data;
    this.errors = event.errors;
    this.disableSave = this.errors.length != 0 || event.customError != '';
    this.customError = event.customError;
  }

  updateMandatoryFormsCompleteStatus() {
    let isCompleted = true;
    for (const checklist of this.checkListData) {
      if (checklist.items.filter(e => e.mandatory && e.formStatus == 'P').length > 0) {
        isCompleted = false;
        break;
      }
    }

    for (const checklist of this.prvdrAssociations) {
      if (checklist.checklistStatus == 'P') {
        isCompleted = false;
        break;
      }
    }

    this.isMandatoryFormsCompleted.emit(isCompleted);
  }

  handleSave() {
    if (this.selectedParticipantId) {
      this.handleSaveAssociation();
      return;
    }

    if (this.errors.length == 0) {
      const checklistSaveResponse = this.generateSaveChecklistResponses();
      const payload = {
        type: this.source,
        checklistItems: checklistSaveResponse.filter(e => e != null)
      };
      this.checkListService.saveParticipantProviderForms(this.program?.program_code, this.userId, this.isParticipantFlow, payload).subscribe((response: HttpResponse<Blob>) => {
        if (response.status === 200) {
          this.loadPDFData(this.selectedForm);
          this.disableSave = true;
          this.isPdfSubmitted = true;

          this.viewEditPDFComponent.onFormSubmission();
        }
      });
    }
  }

  handleSaveAssociation() {
    if (this.errors.length == 0 && this.providerAssociationChecklist && this.selectedParticipantId) {
      const checklistSaveResponse = this.generateSaveChecklistResponsesForAssociation(this.selectedParticipantId);
      const payload = {
        type: "participantProvider",
        checklistItems: checklistSaveResponse.filter(e => e != null)
      };
      this.checkListService.saveParticipantAssocaitionForms(this.program?.program_code,
        this.selectedParticipantId, this.userId, this.selectedProviderType || '', payload).subscribe((response: HttpResponse<Blob>) => {
          if (response.status === 200) {
            this.loadPDFData(this.selectedAssociationForm);
            this.disableSave = true;
            this.isPdfSubmitted = true;

            this.viewEditPDFComponent.onFormSubmission();
          }
        });
    }
  }

  generateSaveChecklistResponses() {
    const checklist: any[] = [];
    if (this.selectedForm) {
      const currentuser = this.selectedForm.details.filter((d: any) => d.associationId == -1);
      checklist.push(new Object({
        checklistId: this.selectedForm.checklistItemId,
        checklistDetailId: this.selectedForm.checklistItemDetailId,
        participantId: this.isParticipantFlow ? this.userId : '',
        providerId: !this.isParticipantFlow ? this.userId : '',
        providerType: currentuser.length > 0 ? currentuser[0].type : '',
        associationId: this.selectedForm.details.filter((e: any) => e.associationId != -1).map((d: any) => d.associationId),
        formValue: this.formData,
      }));
    }

    return checklist;
  }

  generateSaveChecklistResponsesForAssociation(participantId: number) {
    const checklist: any[] = [];
    if (this.selectedAssociationForm) {
      checklist.push(new Object({
        checklistId: this.selectedAssociationForm.checklistItemId,
        checklistDetailId: this.selectedAssociationForm.checklistItemDetailId,
        participantId: participantId,
        providerId: this.userId,
        providerType: this.selectedProviderType,
        associationId: this.selectedAssociationForm.details.filter((d: any) => d.associationId != -1).map((d: any) => d.associationId),
        formValue: this.formData,
      }));
    }
    return checklist;
  }

  loadPDF(form: ChecklistItem) {
    this.errors = [];
    this.customError = '';
    this.formData = undefined;
    this.isPdfSubmitted = form.formStatus == 'C';
    this.checkListService.getPDF(this.program?.program_code, form.formMasterId, 'eng').subscribe((response: HttpResponse<Blob>) => {
      if (response.body) {
        const blob = new Blob([response.body], { type: response.body.type });
        this.pdfBlob = blob;
        this.pdfFile = window.URL.createObjectURL(blob);
      }
    });

    this.loadPDFData(form);
  }

  loadPDFData(form: ChecklistItem) {
    this.checkListService.getFormData(this.program?.program_code,
      form,
      this.source, this.pplId).subscribe(response => {
        if (response?.responsedata) {
          this.pdfConfig = {
            fields: response?.responsedata.Form_Fields,
            form_description: form.formDesc,
            form_id: form.formMasterId + '',
            form_name: form.form_name,
            is_mandatory: false
          };
        }
      });
  }

  hideAll() {
    this.checkListData.forEach(group => {
      group.isActive = false;

      this.toggleCollapsePanel(group, false);
    });
  }

  expandAll() {
    this.checkListData.forEach(group => {
      group.isActive = true;

      this.toggleCollapsePanel(group, true);
    });
  }

  toggleCollapsePanel(group: ChecklistGroup, activate: boolean) {
    group.isActive = activate;
    if (group.children && group.children.length > 0) {
      group.children.forEach(c => {
        this.toggleCollapsePanel(c, activate);
      })
    }
  }

  isPanelActive(group: ChecklistGroup): boolean {
    return group.isActive;
  }

  getErrorMessage(error: any) {
    switch (error.errorName) {
      case 'required':
        return `${error.businessName} is required`;
      default:
        return 'unknown error ' + error.errorName;
    }
  }

  showMailAllDialog() {
    this.showConfirmationDialog = true
  }

  handleConfirmMailAll() {
    const entityId = localStorage.getItem('selected_lead')!;
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!).program_code;
    const entityType = this.isParticipantFlow ? 'participant' : 'provider'

    this.showConfirmationDialog = false
    // TODO: Add proper types
    const formsToMail: SendMailFormPostItem[] = []

    this.checkListData.forEach(checklist => {
      checklist.items.forEach(item => {
        if (item.status !== "Completed" && item.formMasterId !== 50 && item.formMasterId !== 52) {
          formsToMail.push(
            {
              entityId,
              entityType,
              pplId: this.pplId || this.associatedPPLId || "",
              formMasterId: item.formMasterId,
              checklistDetails: item.details
            }
          )
        }
      })
    })
    // TODO: Wrap in a try/catch, only show success alert on successful call
    if (formsToMail.length === 0) {
      this.notificationService.alert(
        'warning',
        'Warning',
        'There are no forms to mail.'
      );
      return
    }
    this.mailFormService.sendMailForms(formsToMail, selectedProgram, entityId, entityType).subscribe(() => {
      this.notificationService.alert(
        'success',
        'Success',
        'All forms were successfully staged for mailing!'
      );
      this.mailFormService.triggerRefresh();
    });

  }

  handleRequestMailClick(formData: ChecklistItem | null) {
    if (!formData) return;
    const entityId = localStorage.getItem('selected_lead')!;
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!).program_code;
    const entityType = this.isParticipantFlow ? 'participant' : 'provider'
    this.selectedItemToMail.set(null);
    if (formData.formMasterId === 50 || formData.formMasterId === 52) {
      this.notificationService.alert(
        'warning',
        'Warning',
        'This form type is not supported for mailing.'
      );
      return
    }
    const formToMail: SendMailFormPostItem[] = [
      {
        entityId,
        entityType,
        pplId: this.pplId || this.associatedPPLId || "",
        formMasterId: formData.formMasterId,
        checklistDetails: formData.details
      }
    ]
    this.mailFormService.sendMailForms(formToMail, selectedProgram, entityId, entityType).subscribe(() => {
      this.notificationService.alert(
        'success',
        'Success',
        'Form was successfully staged for mailing!'
      );
      this.mailFormService.triggerRefresh();
    });
  }

  onCloseDialog() {
    this.showConfirmationDialog = false;
    this.selectedItemToMail.set(null);
  }

  updateChecklistActivity(checklistGroup: any) {
    checklistGroup.isActive = !checklistGroup.isActive; 
  }
}