{
    "participant_info": {
       "sectionId": "info",
       "header": "Employer Information",
       "available_actions": null,
       "attributes": [
           {
               "attribute_ID": "businessName",
               "fieldName": "businessName",
               "label": "Business Name",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your business name",
               "isAvailable": "N",
               "placeholder": "Business Name"
           },
           {
               "attribute_ID": "fname",
               "fieldName": "fname",
               "label": "First Name",
               "type": "input",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please enter your first name",
               "isAvailable": "Y",
               "placeholder": "First Name"
           },
           {
               "attribute_ID": "mname",
               "fieldName": "mname",
               "label": "Middle Name",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your middle name",
               "isAvailable": "Y",
               "placeholder": "Middle Name"
           },
           {
               "attribute_ID": "lname",
               "fieldName": "lname",
               "label": "Last Name",
               "type": "input",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please enter your last name",
               "isAvailable": "Y",
               "placeholder": "Last Name"
           },
           {
               "attribute_ID": "maiden_name",
               "fieldName": "maiden_name",
               "label": "Maiden or Previous Name",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your maiden name",
               "isAvailable": "N",
               "placeholder": "Maiden or Previous Name"
           },
           {
               "attribute_ID": "dob",
               "fieldName": "dob",
               "label": "Date of Birth",
               "type": "datepicker",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please select your date of birth",
               "isAvailable": "Y",
               "placeholder": "Select Date"
           },
           {
               "attribute_ID": "gender",
               "fieldName": "gender",
               "label": "Gender",
               "type": "radio",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your gender",
               "isAvailable": "N"
           },
           {
               "attribute_ID": "relationToParticipant",
               "fieldName": "relationToParticipant",
               "label": "Relationship to Consumer",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your Relationship to Consumer",
               "isAvailable": "N"
           },
           {
               "attribute_ID": "EIN",
               "fieldName": "EIN",
               "label": "EIN",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your EIN",
               "isAvailable": "N",
               "placeholder": "EIN"
           },
           {
               "attribute_ID": "SSN",
               "fieldName": "SSN",
               "label": "Social Security Number",
               "type": "input",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please enter SSN",
               "isAvailable": "Y",
               "placeholder": "Social Security Number"
           },
           {
               "attribute_ID": "additionalDemographics",
               "fieldName": "additionalDemographics",
               "label": "Additional Demographics in case program requires fingerprinting :",
               "type": "section",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": ""
           },
           {
               "attribute_ID": "race",
               "fieldName": "race",
               "label": "Race",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your Race",
               "isAvailable": "N",
               "placeholder": "Select"
           },
           {
               "attribute_ID": "height",
               "fieldName": "height",
               "label": "Height",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your height",
               "isAvailable": "N",
               "placeholder": "Height"
           },
           {
               "attribute_ID": "weight",
               "fieldName": "weight",
               "label": "Weight",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your weight",
               "isAvailable": "N",
               "placeholder": "Weight"
           },
           {
               "attribute_ID": "eyeColor",
               "fieldName": "eyeColor",
               "label": "Eye Color",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your eye color",
               "isAvailable": "N",
               "placeholder": "Select"
           },
           {
               "attribute_ID": "hairColor",
               "fieldName": "hairColor",
               "label": "Hair Color",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your hair color",
               "isAvailable": "N",
               "placeholder": "Select"
           },
           {
               "attribute_ID": "birthPlace",
               "fieldName": "birthPlace",
               "label": "Place of Birth",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": ""
           },
           {
               "attribute_ID": "NPI",
               "fieldName": "NPI",
               "label": "National Provider Number",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "National Provider Number",
               "isAvailable": "N",
               "placeholder": "Please Enter 10 digit NPI"
           },
           {
               "attribute_ID": "addToED",
               "fieldName": "addToED",
               "label": "Would you like to be added to an Employee Directory for Self-Directed Consumers that may be looking for additional employee services?",
               "type": "radio",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": ""
           }
       ],
       "validations": [
           {
               "whatToCheck": "atLeastXEntered",
               "clientOrServer": "C",
               "attributes": [
                   {
                       "validatorid": 1,
                       "attribute_ID": ["fname"],
                       "functionParams": {
                         "ErrMsg": "Please enter First Name."
                       }
                     },
                     {
                       "validatorid": 2,
                       "attribute_ID": ["lname"],
                       "functionParams": {
                         "ErrMsg": "Please enter Last Name"
                       }
                     },
                     {
                        "validatorid": 3,
                        "attribute_ID": ["dob"],
                        "functionParams": {
                          "ErrMsg": "Please select Date of Birth"
                        }
                      },
                      {
                        "validatorid": 4,
                        "attribute_ID": ["SSN"],
                        "functionParams": {
                          "ErrMsg": "Please enter SSN"
                        }
                      }
               ]
           },
           {
               "whatToCheck": "checkFieldValue",
               "clientOrServer": "C",
              "attributes": [ {
               "validatorid": 1,
               "attribute_ID": [
                   "fname"
               ],
               "functionParams": {
                   "isNullAllowed": "N",
                   "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                   "ErrMsg": "Please enter a valid first name with only letters"
               }
           },
           {
               "validatorid": 2,
               "attribute_ID": [
                   "lname"
               ],
               "functionParams": {
                   "isNullAllowed": "N",
                   "Format": "^[A-zÀ-ÿ\\s'\\-.]+$",
                   "ErrMsg": "Please enter a valid last name with only letters"
               }
           }
            ]
           },
           {
               "whatToCheck": "conditionalCheck",
               "clientOrServer": "C",
               "attributes": [
                  
               ]
           },
           {
            "whatToCheck": "customValidator",
            "clientOrServer": "C",
            "attributes": [
             {
                 "validatorid": 1,
                 "attribute_ID": [
                     "SSN"
                 ],
                 "functionParams": {
                     "validatorFunction" : "validateSSN",
                     "ErrMsg": "Please enter valid SSN"
                 }
             }
            ]
        }
       ],
       "actions": [
           {
               "Name": "Previous",
               "Validate": null
           },
           {
               "Name": "Next",
               "Validate": [
                   {
                       "checkType": "atLeastXEntered",
                       "validatorid": [
                           1,
                           2,
                           3,
                           4
                       ]
                   },
                   {
                       "checkType": "checkFieldValue",
                       "validatorid": [
                           1,
                           2,
                           3
                       ]
                   },
                   {
                       "checkType": "conditionalCheck",
                       "validatorid": [
                           1
                       ]
                   },
                   {
                       "checkType": "customValidator",
                       "validatorid": [
                           1
                       ]
                   }
               ]
           }
       ]
   },
   "contact_details": {
       "sectionId": "contact_details",
       "header": "Contact Details",
       "attributes": [
           {
               "attribute_ID": "email",
               "fieldName": "email",
               "label": "Email Address",
               "type": "input",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please enter your email address",
               "isAvailable": "Y",
               "placeholder": "Email Address"
           },
           {
               "attribute_ID": "altPhone",
               "fieldName": "altPhone",
               "label": "Telephone Number",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter telephone or alternate phone number",
               "isAvailable": "N",
               "placeholder": "10-digit Telephone Number"
           },
           {
               "attribute_ID": "mobile",
               "fieldName": "mobile",
               "label": "Mobile Number",
               "type": "input",
               "mandatory": "Y",
               "validator": null,
               "tooltip": "Please enter your Mobile number",
               "isAvailable": "Y",
               "placeholder": "10-digit Mobile Number"
           },
           {
               "attribute_ID": "permanentAddress",
               "fieldName": "permanentAddress",
               "label": "Address",
               "type": "form",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your Address",
               "isAvailable": "Y"
           },
           {
               "attribute_ID": "mailingAddress",
               "fieldName": "mailingAddress",
               "label": "Mailing Address",
               "type": "form",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please enter your Mailing Address",
               "isAvailable": "N"
           },
           {
               "attribute_ID": "yearsInState",
               "fieldName": "yearsInState",
               "label": "How many years have you resided in the State?",
               "type": "input",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": ""
           }
       ],
       "validations": [
           {
               "whatToCheck": "atLeastXEntered",
               "clientOrServer": "C",
               "attributes": [
                {
                    "validatorid": 1,
                    "attribute_ID": [
                        "email","mobile" 
                    ],
                    "functionParams": {
                        "minNeeded": 1,
                        "ErrMsg": "Please Enter email/phone"
                    }
                }
               ]
           },
           {
               "whatToCheck": "checkFieldValue",
               "clientOrServer": "C",
               "attributes": [
                   {
                       "validatorid": 1,
                       "attribute_ID": [
                           "email"
                       ],
                       "functionParams": {
                           "isNullAllowed": "N",
                           "Format": "^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,3}$",
                           "ErrMsg": "Please Enter valid email address"
                       }
                   },
                   {
                       "validatorid": 2,
                       "attribute_ID": [
                           "mobile"
                       ],
                       "functionParams": {
                          "isNullAllowed": "N",
                           "Format": "^\\d{10}$",
                           "ErrMsg": "Please enter mobile number in the format 9999999999"
                       }
                   }
               ]
           },
           {
               "whatToCheck": "conditionalCheck",
               "clientOrServer": "C",
               "attributes": [ ]
           },
           {
               "whatToCheck": "customValidator",
               "clientOrServer": "C",
               "attributes": [ ]
           }
       ],
       "actions": [
           {
               "Name": "Previous",
               "Validate": null
           },
           {
               "Name": "Next",
               "Validate": [
                   {
                       "checkType": "atLeastXEntered",
                       "validatorid": [1]
                   },
                   {
                       "checkType": "checkFieldValue",
                       "validatorid": [
                           1,
                           2
                       ]
                   },
                   {
                       "checkType": "conditionalCheck",
                       "validatorid": []
                   },
                   {
                       "checkType": "customValidator",
                       "validatorid": []
                   }
               ]
           }
       ]
   },
   "comm_preferences": {
       "sectionId": "comm_preferences",
       "header": "Communication Preferences",
       "attributes": [
           {
               "attribute_ID": "registerPref",
               "fieldName": "registerPref",
               "label": "How will the employer register and complete their enrollment with PPL? ",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N"
           },
           {
               "attribute_ID": "emergencyContact",
               "fieldName": "emergencyContact",
               "label": "Emergency Contact",
               "type": "section",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": ""
           },
           {
               "attribute_ID": "nonProfitStatus",
               "fieldName": "nonProfitStatus",
               "label": "Non Profit Status",
               "type": "",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select the non profit Status",
               "isAvailable": "N",
               "placeholder": ""
           },
           {
               "attribute_ID": "taxExempt",
               "fieldName": "taxExempt",
               "label": "Tax Exempt",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": "Select"
           },
           {
               "attribute_ID": "nprftDocumentationReceived",
               "fieldName": "nprftDocumentationReceived",
               "label": "Non-Profit Official Documentation Received",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "",
               "isAvailable": "N",
               "placeholder": "Select"
           }
       ],
       "validations": [
           {
               "whatToCheck": "atLeastXEntered",
               "clientOrServer": "C",
               "attributes": []
           },
           {
               "whatToCheck": "checkFieldValue",
               "clientOrServer": "C",
               "attributes": []
           },
           {
               "whatToCheck": "conditionalCheck",
               "clientOrServer": "C",
               "attributes": []
           },
           {
               "whatToCheck": "customValidator",
               "clientOrServer": "C",
               "attributes": []
           }
       ],
       "actions": [
           {
               "Name": "Previous",
               "Validate": null
           },
           {
               "Name": "Next",
               "Validate": [
                   {
                       "checkType": "atLeastXEntered",
                       "validatorid": []
                   },
                   {
                       "checkType": "checkFieldValue",
                       "validatorid": []
                   },
                   {
                       "checkType": "conditionalCheck",
                       "validatorid": []
                   },
                   {
                       "checkType": "customValidator",
                       "validatorid": []
                   }
               ]
           }
       ]
   },
   "service_details": {
       "sectionId": "service_details",
       "header": "Service Details",
       "text_1": "Please share your service details",
       "text_2": null,
       "text_3": null,
       "available_actions": null,
       "attributes": [
           {
               "attribute_ID": "profLicences",
               "fieldName": "profLicences",
               "label": "Professional Licenses",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select your Professional Licenses",
               "isAvailable": "N",
               "placeholder": "Professional Licenses "
           },
           {
               "attribute_ID": "servicesRendered",
               "fieldName": "servicesRendered",
               "label": "Services that you can render",
               "type": "select",
               "mandatory": "N",
               "validator": null,
               "tooltip": "Please select the services",
               "isAvailable": "N",
               "placeholder": "Services"
           }
       ],
       "validations": [],
       "actions": []
   }
}