import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { SectionsService } from '../../../../services/sections.service';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { PAGE_CONSTANTS } from '../../../../shared/constants/page.constants';
import { ClaimsService } from '../../../../services/claims.service';

@Component({
  selector: 'ppl-authorizations-list',
  standalone: true,
  imports: [ButtonComponent, ListTableComponent],
  templateUrl: './authorizations-list.component.html',
  styleUrl: './authorizations-list.component.css',
})
export class AuthorizationsListComponent implements OnInit {
  pageId: string = PAGE_CONSTANTS.PARTICIPANT_ENROLLMENT_DATA_GRID_PAGE;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any = [];
  lazyLoad = true;
  isPageRestrictionLoaded = false;

  data = [
    {
        clientAuth: 'PRC-NY-10000733',
        pplAuth: 'PPL-NY-10000733',
        consumerFirstName: 'Nikhil',
        consumerLastName: 'Peter',
        medicaid: 'PRV-NY-10000722',
        pplId: 'PPL-NY-10000733',
        hcpcsServiceCode: '112233',
        modifier1: 'Nikhil Malhotra',
        modifier2: 'Sumit Murgai',
        units: '20',
        dollars: 'Rate Participant',
        authStartDate: '2024-10-01',
        authEndDate: '2024-10-05',
        mcoId: '123'
    },
    {
        clientAuth: 'PRC-NY-10000733',
        pplAuth: 'PPL-NY-10000733',
        consumerFirstName: 'Venkat',
        consumerLastName: 'Peter',
        medicaid: 'PRV-NY-10000722',
        pplId: 'PPL-NY-10000733',
        hcpcsServiceCode: '112233',
        modifier1: 'Nikhil Malhotra',
        modifier2: 'Sumit Murgai',
        units: '20',
        dollars: 'Rate Participant',
        authStartDate: '2024-10-01',
        authEndDate: '2024-10-05',
        mcoId: '123'
    },
  ]

  constructor(
    private router: Router,
    private sectionsService: SectionsService,
    private claimsService: ClaimsService
  ) {
  }

  ngOnInit() {
    this.sectionsService.setFlow('authorizations');
    const selectedProgram = JSON.parse(localStorage.getItem('selected_program')!);
    this.programName = selectedProgram.program_code;
    this.isPageRestrictionLoaded = true;
  }
  onAddAuthorizations() {
    console.log('process code add button clicked');
    this.router.navigate(['/home/addAuthorizations']);
  }

  rowClicked(rowDetail: any) {
    this.claimsService.clearAuthData();
    const program = JSON.parse(
      localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!
    );
    localStorage.setItem('lead_entry', 'authorizations');
    localStorage.setItem('selected_lead', rowDetail.authorizationId);  //rowDetail.referral_participant_id
    setTimeout(() => {
      this.router.navigate(['/home/authorizations/details/']);
    }, 200);
  }
}
