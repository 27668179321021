import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanYesNo',
  standalone: true
})
export class BooleanYesNoPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== 'boolean') {
      return value;
    }

    return value === true ? 'Yes' : 'No';
  }

}
