<ng-container>
    @if(isPageRestrictionLoaded){
    <div class="ppl-container-body">
        <div class="ppl-container-head">
            Personal Assistant Registration
            <!-- <div class="action">
                <button nz-button nz-dropdown [nzDropdownMenu]="menu4" class="fancy-button" class="primary-button">
                    <span style="font-weight:600">Add Consumer Associate<i class="fa fa-chevron-right" aria-hidden="true" style="margin-left: 4px"></i></span>
                </button>
                <nz-dropdown-menu #menu4="nzDropdownMenu">
                    <ul nz-menu>
                        @for (provider of providerTypes; track $index) {
                            <li nz-menu-item (click)="onNewEnrollment(provider.code)">
                                New {{provider.name}}
                            </li>
                            }
                    </ul>
                </nz-dropdown-menu>
            </div> -->
        </div>
        <div class="ppl-container">
            <div class="ppl-content-container">
                <div class="table-style">
                    <ppl-list-table
                        [pageConfig]="programName"
                        [sharedDataKey]="sharedDataKey"
                        (rowDetailEvent)="rowClicked($event)"
                        [loadPage]="lazyLoad"
                        [columnsToBeHidden]="columnsToBeHidden"
                        [actionsToBeHidden]="actionsToBeHidden"
                        [jsonList]="'list_provider_enrollments'"
                        rowDataAccessibilityKey="ppl_provider_id"
                    >
                    </ppl-list-table>
                </div>
            </div>
        </div>
    </div>
    }

</ng-container>