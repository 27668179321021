<ppl-accordion [title]="'Forms'" [isOpen]="true" *authorize="[pageId, tabId, 'Forms', 'section']">
    <ppl-pdf-form [readonly]="readonly" [isNewReferral]="isNewReferral" (isMandatoryFormsCompleted)="isMandatoryFormsCompleted($event)"></ppl-pdf-form>
</ppl-accordion>

@if(!isNewReferral){
    <ppl-accordion [title]="'Mail Summary'" [isOpen]="true">
        <ppl-list-table [disableSort]="true" [disableSearch]="true" [data]="mailFormsData"
            [disableSelection]="true" [cols]="cols" [gridTitle]="false" [pagination]="false"
            [loadPage]="true">
        </ppl-list-table>
    </ppl-accordion>
}
