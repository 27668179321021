import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule, DatePipe } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import {
  STATIC_JSON_DATA_PATH,
} from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { GridAction } from '../../../../shared/components/list-table/grid-action.model';
import { LookupService } from '../../../../services/lookup.service';
import { ListService } from '../../../../shared/services/list.service';
import { AuthorizationsDetailsService } from '../../../../services/authorizations-service.service';
import { performValidation } from '../../../../shared/utils/validation.util';
import { LoggerService } from '../../../../shared/services/logger.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { FileUploadComponent } from '../../../lead-details/documents/file-upload/file-upload/file-upload.component';
import { LookupValueItem } from '../../../../interfaces/lookup.interface';
@Component({
  selector: 'ppl-auth-forms',
  standalone: true,
  imports: [
    ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    ListTableComponent,
    FileUploadComponent
  ],
  providers: [ListService],
  templateUrl: './auth-forms.component.html',
  styleUrl: './auth-forms.component.css',
})
export class AuthFormsComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  authorizationsForm!: FormGroup;
  providerTypes: any;
  pageTitle = 'Add Authorization';
  mode = 'read';
  savedData: any = {};
  isNew = true;
  isConsumerSelected = false;
  showConsumersList = false;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  cols: any[] = [];
  authCols: any[] = [];
  flow: string | null = '';
  selectedConsumer: any;
  selectedAuth: any;
  showSearchError = false;
  mcoEntries: any;
  showAddedAuths = false;
  modifiersList: any;
  serviceCodesList: any;
  diagnosisCodesList: any;
  authsInSession: any[] = [];
  filedInfo: any;
  selectedLead = 0;
  savedAuthId: any;
  isFormDisabled = false;
  authFrequencyTypeList: LookupValueItem[] = [];
  radioOptions: LookupValueItem[] = [];

  actionButtons: TableRowActionsModel = new TableRowActions({ add: true });

  @Input() formState = 'edit';
  @Input() authData: any;
  @Input() authAdded = false;
  @Input() disableForm = true;
  @Input() from: any;
  @Output() authSubmitted = new EventEmitter<boolean>();
  @Output() authUpdate = new EventEmitter<boolean>();

  authSourceList: any;

  planIdList = [
    {
      lkpValueName: 'Plan 1',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Plan 2',
      lkpValueCode: 'm1',
    },
  ];

  svcAdminList: any;

  unitTypeList: any;

  gridData = [
    {
      consumerFirstName: 'Nikhil',
      consumerLastName: 'Peter',
      medicaid: 'PRV-NY-10000722',
      pplId: 'PPL-NY-10000733',
    },
    {
      consumerFirstName: 'Venkat',
      consumerLastName: 'Paul',
      medicaid: 'PRV-NY-10000745',
      pplId: 'PPL-NY-10000793',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private sectionsService: SectionsService,
    private listService: ListService,
    private authorizationService: AuthorizationsDetailsService,
    private datePipe: DatePipe,
    private logger: LoggerService,
    private sharedService: SharedService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authorizationsForm = this.fb.group({
      consumerFirstName: [''],
      consumerLastName: [''],
      medicaid: [''],
      consumerPPLId: [''],
      pplAuth: [''],
      clientAuth: [''],
      serviceCode: [null],
      diagnosisId: [null],
      primaryDiagnosisId1: [null],
      primaryDiagnosisId2: [null],
      primaryDiagnosisId3: [null],
      planId: [null],
      serviceAdminLkp: [null],
      modifierCode1: [null],
      units: [''],
      unittypeLkp: [null],
      startDate: [''],
      endDate: [''],
      orgName: [null],
      source: [null],
      decisionBy: [null],
      sourceDetail: [null],
      statusLkp: [null],
      decisionComments: [null],
      authFrequencyLkp: [null],
      carryoverUnits: [null],
      sunHrs: [null],
      monHrs: [null],
      tuesHrs: [null],
      wedHrs: [null],
      thuHrs: [null],
      friHrs: [null],
      satHrs: [null],
    });

    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.flow = localStorage.getItem('lead_submission_flow');
    this.selectedAuth = localStorage.getItem('selected_lead');
    this.programName = program.program_code;
    this.secData = this.sectionsService.getSectionData(
      program.program_code,
      'payroll'
    );
    this.getLookupValues();
    console.log('sectionData', this.secData);
    this.providerTypes = this.getLookup('ProviderType');
    this.svcAdminList = this.getLookup('Waiver');
    this.unitTypeList = this.getLookup('Utype');
    this.authSourceList = this.getLookup('AuthS');
    this.authFrequencyTypeList = this.getLookup('AFT');
    this.radioOptions = this.getLookup('Yes_No');
    this.lookupService
      .getOrgData(program.program_code)
      .subscribe((data: any) => {
        console.log('data', data);
        if (data.status == 200) {
          this.mcoEntries = data.responsedata.filter(
            (item: any) => item.orgTypeLookup === 'MCO'
          );
          console.log('mcoEntries', this.mcoEntries);
        }
      });
    this.getGridHeaders();
    this.getAuthGridHeaders();
    this.authorizationsForm
      .get('serviceAdminLkp')
      ?.valueChanges.subscribe((value) => {
        this.authorizationsForm.get('orgName')?.reset();
        this.authorizationsForm.get('planId')?.reset();
        if (value !== 'Health Plan') {
          this.authorizationsForm.get('orgName')?.disable();
          this.authorizationsForm.get('planId')?.disable();
        } else {
          if (this.mode !== 'edit') {
            this.authorizationsForm.get('orgName')?.enable();
            this.authorizationsForm.get('planId')?.enable();
          }
        }
      });

    if (this.disableForm) {
      this.authorizationsForm.disable();
      this.getSelectedAuthDetails();
    }
    if (this.mode === 'edit') {
      this.disableFieldsForEdit();
      this.getSelectedAuthDetails();
    }
    this.authorizationService.getDocUpload().subscribe((docUpload: any) => {
      console.log('docupload status', docUpload);
      if (docUpload) {
        // this.authorizationsForm.reset();
        this.authorizationsForm.disable();
      }
    });
  }

  getLookupValues(): void {
    this.modifiersList =
      this.lookupService.getTasLookupValue('modifierDetailList');
    this.serviceCodesList = this.lookupService.getTasLookupValue(
      'servicecodeDetailList'
    );
    this.diagnosisCodesList = this.lookupService.getTasLookupValue(
      'diagnosisDetailList'
    );
    console.log('modifiers', this.modifiersList);
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes['formState'] && changes['formState'].currentValue) {
        this.mode = changes['formState'].currentValue;
      }
      if (changes['from'] && changes['from'].currentValue) {
        if (this.from === 'add' && this.authorizationsForm) {
          this.authorizationsForm.enable();
        }
      }
      if (changes['authData'] && changes['authData'].currentValue) {
        this.getSelectedAuthDetails();
      }
      if (
        changes['disableForm'] &&
        changes['disableForm'].currentValue !==
          changes['disableForm'].previousValue
      ) {
        if (this.disableForm && this.authorizationsForm) {
          this.authorizationsForm.disable();
          this.getSelectedAuthDetails();
        } else if (!this.disableForm && this.authorizationsForm) {
          this.mode = 'edit';
          this.authorizationsForm.enable();
          this.disableFieldsForEdit();
          this.getSelectedAuthDetails();
        }
      }
    } catch (e) {
      console.log('Error in getting section data and updating latest info', e);
    }
  }

  getSelectedAuthDetails() {
    // API call to fetch the details and patch form
    console.log('auth data', this.authData);
    const formValuesToPatch = {
      ...this.authData,
      serviceCode: this.authData?.serviceCodeId,
      planId: this.authData?.planIdLkp,
      modifierCode1: this.authData?.modifier1,
      startDate: new Date(this.authData?.startDate),
      endDate: this.authData?.endDate ? new Date(this.authData?.endDate) : null,
      orgName: this.authData?.orgId,
      carryoverUnits:
        this.authData?.carryoverUnits === true
          ? 'Yes'
          : this.authData?.carryoverUnits === false
          ? 'No'
          : this.authData?.carryoverUnits,
    };

    this.authorizationsForm?.patchValue(formValuesToPatch);
  }

  disabledStartDate = (startValue: Date): boolean => {
    const endDate = this.authorizationsForm.get('endDate')?.value;
    if (!startValue || !endDate || !(endDate instanceof Date)) {
      return false;
    }
    return startValue.getTime() > endDate.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    const startDate = this.authorizationsForm.get('startDate')?.value;
    if (!endValue || !startDate || !(startDate instanceof Date)) {
      return false;
    }
    return endValue.getTime() <= startDate.getTime();
  };

  disableFieldsForEdit() {
    const fieldsToKeepEnabled = [
      'units',
      'unittypeLkp',
      'startDate',
      'endDate',
    ];
    const formControls = Object.keys(this.authorizationsForm.controls);

    formControls.forEach((control) => {
      if (!fieldsToKeepEnabled.includes(control)) {
        this.authorizationsForm.get(control)?.disable();
      }
    });
  }

  onConsumerSearch() {
    const formData = this.authorizationsForm.value;
    const from = '';
    console.log('formData', formData);
    this.showSearchError = !(
      formData.consumerFirstName.trim() !== '' ||
      formData.consumerLastName.trim() !== '' ||
      formData.medicaid.trim() !== '' ||
      formData.consumerPPLId.trim() !== ''
    );
    // this.showConsumersList = !this.showSearchError;
    if (!this.showSearchError) {
      const payload = this.buildRequestPayload();
      this.listService.getListData(payload, from).subscribe((data: any) => {
        console.log('search data', data);
        this.gridData = data.responsedata;
        this.showConsumersList = true;
      });
    }
  }

  buildRequestPayload(event?: any) {
    const formData = this.authorizationsForm.value;
    const payload: any = {
      pageName: 'consumer-search',
      programCode: this.programName,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: event ? (event?.startingPageNumber).toString() : '1',
      searchParam: [],
      orderByColumn: [],
      orderBy: [],
    };
    let searchParams = [];
    if (formData.consumerFirstName) {
      searchParams.push({
        jsonPath: 'consumerFirstName',
        value: formData.consumerFirstName,
        operator: 'equal',
      });
    }

    if (formData.consumerLastName) {
      searchParams.push({
        jsonPath: 'consumerLastName',
        value: formData.consumerLastName,
        operator: 'equal',
      });
    }

    if (formData.medicaid) {
      searchParams.push({
        jsonPath: 'medicaid',
        value: formData.medicaid,
        operator: 'equal',
      });
    }

    if (formData.consumerPPLId) {
      searchParams.push({
        jsonPath: 'consumerPPLId',
        value: formData.consumerPPLId,
        operator: 'equal',
      });
    }
    payload.searchParam = searchParams;
    return payload;
  }

  async getGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.AUTHORIZATIONS_CONSUMER_LIST_HEADERS,
          this.flow
        );
        console.log(tempCols.columns);
        this.cols = tempCols.columns;
      }
    } catch (e) {}
  }

  async getAuthGridHeaders() {
    try {
      if (this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.AUTHORIZATIONS_LIST_HEADERS,
          this.flow
        );
        console.log(tempCols.columns);
        this.authCols = tempCols.columns;
      }
    } catch (e) {}
  }

  onConsumerSelect() {}

  action(event: GridAction) {
    console.log('event');
    if (event && event.action === 'add') {
      console.log(event.data);
      this.selectedConsumer = event.data;
      this.isConsumerSelected = true;
      this.mode = 'add';
      this.showConsumersList = false;
    }
  }

  onCancelUser() {
    this.isConsumerSelected = false;
    this.selectedConsumer = {};
    this.authorizationsForm.reset();
    this.showAddedAuths = false;
    this.authsInSession = [];
  }

  onAdd() {
    console.log('Adding!!');
    this.saveAuthDetails('submit');
  }

  saveAuthDetails(submitMode: string) {
    const formData = this.authorizationsForm.value;
    const data = { ...this.authorizationsForm.value };

    data.startDate = this.dateFormatter.formatToString(data.startDate);

    if (data.endDate) {
      data.endDate = this.dateFormatter.formatToString(data.endDate);
    }
    console.log('section data', this.secData);
    console.log('formData', data);
    try {
      this.validationErrors = performValidation(data, this.secData, 'Submit');
      console.log('validationErrors', this.validationErrors);
    } catch (e) {
      this.logger.debug('Error in validations', e);
    }
    if (Object.keys(this.validationErrors).length === 0) {
      const payload = {
        ...formData,
        modifier1: formData.modifierCode1,
        startDate: this.datePipe.transform(formData.startDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(formData.endDate, 'yyyy-MM-dd'),
        orgId: formData.orgName,
        participantId: this.selectedConsumer.participantId,
        medicaidId: this.selectedConsumer.medicaid,
        billableRate: 0,
        planIdLkp: formData.planId,
        carryoverUnits:
          formData.carryoverUnits === 'Yes'
            ? true
            : formData.carryoverUnits === 'No'
            ? false
            : formData.carryoverUnits,
      };
      console.log('payload for save', payload);
      this.authorizationService
        .saveAuthorization(this.programName, payload, true)
        .subscribe((data: any) => {
          console.log('saved auth', data);
          this.savedAuthId = data.responsedata.authorizationId;
          if (data.status == 200) {
            // this.authAdded = true;
            this.authSubmitted.emit(true);
          }

          // if (submitMode === 'submit') {
          //   this.showAddedAuths = false;
          // } else {
          //   this.mode = 'add';
          //   this.authorizationsForm.reset();
          //   this.authsInSession = [...this.authsInSession, data.responsedata];
          //   console.log(this.authsInSession);
          //   this.showAddedAuths = true;
          // }
        });
    } else {
      return;
    }
  }

  onAddMoreAuths() {
    this.saveAuthDetails('Add more');
  }

  onCancelUpdate() {
    this.validationErrors = {};
    // this.authorizationsForm.reset();
    this.authUpdate.emit(true);
  }

  onUpdate() {
    const formData = this.authorizationsForm.getRawValue();
    try {
      this.validationErrors = performValidation(
        formData,
        this.secData,
        'Submit'
      );
      console.log('validationErrors', this.validationErrors);
    } catch (e) {
      this.logger.debug('Error in validations', e);
    }
    if (Object.keys(this.validationErrors).length === 0) {
      const payload = {
        ...formData,
        authorizationId: this.selectedAuth,
        units: formData.units,
        unittypeLkp: formData.unittypeLkp,
        startDate: this.datePipe.transform(formData.startDate, 'yyyy-MM-dd'),
        endDate: this.datePipe.transform(formData.endDate, 'yyyy-MM-dd'),
        carryoverUnits:
          formData.carryoverUnits === 'Yes'
            ? true
            : formData.carryoverUnits === 'No'
            ? false
            : formData.carryoverUnits
      };
      this.authorizationService
        .updateAuthorizationDetails(
          this.programName,
          this.selectedAuth,
          payload
        )
        .subscribe((data: any) => {
          console.log('updated auth', data);
          this.authData = data.responsedata;
          this.getSelectedAuthDetails();
          this.onCancelUpdate();
        });
    } else {
      return;
    }
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];
  }

  filesUpdated() {
    console.log('Files List update');
    this.getDocumentsUploaded(true);
  }

  getDocumentsUploaded(hasUpdatedEntry?: boolean) {
    this.sharedService
      .getUploadedFileDetails(
        this.programName,
        this.savedAuthId,
        'auth',
        hasUpdatedEntry
      )
      .subscribe((data: any) => {
        console.log(data);
        this.filedInfo = data?.responsedata;
      });
  }
}
