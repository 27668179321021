import { Component, OnInit } from '@angular/core';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzInputModule } from 'ng-zorro-antd/input';
import { BaseComponent } from '../../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CommonModule } from '@angular/common';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { RADIO_YES_NO_OPTIONS, STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SectionsService } from '../../../../services/sections.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { Router } from '@angular/router';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { AuthUtilizationComponent } from "../auth-utilization/auth-utilization.component";
import { AuthTimesheetsComponent } from "../auth-timesheets/auth-timesheets.component";
import { ListTableComponent } from '../../../../shared/components/list-table/list-table.component';
import { TableRowActionsModel } from '../../../../shared/interfaces/list.interface';
import { TableRowActions } from '../../../../shared/interfaces/actions';
import { GridAction } from '../../../../shared/components/list-table/grid-action.model';
import { AuthFormsComponent } from "../auth-forms/auth-forms.component";
import { AuthorizationsDetailsService } from '../../../../services/authorizations-service.service';
import { ListService } from '../../../../shared/services/list.service';
import { AuthorizationDocumentsComponent } from '../authorization-documents/authorization-documents.component';
import { LookupService } from '../../../../services/lookup.service';
import { ClaimsService } from '../../../../services/claims.service';
import { AuthAdditionalDetailsComponent } from '../auth-additional-details/auth-additional-details.component';
@Component({
  selector: 'ppl-authorization-details',
  standalone: true,
  imports: [ButtonComponent,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzCardModule,
    NzInputModule,
    NzToolTipModule,
    CommonModule,
    NzDatePickerModule,
    NzToolTipModule,
    NzSelectModule,
    NzButtonModule,
    NzCollapseModule,
    AccordionComponent,
    AuthUtilizationComponent,
    AuthTimesheetsComponent,
    ListTableComponent, 
    AuthFormsComponent,
  AuthorizationDocumentsComponent,
AuthAdditionalDetailsComponent],
  templateUrl: './authorization-details.component.html',
  styleUrl: './authorization-details.component.css'
})
export class AuthorizationDetailsComponent extends BaseComponent implements OnInit  {
  authorizationsForm!: FormGroup;
  radioOptions = RADIO_YES_NO_OPTIONS;
  providerTypes: any;
  pageTitle = 'Add Authorization';
  mode = 'read';
  savedData: any = {};
  isNew = true;
  isParticipantSelected = false;
  isConsumerSelected = false;
  showConsumersList = false;
  programName!: string;
  columnsToBeHidden: any;
  sharedDataKey: any;
  actionsToBeHidden: any;
  lazyLoad = true;
  cols: any[] = [];
  flow: string|null = '';
  selectedConsumer: any;
  selectedAuthId: any;
  diagnosisCodesList: any;
  filledDiagnosisValue = '';
  disableForm = true;
  actionButtons: TableRowActionsModel = new TableRowActions({ add: true });

authData: any;
  participants = [
    {
      lkpValueName: 'Manoj Kumar',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Neha Dogra',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Venkat N',
      lkpValueCode: 'm1',
    }
  ];

  providers = [
    {
      lkpValueName: 'Provider Manoj Kumar',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Provider Neha Dogra',
      lkpValueCode: 'm1',
    },
    {
      lkpValueName: 'Provider Venkat N',
      lkpValueCode: 'm1',
    }
  ];

  gridData = [
    {
        consumerFirstName: 'Nikhil',
        consumerLastName: 'Peter',
        medicaid: 'PRV-NY-10000722',
        pplId: 'PPL-NY-10000733',

    },
    {
        consumerFirstName: 'Venkat',
        consumerLastName: 'Paul',
        medicaid: 'PRV-NY-10000745',
        pplId: 'PPL-NY-10000793',
    },
  ]

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private sectionsService: SectionsService,
    private listService: ListService,
    private lookupService: LookupService,
    private authorizationService: AuthorizationsDetailsService,
    private claimsService: ClaimsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.authorizationsForm = this.fb.group({
        firstName: [[]],
        lastName: [[]],
        medicaid: [[]],
        pplId: [[]],
        plAuthNumber: [[]],
        clientAuthNumber: [[]],
        hcpcsServiceCode: [[]],
        modifier1: [null],
        modifier2: [null],
        modifier3: [null],
        modifier4: [null],
        units: [[]],
        dollars: [[]],
        authStartDate: [[]],
        authEndDate: [[]],
        mcoId: [[]],
    });

    const program = JSON.parse(localStorage.getItem('selected_program')!);
    this.flow = localStorage.getItem('lead_submission_flow');
    this.selectedAuthId = localStorage.getItem('selected_lead');
    this.programName = program.program_code;
    this.secData = this.sectionsService.getSectionData(
      program.program_code,
      'payroll'
    );
    this.providerTypes = this.getLookup('ProviderType');
    this.diagnosisCodesList = this.lookupService.getTasLookupValue(
      'diagnosisDetailList'
    );

    this.claimsService.authUpdated.subscribe((val)=>{
      if(val) {
        this.getAuthDetails();
      }
    })
    this.getGridHeaders();
    this.getAuthDetails();

  }

  returnClicked() {
    console.log('going back from process codes');
    this.router.navigate(['/home/authorizationsList']);
  }
  
  goBack() {
    this.router.navigate(['/home/authorizationsList']);
  }

  onEdit() {
    this.mode = 'edit';
    this.disableForm = false;
    //patch form here;
  }

  onViewSplit(){
    this.claimsService.setPopUpState('authSplit')
  }

  onConsumerSearch() {
    this.showConsumersList = true;
  }

  async getGridHeaders() {
    try {
        if(this.flow) {
        const tempCols = await this.sectionsService.getJsonConfigurationData(STATIC_JSON_DATA_PATH.AUTHORIZATIONS_CONSUMER_LIST_HEADERS, this.flow);
        console.log(tempCols.columns);
        this.cols = tempCols.columns;
        }
    } catch (e) {
    }

  }

  getAuthDetails() {
    // this.authorizationService.getAuthorizationDetails(this.programName, this.selectedAuthId).subscribe((data: any) => {
    //   console.log('details', data);
    // })

    const payload = this.buildRequestPayload();
    const from = '';
      this.listService.getListData(payload, from).subscribe((data: any) => {
        console.log('search data', data);
        this.authData = data.responsedata[0];
        this.filledDiagnosisValue = this.diagnosisCodesList.find((diagnosis:any) => diagnosis.diagnosisDetailId === this.authData?.diagnosisId).description;

        //this.gridData = data.responsedata;
      });

  }

  buildRequestPayload(event?: any) {
    const formData = this.authorizationsForm.value;
    const payload: any = {
      pageName: 'consumer-auth',
      programCode: this.programName,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: '1',
      searchParam: [{
        jsonPath: 'authorizationId',
        value: this.selectedAuthId,
        operator: 'equal'
      }],
      orderByColumn: [],
      orderBy: [],
    }

    return payload;
  }

  action(event: GridAction) {
    if (event && event.action === 'add') {
        console.log(event.data);
        this.selectedConsumer = event.data;
        this.isConsumerSelected = true;
        this.showConsumersList = false;
    }

  }

  onAuthUpdate(event: any) {
    console.log('update event!', event);
    this.mode = 'read';
    this.disableForm = true;
    if(event) {
      this.getAuthDetails();
    }
  }

  onAdd() {
    console.log('Adding!!');
  }

  onCancel() {
    this.mode = 'read';
    this.disableForm = true;
    this.validationErrors = {};
    this.authorizationsForm.reset();
  }

  onUpdate() {
    console.log('Updating!!');
    this.mode = 'read';
    this.disableForm = true;
    this.validationErrors = {};
    this.authorizationsForm.reset();
  }

  viewerValueChange(value: string) {
    const viewerValue = [value][0];
    this.isParticipantSelected = true;
  }
}
