import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AccordionComponent } from "../../../../shared/components/accordion/accordion.component";
import { AuthorizeDirective } from '../../../../shared/authorization/authorize.directive.';
import { STATIC_DATA } from '../../../../shared/constants/static-data.constants';
import { Program } from '../../../../interfaces/user.interface';
import { LeadDetailsService } from '../../../../services/lead-details.service';
import { STATIC_JSON_DATA_PATH } from '../../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../../services/sections.service';
import { EnrollmentSummaryResponseItem, SummaryItem } from '../../../../shared/interfaces/lead-details.service-types';
import { ChartModule } from 'primeng/chart';
import { NgZorroModule } from '../../../../shared/modules/ng-zorro/ng-zorro.module';
import { MEMBER_VIEW_PAGE_IDS } from '../../../../shared/constants/page.constants';
import { EditpdfComponent } from "../../../editpdf/editpdf.component";
import { PDFFormConfig } from '../../../../interfaces/config';
import { DialogConfig, DialogService } from '../../../../shared/services/dialog.service';
import { CheckListService } from '../../../../services/check-list.service';
import { ChecklistItem, DownloadFormPayload, DownloadFormPayloadItem } from '../../../../interfaces/checklist.model';
import { HttpResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ppl-lead-detail-summary',
  standalone: true,
  imports: [AccordionComponent, AuthorizeDirective, ChartModule, NgZorroModule, EditpdfComponent, CommonModule],
  templateUrl: './lead-detail-summary.component.html',
  styleUrl: './lead-detail-summary.component.css'
})
export class LeadDetailSummaryComponent implements OnInit {
fieldsUpdated($event: any) {
throw new Error('Method not implemented.');
}
  private dialogSvc = inject(DialogService);
  @ViewChild('pdfDialog') pdfDialogRef!: TemplateRef<any>;

  pageId = '';
  tabId = 'Summary';
  entry: string | null = '';
  selectedLead = 0;
  program!: Program;
  staticSummaryItems: SummaryItem[] = [];
  summaryItems: SummaryItem[] = [];
  staticAuthorizationItems: SummaryItem[] = [];
  authorizationItems: SummaryItem[] = [];
  staticApplicationInfoItems: SummaryItem[] = [];
  applicationInfoItems: SummaryItem[] = [];
  chartData: any;
  chartOptions: any;
  totalEarnings !: number;
  services = [
    {
      label: 'Support Broker Services',
      value: 'Support Broker Services'
    }
  ]

  participants = [
    {
      label: 'Nikhil',
      value: 'Nikhil'
    }
  ]

  payperiods = [
    {
      label: 'Nov 2, 2024 - Nov 14, 2024',
      value: 'Nov 2, 2024 - Nov 14, 2024'
    }
  ];
  pdfConfig?: PDFFormConfig;
  pdfFile?: string;
  pdfBlob?: Blob;
  showModal = false;
  modalBody = {
    associationModal: '',
    pdfViewer: ''
  };
  isParticipantFlow = false;
  associationMap: Record<string, string> = {};
  isDataLoaded: boolean = false;

  constructor(
    private leadDetailsService: LeadDetailsService,
    private sectionService: SectionsService,
    private checkListService: CheckListService
  ) { }


  ngOnInit(): void {
    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
    this.entry = localStorage.getItem('lead_entry');
    this.isParticipantFlow = localStorage.getItem('lead_entry') == 'participantEnrollment' || localStorage.getItem('lead_submission_flow') == 'participantEnrollment';
    this.selectedLead = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);
    this.program = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_PROGRAM)!);
    this.getStaticSummaryItems();

    this.chartData = {
      labels: ['Submitted', 'Approved', 'Processing for Payment', 'Paid'],
      datasets: [
        {
          data: [0, 0, 1628.8, 254.5],
          backgroundColor: ['#b44bff', '#ffbd3e', '#4abaff', '#ff5f47'],
          hoverBackgroundColor: ['#b44bff', '#ffbd3e', '#4abaff', '#ff5f47']
        }
      ]
    };

    // Calculate total earnings
    this.totalEarnings = this.chartData.datasets[0].data.reduce((a: number, b: number) => a + b, 0);

    // Chart options configuration
    this.chartOptions = {
      plugins: {
        datalabels: {
          display: true,
          color: '#000', // Set label color
          font: {
            weight: 'bold',
            size: 14 // Customize font size as needed
          },
          formatter: (value: any, context: any) => `${context.chart.data.labels[context.dataIndex]}: $${value.toFixed(2)}`
        },
        legend: {
          position: 'right',
          labels: {
            font: {
              size: 14
            },
            color: '#666',
            formatter: (value: any, context: any) => `${context.chart.data.labels[context.dataIndex]}: $${value.toFixed(2)}`
          }
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem: any) => {
              const label = this.chartData.labels[tooltipItem.dataIndex];
              const value = this.chartData.datasets[0].data[tooltipItem.dataIndex];
              return `$${value} ${label}`;
            }
          }
        }
      }
    };

  }

  fetchSummaryItemCountData() {
    const userType = this.entry === 'participantEnrollment' ? 'participant' : 'provider';
    this.leadDetailsService.getEnrollmentSummary(this.program.program_code, this.selectedLead, userType).subscribe({
      next: (data: any) => {
        console.log("summary response ", data);
        const associationItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.associations;
        const authorizationItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.authorizations;

        const applicationInfoItems: EnrollmentSummaryResponseItem[] = data?.responsedata?.applicationInfo;

        console.log("association Items ", associationItems);
        console.log("authorization Items ", authorizationItems);
        console.log("applicationInfo Items ", applicationInfoItems);

        let associationValueMap: Record<string, string> = {};
        let authorizationValueMap: Record<string, string> = {};

        let applicationValueMap: Record<string, string> = {};

        associationValueMap = associationItems.reduce((acc, cur) => {
          acc[cur.typeCode] = cur.typeValue;
          return acc;
        }, associationValueMap);

        this.associationMap = associationValueMap;

        console.log("associationValueMap items ", associationValueMap);
        console.log("staticSummary Items ", this.staticSummaryItems);

        this.summaryItems = this.staticSummaryItems.map(item => {
          return {
            ...item,
            summaryItemValue: associationValueMap[item.summaryItemKey] || 0
          }
        });

        console.log("UI summary items ", this.summaryItems);

        // participant specific properties
        if (authorizationItems) {
          console.log("INSIDE authorizationItems");

          authorizationValueMap = authorizationItems.reduce((acc, cur) => {
            acc[cur.typeName] = cur.typeValue;
            return acc;
          }, authorizationValueMap);

          console.log("INSIDE authorizationItems", authorizationValueMap);
          console.log("INSIDE authorizationItems", this.staticAuthorizationItems);

          this.authorizationItems = this.staticAuthorizationItems.map(item => {
            return {
              ...item,
              summaryItemValue: authorizationValueMap[item.summaryItemDisplayName] || 0
            }
          });
        }

        console.log("UI authorization Items ", this.authorizationItems);

        // participant specific properties
        if (applicationInfoItems) {
          console.log("INSIDE applicationInfoItems");
          applicationValueMap = applicationInfoItems.reduce((acc, cur) => {
            acc[cur.typeName] = cur.typeValue;
            return acc;
          }, applicationValueMap);

          console.log("INSIDE applicationInfoItems", applicationValueMap);
          console.log("INSIDE applicationInfoItems", this.staticApplicationInfoItems);

          this.applicationInfoItems = this.staticApplicationInfoItems.map(item => {
            return {
              ...item,
              summaryItemValue: applicationValueMap[item.summaryItemDisplayName] || '-'
            }
          })
        }

        console.log("UI applicationInfo Items ", this.applicationInfoItems);

        this.isDataLoaded = true;
      },
      error: () => {
        console.log("error occured during calculating summary", this.staticSummaryItems);
        this.summaryItems = [...this.staticSummaryItems];
        this.isDataLoaded = true;
      }
    })
  }

  async getStaticSummaryItems() {
    const summaryItemsData = await this.sectionService.getJsonConfigurationData(
      STATIC_JSON_DATA_PATH.ENROLLMENT_SUMMARY_ITEMS,
      this.entry
    );
    this.staticSummaryItems = summaryItemsData.summaryItems as SummaryItem[];
    this.staticAuthorizationItems = summaryItemsData.authorizationsItems as SummaryItem[];
    this.staticApplicationInfoItems = summaryItemsData.applicationInfoItems as SummaryItem[];
    this.fetchSummaryItemCountData();
  }

  viewWelcomeLetter() {
    this.showModal = true;
    this.modalBody.pdfViewer = 'Welcome Letter';

    const form = {
      formMasterId: this.entry === 'participantEnrollment' ? 71 : 72,
      formDesc: 'Welcome Letter',
      form_name: 'Welcome Letter'
    };

    this.pdfConfig = {
      form_id: form.formMasterId + '',
      is_mandatory: false,
      form_description: form.formDesc,
      form_name: form.form_name,
      fields: []
    };
    const dialogConfig: DialogConfig = {
      title: this.modalBody.pdfViewer,
      isOkText: 'Save',
      size: 'xx-large',
      position: 'top',
      showClose: true,
      showButtons: false,
    }

    this.dialogSvc.openDialog(this.pdfDialogRef, dialogConfig, () => this.handleCancel(), () => this.handleCancel());


    this.loadPDF(form);
  }

  handleCancel() {
    this.showModal = false;
    this.pdfConfig = undefined;
    this.pdfFile = undefined;
  }

  loadPDF(form: any) {
    this.checkListService.getPDF(this.program?.program_code, form.formMasterId, 'eng').subscribe((response: HttpResponse<Blob>) => {
      if (response.body) {
        const blob = new Blob([response.body], { type: response.body.type });
        this.pdfBlob = blob;
        this.pdfFile = window.URL.createObjectURL(blob);
      }
    });
  }

  
  handleDownload() {
    const pdfPayload: DownloadFormPayloadItem[] = [];
    const forms = [{
      formMasterId: this.entry === 'participantEnrollment' ? 71 : 72,
      formDesc: 'Welcome Letter',
      form_name: 'Welcome Letter'
    }];

    forms.forEach(f => {
      if (f.formMasterId != 0) {
        const payload: any = {
          formId: f.formMasterId + '',
          programCode: this.program?.program_code,
          languageCode: 'ENG',
        };

        pdfPayload.push(payload);
      }
    });

    const formPayload: DownloadFormPayload = {
      forms: [...pdfPayload]
    };

    this.checkListService.downloadPDFForm(formPayload, "Welcome_Letter" + '_forms.zip');
  }
}
