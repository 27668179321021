/* src/app/shared/components/cdpap-form/cdpap-form.component.css */
.cdpap-td {
  display: flex;
  gap: 4px;
}
.back-btn-wrapper {
  width: fit-content;
  margin-left: auto;
  margin-top: 10px;
  position: sticky;
  bottom: 5px;
  right: 5px;
  z-index: 99;
}
.checkmark-icon {
  margin-left: 2px;
}
.data-wrapper {
  margin-right: 3px;
}
/*# sourceMappingURL=cdpap-form.component-PE2D4C26.css.map */
