[
    {
      "stepname_id": 1,
      "step_name": "Basic Information",
      "isAvailable": "Y",
      "step_id": "participant_info",
      "isMandatory": "Y"
    }, 
    {
      "stepname_id": 2,
      "step_name": "Contact Details",
      "isAvailable": "Y",
      "step_id": "contact_details",
      "isMandatory": "Y"
    },
    {
      "stepname_id": 3,
      "step_name": "Communication Preferences",
      "isAvailable": "Y",
      "step_id": "comm_preferences",
      "isMandatory": "Y"
    },
    {
      "stepname_id": 4,
      "step_name": "Consumer Associations",
      "isAvailable": "Y",
      "step_id": "associations",
      "isMandatory": "N"
    },
    {
      "stepname_id": 5,
      "step_name": "Signature",
      "isAvailable": "Y",
      "step_id": "signatures",
      "isMandatory": "N"
    },
    {
      "stepname_id": 6,
      "step_name": "Forms",
      "isAvailable": "Y",
      "step_id": "forms",
      "isMandatory": "N"
    },
    {
      "stepname_id": 7,
      "step_name": "Checklist",
      "isAvailable": "N",
      "step_id": "checklist",
      "isMandatory": "N"
    },
    {
      "stepname_id": 8,
      "step_name": "Required Documents",
      "isAvailable": "Y",
      "step_id": "upload_docs",
      "isMandatory": "N"
    },
    {
      "stepname_id": 9,
      "step_name": "Payment Details",
      "isAvailable": "N",
      "step_id": "payment_details",
      "isMandatory": "N"
    },
    {
      "stepname_id": 10,
      "step_name": "Summary",
      "isAvailable": "Y",
      "step_id": "summary",
      "isMandatory": "N"
    }
  ]
  