<ppl-accordion [title]="'Application Summary'" [isOpen]="true" *authorize="[pageId, tabId, 'Summary', 'section']">
    <div class="tile-grid">

        @if (isDataLoaded) {
            @if(isParticipantFlow || (associationMap && associationMap['IR'])) {
            <div class="tile">
                <h4>Welcome Letter</h4>
                <div class="button-container">
                    <a class="view-welcome-letter btn btn-info" (click)="viewWelcomeLetter()">View</a>
                    <a class="download-welcome-letter btn btn-primary" (click)="handleDownload()">Download</a>
                </div>
            </div>
            }

            @for (appInfo of applicationInfoItems; track appInfo; let idx = $index ) {
            <div class="tile">
                <h4>{{appInfo.summaryItemDisplayName}}</h4>
                <p class="count">{{appInfo.summaryItemValue}}</p>
            </div>
            }


            @for (summaryItem of summaryItems; track summaryItem; let idx = $index ) {
            <div class="tile">
                <h4>{{summaryItem.summaryItemDisplayName}}</h4>
                <p class="count">{{summaryItem.summaryItemValue}}</p>
            </div>
            }

            @for (auth of authorizationItems; track auth; let idx = $index ) {
            <div class="tile">
                <h4>{{auth.summaryItemDisplayName}}</h4>
                <p class="count">{{auth.summaryItemValue}}</p>
            </div>
            }
        }
    </div>
</ppl-accordion>



<div class="filter-panel">

    <ng-template #pdfDialog let-cancel="cancel">
        <div class="" *ngIf="pdfConfig && pdfFile">
            <ppl-editpdf (fieldsUpdated)="fieldsUpdated($event)" [readonly]="true" [pdfConfig]="pdfConfig"
                [signature]="" [pdfBlob]="pdfBlob" [pdfFile]="pdfFile" [signatureId]=""
                [isParticipantFlow]="isParticipantFlow" [selectedParticipantId]="selectedLead"
                [isSelf]="true"></ppl-editpdf>
        </div>
    </ng-template>
</div>