import { CommonModule, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzI18nService, en_US } from 'ng-zorro-antd/i18n';
import { ReferralDataService } from '../../../services/referral-data.service';
import { SectionsService } from '../../../services/sections.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../shared/utils/base.component';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCardModule } from 'ng-zorro-antd/card';
import { UploadComponent } from '../../../shared/components/upload/upload.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { performValidation } from '../../../shared/utils/validation.util';
import { DAYS_OF_WEEK, RADIO_YES_NO_OPTIONS, STATIC_DATA, STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { ListTableComponent } from '../../../shared/components/list-table/list-table.component';

@Component({
  selector: 'ppl-service-details',
  standalone: true,
  imports: [
    NzToolTipModule,
    NzSelectModule,
    NzFormModule,
    NzInputModule,
    NzTimePickerModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzCardModule,
    UploadComponent,
    NzRadioModule,
    NzIconModule,
    NzButtonModule,
    NzToolTipModule,
    CommonModule,
    ListTableComponent
  ],
  templateUrl: './service-details.component.html',
  styleUrl: './service-details.component.css',
})
export class ServiceDetailsComponent extends BaseComponent implements OnInit {
  servicesForm!: FormGroup;
  private navigationSubscription: Subscription | undefined;
  @Input() mode = 'edit';
  @Input() source?: string = '';
  filledInfo: any = {};
  override daysOfWeek: string[] = DAYS_OF_WEEK;
  services: any[] = [];
  licences: any[] = [];
  formHasBeenFilled = false;
  radioOptions = RADIO_YES_NO_OPTIONS;
  fileList: any;
  flow: string | null = '';
  cols: any[] = [];
  leadId: string | null = '';
  formReadonly = false;
  prospectiveParticipantsErrors: any[]=[];

  constructor(
    private fb: FormBuilder,
    private i18n: NzI18nService,
    private datePipe: DatePipe,
    private referralDataService: ReferralDataService,
    private sectionService: SectionsService
  ) {
    super();
  }

  ngOnInit(): void {

    this.getFileCols();
    this.formReadonly = this.referralDataService.getAssociationsEditable();

    this.servicesForm = this.fb.group({
      profLicences: [[]],
      servicesRendered: [[]],
      availabilityDays: this.fb.array(
        this.daysOfWeek.map(() => this.fb.control(false))
      ),
      dayEmergencyAvailablityStart: [null],
      dayEmergencyAvailablityEnd: [null],
      nightEmergencyAvailablityStart: [null],
      nightEmergencyAvailablityEnd: [null],
      distanceWillingToTravel: [null],
      availabilityTimingStart: [null],
      availabilityTimingEnd: [null],
      isAvailableForEmergencies: [null],
      prospectiveParticipants: this.fb.array([this.createRow()]),
      filesUploaded: [[]],
      consent: [this.formReadonly]
    });

    this.flow = this.sectionService.getFlow();
    const associationFlows = ['employer', 'authRepresentative', 'provider', 'vendor', 'contractor', 'legalGuardian', 'poa'];
    

    if(this.formReadonly){
      this.servicesForm.disable()
    } else {
      this.servicesForm.enable()
    }

    this.i18n.setLocale(en_US);
    const program = JSON.parse(localStorage.getItem('selected_program')!);
    let steps: any;
    if (this.flow === 'providerEnrollment') {
      steps = this.sectionService.getProviderSectionData(
        program.program_code,
        STATIC_DATA.SERVICE_DETAILS
      );
      this.secData = steps;
    } else {
      steps = this.sectionService.getSectionData(
        program.program_code,
        this.source
      );
      this.secData = steps[STATIC_DATA.SERVICE_DETAILS];
    }

    this.leadId = this.flow == 'providerEnrollment' ? localStorage.getItem('providerId') : localStorage.getItem('participantId');
    this.navigationSubscription = this.servicesForm.valueChanges.subscribe(
      () => {
        this.fileList = this.servicesForm.value.filesUploaded;
        const hasValue = this.hasAnyValueInForm(this.servicesForm);
        this.formHasBeenFilled = hasValue;

        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isServiceFormUpdated',
            this.formHasBeenFilled
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isServiceFormUpdated',
            this.formHasBeenFilled);
        }
        // this.referralDataService.setReferralFormData(
        //   'isServiceFormUpdated',
        //   this.formHasBeenFilled
        // );
        const selectedDays = this.getDaysSelected(this.servicesForm.value.availabilityDays);
        this.servicesForm.value.availabilityDays = selectedDays;

        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'serviceDetailsData',
            this.servicesForm.value
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'serviceDetailsData',
            this.servicesForm.value
          );
        }

        // this.referralDataService.setReferralFormData(
        //   'serviceDetailsData',
        //   this.servicesForm.value
        // );
        // this.referralDataService.setReferralFormData('isServiceFormDirty', this.servicesForm.dirty);
        this.referralDataService.setChanged(!hasValue ? false : this.servicesForm.dirty);

        if (!associationFlows.includes(this.flow ?? '')) {
          this.referralDataService.setReferralFormData(
            'isServiceFormDirty',
            !hasValue ? false : this.servicesForm.dirty
          );
        } else {
          this.referralDataService.setAssociationFormData(
            'isServiceFormDirty',
            !hasValue ? false : this.servicesForm.dirty
          );
        }
        // this.referralDataService.setReferralFormData(
        //   'isServiceFormDirty',
        //   !hasValue ? false : this.servicesForm.dirty
        // );
      }
    );

    if(associationFlows.includes(this.flow ?? '')) {
      const filledData = this.referralDataService.getAssociationDetails(
        'serviceDetailsData'
      )!;
      if (filledData) {
        this.servicesForm.patchValue(filledData);
      }
    }
    if (((this.flow == 'referral' && localStorage.getItem('lead_entry') == 'referral') || this.flow === 'participantEnrollment' || this.flow === 'providerEnrollment') && !this.referralDataService.getReferralFormData().serviceDetailsData) {
      const filledData =
        this.referralDataService.getReferralDetails('serviceDetailsData', this.flow)!;
      if (filledData) {
        this.servicesForm.patchValue(filledData);
      }
    }

    if (!associationFlows.includes(this.flow ?? '')) {
      if (this.referralDataService.getReferralFormData().serviceDetailsData) {
        this.filledInfo =
          this.referralDataService.getReferralFormData().serviceDetailsData;
        if (
          this.filledInfo.prospectiveParticipants &&
          this.filledInfo.prospectiveParticipants.length
        ) {
          const infoArray = this.servicesForm.get(
            'prospectiveParticipants'
          ) as FormArray;
          infoArray.clear();
          this.filledInfo.prospectiveParticipants.forEach((participant: any) => {
            infoArray.push(
              this.fb.group({
                firstName: [participant.firstName || ''],
                lastName: [participant.lastName || ''],
                phoneNum: [participant.phoneNum || '']
                // code for validation
                // phoneNum: [
                //   participant.phoneNum || '',
                //   [
                //     Validators.pattern(/^[0-9]{10}$/)
                //   ]
                // ]
              })
            );
          });
        }
        const availabilityDays = this.daysOfWeek.map((day: any) => this.filledInfo.availabilityDays?.includes(day));
        const updatedValues = {
          ...this.filledInfo,
          availabilityDays
        };
        this.servicesForm.patchValue(updatedValues);
      }
    }

    this.services = this.getLookup('Service_Type');
    this.licences = this.getLookup('License_Type');
    if (associationFlows.includes(this.flow ?? '')) {
      this.referralDataService.associationSaveClicked.subscribe(() => {
        this.validationErrors = performValidation(
          this.servicesForm.value,
          this.secData,
          'Next'
        );
        this.referralDataService.setError(this.validationErrors);
        this.referralDataService.setAssociationFormData(
          'isServiceFormValid',
          Object.keys(this.validationErrors).length == 0
        );
      });
    }

    this.referralDataService.nextClicked.subscribe((val: any) => {

      
      if(val === STATIC_DATA.SERVICE_DETAILS){
      this.validationErrors = performValidation(
        this.servicesForm.value,
        this.secData,
        'Next'
      );
      

      const prospectiveParticipants = this.validatePhoneNumber();

      if(prospectiveParticipants.length > 0){
        this.validationErrors['phoneNumber'] = 'Invalid phone number'
      }
      this.referralDataService.setError(this.validationErrors);
    }
    });

  }


  filesUploaded(files: any) {
    this.servicesForm.patchValue({
      filesUploaded: files,
    });
  }

  hasFilledParticipantInfo(): boolean {
    return (
      this.filledInfo['prospectiveParticipants'] &&
      this.filledInfo['prospectiveParticipants'].some(
        (participant: any) =>
          participant.firstName ||
          participant.lastName ||
          participant.phoneNum
      )
    );
  }

  async getFileCols() {
    const tempCols = await this.sectionService.getJsonConfigurationData(
      STATIC_JSON_DATA_PATH.FILE_UPLOAD_HEADERS
    );
    this.cols = tempCols.documents.columns;
  }

  hasUploadedFile(): boolean {
    return this.filledInfo['filesUploaded'] && this.filledInfo['filesUploaded'].length > 0;
  }

  hasAnyValue(form: FormGroup): boolean {
    return Object.values(form.controls).some((control) => control.value !== '');
  }

  get prospectiveParticipants() {
    return this.servicesForm.get('prospectiveParticipants') as FormArray;
  }

  createRow(): FormGroup {
    return this.fb.group({
      firstName: [''],
      lastName: [''],
      phoneNum: ['']
    });
  }

  addRow(): void {
    if(this.prospectiveParticipants.value.length > 0){
      this.validatePhoneNumber();
    }
    if (this.prospectiveParticipants.length < 5) {
      this.prospectiveParticipants.push(this.createRow());
    }
  }

  validatePhoneNumber(): any[] {
    const regex = new RegExp(/^[0-9]{10}$/);
    this.prospectiveParticipantsErrors = [];
    this.prospectiveParticipants.value.forEach((itm : any, idx: number) => {
      const phoneNumControl = itm['phoneNum'] !== null ? itm['phoneNum'].trim() : itm['phoneNum'];
    if (phoneNumControl && phoneNumControl.length > 0) {
        if(!regex.test(phoneNumControl)){
          this.prospectiveParticipantsErrors[idx]= {phoneNum: 'Please enter a valid phone number'};
        }
    }
    });
    
    return this.prospectiveParticipantsErrors;
  }

  removeRow(index: number): void {
    if (this.prospectiveParticipants.length > 1) {
      this.prospectiveParticipants.removeAt(index);
    }
  }

  disabledNightHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.night;
  };

  disabledDayHours = (): number[] => {
    return this.dateFormatter.disabledEmergencyHours.day;
  };
}
