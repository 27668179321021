import { Component, Input, SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { LeadDetailsService } from '../../../services/lead-details.service';
import { LoggerService } from '../../../shared/services/logger.service';
import { STATIC_JSON_DATA_PATH } from '../../../shared/constants/static-data.constants';
import { SectionsService } from '../../../services/sections.service';
import { ListTableComponent } from "../../../shared/components/list-table/list-table.component";
import { NgZorroModule } from '../../../shared/modules/ng-zorro/ng-zorro.module';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../../../shared/utils/base.component';
import { ColumnType } from '../../../interfaces/list.interface';

@Component({
  selector: 'ppl-communication-logs',
  standalone: true,
  imports: [ListTableComponent, NgZorroModule, CommonModule],
  templateUrl: './communication-logs.component.html',
  styleUrl: './communication-logs.component.css'
})
export class CommunicationLogsComponent extends BaseComponent implements OnInit, OnChanges  {
  comments: any[]=[];
  showCommunicationBox = false;
  entry: any;
  communicationGridData: any = [];
  programCode !: string;
  cols: ColumnType[] = [];
  copyOfGridData: any = [];
  

  @Input() set sourcePage(data: any) {
    this.entry = data;
  }

  @Input() set program(data: string) {
    this.programCode = data
  }

  @Input() leadId  = 0;
  
  @Input() isCommunicationLogs  = false;

  constructor(private leadDetailsService : LeadDetailsService, private logger : LoggerService, private sectionService : SectionsService){
    super();
  }

  ngOnInit(){

    this.getGridHeaders()

  }


  ngOnChanges(changes: SimpleChanges){
    try{
      if(changes['isCommunicationLogs'] && changes['isCommunicationLogs'].currentValue){
        this.getCommunicationLogs();
      }
    }catch(e){
      this.logger.debug('Error in getting section data and updating latest info', e);
    }
  }



  getCommunicationLogs(){

    
    const entry = this.leadDetailsService.getEntry(this.entry);

    this.leadDetailsService.getCommunicationLog(this.programCode, entry, this.leadId).subscribe(
      (data)=> {
        this.communicationGridData = data?.responsedata;
        this.logger.debug(`Communication`, this.communicationGridData);
        this.copyAndTransformGridData(this.communicationGridData);
      }
    )
    
  }

  

  async getGridHeaders() {
    try {
      if (this.entry) {
        const tempCols = await this.sectionService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_CONTACT_LIST_HEADERS, this.entry
        );
        this.cols = tempCols.communication_logs.columns;
      }
    } catch (e) {
      this.logger.debug('Communication logs - Error in getting column data', e);
    }

  }

  copyAndTransformGridData(gridData: any[]) {
    if (gridData) {
      const copyGridData = JSON.parse(JSON.stringify(gridData));

      this.copyOfGridData = copyGridData.map((item: any) => ({
        ...item,
        cmnctnCreatedDate: super.formatDateString(item.cmnctnCreatedDate),
        cmctnTypeLkp: item.cmctnTypeLkp,
        cmctnSentByName: item.cmctnSentByName,
        cmctnSentTo: item.cmctnSentTo,
        cmctnSentStatus: item.cmctnSentStatus.trim().toUpperCase() === 'SUCCESSFULLY_COMPLETED' ? 'Success' : (item.cmctnSentStatus === 'IN_PROGRESS' ? 'In Progress' : this.formatText(item.cmctnSentStatus)),
        cmctnText: item.cmctnText
       

      }));
      this.copyOfGridData = [...this.copyOfGridData];
      console.log('🔴 copyOfGridData: ', this.copyOfGridData);
    }
    else {
      this.copyOfGridData = [];
    }

  }

  formatText(text: string) {
    const txt = text.replace(/_/g, " ");
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  }
}
