{
  "sectionId": "billablePARates",
  "header": "Billable - Personal Assistant Rates",
  "available_actions": null,
  "attributes": [
    {
      "attribute_ID": "serviceAdminName",
      "fieldName": "serviceAdminName",
      "label": "Service Administration",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select Service Administration",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "mcoId",
      "fieldName": "mcoId",
      "label": "MCO",
      "type": "input",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select MCO",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "rate",
      "fieldName": "rate",
      "label": "Rate",
      "type": "input",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please enter Rate",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "serviceCodeDetailId",
      "fieldName": "serviceCodeDetailId",
      "label": "Service Code",
      "type": "select",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select service code",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "fromDate",
      "fieldName": "fromDate",
      "label": "Start Date",
      "type": "datepicker",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select start date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "toDate",
      "fieldName": "toDate",
      "label": "End Date",
      "type": "datepicker",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select end date",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "union",
      "fieldName": "union",
      "label": "Union",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select union",
      "isAvailable": "N"
    },
    {
      "attribute_ID": "grandFathered",
      "fieldName": "grandFathered",
      "label": "GrandFathered",
      "type": "radio",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select GrandFathered",
      "isAvailable": "N"
    },
    {
      "attribute_ID": "consumerTypeLkp",
      "fieldName": "consumerTypeLkp",
      "label": "Consumer Type",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Consumer Type",
      "isAvailable": "N"
    },
    {
      "attribute_ID": "regionId",
      "fieldName": "regionId",
      "label": "Region",
      "type": "text",
      "mandatory": "Y",
      "validator": null,
      "tooltip": "Please select region",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "county",
      "fieldName": "county",
      "label": "County",
      "type": "text",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select county",
      "isAvailable": "Y"
    },
    {
      "attribute_ID": "modifier",
      "fieldName": "modifier",
      "label": "Modifier",
      "type": "select",
      "mandatory": "N",
      "validator": null,
      "tooltip": "Please select Modifier",
      "isAvailable": "Y"
    }
  ],
  "validations": [
    {
      "whatToCheck": "atLeastXEntered",
      "clientOrServer": "C",
      "attributes": [ {
        "validatorid": 1,
        "attribute_ID": ["regionId"],
        "functionParams": {
          "ErrMsg": "Please select Region"
        }
      },
      {
        "validatorid": 2,
        "attribute_ID": ["rate"],
        "functionParams": {
          "ErrMsg": "Please enter Rate"
        }
      },
      {
        "validatorid": 3,
        "attribute_ID": ["serviceCodeDetailId"],
        "functionParams": {
          "ErrMsg": "Please select Service Code"
        }
      },
      {
        "validatorid": 4,
        "attribute_ID": ["fromDate"],
        "functionParams": {
          "ErrMsg": "Please select Start Date"
        }
      },
      {
        "validatorid": 5,
        "attribute_ID": ["serviceAdminName"],
        "functionParams": {
          "ErrMsg": "Please select Service Administration"
        }
      }]
    },
    {
      "whatToCheck": "checkFieldValue",
      "clientOrServer": "C",
      "attributes": []
    },
    {
      "whatToCheck": "conditionalCheck",
      "clientOrServer": "C",
      "attributes": [
                {
                  "validatorid": 1,
                  "functionParams": {
                    "basecondition": ["serviceAdminName"],
                    "baseconditionValue": ["Health Plan"],
                    "condtionalAttributes": ["mcoId"],
                    "ErrMsg": "Please select MCO"
                  }
                }
      ]
    },
    {
      "whatToCheck": "customValidator",
      "clientOrServer": "C",
      "attributes": []
    }
  ],
  "actions": [
    {
      "Name": "Previous",
      "Validate": null
    },
    {
      "Name": "Next",
      "Validate": [
        {
          "checkType": "atLeastXEntered",
          "validatorid": [1, 2, 3, 4, 5]
        },
        {
          "checkType": "checkFieldValue",
          "validatorid": []
        },
        {
          "checkType": "conditionalCheck",
          "validatorid": [1]
        },
        {
          "checkType": "customValidator",
          "validatorid": []
        }
      ]
    }
  ]
}
