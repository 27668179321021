import { CommonModule } from "@angular/common";
import { Router, RouterModule } from "@angular/router";
import { NgZorroModule } from "../../../../shared/modules/ng-zorro/ng-zorro.module";
import { LeadCommentsComponent } from "../../../lead-details/lead-comments/lead-comments.component";
import { ActionsComponent } from "../../../../shared/components/actions/actions.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { NotificationSMSComponent } from "../../../../shared/modals/notification-sms/notification-sms.component";
import { TimelineComponent } from "../../../../shared/components/timeline/timeline.component";
import { SideDrawerComponent } from "../../../../shared/components/side-drawer/side-drawer.component";
import { AuthorizeDirective } from "../../../../shared/authorization/authorize.directive.";
import { CommunicationLogsComponent } from "../../../lead-details/communication-logs/communication-logs.component";
import { ListService } from "../../../../shared/services/list.service";
import { BaseComponent } from "../../../../shared/utils/base.component";
import { LeadDetailsService } from "../../../../services/lead-details.service";
import { SectionsService } from "../../../../services/sections.service";
import { LookupService } from "../../../../services/lookup.service";
import { LoggerService } from "../../../../shared/services/logger.service";
import { AuthorizationUtility } from "../../../../shared/authorization/auth.utility";
import { AuthorizationService } from "../../../../shared/authorization/authorization.service";
import { environment } from "../../../../../environments/environment";
import { PARTICIPANT_LIMITED_ACCESS_PAGES, STATIC_DATA, STATIC_JSON_DATA_PATH } from "../../../../shared/constants/static-data.constants";
import { MEMBER_VIEW_PAGE_IDS } from "../../../../shared/constants/page.constants";
import { MainMenuItem } from "../../../../interfaces/menu.interface";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { ClaimsService } from "../../../../services/claims.service";
import { PopupDialogComponent } from "../../../../shared/components/popup-dialog/popup-dialog.component";
import { ListTableComponent } from "../../../../shared/components/list-table/list-table.component";


@Component({
  selector: 'ppl-authorization-member-view',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgZorroModule,
    LeadCommentsComponent,
    ActionsComponent,
    ButtonComponent,
    NotificationSMSComponent,
    TimelineComponent,
    SideDrawerComponent,
    AuthorizeDirective,
    CommunicationLogsComponent,
    PopupDialogComponent,
    ListTableComponent
],
  providers: [ListService],
  templateUrl: './authorization-member-view.component.html',
  styleUrl: './authorization-member-view.component.css',
})
export class AuthorizationMemberViewComponent extends BaseComponent implements OnInit, OnDestroy {
  horizontalMenuItemstoDisplay: any;
  selectedMenuItem: any;
  enableComments = false;
  changedCLass = '';
  filledInfo: any;
  id: any;
  applicationData: any[] = [];
  listButton: any[] = [];

  applicationStatusList: any;
  entry: any;
  sectionDataPath: string = STATIC_JSON_DATA_PATH.LEAD_DETAILS_SECTION_DATA;
  program: any;
  pageId = '';
  tabId = '';
  tabsToBeHidden: string[] = [];
  buttonsToBeHidden: string[] = [];
  actionToBeHidden: string[] = [];
  steps: any;
  showSMSModal: any = '';
  notificationType = '';
  mobileNumber: string[] = [];
  emailId: string[] = [];
  isPageRestrictionLoaded = false;
  auditHistory: any;
  backLabel ="Back";
  leadData: any;
  isCommunicationLogs  = false;
  detailPageBaseUrl = '';
  isExternalEnvironment  = false;
  limitedAccess = false;
  authData: any;
  authId: any;
  selectedLead: any;
  show = false;
  splitAuthData: any;
  cols: any = [
    {
      "field": "authDetailId",
      "header": "Authorization ID",
      "jsonpath": "authDetailId",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "startDate",
      "header": "Start Date",
      "jsonpath": "startDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "endDate",
      "header": "End Date",
      "jsonpath": "endDate",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "units",
      "header": "Units",
      "jsonpath": "units",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    },
    {
      "field": "usedUnits",
      "header": "Used Units",
      "jsonpath": "usedUnits",
      "isDisplayedAtHeader": "y",
      "isSearchable": "n"
    }
  ]

  constructor(
    private leadDetailsService: LeadDetailsService,
    private router: Router,
    private sectionsService: SectionsService,
    private lookupService: LookupService,
    private loggerService : LoggerService,
    private authUtility: AuthorizationUtility,
    private listService: ListService,
    private authService: AuthorizationService,
    private claimsService: ClaimsService

  ) {
    super();
  }
  ngOnDestroy(): void {
    this.claimsService.clearAuthData();
    this.sectionsService.setEnrollmentType('');
    localStorage.removeItem('limitedAccess');
    
  }

  ngOnInit(): void {

    this.isExternalEnvironment  = environment.isExternal;

    this.limitedAccess = localStorage.getItem('limitedAccess') as unknown == true ? true : false;

    this.detailPageBaseUrl = this.router.url;

    this.program = JSON.parse(localStorage.getItem('selected_program')!);
    this.entry = localStorage.getItem('lead_entry')!;

    this.id = JSON.parse(localStorage.getItem(STATIC_DATA.SELECTED_LEAD)!);


    this.pageId = MEMBER_VIEW_PAGE_IDS[localStorage.getItem(STATIC_DATA.URL_FIRST_SEGMENT)!];
    this.setPageAccess();
    this.claimsService.openPopUp.subscribe((val: any)=>{
      if(val.name == 'authSplit'){
        this.claimsService.getAuthSpiltData(this.program.program_code, this.authData.authorizationId).subscribe((data: any)=>{
          if(data.status == 200){
            this.splitAuthData = data.responsedata;
            this.show = val.val;
          }
        })
      }
    })
  }

  private loadData() {
    try {
      if (this.program.program_code) {
        this.getMenuItems();
      }

        const payload = this.buildRequestPayload();
        const from = '';
          this.listService.getListData(payload, from).subscribe((data: any) => {
            this.authId = data.responsedata;
            this.authData = data.responsedata[0];
            this.selectedLead = this.authData.participantId;
        if (this.authData) {
        if (this.authData?.statusLkp?.toLowerCase() === 'approved' || this.authData?.statusLkp?.toLowerCase() === 'rejected') {
              this.listButton = [];
            } 
            else {
              this.listButton = [  {
                "button_id":"Approve",
                "button_label":"Approve",
                "buttonAction" : "approve",
                "className": "primary-btn-color"
              },
              {
                "button_id":"Deny",
                "button_label":"Deny",
                "buttonAction" : "Deny",
                "className": "primary-btn-color"
              }];
            }
         this.listButton = this.getAuthorizedActions(this.listButton);
        }
      })
    } catch (e) {
      this.loggerService.info('Error in loading data', e);
    }
  }

  private setPageAccess() {
    const pageConfig = this.authUtility.getPageConfig(this.pageId);
    if(!pageConfig || !pageConfig.pageName) {
      this.authService.fetchPageAuthorization(this.program.program_code, this.pageId, "DETAIL").subscribe(response => {
        this.setRestrictions(response);
      })
    } else {
      this.setRestrictions(pageConfig);
    }
  }

  private setRestrictions(pageConfig: any) {
    if (pageConfig) {
      this.tabsToBeHidden = this.authUtility.getHiddenTabs(pageConfig, this.pageId);
      this.actionToBeHidden = this.authUtility.getHiddenActions(pageConfig, this.pageId);
      this.buttonsToBeHidden = this.authUtility.getHiddenButtons(pageConfig, this.pageId);
      console.log('restricted tabs', this.tabsToBeHidden);
      console.log('restricted actions', this.actionToBeHidden);
      console.log('restricted buttons', this.buttonsToBeHidden);
    }
    this.isPageRestrictionLoaded = true;
    this.loadData();
  }

  getAuthorizedTabs(tabs: any[]) {
    return tabs.filter(c => this.tabsToBeHidden.find(hc => hc.toLowerCase() === c.menu_id.toLowerCase()) === undefined);
  }

  getAuthorizedButtons(buttons: any[]) {
    return buttons.filter(c => this.buttonsToBeHidden.find(hc => hc.toLowerCase() === c.button_id.toLowerCase()) === undefined);
  }

  getAuthorizedActions(actions: any[]) {
    return actions.filter(c => this.actionToBeHidden.find(hc => hc.toLowerCase() === c.button_id.toLowerCase()) === undefined);
  }

  onClose() {
    this.show = false;
    this.splitAuthData = [];
  }

  async getMenuItems() {
    try {
      if (this.entry) {
        const menu = await this.sectionsService.getJsonConfigurationData(
          STATIC_JSON_DATA_PATH.LEAD_DETAILS_MENU,
          this.entry
        );
        this.horizontalMenuItemstoDisplay = this.getAuthorizedTabs(this.filterHorizontalMenuItems(
          menu.horizontal_menu
        ));
      }
    } catch (e) {
      console.log('error fetching menu items: ', e);
    }

    this.selectedMenuItem = this.horizontalMenuItemstoDisplay[0].menu_id;
    localStorage.setItem('horizontal_first_menu', this.selectedMenuItem);
    await this.getSectionData();


    this.router.navigate([
      `${this.detailPageBaseUrl}/${this.horizontalMenuItemstoDisplay[0].landing_component}`,
    ]);

  }

  async getSectionData() {
    try {
      if (this.entry) {
        this.steps = await this.sectionsService.getJsonConfigurationData(
          this.sectionDataPath,
          this.entry
        );
        this.secData = this.steps['participant_info'];
      }
    } catch (e) {
      console.log('error fetching section data: ', e);
    }
  }

  filterHorizontalMenuItems = (menu: MainMenuItem[]) => {
    return menu.filter((menuItem: MainMenuItem) => {
      return this.limitedAccess === true ? menuItem.display == 'y' && PARTICIPANT_LIMITED_ACCESS_PAGES.includes(menuItem.menu_id) : menuItem.display == 'y';
    });
  };

  onMenuSelection = (menuSelected: any) => {
    this.selectedMenuItem = menuSelected.menu_id;

    this.router.navigate([
      `${this.detailPageBaseUrl}/${menuSelected.landing_component}`,
    ]);
    
  };


  getUrlForBackButton() : string {
    // Get the current URL
    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split('/'); // Split the URL by '/' and remove the last segment
    urlSegments.splice(-2); // Remove the last two segment
    const newUrl = urlSegments.join('/'); // Join the remaining segments to form the URL
    console.log(newUrl); // Logs the URL without the last segment
    return newUrl;
  }

  comments() {
    this.enableComments = !this.enableComments;
  }

  getValue(code: string, valueOf: string) {
    let check;
    if (valueOf == 'status') {
      check = this.applicationStatusList;
    } else if (valueOf == 'stage') {
      check = this.applicationStatusList;
    }

    return this.lookupService.getLookupName(check, code);
  }

  // new UI changes

  isTempZActive = false;
  isAuditHistory = false;

  toggleTempZ() {
    this.isTempZActive = !this.isTempZActive; // Toggle the state when the button is clicked
  }

  closeDropdown() {
    this.isTempZActive = false;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event) {
    const target = event.target as HTMLElement;
    if (!target.closest('.dropdown-container')) {
      this.closeDropdown();
    }
  } 
  leadId: string | null = null;

  navigateToLeads() {
    if (environment.isExternal) {
      this.router.navigate([
        `/`,
      ]);
      return;
    }

    this.router.navigate([
      `${this.getUrlForBackButton()}`,
    ]);
    
  } 

  getUpdatedLeadData = (event: boolean) => {
    if (event) {
      this.claimsService.setAuthStatusUpdated();
      this.getAuthDetails(true);
    }
  };

  getLeadHistory() {
    
    const entry = this.leadDetailsService.getEntry(this.entry); // this is a special case where the url path parameter is not the same as the UI entry variable
    this.leadDetailsService.getLeadAuditHistory(this.program.program_code, entry,
      this.id).subscribe(data =>{
        this.auditHistory = data.responsedata;
        this.isAuditHistory = true;
      });
  }

  communicationLogs(){
    this.isCommunicationLogs = true;
  }

  onHandleCancel(event: any) {
    this.showSMSModal = event;
  }

  getAuthDetails(isAfterUpdate = false) {

    const payload = this.buildRequestPayload();
    const from = '';
      this.listService.getListData(payload, from, isAfterUpdate).subscribe((data: any) => {
        console.log('search data', data);
        this.authId = data.responsedata;
        this.authData = data.responsedata[0];
        this.selectedLead = this.authData.participantId;
      });

  }

  buildRequestPayload(event?: any) {
    //const formData = this.authorizationsForm.value;
    const payload: any = {
      pageName: 'consumer-auth',
      programCode: this.program.program_code,
      condition: 'and',
      recordsPerPage: 20,
      startingPageNumber: '1',
      searchParam: [{
        jsonPath: 'authorizationId',
        value:  this.id,
        operator: 'equal'
      }],
      orderByColumn: [],
      orderBy: [],
    }

    return payload;
  }

}
