{
	"columns":[
		
		{
			"header_id": "tracking_number",
			"header_label": "Tracking Number",
			"jsonpath":  "tracking_number" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "lead_provider_id",
			"header_label": "Lead Id",
			"jsonpath":  "lead_provider_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "provider_id",
			"header_label": "Personal Assistant Id",
			"jsonpath":  "provider_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "first_name",
			"header_label": "First Name",
			"jsonpath":  "first_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "middle_name",
			"header_label": "Middle Name",
			"jsonpath":  "middle_name" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "last_name",
			"header_label": "Last Name",
			"jsonpath":  "last_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "date_of_birth",
			"header_label": "Date of Birth",
			"jsonpath":  "date_of_birth" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "ssn",
			"header_label": "SSN",
			"jsonpath":  "ssn" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "maiden_previous",
			"header_label": "Maiden previous",
			"jsonpath":  "maiden_previous" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "final_applicant_disposition",
			"header_label": "Application Disposition",
			"jsonpath":  "final_applicant_disposition" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_source_lkp",
			"header_label": "Application Source",
			"jsonpath":  "application_source_lkp" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "program_code",
			"header_label": "Program Code",
			"jsonpath":  "program_code" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "decision_comments",
			"header_label": "Comments",
			"jsonpath":  "decision_comments" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_status_lkp",
			"header_label": "Application Status",
			"jsonpath":  "application_status_lkp" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "referral_id",
			"header_label": "Referral ID",
			"jsonpath":  "referral_id" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "application_assigned_to_name",
			"header_label": "Assigned To",
			"jsonpath":  "application_assigned_to_name" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "stateOfResidence",
			"header_label": "Residence State",
			"jsonpath":  "stateOfResidence" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "n"
		},
		{
			"header_id": "application_submission_date",
			"header_label": "Submission Date",
			"jsonpath":  "application_submission_date" ,
			"isDisplayedAtHeader": "y",
			"isSearchable": "y"
		},
		{
			"header_id": "providingcontractedservices",
			"header_label": "Providing Contractor Services",
			"jsonpath":  "providingcontractedservices" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "n"
		},
		{
			"header_id": "fiOrgName",
			"header_label": "CDPAP Facilitator",
			"jsonpath":  "fiOrgName" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "cin",
			"header_label": "CIN",
			"jsonpath":  "cin" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "county",
			"header_label": "County",
			"jsonpath":  "county" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "city",
			"header_label": "City",
			"jsonpath":  "city" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "zipCode",
			"header_label": "ZipCode",
			"jsonpath":  "zipCode" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "mobile",
			"header_label": "Mobile",
			"jsonpath":  "mobile" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "altPhone",
			"header_label": "Telephone Number",
			"jsonpath":  "altPhone" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "email",
			"header_label": "Email",
			"jsonpath":  "email" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		},
		{
			"header_id": "languages",
			"header_label": "Languages",
			"jsonpath":  "languages" ,
			"isDisplayedAtHeader": "n",
			"isSearchable": "y"
		}
    ],
	"buttons": [
		{
			"button_id":"assign_to_user",
			"button_label":"Assign to User",
			"buttonAction" : "assign_to_user",
			"className": "primary-btn-color"
		},
		{
			"button_id":"assign_to_me",
			"button_label":"Assign to Me",
			"buttonAction" : "assign_to_me",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Approve",
			"button_label":"Approve",
			"buttonAction" : "approve",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Deny",
			"button_label":"Deny",
			"buttonAction" : "Deny",
			"className": "primary-btn-color"
		},
		{
			"button_id":"Resurrect",
			"button_label":"Resurrect",
			"buttonAction" : "Resurrect",
			"className": "primary-btn-color"
		},
		{
			"button_id":"send_sms",
			"button_label":"Send SMS",
			"buttonAction" : "sendSMS",
			"className": "primary-btn-color"
		},
		{
			"button_id":"send_email",
			"button_label":"Send Email",
			"buttonAction" : "sendEmail",
			"className": "primary-btn-color"
		}
    ],
    "apiDetails": "referral.json",
	"pageName": "referrallist",
	"orderByColumn": ["tracking_number"],
	"orderBy": ["desc"]
}